import { createSvgIcon } from 'shared/icons/IconUtils';

export const PaymentCheckIcon = createSvgIcon(
  'PaymentCheckIcon',
  <path
    d="M1.33301 12.6667V3.33334H14.6663V5.33334H13.333L9.99967 8.66668H3.99967V10H8.66634L5.99967 12.6667H1.33301ZM3.99967 7.33334H7.99967V6.00001H3.99967V7.33334ZM8.66634 14V12.8333L13.0997 8.40001L14.2663 9.56668L9.83301 14H8.66634ZM14.6663 9.16668L13.4997 8.00001L14.0997 7.40001C14.1552 7.34445 14.2163 7.31668 14.283 7.31668C14.3497 7.31668 14.4108 7.34445 14.4663 7.40001L15.2663 8.20001C15.3219 8.25557 15.3497 8.31668 15.3497 8.38334C15.3497 8.45001 15.3219 8.51112 15.2663 8.56668L14.6663 9.16668Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 16' },
);
