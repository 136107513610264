import { Representative } from '@moovio/moov-js';
import { useSnackbarStack } from '@superdispatch/ui';
import { updatedDiff } from 'deep-object-diff';
import { isEmpty, unset } from 'lodash';
import { useAppFormik } from 'shared/form/AppFormik';
import { useEditRepresentative } from 'superpay/data/MoovAPI';
import { MoovRepresentative } from 'superpay/data/MoovDTO';
import { logPaymentInfo } from 'superpay/helpers/PaymentLogger';
import {
  ownerEditFormValidationSchema,
  OwnerEditFormValues,
} from './OwnerEditFormValues';

interface BusinessOwnerFormArgs {
  initialValues?: MoovRepresentative;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function useOwnerEditForm({
  initialValues: initialValuesProp,
  onClose,
  onSubmitSuccess,
}: BusinessOwnerFormArgs) {
  const { addSnackbar } = useSnackbarStack();
  const { mutateAsync: editRepresentative } = useEditRepresentative();

  return useAppFormik({
    initialValues: {
      ...initialValuesProp,
      phone: initialValuesProp?.phone.number,
    },
    validationSchema: ownerEditFormValidationSchema,
    onSubmit: (data) => {
      if (!initialValuesProp) {
        return Promise.reject(
          new Error('Please try again or reload the page.'),
        );
      }
      const { representativeID } = initialValuesProp;
      const normalizedValues = normalizePayload(data);

      const updatedData = getUpdatedData(initialValuesProp, normalizedValues);

      return isEmpty(updatedData)
        ? Promise.resolve(null)
        : editRepresentative({ representativeID, data: updatedData });
    },
    onSubmitSuccess: () => {
      addSnackbar('Owner updated', { variant: 'success' });
      onSubmitSuccess();
      logPaymentInfo('Edited Representative', 'EditRepresentativeForm');
      onClose();
    },
  });
}

function normalizePayload(data: OwnerEditFormValues) {
  const { phone } = data;
  return {
    ...data,
    phone: {
      countryCode: '1',
      number: phone,
    },
  } as Representative;
}

function getUpdatedData(
  initialValues: MoovRepresentative,
  newValues: Representative,
) {
  const values = { ...newValues };
  const {
    governmentID: { ssn },
    birthDate: { day, month, year },
  } = values;

  if (!ssn?.full) {
    unset(values, 'governmentID');
  }

  if (!day && !month && !year) {
    unset(values, 'birthDate');
  }
  const dataDiff: Partial<Representative> = updatedDiff(
    {
      ...initialValues,
      //Initial values don't have these fields, instead have birthDateProvided and governmentIDProvided boolean fields,
      //so manually setting their values to null
      birthDate: { day: null, month: null, year: null },
      governmentID: null,
    },
    values,
  );

  return dataDiff;
}
