import { ColorDynamic } from '@superdispatch/ui';
import styled from 'styled-components';

interface ListProps {
  color?: string;
}

export const List = styled.ul<ListProps>`
  margin: 0;
  padding-left: 20px;
  & > li {
    color: ${({ color }) => color ?? ColorDynamic.Dark300};
  }
`;
