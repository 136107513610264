import { useFormikEnhanced } from '@superdispatch/forms';
import { useDeepEqualMemo } from '@superdispatch/hooks';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Form } from 'formik';
import { camelCaseFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { InsuranceForm } from '../core/InsuranceForm';
import { VerificationFormikProvider } from '../core/VerificationCloseConfirmation';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import {
  useVerificationApplication,
  useVerificationApplicationAPI,
} from '../data/VerificationApplicationAPI';
import {
  VerificationInsuranceDTO,
  verificationInsuranceSchema,
} from '../data/VerificationApplicationDTO';
import {
  VerificationApplicationStepsFormBox,
  VerificationApplicationStepsWrapper,
} from './VerificationApplicationLayout';
import { VerificationStepper } from './VerificationStepper';

type VerificationInsuranceForm = Omit<
  VerificationInsuranceDTO,
  'insurance_certificate_url' | 'company_insurance_type'
> & {
  insurance_certificate_url: string | File | null;
  company_insurance_type:
    | VerificationInsuranceDTO['company_insurance_type']
    | null;
};

interface VerificationApplicationInsuranceProps {
  onSubmitSuccess: () => void;
  onGoBack: () => void;
}

export function VerificationApplicationInsurance({
  onSubmitSuccess,
  onGoBack,
}: VerificationApplicationInsuranceProps) {
  const { addSnackbar } = useSnackbarStack();

  const { data, isLoading } = useVerificationApplication({
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
  const { updateInsurance, uploadFile } = useVerificationApplicationAPI();

  const initialValues = useDeepEqualMemo(() => {
    //if user have not selected insurance type, set default insurance type based on company type
    if (data && data.company_insurance_type == null) {
      return verificationInsuranceSchema.cast({
        ...data,
        company_insurance_type:
          data.company_type === 'BROKER' ? 'BOND' : 'INSURANCE',
      });
    }

    return verificationInsuranceSchema.cast(data);
  }, [data]);

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: verificationInsuranceSchema,
    getFormErrors: camelCaseFormErrorsGetter,
    async onSubmit(values: VerificationInsuranceForm) {
      if (values.insurance_certificate_url instanceof File) {
        values.insurance_certificate_url = await uploadFile(
          values.insurance_certificate_url,
        );
      }

      return updateInsurance(values as VerificationInsuranceDTO);
    },
    onSubmitSuccess,
    onSubmitFailure() {
      addSnackbar(
        'Please ensure all required fields are filled out correctly',
        { variant: 'error' },
      );
    },
  });

  return (
    <VerificationFormikProvider value={formik}>
      <VerificationApplicationStepsWrapper
        step={4}
        onGoBack={() => {
          onGoBack();
          trackVerificationApplicationEvent({
            name: 'Shipper Clicked Back',
            utm_content: 'Insurance',
          });
        }}
      >
        <Stack space="small">
          <VerificationStepper step={4} />
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <Form>
              <VerificationApplicationStepsFormBox>
                <InsuranceForm
                  showInsuranceLearnMoreLink={true}
                  isBroker={
                    data?.company_type === 'BROKER' &&
                    data.company_insurance_type == null
                  }
                />
              </VerificationApplicationStepsFormBox>
            </Form>
          )}
        </Stack>
      </VerificationApplicationStepsWrapper>
    </VerificationFormikProvider>
  );
}
