import { useUserState } from 'shared/data/AppUserState';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { getUserRoleNamesString } from 'shared/helpers/UserHelpers';
import { EditBillingAddressDrawerContent } from './EditBillingAddressDrawerContent';
import { EditDrawer } from './EditDrawer';

interface BillingAddressEditDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function EditBillingAddressDrawer({
  open,
  onClose,
}: BillingAddressEditDrawerProps) {
  const { user } = useUserState();

  const shipper = user?.shipper;
  const shipperType = shipper?.shipper_type;

  const userRole = getUserRoleNamesString(user);

  return (
    <EditDrawer open={open} onClose={onClose} title="Edit Billing Address">
      <EditBillingAddressDrawerContent
        onSubmitSuccess={() => {
          onClose();
          trackEvent('Shipper Edited Billing Address', {
            utm_medium: 'Shipper Edited Billing Address',
            utm_source: 'Subscription Details',
            user_roles: userRole,
            shipper_type: shipperType,
          });
        }}
      />
    </EditDrawer>
  );
}
