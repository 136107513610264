import { Typography, TypographyProps } from '@material-ui/core';
import { round } from 'lodash';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import { kmToMile } from 'shared/utils/MeasurementUtils';

function calculatePricePerMile(price: number, order: Order) {
  const distanceInMeters = order.distance_meters;
  const vehicleCount = order.vehicles?.length || 1;

  if (!distanceInMeters) {
    return 0;
  }

  const distanceInMiles = kmToMile(distanceInMeters / 1000);
  return round(price / distanceInMiles / vehicleCount, 2);
}

interface OrderPricePerMileProps {
  price?: number;
  order: Order;
  TypographyProps?: TypographyProps;
}

export function PricePerMile({
  order,
  price = 0,
  TypographyProps: TypographyPropsOption,
}: OrderPricePerMileProps) {
  const nextPricePerMile = calculatePricePerMile(price, order);

  return (
    <Typography
      aria-label="price per mile"
      color="textSecondary"
      noWrap={true}
      {...TypographyPropsOption}
    >
      {formatCurrency(nextPricePerMile, { minimumFractionDigits: 0 })}/mi
    </Typography>
  );
}

interface OrderPricePerMileProps {
  order: Order;
  TypographyProps?: TypographyProps;
}

export function OrderPricePerMile({
  order,
  TypographyProps: TypographyPropsOption,
}: OrderPricePerMileProps) {
  return (
    <PricePerMile
      order={order}
      price={order.price}
      TypographyProps={TypographyPropsOption}
    />
  );
}
