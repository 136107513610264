import {
  Box,
  Chip,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  formatPaymentMethod,
  formatPaymentTerm,
  PaymentMethod,
} from '@superdispatch/sdk';
import { DrawerActions, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { usePaymentTermUtils } from 'core/PaymentUtils';
import { Form, FormikProvider } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { FormikPaymentMethodField } from 'shared/form/FormikPaymentMethodField';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { useQuery } from 'shared/helpers/RouteHelpers';
import {
  SelectSuperPayHelperText,
  useSuperPayEnabled,
  useSuperPaySuspend,
} from 'shared/helpers/superpay/SuperPayHelpers';
import { ShipperProfileDTO } from 'shipper-profile/data/ShipperProfileDTO';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';

interface PaymentInfoEditProps {
  open: boolean;
  profile: ShipperProfileDTO;
  onClose: () => void;
  onUpdate: () => void;
}
const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    margin: '2px',
  },
});

export function PaymentInfoEditDrawer({
  profile,
  open,
  onClose,
  onUpdate,
}: PaymentInfoEditProps) {
  return (
    <FormikDrawer open={open} onClose={onClose}>
      <PaymentInfoEditDrawerContent
        profile={profile}
        open={open}
        onClose={onClose}
        onUpdate={onUpdate}
      />
    </FormikDrawer>
  );
}

export function PaymentInfoEditDrawerContent({
  profile,
  open,
  onUpdate,
}: PaymentInfoEditProps) {
  const styles = useStyles();
  const { addSnackbar } = useSnackbarStack();
  const { updatePaymentInfo } = useShipperProfileAPI();
  const [{ source = 'profile' }] = useQuery();
  const isSuperPaySuspend = useSuperPaySuspend();
  const isSuperPayEnabled = useSuperPayEnabled();
  const isPaymentMethodTermsAdoptionEnabled = useFeatureToggle(
    'payment_method_terms_adoption.enabled.ui',
  );

  useEffect(() => {
    if (open) trackEventLegacy('Opened Payment Info Edit Drawer', { source });
  }, [source, open]);

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updatePaymentInfo(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      addSnackbar('Profile updated successfully', {
        variant: 'success',
      });
      trackUpdatedProfileValues('Payment Info', {
        billing_contact_name: updatedValues.billing_contact_name,
        billing_email: updatedValues.billing_email,
        billing_phone: updatedValues.billing_phone,
        preferred_payment_methods: updatedValues.preferred_payment_methods,
        alternative_payment_methods: updatedValues.alternative_payment_methods,
        default_payment_terms: updatedValues.default_payment_terms,
        default_payment_method: updatedValues.default_payment_method,
      });
      onUpdate();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const { isSubmitting, values, handleSubmit, setFieldValue } = formik;

  const { getPaymentTerms, getValidPaymentTerm } = usePaymentTermUtils();
  const paymentTerms = getPaymentTerms(
    values.default_payment_method,
    values.default_payment_terms,
    isPaymentMethodTermsAdoptionEnabled,
  );

  const onDelete = (key: string, value: string) => {
    setFieldValue(
      key,
      values[key].filter((item: string) => item !== value),
    );
  };

  const onPaymentMethodChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    void setFieldValue(
      'default_payment_terms',
      getValidPaymentTerm(value as PaymentMethod, values.default_payment_terms),
    );
  };

  // eslint-disable-next-line react/display-name
  const renderValue = (key: string) => (options: unknown) =>
    (
      <div className={styles.list}>
        {(options as string[]).map((value, index) => (
          <Chip
            key={index}
            className={styles.item}
            label={formatPaymentMethod(value as PaymentMethod)}
            onDelete={() => onDelete(key, value)}
            onMouseDown={(event) => event.stopPropagation()}
          />
        ))}
      </div>
    );

  return (
    <FormikDrawerContent
      title="Payment Info"
      formik={formik}
      DrawerContentProps={{ style: { width: '432px' } }}
      actions={
        <DrawerActions>
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            Save
          </Button>
        </DrawerActions>
      }
    >
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack space="small">
            <Typography variant="h4">Billing</Typography>
            <FormikTextField
              name="billing_contact_name"
              label="Name"
              fullWidth={true}
            />
            <FormikTextField
              name="billing_email"
              label="Email"
              type="email"
              fullWidth={true}
              helperText="Shown on Customer Invoices and Dispatch Sheets."
            />
            <SuspendedFormikPhoneField
              name="billing_phone"
              label="Phone"
              fullWidth={true}
            />
            <Box mt={2}>
              <Typography variant="h4">
                Default Payment Method and Terms
              </Typography>
              <Typography color="textSecondary">
                Set default payment type for new orders.
              </Typography>
            </Box>
            <FormikPaymentMethodField
              name="default_payment_method"
              label="Payment Method"
              fullWidth={true}
              onChange={onPaymentMethodChange}
              excludeOther={isPaymentMethodTermsAdoptionEnabled}
              helperText={
                values.default_payment_method !== 'superpay' &&
                !isSuperPaySuspend &&
                isSuperPayEnabled && (
                  <SelectSuperPayHelperText
                    source="Shipper Profile"
                    onClick={() => {
                      setFieldValue('default_payment_method', 'superpay');
                      setFieldValue('default_payment_terms', '1_3_days');
                    }}
                  />
                )
              }
            />

            {isPaymentMethodTermsAdoptionEnabled &&
            !values.default_payment_method &&
            !values.default_payment_terms ? (
              <TextField
                label="Terms"
                fullWidth={true}
                select={true}
                disabled={true}
                value="empty"
              >
                <MenuItem value="empty">
                  First, select a payment method.
                </MenuItem>
              </TextField>
            ) : (
              <FormikTextField
                name="default_payment_terms"
                label="Terms"
                fullWidth={true}
                select={true}
                validate={(value) => {
                  if (
                    isPaymentMethodTermsAdoptionEnabled &&
                    !value &&
                    !!values.default_payment_method
                  ) {
                    return 'Payment terms are required';
                  }

                  return undefined;
                }}
              >
                {paymentTerms.map((value) => (
                  <MenuItem key={value} value={value}>
                    {formatPaymentTerm(value)}
                  </MenuItem>
                ))}
              </FormikTextField>
            )}

            <Box mt={2}>
              <Typography variant="h4">Additional Payment Methods</Typography>
              <Typography color="textSecondary">
                Display additional payment options on your profile.
              </Typography>
            </Box>

            <FormikPaymentMethodField
              name="preferred_payment_methods"
              label="Preferred"
              fullWidth={true}
              format={(x) => x} // TODO fix this issue on superdispatch/ui
              SelectProps={{
                multiple: true,
                renderValue: renderValue('preferred_payment_methods'),
              }}
            />
            <FormikPaymentMethodField
              name="alternative_payment_methods"
              label="Alternative"
              fullWidth={true}
              format={(x) => x}
              SelectProps={{
                multiple: true,
                renderValue: renderValue('alternative_payment_methods'),
              }}
            />
          </Stack>
        </Form>
      </FormikProvider>
    </FormikDrawerContent>
  );
}
