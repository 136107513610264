import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { List } from 'shared/ui/List';
import styled from 'styled-components';
import limitedExperience from '../../assets/limited_experience.png';

const Illustration = styled.img`
  width: 100%;
`;

const CustomDialogTitle = styled(DialogTitle)`
  padding: 16px;
`;

const CustomDialogContent = styled(DialogContent)`
  padding: 0 16px;
`;

const CenteredDialogActions = styled(DialogActions)`
  justify-content: center;
  padding: 16px 16px 24px;
`;

interface LimitedExperienceDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  source: 'Send Offer' | 'Carrier Request';
}

export function LimitedExperienceDialog({
  isOpen,
  onCancel,
  onConfirm,
  source,
}: LimitedExperienceDialogProps) {
  useEffect(() => {
    if (isOpen) {
      trackEvent('Shipper Opened Limited Experience Dialog', {
        utm_medium: source,
      });
    }
  }, [isOpen, source]);

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth={true}>
      <CustomDialogTitle>
        <Stack space="small">
          <Illustration
            src={limitedExperience}
            alt="Limited expirience carrier warning"
          />
          <Typography variant="h3">Warning</Typography>
        </Stack>
      </CustomDialogTitle>
      <CustomDialogContent>
        <Stack space="small">
          <Typography>
            You are dispatching a load offer to a Verified Carrier who has
            limited experience transporting vehicles and/or using Super
            Dispatch. This carrier meets one or more of the following criteria:
          </Typography>
          <List>
            <li>
              <Typography variant="body1" color="textPrimary">
                Less than 90 days FMCSA registration
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="textPrimary">
                Less than 3 ratings on Super Dispatch
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="textPrimary">
                Less than 5 delivered loads on Super Dispatch
              </Typography>
            </li>
          </List>
          <Typography>
            These factors suggest a higher level of uncertainty and may impact
            reliability. By proceeding, you acknowledge and accept these
            potential risks.
          </Typography>
        </Stack>
      </CustomDialogContent>

      <CenteredDialogActions>
        <Inline space="small" horizontalAlign="center">
          <Button
            variant="critical"
            onClick={() => {
              trackEvent(
                'Shipper Clicked I Understand the Risks In Limited Experience Dialog',
                {
                  utm_medium: source,
                },
              );
              onConfirm();
            }}
          >
            I Understand the Risks
          </Button>
          <Button
            variant="neutral"
            onClick={() => {
              trackEvent(
                'Shipper Clicked Cancel In Limited Experience Dialog',
                {
                  utm_medium: source,
                },
              );
              onCancel();
            }}
          >
            Cancel
          </Button>
        </Inline>
      </CenteredDialogActions>
    </Dialog>
  );
}
