import { Tab, Tabs } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import {
  Box,
  Sidebar,
  SidebarContainer,
  SidebarMenuItem,
} from '@superdispatch/ui-lab';
import { GeneralSettingsPage } from 'general-settings/GeneralSettingsPage';
import { SuperLoadboardSettingsPage } from 'loadboard-settings/SuperLoadboardSettingsPage';
import { ManageFieldsPage } from 'manage-fields/ManageFieldsPage';
import { ManageUsersPage } from 'manage-users/ManageUsersPage';
import { NotificationSettingsPage } from 'notification-settings/NotificationSettingsPage';
import {
  matchPath,
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { superPayRoute } from 'superpay/SuperPayContainer';

export const settingsRoute: RouteObject = {
  path: 'settings',
  Component: SettingsPage,
  children: [
    {
      path: 'general',
      Component: GeneralSettingsPage,
    },
    superPayRoute,
    {
      path: 'super-loadboard',
      Component: SuperLoadboardSettingsPage,
    },
    {
      path: 'users/*',
      Component: () => {
        const { isAdmin, isSuperUser } = useUserState();
        const canManageUsers = isAdmin || isSuperUser;

        if (!canManageUsers) {
          return <Navigate to="" replace={true} />;
        }

        return <ManageUsersPage />;
      },
    },
    {
      path: 'manage-fields',
      handle: { isProtected: true },
      Component: ManageFieldsPage,
    },
    {
      path: 'notifications',
      Component: NotificationSettingsPage,
    },
    {
      path: '*?',
      element: <Navigate to="general" />,
    },
  ],
};

function SettingsPage() {
  const { user } = useUserState();
  const isShipperTypeBroker = user?.shipper.shipper_type === 'BROKER';

  const isMobile =
    useResponsiveValue('mobile', 'tablet', 'desktop') === 'mobile';

  if (!isShipperTypeBroker) {
    return <Navigate to="/" replace={true} />;
  }

  if (isMobile) {
    return (
      <>
        <MobileTabsNavigation />
        <Outlet />
      </>
    );
  }

  return (
    <SidebarContainer sidebar={<DesktopSidebarNavigation />}>
      <Outlet />
    </SidebarContainer>
  );
}

function DesktopSidebarNavigation() {
  const navigationItems = useSettingsNavigationItems();

  return (
    <Sidebar title="Settings">
      {navigationItems.map((item) => (
        <SidebarMenuItem
          key={item.to}
          selected={item.isActive}
          onClick={item.navigate}
        >
          {item.label}
        </SidebarMenuItem>
      ))}
    </Sidebar>
  );
}

function MobileTabsNavigation() {
  const navigationItems = useSettingsNavigationItems();
  const selectedIndex = navigationItems.findIndex((item) => item.isActive);

  return (
    <Box backgroundColor="White">
      <Tabs scrollButtons="auto" value={selectedIndex}>
        {navigationItems.map((item) => (
          <Tab key={item.to} label={item.label} onClick={item.navigate} />
        ))}
      </Tabs>
    </Box>
  );
}

function useSettingsNavigationItems() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isAdmin, isSuperUser } = useUserState();

  const canManageUsers = isAdmin || isSuperUser;

  const { isAdvancedTier } = useProductTiers();

  function createNavigationItem(label: string, to: string) {
    return {
      label,
      to,
      isActive: !!matchPath(`/settings/${to}/*`, location.pathname),
      navigate: () => navigate(to),
    };
  }

  return [
    createNavigationItem('General', 'general'),
    createNavigationItem('SuperPay', 'superpay'),
    createNavigationItem('Super Loadboard', 'super-loadboard'),
    ...(canManageUsers ? [createNavigationItem('Manage Users', 'users')] : []),
    ...(isAdvancedTier
      ? [createNavigationItem('Manage Order Fields', 'manage-fields')]
      : []),
    createNavigationItem('Notifications', 'notifications'),
  ];
}
