import { Drawer, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider, useFormikContext } from 'formik';
import { useOrderPaymentFlags } from 'orders/data/OrderPaymentFlagsAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useQuery } from 'shared/helpers/RouteHelpers';
import Order from 'shared/types/order';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { OrderFormValues } from '../../orders/core/form/OrderForm';
import { SuperPayEditDrawerResult } from './SuperPayEditDrawerResult';
import {
  SuperPayEditableVehicle,
  vehiclePrices,
  VehiclesTable,
} from './superPayEditDrawerVehicle';
import { useSuperPayEditDrawerForm } from './useSuperPayEditDrawerForm';

interface SuperPayDrawerContentProps {
  order: OrderFormValues | Order;
  onClose: () => void;
}

function SuperPayDrawerContent({ order, onClose }: SuperPayDrawerContentProps) {
  const {
    values: { editableVehicles },
    initialValues: { editableVehicles: initialEditableVehicles },
    isSubmitting,
  } = useFormikContext<{ editableVehicles: SuperPayEditableVehicle[] }>();
  const { vehicles, payment, id, price: orderPrice } = order;

  const orderPaymentFlags = useOrderPaymentFlags(id);
  const canReviseVehiclePrice = !!orderPaymentFlags?.can_revise_price;
  const canIncreasePrice = !!orderPaymentFlags?.can_increase_price;
  const expeditedPayFeeAmount =
    payment?.super_pay?.expedited_pay_fee_amount || 0;
  const superPayChargedAmount = payment?.super_pay?.amount || 0;
  const superPayStatus = payment?.super_pay?.status;
  const hasVehicles = !!vehicles?.length;

  const { totalCarrierPrice, totalCarrierPriceMinusFee } = vehiclePrices({
    editableVehicles,
    expeditedPayFeeAmount,
    initialEditableVehicles,
    orderPrice,
  });

  const isTotalCarrierPriceNotBeZero =
    totalCarrierPriceMinusFee === 0 &&
    (superPayStatus === 'CREATED' || superPayStatus === 'CHARGE_SCHEDULED');

  const refundAmount = useMemo(() => {
    if (superPayChargedAmount === totalCarrierPriceMinusFee) {
      return superPayChargedAmount;
    }

    if (superPayChargedAmount > totalCarrierPriceMinusFee) {
      return superPayChargedAmount - totalCarrierPriceMinusFee;
    }

    return 0;
  }, [superPayChargedAmount, totalCarrierPriceMinusFee]);

  return (
    <>
      <DrawerContent>
        {hasVehicles ? (
          <Stack space="large">
            <Stack space={['small', 'xsmall']}>
              <VehiclesTable
                isDisabled={!canReviseVehiclePrice}
                editableVehicles={editableVehicles}
              />
              <SuperPayEditDrawerResult
                refundAmount={refundAmount}
                totalCarrierPrice={totalCarrierPrice}
                totalCarrierPriceMinusFee={totalCarrierPriceMinusFee}
                expeditedPayFeeAmount={expeditedPayFeeAmount}
                superPayChargedAmount={superPayChargedAmount}
                isTotalCarrierPriceNotBeZero={isTotalCarrierPriceNotBeZero}
                canIncreasePrice={canIncreasePrice}
                order={order}
                onClose={onClose}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack align="center">
            <Typography color="textSecondary">No data</Typography>
          </Stack>
        )}
      </DrawerContent>
      {hasVehicles && (
        <DrawerActions>
          <Button
            type="submit"
            pending={isSubmitting}
            disabled={
              (!refundAmount && !canIncreasePrice) ||
              isTotalCarrierPriceNotBeZero
            }
          >
            Save
          </Button>
        </DrawerActions>
      )}
    </>
  );
}

interface SuperPayEditDrawerProps {
  order: OrderFormValues | Order;
  onClose: () => void;
  open: boolean;
  onSuccess: () => void;
}

export function SuperPayEditDrawer({
  open,
  order,
  onClose,
  onSuccess,
}: SuperPayEditDrawerProps) {
  const [query] = useQuery();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const formik = useSuperPayEditDrawerForm({
    order,
    onClose,
    onSuccess,
  });
  const { resetForm, isSubmitting, dirty, status } = formik;

  const handleDrawerClose = useCallback(() => {
    if (isSubmitting) {
      return;
    }

    if (dirty && status.type !== 'submitted') {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  }, [isSubmitting, dirty, status, onClose]);

  const discardChanges = () => {
    setShowConfirmation(false);
    resetForm();
    onClose();
  };

  useEffect(() => {
    trackEvent('Shipper Opened Edit SuperPay Drawer', {
      utm_medium: query.utm_medium ?? 'Order View',
    });
  }, [query]);

  return (
    <>
      <Drawer open={open} onClose={handleDrawerClose}>
        <Box width={['initial', '448px']}>
          <FormikProvider value={formik}>
            <DrawerTitle
              title="Edit SuperPay"
              endAction={
                <IconButton
                  edge="end"
                  onClick={handleDrawerClose}
                  disabled={isSubmitting}
                >
                  <Close />
                </IconButton>
              }
            />
            <Form aria-labelledby="superpay edit drawer form">
              <SuperPayDrawerContent order={order} onClose={onClose} />
            </Form>
          </FormikProvider>
        </Box>
      </Drawer>
      <ConfirmDialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        title="Discard unsaved changes?"
        cancelButtonProps={{ children: 'Cancel' }}
        confirmButtonProps={{
          onClick: discardChanges,
          children: 'Discard',
        }}
      >
        Changes have not been saved. Leaving will result in unsaved changes
        being lost.
      </ConfirmDialog>
    </>
  );
}
