import { useOrderAPI } from 'orders/data/OrderAPI';
import { useCallback } from 'react';
import { VehicleDTO, VehiclePhotoDTO } from 'shared/dto/Order/VehicleDTO';
import { Attachment, AttachmentWithFile } from 'shared/types/attachment';
import Order from 'shared/types/order';

export function useOrderAttachmentsUpload() {
  const { createOrderAttachment, editOrderAttachment, removeOrderAttachment } =
    useOrderAPI();

  const attachmentsCreateUpload = useCallback(
    (attachments: AttachmentWithFile[], order: Order) => {
      return Promise.all(
        attachments.map((attachment: AttachmentWithFile) => {
          if (attachment.file instanceof File) {
            return createOrderAttachment(
              order.id,
              attachment.file,
              attachment.is_shared_with_carrier,
              attachment.is_shared_with_customer,
            );
          }

          return undefined;
        }),
      );
    },
    [createOrderAttachment],
  );

  const attachmentsEditUpload = useCallback(
    async (
      prevAttachments: Attachment[],
      nextAttachments: Attachment[],
      order: Order,
    ) => {
      const editedAttachments = nextAttachments.filter(
        (attachment: AttachmentWithFile) => attachment.changed && attachment.id,
      );

      if (editedAttachments.length > 0) {
        await Promise.all(
          editedAttachments.map((attachment: AttachmentWithFile) => {
            return editOrderAttachment(
              order.id,
              {
                is_shared_with_carrier: !!attachment.is_shared_with_carrier,
                is_shared_with_customer: !!attachment.is_shared_with_customer,
              },
              attachment.id,
            );
          }),
        );
      }

      await Promise.all(
        prevAttachments.map((attachment) => {
          if (!nextAttachments.find((x) => x.id === attachment.id)) {
            return removeOrderAttachment(order.id, attachment.id);
          }

          return undefined;
        }),
      );

      await Promise.all(
        nextAttachments.map((attachment: AttachmentWithFile) => {
          if (attachment.file instanceof File) {
            return createOrderAttachment(
              order.id,
              attachment.file,
              attachment.is_shared_with_carrier,
              attachment.is_shared_with_customer,
            );
          }

          return undefined;
        }),
      );
    },
    [createOrderAttachment, editOrderAttachment, removeOrderAttachment],
  );

  return { attachmentsCreateUpload, attachmentsEditUpload };
}

export function useOrderVehiclePhotosUpload() {
  const { uploadOrderVehiclePhoto, removeOrderVehiclePhoto } = useOrderAPI();

  const vehiclesCreateUpload = useCallback(
    (vehicles: VehicleDTO[], order: Order) => {
      return Promise.all(
        vehicles.map((vehicle, index) => {
          const createdVehicle = order.vehicles?.[index];

          const photos = vehicle.photos?.filter(
            (x) => x.photo_file instanceof File,
          );

          if (createdVehicle && photos) {
            return Promise.all(
              photos.map((value) => {
                if (value.photo_file instanceof File) {
                  return uploadOrderVehiclePhoto(
                    order.id,
                    createdVehicle.id,
                    value.photo_file,
                  );
                }
                return undefined;
              }),
            );
          }
          return undefined;
        }),
      );
    },
    [uploadOrderVehiclePhoto],
  );

  const vehiclesEditUpload = useCallback(
    (prevVehicles: VehicleDTO[], nextVehicles: VehicleDTO[], order: Order) => {
      return Promise.all(
        nextVehicles.map(async (nextVehicle, nextVehicleIndex) => {
          let photosToUpload: VehiclePhotoDTO[] = [];
          const vehicle = nextVehicle.id
            ? nextVehicle
            : order.vehicles?.[nextVehicleIndex];

          // new vehicle: no id
          if (!nextVehicle.id) {
            photosToUpload = nextVehicle.photos || [];
          } else {
            const prevVehicle = prevVehicles.find(
              (v) => v.id === nextVehicle.id,
            );
            if (prevVehicle) {
              const prevPhotos = prevVehicle.photos || [];
              const nextPhotos = nextVehicle.photos || [];

              // user has add photo and remove all photos options only
              // if any photo is missing, it means user removed all previous photos
              const hasRemovedPhotos = prevPhotos.some(
                (prevPhoto) =>
                  !nextPhotos.some(
                    (nextPhoto) => nextPhoto.id === prevPhoto.id,
                  ),
              );

              if (hasRemovedPhotos) {
                await removeOrderVehiclePhoto(order.id, nextVehicle.id);
              }

              photosToUpload = hasRemovedPhotos
                ? nextPhotos
                : nextPhotos.filter(
                    (photo) =>
                      !prevPhotos.some(
                        (prevPhoto) => prevPhoto.id === photo.id,
                      ),
                  );
            }
          }

          if (vehicle?.id && photosToUpload.length > 0) {
            return Promise.all(
              photosToUpload
                .filter((photo) => photo.photo_file instanceof File)
                .map((photo) =>
                  uploadOrderVehiclePhoto(
                    order.id,
                    vehicle.id,
                    photo.photo_file as File,
                  ),
                ),
            );
          }
          return undefined;
        }),
      );
    },
    [removeOrderVehiclePhoto, uploadOrderVehiclePhoto],
  );

  return { vehiclesCreateUpload, vehiclesEditUpload };
}
