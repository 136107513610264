import { formatVehicleType } from '@superdispatch/sdk';
import { Column, Columns, OverflowText, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { joinStrings } from 'shared/utils/StringUtils';
import { PricingInsightsRecentLoadVehicleDTO } from '../../data/dto/PricingInsightsRecentPostings';
import { VehicleConditionIcon } from '../pricing-insights/VehicleConditionIcon';

export function formatVehicleTitle({
  year,
  make,
  model,
}: PricingInsightsRecentLoadVehicleDTO | VehicleDTO) {
  return joinStrings(' ', year, make, model);
}

interface PostingVehicleProps {
  vehicle: PricingInsightsRecentLoadVehicleDTO | VehicleDTO;
}

export const PostingVehicle = ({
  vehicle,
  vehicle: { type, is_inoperable, requires_enclosed_trailer },
}: PostingVehicleProps) => {
  const title = formatVehicleTitle(vehicle);

  return (
    <Stack space="none">
      <Columns space="xxsmall" align="center">
        <Column width="adaptive">
          <Box maxWidth="200px">
            <OverflowText aria-label="Vehicle title" disableUnderline={true}>
              {title}
            </OverflowText>
          </Box>
        </Column>
        <Column width="content">
          <VehicleConditionIcon
            requiresEnclosedTrailer={!!requires_enclosed_trailer}
            isInoperable={!!is_inoperable}
          />
        </Column>
      </Columns>
      {type && (
        <TextBox variant="body-semibold" aria-label="Vehicle type">
          {formatVehicleType(type.toLowerCase())}
        </TextBox>
      )}
    </Stack>
  );
};
