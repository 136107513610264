import { Popper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormikTextField } from '@superdispatch/forms';
import { useField } from 'formik';
import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo } from 'react';
import styled from 'styled-components';

interface VehicleYearAutocompleteProps {
  name: string;
  disabled?: boolean;
}

const StyledTextField = styled(FormikTextField)`
  max-width: 90px;
  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 30px;
  }
`;

const StyledPopper = styled(Popper)`
  & .MuiAutocomplete-paper {
    border: none;
  }
`;

const currentYear = new Date().getFullYear();
const MIN_YEAR = 1886;
const MAX_YEAR = currentYear + 2;
const yearOptions: string[] = [
  currentYear + 1,
  currentYear,
  currentYear - 1,
  currentYear - 2,
  currentYear - 3,
].map((year: number) => year.toString());

export function VehicleYearAutocomplete({
  name,
  disabled,
}: VehicleYearAutocompleteProps) {
  const [options, setOptions] = React.useState<string[]>(yearOptions);
  const [field, _, { setValue }] = useField(name);
  const inputValue = useMemo(() => field.value || '', [field.value]);

  useEffect(() => {
    setOptions(yearOptions.filter((option) => option.startsWith(inputValue)));
  }, [inputValue]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (_e: ChangeEvent<{}>, newValue: string | null) => {
    if (newValue) {
      void setValue(newValue);
    }
  };

  const handleInputChange = (
    _e: ChangeEvent<{}>,
    newInputValue: string,
    reason: string,
  ) => {
    if (newInputValue || reason === 'clear') {
      void setValue(newInputValue);
    }
  };

  const validateYear = (value: string) => {
    if (!value) return undefined;

    const yearNum = Number(value);
    return yearNum < MIN_YEAR || yearNum > MAX_YEAR
      ? 'Invalid year'
      : undefined;
  };

  return (
    <Autocomplete
      disabled={disabled}
      inputValue={inputValue?.toString()}
      ListboxProps={{ 'aria-label': 'vehicle year list' }}
      PopperComponent={options.length > 0 ? Popper : StyledPopper}
      autoComplete={true}
      freeSolo={true}
      includeInputInList={true}
      filterOptions={(x) => x}
      options={options}
      onBlur={field.onBlur}
      onKeyPress={handleKeyPress}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderOption={(option: string) => option}
      renderInput={(props) => (
        <StyledTextField {...props} name={name} validate={validateYear} />
      )}
    />
  );
}
