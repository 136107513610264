import { createSvgIcon } from 'shared/icons/IconUtils';

export const PaymentCashAppIcon = createSvgIcon(
  'PaymentCashAppIcon',
  <>
    <path
      d="M4.66667 2H11.3333C12.806 2 14 3.194 14 4.66667V11.3333C14 12.806 12.806 14 11.3333 14H4.66667C3.194 14 2 12.806 2 11.3333V4.66667C2 3.194 3.194 2 4.66667 2Z"
      fill="currentColor"
    />
    <path
      d="M7.68534 11.311C7.64467 11.311 7.60367 11.3107 7.56201 11.3097C6.35801 11.2803 5.56867 10.539 5.30167 10.2437C5.24267 10.1787 5.24467 10.0807 5.30534 10.017L5.86001 9.39166C5.91934 9.32933 6.01467 9.322 6.08234 9.375C6.32834 9.56833 6.89767 10.0597 7.64767 10.1007C8.51834 10.148 8.92401 9.90033 8.97634 9.522C9.02601 9.16366 8.85134 8.86 7.88401 8.609C6.15567 8.16066 5.84567 7.15233 5.95201 6.31C6.06367 5.42366 6.98201 4.71766 8.04701 4.72833C9.56901 4.744 10.507 5.42366 10.834 5.70766C10.9073 5.77133 10.909 5.88266 10.84 5.951L10.313 6.54633C10.258 6.601 10.1723 6.61133 10.1073 6.569C9.84101 6.39566 9.31001 5.87766 8.36201 5.87766C7.77867 5.87766 7.42334 6.10033 7.38634 6.39133C7.33501 6.798 7.60667 7.14933 8.43801 7.337C10.2803 7.75266 10.5903 8.826 10.3947 9.693C10.2147 10.4913 9.30234 11.311 7.68534 11.311Z"
      fill="#FAFAFA"
    />
    <path
      d="M9.344 5.53067L9.62366 4.20067C9.64566 4.09733 9.56633 4 9.46066 4H8.43899C8.36033 4 8.29199 4.05533 8.27599 4.13233L7.995 5.46933L9.344 5.53067Z"
      fill="#FAFAFA"
    />
    <path
      d="M6.97199 10.3333L6.66366 11.799C6.64199 11.9026 6.72099 12 6.82699 12H7.84866C7.92733 12 7.99566 11.9446 8.01166 11.8676L8.33433 10.3333H6.97199Z"
      fill="#FAFAFA"
    />
  </>,
  { viewBox: '0 0 16 16' },
);
