import { yupDateString, yupObject, yupString } from 'shared/utils/YupUtils';

export const zendeskDTO = yupObject({
  token: yupString(),
  expires_at: yupDateString('DateISO'),
});

export type Scope = 'messenger' | 'messenger:on' | 'messenger:set';

export type ZendeskMethod =
  | 'show'
  | 'hide'
  | 'open'
  | 'conversationTags'
  | 'zIndex'
  | 'loginUser'
  | 'logoutUser';

export interface ZendeskError {
  type: string;
  reason: string;
  message: string;
}

declare global {
  interface Window {
    zE?: (scope: Scope, method: ZendeskMethod, ...args: unknown[]) => void;
  }
}
