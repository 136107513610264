import { Fade } from '@material-ui/core';
import { useVisibilityObserver } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ $disableScrollbar?: boolean }>(
  ({ $disableScrollbar }) => ({
    display: 'flex',
    paddingRight: '1px',
    'overflow-x': 'auto',

    '&::-webkit-scrollbar': {
      display: $disableScrollbar ? 'none' : undefined,
    },
  }),
);

const Gradient = styled.div(({ theme }) => {
  const isDark = theme.palette.type === 'dark';
  const mode = isDark
    ? `rgba(21, 27, 34, 1) 0%,transparent 100%`
    : `rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 0) 100%`;
  return ` width: 40px;
  height: 100%;
    background: rgb(255, 255, 255);

  background:linear-gradient( 270deg, ${mode});

  &[data-direction='left'] {
    background: linear-gradient( 90deg, ${mode});
  }
`;
});

interface ScrollableViewportProps {
  children: ReactNode;
  disableScrollbar?: boolean;
  rightRefStyle?: React.CSSProperties;
}

export function ScrollableViewport({
  children,
  disableScrollbar,
  rightRefStyle = {},
}: ScrollableViewportProps) {
  const leftRef = useRef<HTMLDivElement | null>(null);
  const rightRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const leftVisibility = useVisibilityObserver(leftRef.current);
  const rightVisibility = useVisibilityObserver(rightRef.current);

  return (
    <Box position="relative">
      <Container ref={containerRef} $disableScrollbar={disableScrollbar}>
        <div ref={leftRef} />

        {children}

        <div ref={rightRef} style={rightRefStyle} />
      </Container>

      <Box position="absolute" bottom={0} left={0} top={0}>
        <Fade in={leftVisibility === 'invisible'} unmountOnExit={true}>
          <Gradient data-direction="left" />
        </Fade>
      </Box>

      <Box position="absolute" bottom={0} right={0} top={0}>
        <Fade in={rightVisibility === 'invisible'} unmountOnExit={true}>
          <Gradient />
        </Fade>
      </Box>
    </Box>
  );
}
