import { FormikErrors, FormikValues } from 'formik';
import { set, snakeCase } from 'lodash';
import { isAPIError } from '../errors/APIError';

export interface APIFormErrorResponse {
  details: APIErrorDetails;
  error_id: string;
  message: string;
}

export type APIErrorDetails =
  | string
  | Record<string, string | APIFormErrorResponse>;

export function isFormError(error: unknown): error is APIFormErrorResponse {
  return error instanceof Error && 'details' in error;
}

export function defaultFormErrorsGetter<TValues extends FormikValues>(
  error: unknown,
): FormikErrors<TValues> {
  const errors: FormikErrors<TValues> = {};

  if (isFormError(error)) {
    const { details } = error;

    for (const [key, value] of Object.entries(details)) {
      set(errors, key, Array.isArray(value) ? value[0] : value);
    }
  }

  return errors;
}

export function customFormErrorsGetter<TValues extends FormikValues>(
  error: unknown,
): FormikErrors<TValues> {
  const errors: FormikErrors<TValues> = {};
  if (isAPIError(error)) {
    const { context } = error;

    if (context && !Array.isArray(context) && typeof context === 'object') {
      for (const key in context) {
        set(errors, key, context[key]);
      }
    }
  }

  return errors;
}

export function camelCaseFormErrorsGetter<TValues extends FormikValues>(
  error: unknown,
): FormikErrors<TValues> {
  const errors: FormikErrors<TValues> = {};

  if (isFormError(error)) {
    const { details } = error;

    for (const [key, value] of Object.entries(details)) {
      set(errors, snakeCase(key), Array.isArray(value) ? value[0] : value);
    }
  }

  return errors;
}
