import { Box, Link } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { ColorDynamic, Inline } from '@superdispatch/ui';
import { formatPlural } from 'shared/helpers/IntlHelpers';

export function OrderInspectionPhotoIssues({
  online_bol_url,
  inspection_photo_issues_count,
  onClick,
}: {
  inspection_photo_issues_count: number | undefined;
  online_bol_url: string | undefined;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}) {
  if (!inspection_photo_issues_count) {
    return null;
  }

  return (
    <Box
      bgcolor={ColorDynamic.Red50}
      borderRadius="4px"
      px="6px"
      py="2px"
      display="inline-block"
    >
      <Inline verticalAlign="center" space="xxsmall">
        <Error color="error" fontSize="small" />
        <Link href={online_bol_url} onClick={onClick}>
          {inspection_photo_issues_count} Inspection photo{' '}
          {formatPlural(inspection_photo_issues_count, 'issue', 'issues')}
        </Link>
      </Inline>
    </Box>
  );
}
