import { CircularProgress, Link, Typography } from '@material-ui/core';
import { parseDate } from '@superdispatch/dates';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { MouseEventHandler } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useSignMedia } from 'shared/data/MediaServiceAPI';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { CheckIconRounded } from 'shared/icons/CheckIconRounded';
import { WarningIconRounded } from 'shared/icons/WarningIconRounded';
import { CarrierFullInfo, isCarrierHasValidUSDOT } from 'shared/types/carrier';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import styled from 'styled-components';

const VerifiedDocumentIcon = styled(CheckIconRounded)`
  height: 20px;
  width: 20px;
`;

const UnverifiedDocumentIcon = styled(WarningIconRounded)`
  height: 20px;
  width: 20px;
`;

interface CarrierDocumentLinkProps {
  isVerified: boolean;
  isRestricted?: boolean;
  link: string | null;
  'aria-label'?: string;
  label: string;
  emptyLabel: string;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>;
}

function DocumentLink({
  isVerified,
  isRestricted,
  'aria-label': ariaLabel,
  link,
  label,
  emptyLabel,
  onClick,
}: CarrierDocumentLinkProps) {
  const { mutateAsync, isLoading } = useSignMedia();
  return (
    <Columns aria-label={ariaLabel} space="xsmall" align="center">
      <Column width="content">
        {isLoading ? (
          <Box paddingRight="xxsmall">
            <CircularProgress size="16px" />
          </Box>
        ) : isVerified ? (
          <VerifiedDocumentIcon />
        ) : (
          <ResponsiveTooltip placement="top" title="Unverified document">
            <UnverifiedDocumentIcon />
          </ResponsiveTooltip>
        )}
      </Column>
      <Column>
        {link ? (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={isRestricted && link ? '#' : link}
            onClick={(event) => {
              if (link && isRestricted) {
                event.preventDefault();
                void mutateAsync(link)
                  .then((data) => {
                    window.open(data.signed_url, '_blank');
                  })
                  .catch(() => {
                    window.open(link, '_blank');
                  });
              }
              onClick?.(event);
            }}
          >
            {label}
          </Link>
        ) : (
          <Typography component="span">{emptyLabel}</Typography>
        )}
      </Column>
    </Columns>
  );
}

interface CarrierDocumentationProps {
  carrier: CarrierFullInfo;
}

export function CarrierDocumentation({ carrier }: CarrierDocumentationProps) {
  const isW9restrictionEnabled = useFeatureToggle('restricted-w9.enabled.ui');
  const hasUSDOT = isCarrierHasValidUSDOT(carrier);
  const autoTowing =
    carrier.logistics_type === 'AUTO_HAULER' ||
    carrier.logistics_type === 'TOWING';
  const driveaway = carrier.logistics_type === 'DRIVEAWAY';
  const hasMultipleInsurances =
    !!carrier.insurances && carrier.insurances.length > 1;

  return (
    <Stack space="xsmall">
      <DocumentLink
        isVerified={carrier.is_verified}
        isRestricted={isW9restrictionEnabled}
        link={carrier.w9}
        aria-label="carrier w9 certificate"
        label="W9 Cert"
        emptyLabel="No W9 Cert"
        onClick={() => trackEvent('[STMS] Clicked Carrier W9 Certificate')}
      />
      {hasUSDOT && autoTowing && (
        <DocumentLink
          isVerified={carrier.is_verified}
          link={carrier.usdot_certificate}
          aria-label="carrier usdot certificate"
          label="USDOT Cert"
          emptyLabel="No USDOT Cert"
          onClick={() => trackEvent('[STMS] Clicked Carrier USDOT Certificate')}
        />
      )}
      {hasUSDOT && driveaway && (
        <>
          <DocumentLink
            isVerified={carrier.is_verified}
            link={carrier.usdot_certificate}
            aria-label="carrier usdot certificate"
            label="USDOT Cert"
            emptyLabel="No USDOT Cert"
            onClick={() =>
              trackEvent('[STMS] Clicked Carrier USDOT Certificate')
            }
          />
          <DocumentLink
            isVerified={carrier.is_verified}
            link={carrier.transporter_plate_registration}
            aria-label="transporter plate registration"
            label="Transporter Plate Registration"
            emptyLabel="No Transporter Plate Registration"
            onClick={() =>
              trackEvent(
                '[STMS] Clicked Carrier Transporter Plate Registration',
              )
            }
          />
        </>
      )}
      {!hasUSDOT && autoTowing && (
        <DocumentLink
          isVerified={carrier.is_verified}
          link={carrier.state_motor_carrier_permit}
          aria-label="state motor carrier permit"
          label="State Motor Carrier Permit"
          emptyLabel="No State Motor Carrier Permit"
          onClick={() =>
            trackEvent('[STMS] Clicked Carrier State Motor Carrier Permit')
          }
        />
      )}
      {!hasUSDOT && driveaway && (
        <>
          <DocumentLink
            isVerified={carrier.is_verified}
            link={carrier.transporter_plate_registration}
            aria-label="transporter plate registration"
            label="Transporter Plate Registration"
            emptyLabel="No Transporter Plate Registration"
            onClick={() =>
              trackEvent(
                '[STMS] Clicked Carrier Transporter Plate Registration',
              )
            }
          />
          <DocumentLink
            isVerified={carrier.is_verified}
            link={carrier.business_license}
            aria-label="business license"
            label="Business License"
            emptyLabel="No Business License"
            onClick={() =>
              trackEvent('[STMS] Clicked Carrier Business License')
            }
          />
        </>
      )}
      {carrier.insurances?.map((insurance) => (
        <DocumentLink
          key={insurance.guid}
          isVerified={insurance.is_verified}
          link={insurance.file_url}
          aria-label={
            insurance.is_current
              ? 'carrier cargo insurance'
              : 'carrier cargo insurance renewal'
          }
          label={
            hasMultipleInsurances
              ? `Cargo Insurance (Exp. ${
                  parseDate(insurance.policy_expiration_date).year
                })`
              : 'Cargo Insurance'
          }
          emptyLabel="No Cargo Insurance"
          onClick={() =>
            insurance.is_current
              ? trackEvent('[STMS] Clicked Carrier Cargo Insurance')
              : trackEvent('[STMS] Clicked Carrier Cargo Insurance Renewal')
          }
        />
      ))}
    </Stack>
  );
}
