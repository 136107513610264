import { Popover, Typography } from '@material-ui/core';
import { PopoverProps } from '@material-ui/core/Popover';
import { Box, Flex } from '@rebass/grid';
import {
  CalendarQuickSelection,
  CalendarQuickSelectionItem,
  DateRangeFieldProps,
  DateTimeRange,
  stringifyDateRange,
} from '@superdispatch/dates';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { FormikDateRangeField } from 'shared/form/FormikDateRangeField';
import { endOfWorkDay, startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import Order from 'shared/types/order';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';
import { OrderErrorModal } from '../error-modal/OrderErrorModal';

function requiredDateRange(value: unknown) {
  if (Array.isArray(value) && (!value[0] || !value[1])) {
    return 'Please fill this field';
  }

  return undefined;
}

function renderDateRangeFooter() {
  return (
    <Typography color="textSecondary">
      Selected date range allows preferred carriers to instantly book loads
      inside the Super Loadboard.
      <br />
      Dates out of selected range will still be available to request.
    </Typography>
  );
}

function renderQuickSelection(
  api: Parameters<Required<DateRangeFieldProps>['renderQuickSelection']>[0],
) {
  const today = DateTime.local();
  return (
    <CalendarQuickSelection>
      {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((daysCount) => (
        <CalendarQuickSelectionItem
          key={daysCount}
          onClick={() =>
            api.change([
              startOfWorkDay(today),
              endOfWorkDay(today).plus({ day: daysCount - 1 }),
            ])
          }
        >{`${daysCount} days`}</CalendarQuickSelectionItem>
      ))}
    </CalendarQuickSelection>
  );
}

interface BulkDateRangeUpdatePopoverProps extends Omit<PopoverProps, 'open'> {
  orders: Order[] | undefined;
  onSubmitSuccess: () => void;
}

export function BulkDateRangeUpdatePopover({
  orders,
  anchorEl,
  onClose,
  onSubmitSuccess,
  ...props
}: BulkDateRangeUpdatePopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const [error, setError] = useState<unknown>();
  const { bulkUpdateDateRanges } = useBulkOrderActionAPI();

  const form = useFormikEnhanced<
    {
      pickup_date_range?: DateTimeRange;
      delivery_date_range?: DateTimeRange;
    },
    unknown
  >({
    key: !!orders,
    initialValues: {
      pickup_date_range: [null, null],
      delivery_date_range: [null, null],
    },
    onSubmit({ pickup_date_range, delivery_date_range }) {
      if (orders) {
        const ids = orders.map((x) => x.id);
        const [pickup_start_date, pickup_ends_date] = stringifyDateRange(
          pickup_date_range,
          { format: 'JodaISO' },
        );
        const [delivery_start_date, delivery_ends_date] = stringifyDateRange(
          delivery_date_range,
          { format: 'JodaISO' },
        );

        return bulkUpdateDateRanges(ids, {
          pickup_start_date,
          pickup_ends_date,
          delivery_start_date,
          delivery_ends_date,
        });
      }

      return Promise.reject(new Error('Order not selected'));
    },
    onSubmitSuccess() {
      if (orders) {
        addSnackbar('Date Ranges are Updated', { variant: 'success' });
        onSubmitSuccess();
      }
    },
    onSubmitFailure(failureError) {
      setError(failureError);
    },
  });

  const { setFieldValue, values } = form;

  useEffect(() => {
    setFieldValue('delivery_date_range', values.pickup_date_range);
  }, [values.pickup_date_range, setFieldValue]);

  return (
    <>
      <Popover
        {...props}
        anchorEl={anchorEl}
        open={!!orders && !!anchorEl}
        onClose={form.isSubmitting ? undefined : onClose}
      >
        <FormikProvider value={form}>
          <Form noValidate={true}>
            <Flex flexDirection="column" m={2}>
              <Box m={2}>
                <Flex mx={-1}>
                  <Box mx={1} width="205px">
                    <FormikDateRangeField
                      name="pickup_date_range"
                      label="Pickup Date"
                      placeholder="Select date range"
                      validate={requiredDateRange}
                      renderFooter={renderDateRangeFooter}
                      renderQuickSelection={renderQuickSelection}
                    />
                  </Box>

                  <Box mx={1} width="205px">
                    <FormikDateRangeField
                      name="delivery_date_range"
                      label="Delivery Date"
                      placeholder="Select date range"
                      validate={requiredDateRange}
                      renderFooter={renderDateRangeFooter}
                      renderQuickSelection={renderQuickSelection}
                    />
                  </Box>
                </Flex>
              </Box>
              <Box m={2} alignSelf="flex-end">
                <Button
                  size="small"
                  type="submit"
                  variant="primary"
                  pending={form.isSubmitting}
                >
                  Done
                </Button>
              </Box>
            </Flex>
          </Form>
        </FormikProvider>
      </Popover>

      <OrderErrorModal error={error} onCancel={() => setError(undefined)} />
    </>
  );
}
