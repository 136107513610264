import { useSnackbarStack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { OrderFormValues } from '../../orders/core/form/OrderForm';
import { useOrderCache } from '../../orders/data/OrderAPI';
import Order from '../../shared/types/order';
import { useSuperPayCancel } from '../data/SuperPayAPI';
import { cancelSchema } from '../data/SuperPayDTO';

export type Source = 'Order List' | 'Order Edit';

interface SuperPayCancelFormProps {
  order?: Order | OrderFormValues;
  onClose: () => void;
  source: Source;
  onSuccess?: () => void;
}
export function useSuperPayCancelForm({
  order,
  onClose,
  source,
  onSuccess,
}: SuperPayCancelFormProps) {
  const { invalidateOrder } = useOrderCache();
  const { addSnackbar } = useSnackbarStack();

  const { mutateAsync: cancelSuperPay, isLoading } = useSuperPayCancel();

  const form = useAppFormik({
    validationSchema: cancelSchema,
    initialValues: {
      method: '',
      terms:
        order?.payment?.terms === '1_3_days' ? '5_days' : order?.payment?.terms,
    },
    onSubmit: (payment) => {
      if (!order?.id) {
        return Promise.reject(new Error('No order found'));
      }

      return cancelSuperPay({ orderId: order.id, payment });
    },
    onSubmitSuccess: () => {
      addSnackbar('SuperPay Payment Canceled Successfully.', {
        variant: 'success',
      });
      trackEvent('Shipper Canceled SuperPay', { utm_medium: source });

      if (order?.guid) {
        void invalidateOrder(order.guid);
      }

      onClose();
      onSuccess?.();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return { form, isLoading };
}
