import { Link, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import { useSuperPayAlert } from 'shared/helpers/superpay/SuperPayAPI';
import { SuperPayAlertDTO } from '../data/SuperPayDTO';

function getIntercomDataTarget(code: string) {
  switch (code) {
    case 'R01':
      return 'SuperPay Bank Insufficient Funds';
    case 'R02':
      return 'SuperPay Bank Account Closed';
    case 'R03':
      return 'SuperPay No Bank Account Located';
    case 'R16':
      return 'SuperPay Bank Account Frozen';
    case 'R29':
      return 'SuperPay Originator Unauthorized';
    default:
      return '';
  }
}

type Source = 'Alert' | 'SuperPay Settings';

interface SuperPayContentAlertProps {
  alertData: SuperPayAlertDTO;
  onClose?: () => void;
  titlePrefix?: string;
  source: Source;
}

export function SuperPayContentAlert({
  alertData,
  onClose,
  titlePrefix,
  source,
}: SuperPayContentAlertProps) {
  const { showCustomerDialog } = useCustomerSupport();

  const isWarning = alertData.status === 'WARNING';
  return (
    <Alert severity={isWarning ? 'caution' : 'critical'} onClose={onClose}>
      <Stack align="left">
        <Stack space={alertData.description ? 'medium' : 'none'}>
          <Box>
            <Typography variant="body1">
              {titlePrefix && `${titlePrefix}: `}
              {alertData.name}
            </Typography>
            <Typography>{alertData.description}</Typography>
          </Box>
          <Typography>
            {isWarning && (
              <>
                Payment for{' '}
                {alertData.is_multiple ? (
                  <Link href="/orders/superpay_failed">
                    {alertData.order_count} orders
                  </Link>
                ) : (
                  <>
                    order{' '}
                    <Link href={`/orders/view/${alertData.order_guid}`}>
                      {alertData.order_number}
                    </Link>
                  </>
                )}{' '}
                failed due to {alertData.name}.
              </>
            )}{' '}
            {alertData.action} {`[Error ${alertData.code}]`}
          </Typography>
        </Stack>
        <Button
          variant="neutral"
          size="small"
          data-intercom-target={getIntercomDataTarget(alertData.code)}
          onClick={(e) => {
            e.preventDefault();
            trackEvent('Clicked Contact Support', {
              utm_medium: source,
              utm_content: `Error ${alertData.code}`,
            });
            showCustomerDialog();
          }}
        >
          Contact Support
        </Button>
      </Stack>
    </Alert>
  );
}

export function LegacySuperPayAlert() {
  const { data } = useSuperPayAlert();

  if (!data) {
    return null;
  }

  return <SuperPayContentAlert alertData={data} source="SuperPay Settings" />;
}
