import { useZendesk } from './zendesk/zendesk';

export function useCustomerSupport() {
  const { showZendeskButton, hideZendeskButton, showZendeskDialog } =
    useZendesk();

  const showCustomerButton = () => {
    showZendeskButton();
  };

  const hideCustomerButton = () => {
    hideZendeskButton();
  };

  const showCustomerDialog = () => {
    showZendeskDialog();
  };

  return {
    showCustomerButton,
    hideCustomerButton,
    showCustomerDialog,
  };
}
