import { apiInstance } from 'shared/api/API';
import { ResolveEscalationDTO, SubmitEscalationDTO } from './EscalationDTO';

export function useEscalationAPI() {
  return {
    submitEscalation: (payload: SubmitEscalationDTO) =>
      apiInstance.request('POST /internal/escalations', { json: payload }),

    resolveEscalation: (
      escalationGuid: string,
      payload: ResolveEscalationDTO,
    ) =>
      apiInstance.request(
        `POST /internal/escalations/${escalationGuid}/resolve`,
        {
          json: payload,
        },
      ),
  };
}
