import { SidebarContainer } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { Outlet, RouteObject, useOutletContext } from 'react-router-dom';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { CustomersSelection } from './core/CustomersSelection';
import { CustomerCreatePage } from './create/CustomerCreatePage';
import { CustomersImportPage } from './CustomersImportPage';
import { useCustomersNavigation } from './CustomersRouteHelpers';
import { useCustomersList } from './data/CustomerAPI';
import { CustomerDetailsPage } from './details/CustomerDetailsPage';
import { CustomersEmptyList } from './details/CustomersEmptyList';
import { CustomersList } from './list/CustomersList';

export const customersRoute: RouteObject = {
  path: 'customers',
  handle: { isProtected: true },
  Component: CustomersPage,
  children: [
    {
      index: true,
      element: <CustomersIndexPage />,
    },
    {
      path: 'create',
      element: <CustomerCreatePage />,
    },
    {
      path: 'import',
      element: <CustomersImportPage />,
    },
  ],
};

interface CustomersPageContext {
  customers: CustomerDTO[];
  selectedCustomers: Set<number>;
  isLoading: boolean;
  setSelectedCustomers: (customers: Set<number>) => void;
}

function useCustomersPageContext() {
  return useOutletContext<CustomersPageContext>();
}

function CustomersIndexPage() {
  const { customers, selectedCustomers, setSelectedCustomers, isLoading } =
    useCustomersPageContext();

  const {
    customersSearchParams: { query },
  } = useCustomersNavigation();

  if (selectedCustomers.size > 1) {
    return (
      <CustomersSelection
        count={selectedCustomers.size}
        onClear={() => {
          setSelectedCustomers(new Set());
        }}
      />
    );
  }

  if (!query && !isLoading && customers.length === 0) {
    return <CustomersEmptyList />;
  }

  return <CustomerDetailsPage customers={customers} />;
}

function CustomersPage() {
  const {
    customersSearchParams: { query },
  } = useCustomersNavigation();
  const debouncedQuery = useDebouncedValue(query, 500);
  const {
    list = [],
    data,
    hasNextPage,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useCustomersList(debouncedQuery);
  const [selectedCustomers, setSelectedCustomers] = useState<Set<number>>(
    new Set(),
  );

  useDocumentTitle('Customers');
  useTrackEvent('[STMS] Viewed Customers Page');

  function getTotalCustomersCount() {
    const lastPage = data?.pages[data.pages.length - 1];
    return lastPage?.pagination.total_objects ?? 0;
  }

  return (
    <PageLayout>
      <SidebarContainer
        sidebar={
          <CustomersList
            total={getTotalCustomersCount()}
            customers={list}
            hasNextPage={!!hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            selectedCustomers={selectedCustomers}
            onSelectionChange={setSelectedCustomers}
          />
        }
      >
        <Outlet
          context={
            {
              customers: list,
              selectedCustomers,
              setSelectedCustomers,
              isLoading,
            } as CustomersPageContext
          }
        />
      </SidebarContainer>
    </PageLayout>
  );
}
