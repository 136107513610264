import { Link, TextField, Typography } from '@material-ui/core';
import { Add, ArrowForward } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Inline, Tag } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { CounterpartyContactDTO } from 'shared/dto/CounterpartyContactDTO';

interface AutocompleteButtonOption {
  name: string;
}

type CounterPartyOption = CounterpartyContactDTO | AutocompleteButtonOption;

export type CounterpartyContactChangeReason =
  | 'select-option'
  | 'save-as-new'
  | 'dont-save'
  | 'clear'
  | 'close';

interface CounterpartyContactAutocompleteProps {
  name: string;
  isNew: boolean;
  showContinue?: boolean;
  options?: CounterPartyOption[];
  validate?: (value: CounterpartyContactDTO) => void;
  onChange?: (
    value: Partial<CounterpartyContactDTO>,
    reason: CounterpartyContactChangeReason,
  ) => void;
}

export function CounterpartyContactAutocomplete({
  name,
  isNew,
  validate,
  showContinue,
  options = [],
  onChange: onChangeProp,
}: CounterpartyContactAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const [{ value, onBlur }, { error }, { setValue }] = useField({
    name,
    validate,
  });

  const inputValue = useMemo(() => value || '', [value]);

  const nameError = get(error, 'name');

  const hasSimilarContact = useMemo(
    () =>
      !!value?.name &&
      options.some((x) =>
        x.name.toLowerCase().includes(value.name.toLowerCase()),
      ),
    [options, value],
  );

  const optionsWithActionButtons = useMemo(() => {
    if (options.length === 0 && query.length < 3) return [];

    if (showContinue) {
      return [
        ...options,
        { name: 'continue_button' },
        { name: 'save_as_new_button' },
      ];
    }
    return [...options, { name: 'save_as_new_button' }];
  }, [options, query, showContinue]);

  function handleChange(
    autocompleteValue: Partial<CounterpartyContactDTO>,
    reason: CounterpartyContactChangeReason,
  ) {
    setValue(autocompleteValue);
    onChangeProp?.(autocompleteValue, reason);
  }

  function handleAsNewSave(reason: CounterpartyContactChangeReason) {
    setOpen(false);
    handleChange({ ...value, id: undefined, name: query }, reason);
  }

  function handleContinue() {
    setOpen(false);
    handleChange({ ...value, name: query }, 'dont-save');
  }

  return (
    <Autocomplete
      open={open}
      onBlur={onBlur}
      onOpen={() => setOpen(true)}
      value={inputValue}
      options={optionsWithActionButtons}
      filterOptions={(data) => data}
      clearOnBlur={false}
      onClose={(_, reason) => {
        setOpen(false);

        if (
          query.length > 0 &&
          query !== value?.name &&
          reason !== 'toggleInput' &&
          reason !== 'select-option'
        ) {
          handleAsNewSave('close');
        }
      }}
      onChange={(_, updatedValue) => {
        if (updatedValue?.name === 'continue_button') {
          handleContinue();
          return;
        }

        if (updatedValue?.name === 'save_as_new_button') {
          handleAsNewSave('save-as-new');
          return;
        }

        if (updatedValue) {
          handleChange(updatedValue, 'select-option');
        } else {
          handleChange({}, 'clear');
        }
      }}
      onInputChange={(_, input) => setQuery(input)}
      getOptionSelected={(option, selectedValue) =>
        option.name === selectedValue.name
      }
      getOptionLabel={(option) => option.name || ''}
      noOptionsText={
        query.length < 3 ? 'Type at least 3 characters' : 'No option'
      }
      renderOption={(option: CounterPartyOption) => {
        if (option.name === 'save_as_new_button') {
          return (
            <Button variant="text" startIcon={<Add />}>
              Save as New Contact
            </Button>
          );
        }

        if (option.name === 'continue_button') {
          return (
            <Button variant="text" startIcon={<ArrowForward />}>
              Continue without Saving
            </Button>
          );
        }

        return option.name;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!nameError}
          label={
            <Inline>
              <Typography color="textPrimary">Contact Name</Typography>

              {isNew && (
                <Tag variant="subtle" color="blue">
                  New Contact
                </Tag>
              )}
            </Inline>
          }
          helperText={
            nameError ? (
              nameError
            ) : isNew && hasSimilarContact ? (
              <Typography component="span">
                Similar contact already exists.{' '}
                <Link href="#" color="primary" onClick={() => setOpen(true)}>
                  Review
                </Link>
              </Typography>
            ) : undefined
          }
        />
      )}
    />
  );
}
