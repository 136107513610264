import { Box, Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { CarrierAutocompleteField } from 'core/CarrierAutocomplete';
import { Form, FormikProvider } from 'formik';
import countBy from 'lodash/countBy';
import { ReactNode } from 'react';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { SourceManagerLegacy } from 'shared/helpers/SourceManagmentLegacy';
import { Carrier } from 'shared/types/carrier';
import Order from 'shared/types/order';
import { Drawer } from 'shared/ui/Drawer';
import {
  useBulkOrderActionAPI,
  useSingleOrderActionAPI,
} from '../../data/OrderActionAPI';

interface MarkAsAcceptedDrawerForm {
  carrier: Carrier | null;
}

interface MarkAsAcceptedDrawerProps<T> {
  open: boolean;
  title: ReactNode;
  onClose: () => void;
  onSubmit: (values: MarkAsAcceptedDrawerForm) => Promise<T>;
  onSubmitSuccess: (response: T, values: MarkAsAcceptedDrawerForm) => void;
}

function MarkAsAcceptedDrawer<T>({
  open,
  title,
  onClose,
  onSubmit,
  onSubmitSuccess,
}: MarkAsAcceptedDrawerProps<T>) {
  const { addSnackbar } = useSnackbarStack();

  const form = useFormikEnhanced<MarkAsAcceptedDrawerForm, T>({
    key: open,
    initialValues: { carrier: null },
    validate({ carrier }) {
      if (!carrier) {
        return { carrier: { guid: 'Please select carrier' } };
      }

      return undefined;
    },
    onSubmit,
    onSubmitSuccess,
    onSubmitFailure(error) {
      addSnackbar(error.message || 'Error marking order as accepted', {
        variant: 'error',
      });
    },
  });

  return (
    <Drawer
      header={title}
      width="576px"
      isOpen={open}
      onClose={form.isSubmitting ? undefined : onClose}
    >
      <SourceManagerLegacy primarySource="Mark as Accepted Drawer" />

      <FormikProvider value={form}>
        <Form>
          <Box mx={4} my={2}>
            <Box mb={2}>
              <CarrierAutocompleteField name="carrier" withFMCSA={false} />
            </Box>

            <Box mb={2}>
              <Button
                type="submit"
                variant="primary"
                pending={form.isSubmitting}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </Drawer>
  );
}

interface BulkMarkAsAcceptedDrawerProps {
  orders: Order[] | undefined;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function BulkMarkAsAcceptedDrawer({
  onClose,
  orders = [],
  onSubmitSuccess,
}: BulkMarkAsAcceptedDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkMarkAsAccepted } = useBulkOrderActionAPI();

  return (
    <MarkAsAcceptedDrawer
      open={orders.length > 0}
      onClose={onClose}
      title={
        <div>
          <Typography variant="h2">Mark as Accepted</Typography>
          <Typography variant="caption">{orders.length} orders</Typography>
        </div>
      }
      onSubmit={({ carrier }) => {
        if (orders.length > 0 && carrier) {
          const ids = orders.map((x) => x.id);
          const offer = Carrier.generateOrderOffer(carrier);

          return bulkMarkAsAccepted(ids, offer);
        }
        return Promise.reject(new Error('Order not selected'));
      }}
      onSubmitSuccess={() => {
        trackEventLegacy('Bulk Mark as Accepted', {
          count: orders.length,
          orders: countBy(orders, 'status'),
        });
        addSnackbar(`${orders.length} orders marked as accepted`, {
          variant: 'success',
        });
        onSubmitSuccess();
      }}
    />
  );
}

interface SingleMarkAsAcceptedDrawerProps {
  order: Order | undefined;
  onClose: () => void;
  onSubmitSuccess: (order: Order) => void;
}

export function SingleMarkAsAcceptedDrawer({
  order,
  onClose,
  onSubmitSuccess,
}: SingleMarkAsAcceptedDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { markAsAccepted } = useSingleOrderActionAPI();

  return (
    <MarkAsAcceptedDrawer
      open={!!order}
      onClose={onClose}
      title={<Typography variant="h2">Mark as Accepted</Typography>}
      onSubmit={({ carrier }) => {
        if (order && carrier) {
          const offer = Carrier.generateOrderOffer(carrier);
          return markAsAccepted(order.id, offer);
        }
        return Promise.reject(new Error('Order not found'));
      }}
      onSubmitSuccess={(response: Order, { carrier }) => {
        if (carrier) {
          const offer = Carrier.generateOrderOffer(carrier);

          trackEventLegacy('Marked as Accepted', { order, offer });
          addSnackbar('Order marked as accepted', { variant: 'success' });
          onSubmitSuccess(response);
        }
      }}
    />
  );
}
