import {
  AppBar,
  CircularProgress,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { ColorDynamic } from '@superdispatch/ui';
import { Container } from '@superdispatch/ui-lab';
import * as React from 'react';
import { createContext, useContext, useMemo, useRef } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { FeatureToggle } from 'shared/data/FeatureToggle';
import styled from 'styled-components';
import { SubscriptionPaymentFailedAlert } from 'subscription-details/SubscriptionPaymentFailedAlert';
import { SystemAlertsList } from './system-alerts/SystemAlertsList';

interface LayoutContext {
  scrollTop: () => void;
}

const Context = createContext<null | LayoutContext>(null);

export function useLayoutContext() {
  const context = useContext(Context);
  if (context == null) {
    throw Error('Layout Provider not found');
  }

  return context;
}

const StyledLayout = styled(Container)`
  display: flex;
  flex-grow: 1;
  overflow: auto;
  background: ${ColorDynamic.White};
`;

const StyledMain = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 1;
  position: relative;
`;

const StyledHeader = styled.div`
  flex-shrink: 0;
`;

const StyledContent = styled(Flex)<{
  contentBackground: string;
  withContentPadding: boolean;
}>`
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  background: ${(props) => props.contentBackground};

  ${(props) =>
    props.withContentPadding &&
    `
    padding: 16px 24px;
  `}
`;

export interface LayoutProps {
  title?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  isContentLoading?: boolean;
  contentBackground?: string;
  withContentPadding?: boolean;
  children?: React.ReactNode;
}

export function PageLayout({
  title,
  header,
  footer,
  children,
  isContentLoading,
  withContentPadding = false,
  contentBackground = ColorDynamic.Silver200,
}: LayoutProps) {
  const { authState } = useUserState();
  const contentRef = useRef<HTMLElement | null>(null);

  const ctx = useMemo(
    () => ({
      scrollTop: () => {
        if (contentRef.current) {
          contentRef.current.scrollTop = 0;
        }
      },
    }),
    [],
  );
  return (
    <Context.Provider value={ctx}>
      <StyledLayout fullViewportHeight={true}>
        <StyledMain>
          {authState === 'authorized' && (
            <>
              <SubscriptionPaymentFailedAlert />
              <FeatureToggle name="system-alerts.ui">
                <SystemAlertsList />
              </FeatureToggle>
            </>
          )}

          {(header || title) && (
            <StyledHeader>
              {header ? (
                header
              ) : (
                <AppBar>
                  <Toolbar>
                    <Typography variant="h2">{title}</Typography>
                  </Toolbar>
                </AppBar>
              )}
            </StyledHeader>
          )}
          <StyledContent
            ref={contentRef}
            withContentPadding={withContentPadding}
            contentBackground={contentBackground}
          >
            {isContentLoading ? (
              <Flex flex={1} alignItems="center" justifyContent="center">
                <CircularProgress />
              </Flex>
            ) : (
              children
            )}
          </StyledContent>
          {footer}
        </StyledMain>
      </StyledLayout>
    </Context.Provider>
  );
}
