import { MenuItem, Popover, Tooltip, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { FormikCheckboxField, useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, Inline, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import {
  usePrivateNetworkAPIs,
  usePrivateNetworkGroups,
} from 'manage-carriers/data/PrivateNetworkAPI';
import { formatGroupNames } from 'manage-carriers/data/PrivateNetworkDTO';
import { useEffect, useState } from 'react';
import {
  FormikCheckboxArrayField,
  FormikCheckboxArrayGroup,
} from 'shared/form/FormikCheckboxArray';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';
import { PrivateNetworkDialog } from './PrivateNetworkDialog';

interface AddPrivateNetworkGroupFormProps {
  carrierGuids: string[];
  source: string;
  onSubmitSuccess: () => void;
  onSubmitting: (value: boolean) => void;
}

export function AddPrivateNetworkGroupForm({
  carrierGuids,
  source,
  onSubmitSuccess,
  onSubmitting,
}: AddPrivateNetworkGroupFormProps) {
  const [open, setOpen] = useState(false);
  const { addSnackbar } = useSnackbarStack();
  const { data } = usePrivateNetworkGroups();
  const { addCarrierToPrivateNetwork, addCarrierToPrivateNetworkGroups } =
    usePrivateNetworkAPIs();

  const formik = useFormikEnhanced({
    initialValues: { is_in_private_network: false, groups: [] },
    onSubmit({ is_in_private_network, groups }) {
      if (groups.length) {
        return addCarrierToPrivateNetworkGroups(carrierGuids, groups);
      }

      if (is_in_private_network) {
        return addCarrierToPrivateNetwork(carrierGuids);
      }

      return Promise.reject(new Error('Select at least one option'));
    },
    onSubmitSuccess(_, { groups }) {
      trackEvent('Shipper Added Carrier to Private Network & Groups', {
        utm_medium: source,
        carrier_count: carrierGuids.length,
        group_count: groups.length,
      });
      if (groups.length) {
        const names = formatGroupNames(groups, data?.groups)
          .filter(Boolean)
          .map((x) => `'${x || ''}'`)
          .join(', ');

        addSnackbar(
          `${carrierGuids.length} ${formatPlural(
            carrierGuids.length,
            'carrier',
            'carriers',
          )} added to ${formatPlural(
            groups.length,
            `'${names}' group`,
            `${groups.length} groups`,
          )}`,
        );
      } else {
        addSnackbar(
          `${carrierGuids.length} ${formatPlural(
            carrierGuids.length,
            'carrier',
            'carriers',
          )} added to Private Network`,
        );
      }
      onSubmitSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const { values, setFieldValue, dirty } = formik;

  useEffect(() => {
    const { groups, is_in_private_network } = values;
    if (groups.length > 0 && !is_in_private_network) {
      setFieldValue('is_in_private_network', true);
    }
  }, [setFieldValue, values]);

  useEffect(() => {
    onSubmitting(formik.isSubmitting);
  }, [formik.isSubmitting, onSubmitting]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box
          paddingTop="xsmall"
          data-intercom-target={
            dirty
              ? 'Add to Private Network Dropdown Selected'
              : 'Add to Private Network Dropdown Empty'
          }
        >
          <FormikCheckboxArrayGroup name="groups">
            <MenuItem component="label">
              <FormikCheckboxField
                name="is_in_private_network"
                label="Private Network"
                disabled={formik.isSubmitting}
              />
            </MenuItem>

            <MenuItem button={false}>
              <Typography color="textSecondary">Groups</Typography>
            </MenuItem>

            <Box paddingLeft="xsmall">
              {data?.groups.map(({ guid, name }) => (
                <MenuItem key={guid} component="label">
                  <FormikCheckboxArrayField
                    name={guid}
                    label={name}
                    disabled={formik.isSubmitting}
                  />
                </MenuItem>
              ))}
            </Box>

            {!!data && data.groups.length >= 10 ? (
              <Tooltip title="Max 10 carrier groups allowed.">
                <span>
                  <MenuItem divider={true} disabled={true}>
                    <Box paddingLeft="xxsmall">
                      <Inline verticalAlign="center" horizontalAlign="center">
                        <Add color="disabled" />

                        <Typography color="textSecondary">
                          Create Group
                        </Typography>
                      </Inline>
                    </Box>
                  </MenuItem>
                </span>
              </Tooltip>
            ) : (
              <MenuItem divider={true} onClick={() => setOpen(true)}>
                <Box paddingLeft="xxsmall">
                  <Inline verticalAlign="center" horizontalAlign="center">
                    <Add color="action" />

                    <Typography>Create Group</Typography>
                  </Inline>
                </Box>
              </MenuItem>
            )}

            <MenuItem button={false}>
              <Columns>
                <Column width="fluid" />
                <Column width="content">
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    disabled={!formik.dirty}
                    pending={formik.isSubmitting}
                    data-intercom-target={formik.dirty ? 'Save' : undefined}
                  >
                    Save
                  </Button>
                </Column>
              </Columns>
            </MenuItem>
          </FormikCheckboxArrayGroup>
        </Box>
      </Form>

      <PrivateNetworkDialog
        source={source}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </FormikProvider>
  );
}

interface AddPrivateNetworkButtonProps {
  carrierGuids: string[];
  source: string;
  onSubmitSuccess: () => void;
}

export function AddPrivateNetworkButton({
  carrierGuids,
  source,
  onSubmitSuccess,
}: AddPrivateNetworkButtonProps) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  return (
    <>
      <Button
        data-intercom-target="Add to Private Network Button"
        onClick={(event) => {
          setAnchor(event.currentTarget);
        }}
        variant="neutral"
      >
        Add to Private Network
      </Button>

      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => {
          if (!isSubmitting) {
            setAnchor(null);
          }
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: 0,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <AddPrivateNetworkGroupForm
          source={source}
          carrierGuids={carrierGuids}
          onSubmitting={setSubmitting}
          onSubmitSuccess={() => {
            setAnchor(null);
            onSubmitSuccess();
          }}
        />
      </Popover>
    </>
  );
}
