import {
  FormikCheckboxField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  CheckboxField,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { updatedDiff } from 'deep-object-diff';
import { Form, FormikProvider } from 'formik';
import { set } from 'lodash';
import {
  useNotificationSettings,
  useNotificationSettingsAPI,
} from 'notification-settings/data/NotificationSettingsAPI';
import { NotificationSettingsDTO } from 'notification-settings/data/NotificationSettingsDTO';
import { useOrderRequests } from 'orders/data/OrderRequestsAPI';
import { useEffect, useState } from 'react';
import { useNotificationAPI } from 'shared/data/notifications/NotificationAPI';
import { useCanExecute } from 'shared/data/UserPermissions';
import {
  getFCMCurrentToken,
  setFCMCurrentToken,
  useFirebaseMessaging,
} from 'shared/helpers/FirebaseHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { Order } from 'shared/types/order';
import { required } from 'shared/utils/ValidatorUtils';
import styled from 'styled-components';
import { trackOrderRecommendations } from './OrderRequestRecommendationAnalytics';

const DONT_SUGGEST_AGAIN_LS_KEY =
  'is_dont_suggest_again_notification_recommendation_clicked';
const NOTIFICATION_RECOMMENDATION_SUBMITTED_LS_KEY =
  'notification_recommendation_submitted';

const Container = styled.div`
  margin-left: 30px;
`;

export function useNotificationSettingsRecommendation() {
  const { data: notificationSettings } = useNotificationSettings();
  const [status, setStatus] = useState<null | 'error' | 'success'>(null);
  const isNotificationsDontSuggestAgainClicked = Boolean(
    useStorageValue(DONT_SUGGEST_AGAIN_LS_KEY),
  );
  const isNotificationRecommendationSubmitted = Boolean(
    useStorageValue(NOTIFICATION_RECOMMENDATION_SUBMITTED_LS_KEY),
  );

  useEffect(() => {
    if (!notificationSettings) {
      return;
    }

    const isFullyConfigured =
      notificationSettings.is_personal_email_notifications_enabled &&
      !!notificationSettings.sms_notification_phone_number &&
      notificationSettings.is_personal_push_notifications_enabled;

    const isHalfConfigured =
      !!notificationSettings.is_personal_email_notifications_enabled ||
      !!notificationSettings.sms_notification_phone_number;

    if (
      isFullyConfigured ||
      isNotificationsDontSuggestAgainClicked ||
      (isNotificationRecommendationSubmitted && isHalfConfigured)
    ) {
      setStatus('success');
    } else {
      setStatus('error');
    }
  }, [
    notificationSettings,
    isNotificationsDontSuggestAgainClicked,
    isNotificationRecommendationSubmitted,
  ]);

  return {
    status,
    isNotificationsDontSuggestAgainClicked,
  };
}

export function NotificationSettingsRecommendation({
  order,
}: {
  order: Order;
}) {
  const messaging = useFirebaseMessaging();
  const { addSnackbar } = useSnackbarStack();
  const canUpdateCompanyProfile = useCanExecute('UPDATE_COMPANY_PROFILE');
  const { subscribeToPushNotification, disablePushNotification } =
    useNotificationAPI();
  const { data: notificationSettings, refetch } = useNotificationSettings();
  const { data: requests } = useOrderRequests(order.guid);
  const { updateNotificationSettings } = useNotificationSettingsAPI();
  const [isSMSNotificationEnabled, setSMSNotificationEnabled] = useState(false);

  const formik = useFormikEnhanced({
    initialValues: { ...notificationSettings },
    async onSubmit(values) {
      if (messaging) {
        const currentToken = getFCMCurrentToken();

        if (values.is_personal_push_notifications_enabled) {
          const token = await messaging.requestAndGetToken();

          await subscribeToPushNotification(token);
          setFCMCurrentToken(token);
        }

        if (
          currentToken &&
          notificationSettings?.is_personal_push_notifications_enabled &&
          !values.is_personal_push_notifications_enabled
        ) {
          await disablePushNotification(currentToken);
        }
      }

      const payload = values as NotificationSettingsDTO;
      if (!isSMSNotificationEnabled) {
        set(payload, 'sms_notification_phone_number', null);
      }

      return updateNotificationSettings(payload);
    },
    onSubmitSuccess: (_, values) => {
      const updatedValues = updatedDiff(notificationSettings || {}, values);
      trackOrderRecommendations(updatedValues, requests?.objects.length);
      writeStorageItem(
        NOTIFICATION_RECOMMENDATION_SUBMITTED_LS_KEY,
        String(true),
      );
      void refetch();
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to update notification settings', {
        variant: 'error',
      });
    },
  });

  const handleDontSuggestClick = () => {
    writeStorageItem(DONT_SUGGEST_AGAIN_LS_KEY, String(true));
  };

  const showNoPermissionsSnackbar = () => {
    if (!canUpdateCompanyProfile) {
      addSnackbar(
        <Stack space="none">
          <TextBox>No edit permission.</TextBox>
          <TextBox>Request manager to update settings.</TextBox>
        </Stack>,
      );
    }
  };

  useEffect(() => {
    if (notificationSettings) {
      setSMSNotificationEnabled(
        !!notificationSettings.sms_notification_phone_number,
      );
    }
  }, [notificationSettings]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack space="small">
          <Stack space="xsmall">
            <div onClick={showNoPermissionsSnackbar}>
              <Stack space="none">
                <CheckboxField
                  label="SMS"
                  checked={isSMSNotificationEnabled}
                  disabled={!canUpdateCompanyProfile}
                  onChange={(_, checked) => {
                    setSMSNotificationEnabled(checked);
                  }}
                />
                <Container>
                  <Stack space="xsmall">
                    <TextBox color="secondary">
                      Enable instant text notifications to a single phone number
                      for all orders.
                    </TextBox>
                    <Box maxWidth={['100%', '200px']}>
                      <SuspendedFormikPhoneField
                        name="sms_notification_phone_number"
                        fullWidth={true}
                        disabled={
                          !canUpdateCompanyProfile || !isSMSNotificationEnabled
                        }
                        validate={(value, phoneService) => {
                          if (!isSMSNotificationEnabled) {
                            return undefined;
                          }
                          return (
                            required(value) || phoneService.validate(value)
                          );
                        }}
                      />
                    </Box>
                  </Stack>
                </Container>
              </Stack>
            </div>

            <Stack space="none">
              <FormikCheckboxField
                label="Email"
                name="is_personal_email_notifications_enabled"
                disabled={
                  !notificationSettings?.personal_email ||
                  notificationSettings.is_personal_email_unsubscribed
                }
              />
              <Container>
                <TextBox color="secondary">
                  Enable email updates for orders assigned only to you.
                </TextBox>
              </Container>
            </Stack>

            <Stack space="none">
              <FormikCheckboxField
                label="Push"
                name="is_personal_push_notifications_enabled"
              />
              <Container>
                <TextBox color="secondary">
                  Enable browser notifications for orders only assigned to you.
                </TextBox>
              </Container>
            </Stack>
          </Stack>

          <Box paddingTop="small">
            <Inline space="small">
              <Button
                type="submit"
                disabled={
                  (!formik.dirty &&
                    isSMSNotificationEnabled ===
                      !!notificationSettings?.sms_notification_phone_number) ||
                  formik.isSubmitting
                }
              >
                Save
              </Button>
              <Button variant="text" onClick={handleDontSuggestClick}>
                Don’t suggest again
              </Button>
            </Inline>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
