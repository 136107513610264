import { set, unset } from 'lodash';
import { mapOrderToRouteStatus } from 'orders/core/order-status/OrderStatusHelpers';
import { useMemo } from 'react';
import { QueryKey, useQueryClient } from 'react-query';
import { APIPageResponse, useAPI } from 'shared/api/API';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import {
  APIQueryInput,
  APIQueryOptions,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import {
  Load,
  LoadBasicInfo,
  OrderWithLoadsDTO,
  orderWithLoadsSchema,
} from 'shared/types/load';
import Order from 'shared/types/order';
import { RouteStatus } from 'shared/types/routeStatus';
import { CurrentLoadDTO, currentLoadSchema } from './dto/CurrentLoadDTO';
import {
  ExpectedDepositDate,
  expectedDepositDateSchema,
} from './dto/DepositExpectedDateDTO';
import { OrderActivityDTO } from './dto/OrderActivityDTO';
import {
  OrderListPageParamsDTO,
  orderSearchPayloadSchema,
} from './OrderListPageParamsDTO';

export function useOrderActivities(orderID: number | undefined) {
  const { requestPage } = useAPI();

  return useAPIPageQuery<OrderActivityDTO>(
    ['orders', 'activities', { orderID }],
    () =>
      requestPage(
        '/internal/orders/{orderID}/activities',
        (data) => data as OrderActivityDTO,
        { orderID },
      ),
    {
      enabled: orderID !== undefined,
    },
  );
}

export function useOrderActivityCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const invalidateOrderActivity = (orderID: number) => {
      return queryClient.invalidateQueries([
        'orders',
        'activities',
        { orderID },
      ]);
    };

    return { invalidateOrderActivity };
  }, [queryClient]);
}

function getOrderInput(guid: string, isActive = true): APIQueryInput {
  return isActive
    ? ['orders', 'guid', { guid }]
    : ['orders', 'guid/inactive', { guid }];
}

export function useOrder(
  guid: string,
  isActive = true,
  options: UseAPIQueryOptions<Order> = {},
) {
  const { requestResource } = useAPI();
  const endpoint = isActive
    ? '/internal/orders/guid/{guid}'
    : '/internal/orders/inactive/guid/{guid}';

  const input: APIQueryInput = getOrderInput(guid, isActive);

  return useAPIQuery(
    input,
    () => requestResource(endpoint, (data) => data as Order, { guid }),
    {
      enabled: !!guid,
      ...options,
    },
  );
}

export function useOrderCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const replaceOrder = (order: Order, isActive = true) => {
      const input: APIQueryInput = getOrderInput(order.guid, isActive);
      queryClient.setQueryData(input, order);
    };

    const invalidateOrder = (guid: string, isActive = true) => {
      const input: APIQueryInput = getOrderInput(guid, isActive);
      return queryClient.invalidateQueries(input);
    };

    return { replaceOrder, invalidateOrder };
  }, [queryClient]);
}

export function useActiveOrder(guid?: string) {
  const { requestResource } = useAPI();

  return useAPIQuery(
    ['orders', 'guid', { guid }],
    () =>
      requestResource(
        'GET /internal/orders/guid/{guid}',
        (data) => data as Order,
        { guid },
      ),
    {
      enabled: !!guid,
    },
  );
}

export function useMultipleOrders(guids: string[]) {
  const { requestResource } = useAPI();

  return useAPIQuery(
    ['orders', 'multiple', { guids: guids.join(',') }],
    () =>
      Promise.all(
        guids.map((guid) =>
          requestResource(
            'GET /internal/orders/guid/{guid}',
            (data) => data as Order,
            { guid },
          ),
        ),
      ),
    {
      enabled: guids.length > 0,
    },
  );
}

export function useNewLoadCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const clearNewLoadCache = () => {
      queryClient.removeQueries(['shippers', 'get_and_increment_curr_load_id']);
    };

    return {
      clearNewLoadCache,
    };
  }, [queryClient]);
}

export function useNewLoadId() {
  const { requestResource } = useAPI();

  return useAPIQuery<CurrentLoadDTO>(
    ['shippers', 'get_and_increment_curr_load_id'],
    () =>
      requestResource(
        '/internal/shippers/get_and_increment_curr_load_id',
        (data) => data as CurrentLoadDTO,
      ),
    {
      refetchOnWindowFocus: false,
      schema: currentLoadSchema,
    },
  );
}

export function useOrderAPI() {
  const { request, requestResource } = useAPI();

  return useMemo(
    () => ({
      updateOrderPrice: (id: number, price: number) =>
        request('PATCH /internal/orders/{id}/price', {
          id,
          json: { price },
        }),
      createOrder: (order: Partial<Order>) =>
        requestResource('POST /internal/orders', (data) => data as Order, {
          json: order,
        }),
      createDemoOrder: () =>
        requestResource('POST /internal/orders/demo', (data) => data as Order),
      createLoads: (loads: Load[], orderIds: Array<number | undefined>) =>
        request('POST /internal/orders/loads{?orderIds}', {
          json: loads,
          orderIds,
        }),

      updateOrder: (orderId: number, order: Partial<Order>) =>
        requestResource(
          'PUT /internal/orders/{orderId}',
          (data) => data as Order,
          { orderId, json: order },
        ),
      createOrderAttachment: (
        orderId: number,
        file: File,
        is_shared_with_carrier?: boolean,
        is_shared_with_customer?: boolean,
      ) => {
        const body = new FormData();
        body.append('file', file);

        return request(
          'POST /internal/orders/{orderId}/attachments{?is_shared_with_carrier,is_shared_with_customer}',
          { orderId, body, is_shared_with_carrier, is_shared_with_customer },
        );
      },
      editOrderAttachment: (
        orderId: number,
        data: {
          is_shared_with_carrier: boolean;
          is_shared_with_customer: boolean;
        },
        attachmentId?: number,
      ) => {
        return request(
          'PUT /internal/orders/{orderId}/attachments/{attachmentId}',
          { orderId, attachmentId, json: data },
        );
      },
      removeOrderAttachment: (orderId: number, attachmentId: number) =>
        request(
          'DELETE /internal/orders/{orderId}/attachments/{attachmentId}',
          { orderId, attachmentId },
        ),
      uploadOrderVehiclePhoto: (
        orderId: number,
        vehicleId: number,
        file: File,
      ) => {
        const body = new FormData();
        body.append('file', file);

        return request(
          'POST /internal/orders/{orderId}/vehicles/{vehicleId}/photo/sample',
          { body, orderId, vehicleId },
        );
      },
      removeOrderVehiclePhoto: (orderId: number, vehicleId: number) =>
        request(
          'DELETE /internal/orders/{orderId}/vehicles/{vehicleId}/photo/sample',
          { orderId, vehicleId },
        ),
      removeRelations: (id: number) =>
        request('POST /internal/orders/{id}/loads/remove_relations', {
          id,
        }),
    }),
    [request, requestResource],
  );
}

type PageParams =
  | Pick<OrderListPageParamsDTO, 'limit' | 'size'>
  | { sort: string };

interface OrdersListParams {
  page: number;
  status?: RouteStatus;
  params: PageParams;
  enabled: boolean;
}

export function useOrdersList({
  page,
  status,
  params,
  enabled,
}: OrdersListParams) {
  const { requestPage } = useAPI();

  return useAPIPageQuery<Order>(
    ['orders', 'list', { status, page, params }],
    () =>
      requestPage(
        'GET /internal/orders/list/{status}{?page,params*}',
        (data) => data as Order,
        {
          status,
          page,
          params,
        },
      ),
    {
      enabled,
      staleTime: 0,
    },
  );
}

interface OrdersSearchParams {
  page: number;
  params: PageParams;
  searchBody?: string;
  enabled: boolean;
}

export function useOrdersSearch({
  page,
  params,
  searchBody,
  enabled,
}: OrdersSearchParams) {
  const { requestPage } = useAPI();

  return useAPIPageQuery<Order>(
    [
      'orders',
      'search',
      {
        page,
        params,
        searchBody,
      },
    ],
    () =>
      requestPage(
        'POST /internal/orders/search{?page,params*}',
        (data) => data as Order,
        {
          page,
          params,
          body: searchBody,
          headers: { 'content-type': 'application/json' },
        },
      ),
    {
      enabled,
      staleTime: 0,
    },
  );
}

export function useOrdersCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const updateOrderForPage = (queryInput: QueryKey, order: Order) => {
      const _data =
        queryClient.getQueryData<APIPageResponse<Order>['data']>(queryInput);

      if (_data) {
        const objects = _data.objects.filter(({ guid }) => guid !== order.guid);
        queryClient.setQueryData<APIPageResponse<Order>['data']>(queryInput, {
          ..._data,
          objects: [order, ...objects],
        });
      }
    };

    const removeOrderFromPage = (queryInput: QueryKey, order: Order) => {
      const data =
        queryClient.getQueryData<APIPageResponse<Order>['data']>(queryInput);

      if (data) {
        const objects = data.objects.filter(({ guid }) => guid !== order.guid);

        queryClient.setQueryData<APIPageResponse<Order>['data']>(queryInput, {
          ...data,
          objects,
        });
      }
    };

    const updateOrderCacheList = (
      order: Order,
      { status, page, params }: Omit<OrdersListParams, 'enabled'>,
    ) => {
      const routeStatus = mapOrderToRouteStatus(order);
      const queryInput = [
        'orders',
        'list',
        {
          status,
          page: page - 1,
          params,
        },
      ];

      const newQueryInput = [
        'orders',
        'list',
        {
          status: routeStatus,
          page: 0,
          params,
        },
      ];

      updateOrderForPage(newQueryInput, order);
      if (status !== routeStatus) removeOrderFromPage(queryInput, order);

      const allQueryInput = [
        'orders',
        'list',
        {
          page: 0,
          params,
        },
      ];

      updateOrderForPage(allQueryInput, order);
    };

    const updateOrderCacheSearch = (
      order: Order,
      { page, params, searchBody }: Omit<OrdersSearchParams, 'enabled'>,
    ) => {
      const queryInput = [
        'orders',
        'search',
        {
          page,
          params,
          searchBody,
        },
      ];

      updateOrderForPage(queryInput, order);
    };

    /** Invalidating orders list and order counts */
    const invalidateOrders = () => queryClient.invalidateQueries(['orders']);

    return { updateOrderCacheList, updateOrderCacheSearch, invalidateOrders };
  }, [queryClient]);
}

interface SearchBodyParams {
  filters: Omit<OrderListPageParamsDTO, 'sort' | 'limit' | 'size' | 'page'>;
  searchString?: string;
  status?: string;
}

export const useSearchBody = ({
  filters,
  searchString,
  status,
}: SearchBodyParams) =>
  useMemo(() => {
    const values = Object.values(filters).filter(
      (value: undefined | string | unknown[]) => value?.length,
    );

    if (!values.length) {
      return undefined;
    }

    const plain = { ...filters, query: searchString };
    if (plain.delivered_on_date && plain.delivered_on_date.length === 2) {
      set(plain, 'delivered_on_start_date', plain.delivered_on_date[0]);
      set(plain, 'delivered_on_end_date', plain.delivered_on_date[1]);
      unset(plain, 'delivered_on_date');
    }

    if (plain.carriers) {
      set(
        plain,
        'carrier_guids',
        plain.carriers.map(({ guid }) => guid),
      );
      unset(plain, 'carriers');
    }

    if (status === 'new') {
      plain.statuses = ['new', 'canceled'];
    } else if (status) {
      plain.statuses = [status];
    }

    if (plain.is_superpay_available_carrier) {
      set(plain, 'is_superpay_available_carrier', true);
    }

    if (plain.is_carrier_requested_superpay) {
      set(plain, 'is_carrier_requested_superpay', true);
    }

    if (plain.is_expedited_payment) {
      set(plain, 'is_expedited_payment', true);
    }

    return JSON.stringify(orderSearchPayloadSchema.cast(plain));
  }, [filters, searchString, status]);

interface UseOrdersProps {
  page: number;
  searchBody?: string;
  status?: RouteStatus;
  params: PageParams;
}

export function useOrders({
  searchBody,
  status,
  page,
  params,
}: UseOrdersProps) {
  const isSearch = !!searchBody;

  const searchQuery = useOrdersSearch({
    page,
    searchBody,
    params,
    enabled: isSearch,
  });
  const listQuery = useOrdersList({
    page,
    status,
    params,
    enabled: !isSearch,
  });

  return isSearch ? searchQuery : listQuery;
}

export function useRelatedLoads(orderId?: number, enabled?: boolean) {
  const { requestPage } = useAPI();

  return useAPIPageQuery(
    ['orders', 'loads', { orderId }],
    () =>
      requestPage('/internal/orders/{orderId}/loads', (data) => data as Order, {
        orderId,
      }),
    {
      enabled,
      refetchOnMount: true,
    },
  );
}

export function useRelatedLoadBasicInfo(orderIds: number[]) {
  const { requestPage } = useAPI();

  return useAPIPageQuery(
    ['loads', 'basic_info', { orderIds }],
    () =>
      requestPage(
        '/internal/orders/loads/basic_info{?orderIds}',
        (data) => data as LoadBasicInfo,
        {
          orderIds,
        },
      ),
    {
      enabled: orderIds.length > 0,
      refetchOnMount: true,
    },
  );
}

export function useRelatedLegs(orderIds?: number[]) {
  const { requestPage } = useAPI();

  return useAPIPageQuery(
    ['orders', 'related_loads', { orderIds }],
    () =>
      requestPage(
        '/internal/orders/loads{?orderIds}',
        (data) => data as OrderWithLoadsDTO,
        { orderIds },
      ),
    {
      enabled: !!orderIds?.length,
      schema: orderWithLoadsSchema,
    },
  );
}

type RelatedLegsMap = Record<
  OrderWithLoadsDTO['order_id'],
  {
    loads: OrderWithLoadsDTO['loads'];
    has_unassigned_vehicles: OrderWithLoadsDTO['has_unassigned_vehicles'];
  }
>;

export function useHasUnassignedVehicles(orderId?: number): boolean {
  const { data: { objects: relatedLegs } = {} } = useRelatedLegs(
    orderId ? [orderId] : [],
  );

  return useMemo(() => {
    if (!relatedLegs) {
      return false;
    }

    return (
      relatedLegs.find(({ order_id }) => order_id === orderId)
        ?.has_unassigned_vehicles ?? false
    );
  }, [relatedLegs]);
}

export function useRelatedLegsMap(orderIds?: number[]): RelatedLegsMap {
  const { data: { objects: relatedLegs } = {} } = useRelatedLegs(orderIds);

  const relatedLegsMap = useMemo(() => {
    if (!relatedLegs) {
      return {};
    }

    return relatedLegs.reduce(
      (current, { order_id, loads, has_unassigned_vehicles }) => {
        current[order_id] = {
          loads,
          has_unassigned_vehicles,
        };
        return current;
      },
      {},
    );
  }, [relatedLegs]);

  return relatedLegsMap;
}

export function useOrderInspectionPhotoIssues(
  orderIds: number[],
  options?: APIQueryOptions<Record<string, number>>,
) {
  const { requestResource } = useAPI();

  return useAPIQuery(
    ['orders', 'vehicle_photos_with_issues', { orderIds: orderIds.join(',') }],
    () =>
      requestResource(
        'GET /internal/orders/vehicle_photos_with_issues{?orderIds}',
        (data) => data as Record<string, number>,
        {
          orderIds,
        },
      ),
    {
      enabled: orderIds.length > 0,
      refetchOnMount: true,
      ...options,
    },
  );
}

export function useDepositExpectedDate(
  options?: APIQueryOptions<ExpectedDepositDate>,
) {
  const { requestResource } = useAPI();

  return useAPIQuery(
    ['orders', 'deposit_expected_date'],
    () =>
      requestResource(
        'GET /internal/payments/expected_superpay_now_date',
        (data) => expectedDepositDateSchema.cast(data),
      ),
    {
      ...options,
    },
  );
}

export function useCreateDemoOrderMutation(
  options: APIMutationOptions<undefined, Order>,
) {
  const { createDemoOrder } = useOrderAPI();
  return useAPIMutation(createDemoOrder, options);
}
