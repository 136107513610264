import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import {
  ColorDynamic,
  Image,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { apiInstance } from 'shared/api/API';
import { useUserState } from 'shared/data/AppUserState';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { getUserRoleNamesString } from 'shared/helpers/UserHelpers';
import styled from 'styled-components';
import download from './assets/ic-download.svg';
import InvoiceIcon from './assets/ic-notes.svg';
import { StatusTag } from './StatusTag';
import { useInvoices } from './SubscriptionMonthlyUsageCard/InvoicesAPI';

const StyledCard = styled(Card)`
  overflow-x: auto;
  width: 100%;
`;

const StyledEmptyCard = styled(Card)`
  padding: 48px;
  color: ${ColorDynamic.Dark100};
`;

function useInvoiceDownloader() {
  const { addSnackbar } = useSnackbarStack();

  const handleDownload = (invoiceId: string) => {
    apiInstance
      .requestBlob(`/internal/billing/invoices/${invoiceId}?download`)
      .then((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `invoice_${invoiceId}.pdf`;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error: Error) => {
        return addSnackbar(error.message, { variant: 'error' });
      });
  };

  return { handleDownload };
}

export function InvoicesTable() {
  const { data } = useInvoices();
  const { handleDownload } = useInvoiceDownloader();
  const { user } = useUserState();

  const shipper = user?.shipper;
  const shipperType = shipper?.shipper_type;

  const userRole = getUserRoleNamesString(user);

  if (data?.objects.length) {
    return (
      <StyledCard>
        <Stack space="small">
          <Typography variant="h3" style={{ paddingLeft: 16, paddingTop: 16 }}>
            Invoices
          </Typography>
          <Table aria-label="Invoices Table">
            <TableHead>
              <TableRow>
                <TableCell>Invoice Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Invoice #</TableCell>
                <TableCell>Invoice Total</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.objects.map((invoice) => (
                <TableRow key={invoice.number}>
                  <TableCell>
                    {formatDate(invoice.date, { variant: 'Date' })}
                  </TableCell>
                  <TableCell>
                    <StatusTag status={invoice.status} />
                  </TableCell>
                  <TableCell>{invoice.number}</TableCell>
                  <TableCell>{formatCurrency(invoice.total ?? 0)}</TableCell>
                  <TableCell>
                    <Image
                      src={download}
                      alt="download"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleDownload(invoice.number);
                        trackEvent('Shipper Download Billing Invoice ', {
                          utm_medium: 'Shipper Download Billing Invoice ',
                          utm_source: 'Subscription Details',
                          user_roles: userRole,
                          shipper_type: shipperType,
                          status: invoice.status,
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      </StyledCard>
    );
  }
  return (
    <StyledEmptyCard>
      <Stack space="small" align="center">
        <Image src={InvoiceIcon} alt="invoice" />
        <Typography variant="h3">No Invoices yet</Typography>
      </Stack>
    </StyledEmptyCard>
  );
}
