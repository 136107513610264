import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { ColorDynamic } from '@superdispatch/ui';
import * as React from 'react';
import { useCustomersNavigation } from '../CustomersRouteHelpers';

export function CustomersSearchField() {
  const { customersSearchParams, navigateToCustomers } =
    useCustomersNavigation();
  const { query } = customersSearchParams;

  function clearSearch() {
    navigateToCustomers({
      ...customersSearchParams,
      query: null,
    });
  }

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    navigateToCustomers(
      {
        ...customersSearchParams,
        query: event.target.value,
      },
      {
        replace: true,
      },
    );
  }

  return (
    <TextField
      fullWidth={true}
      placeholder="3 symbols to search"
      value={query || ''}
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color="action" />
          </InputAdornment>
        ),
        endAdornment: query && (
          <InputAdornment position="end">
            <IconButton onClick={clearSearch}>
              <Close htmlColor={ColorDynamic.Dark300} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
