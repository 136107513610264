import { ALL_PAYMENT_METHODS } from '@superdispatch/sdk';
import { yupNumber, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type MarkAsPaidPayloadDTO = InferType<typeof markAsPaidPayloadSchema>;

export const markAsPaidPayloadSchema = yupObject({
  method: yupString().oneOf(ALL_PAYMENT_METHODS).optional().nullable(),
  terms: yupString().nullable(),
  amount: yupNumber().optional().nullable(),
  notes: yupString().optional().nullable(),
  sent_date: yupString().optional().nullable(),
  reference_number: yupString().nullable().optional(),
});

export type MarkAsPaidFormDTO = InferType<typeof markAsPaidFormSchema>;

export const markAsPaidFormSchema = markAsPaidPayloadSchema.shape({
  method: yupString().oneOf(ALL_PAYMENT_METHODS).default('').nullable(),
});

export type MarkAsCustomerPaidPayloadDTO = InferType<
  typeof markAsCustomerPaidPayloadSchema
>;

export const markAsCustomerPaidPayloadSchema = yupObject({
  method: yupString().oneOf(ALL_PAYMENT_METHODS).optional().nullable(),
  amount: yupNumber().optional().nullable(),
  deposit: yupNumber().optional().nullable(),
  tariff: yupNumber().optional().nullable(),
  terms: yupString().optional().nullable(),
  notes: yupString().optional().nullable(),
  received_date: yupString().optional().nullable(),
  reference_number: yupString().optional().nullable(),
});

export type MarkAsCustomerPaidFormDTO = InferType<
  typeof markAsCustomerPaidFormSchema
>;

export const markAsCustomerPaidFormSchema =
  markAsCustomerPaidPayloadSchema.shape({
    method: yupString().oneOf(ALL_PAYMENT_METHODS).default('').nullable(),
  });
