import { ColorDynamic } from '@superdispatch/ui';

export function ApprovedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.00001 0.333252C7.87549 0.333252 8.7424 0.50569 9.55123 0.840722C10.3601 1.17575 11.095 1.66682 11.7141 2.28587C12.3331 2.90493 12.8242 3.63986 13.1592 4.4487C13.4942 5.25753 13.6667 6.12444 13.6667 6.99992C13.6667 8.76803 12.9643 10.4637 11.7141 11.714C10.4638 12.9642 8.76812 13.6666 7.00001 13.6666C6.12453 13.6666 5.25762 13.4941 4.44879 13.1591C3.63995 12.8241 2.90502 12.333 2.28596 11.714C1.03572 10.4637 0.333344 8.76803 0.333344 6.99992C0.333344 5.23181 1.03572 3.53612 2.28596 2.28587C3.53621 1.03563 5.2319 0.333252 7.00001 0.333252ZM6.33334 3.66659C5.97972 3.66659 5.64058 3.80706 5.39053 4.05711C5.14049 4.30716 5.00001 4.6463 5.00001 4.99992V10.3333H6.33334V7.66659H7.66668V10.3333H9.00001V4.99992C9.00001 4.6463 8.85953 4.30716 8.60949 4.05711C8.35944 3.80706 8.0203 3.66659 7.66668 3.66659H6.33334ZM6.33334 4.99992H7.66668V6.33325H6.33334V4.99992Z"
        fill={ColorDynamic.Green500}
      />
    </svg>
  );
}
