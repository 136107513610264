import {
  Card,
  CardContent,
  Container,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import {
  ColorDynamic,
  Column,
  Columns,
  Stack,
  SuperDispatchTheme,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import {
  AppThemeModeSetting,
  setThemeModeSetting,
  useThemeModeSetting,
} from 'shared/theme/AppTheme';
import styled, { css } from 'styled-components';
import { useProductTiers } from '../shared/data/TiersUtils';
import {
  useGeneralSettings,
  useGeneralSettingsAPI,
} from './data/GeneralSettingsAPI';
import { GeneralSettingsDTO } from './data/GeneralSettingsDTO';
import { OrderTagsSettings } from './OrderTagsSettings';
import { SettingsAppearance } from './SettingsAppearance';
import { SingleSignOnSettingsCard } from './SingleSignOnSettingsCard';
import { TimeZoneAutocomplete } from './TimeZoneAutocomplete';

export const StyledBodyText = styled(Typography)`
  color: ${ColorDynamic.Dark300};
`;

const StyledAppBar = styled(AppBar)`
  border-left: none;
  border-top: none;
`;

const StyledContainer = styled(Container)(
  ({ theme }: { theme: SuperDispatchTheme }) => css`
    padding-left: 32px;
    padding-right: 32px;
    .MuiFormControl-root {
      max-width: 171px;
    }
    ${theme.breakpoints.down('md')} {
      padding: 0px;
      .MuiFormControl-root {
        max-width: 100%;
      }
    }
  `,
);

function getSettingUpdateParams(
  prevValues: GeneralSettingsDTO,
  values: GeneralSettingsDTO,
): [settingType: keyof GeneralSettingsDTO, value: string] {
  if (prevValues.bol_template !== values.bol_template) {
    return ['bol_template', values.bol_template || ''];
  } else if (prevValues.inspection_type !== values.inspection_type) {
    return ['inspection_type', values.inspection_type || ''];
  }
  return ['time_zone', values.time_zone];
}

export function GeneralSettingsPage() {
  const { data: settings } = useGeneralSettings();
  const { isAdmin, isSuperUser, refetch } = useUserState();
  const { updateSetting } = useGeneralSettingsAPI();
  const canUpdateSettings = useCanExecute('UPDATE_COMPANY_PROFILE');
  const isSSOEnabled = useFeatureToggle('sso.enabled.ui');
  const { isAdvancedTier } = useProductTiers();
  const themeSetting = useThemeModeSetting();

  const { addSnackbar } = useSnackbarStack();
  const formik = useFormikEnhanced<GeneralSettingsDTO, void>({
    initialValues: settings
      ? {
          ...settings,
          theme_mode: themeSetting,
        }
      : {
          theme_mode: themeSetting,
          bol_template: '',
          inspection_type: '',
          time_zone: '',
          order_tag_settings: [],
          max_number_of_tags: 1,
        },
    onSubmit: (values) => {
      setThemeModeSetting(values.theme_mode as AppThemeModeSetting);
      const settingUpdateParams = getSettingUpdateParams(
        formik.initialValues,
        values,
      );
      return updateSetting(settingUpdateParams[0], settingUpdateParams[1]);
    },
    onSubmitSuccess: () => {
      void refetch();
      addSnackbar('Settings updated', {
        variant: 'success',
      });
    },
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
  });

  const { handleSubmit } = formik;

  useDocumentTitle('General Settings');

  useEffect(() => {
    if (!isEqual(formik.initialValues, formik.values)) {
      handleSubmit(undefined);
    }
  }, [formik.initialValues, formik.values, handleSubmit]);

  return (
    <PageLayout
      isContentLoading={!settings}
      header={
        settings && (
          <StyledAppBar>
            <Toolbar>
              <Box width="100%" marginLeft="small" marginRight="medium">
                <Columns>
                  <Column width="fluid">
                    <Typography variant="h2">General</Typography>
                  </Column>
                </Columns>
              </Box>
            </Toolbar>
          </StyledAppBar>
        )
      }
    >
      <FormikProvider value={formik}>
        <StyledContainer maxWidth="md">
          {isSSOEnabled && (isAdmin || isSuperUser) && (
            <Box marginTop="small">
              <SingleSignOnSettingsCard />
            </Box>
          )}
          <Box marginTop="small" marginBottom="large">
            <Stack space="small">
              <SettingsAppearance />
            </Stack>
          </Box>
          <Box marginTop="small" marginBottom="large">
            <Card>
              <CardContent>
                <Box maxWidth="688px">
                  <Stack space="large">
                    <Stack space="xxsmall">
                      <Typography>BOL Template</Typography>
                      <FormikTextField
                        name="bol_template"
                        select={true}
                        disabled={!canUpdateSettings}
                        fullWidth={true}
                      >
                        <MenuItem value="broker-default">Shipper</MenuItem>
                        <MenuItem value="default">Carrier</MenuItem>
                        <MenuItem value="broker-multiple-units">
                          Shipper Multi-Unit
                        </MenuItem>
                        <MenuItem value="multiple-units">
                          Carrier Multi-Unit
                        </MenuItem>
                      </FormikTextField>
                      <StyledBodyText>
                        Default BOL Template for the STMS account. Select if
                        carrier or shipper information is preferred to show on
                        each BOL. Multi-Unit BOLs will have all vehicles listed
                        on one page, as opposed to one page per vehicle.
                      </StyledBodyText>
                    </Stack>
                    <Stack space="xxsmall">
                      <Typography>Inspection Type</Typography>
                      <FormikTextField
                        name="inspection_type"
                        select={true}
                        disabled={!canUpdateSettings}
                        fullWidth={true}
                      >
                        <MenuItem value="standard">Standard</MenuItem>
                        <MenuItem value="advanced">Advanced</MenuItem>
                        <MenuItem value="aiag">AIAG</MenuItem>
                      </FormikTextField>

                      <StyledBodyText>
                        Default Inspection Type for the STMS account. Standard
                        inspection doesn’t have any required steps for drivers.
                        Advanced inspection requires drivers to take at least 6
                        photos at pickup and delivery. AIAG inspection is used
                        for OEM customers and requires 2 photos per damage.
                      </StyledBodyText>
                    </Stack>
                    <Stack space="xxsmall">
                      <Typography>Time Zone</Typography>
                      <TimeZoneAutocomplete
                        name="time_zone"
                        disabled={!canUpdateSettings}
                      />
                      <StyledBodyText>
                        Default time zone for the STMS account. Changing it will
                        affect time-dependent features for all users.
                      </StyledBodyText>
                    </Stack>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Box>

          {settings && isAdvancedTier && (
            <OrderTagsSettings
              orderTags={settings.order_tag_settings}
              maxNumberOfTags={settings.max_number_of_tags}
            />
          )}
        </StyledContainer>
      </FormikProvider>
    </PageLayout>
  );
}
