import { Link, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Inline, Stack } from '@superdispatch/ui';
import { Alert } from '@superdispatch/ui-lab';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useBillingStatus } from 'shared/errors/paywall/data/PaywallAPI';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';

export function SubscriptionAlerts() {
  return <SubscriptionPaymentFailedAlert />;
}

export function SubscriptionPaymentFailedAlert() {
  const { data: billingStatus } = useBillingStatus();
  const isPaymentDueEnabled = useFeatureToggle(
    'subscription-past-due-payment.enabled',
  );

  if (
    !isPaymentDueEnabled ||
    (billingStatus?.status !== 'PAYMENT_DUE' &&
      billingStatus?.status !== 'NOT_PAID')
  ) {
    return null;
  }

  return (
    <SubscriptionAlert title="Payment Required: Action Needed">
      Your recent payment attempt was unsuccessful. Please update your payment
      method to continue to access the Super Loadboard.
    </SubscriptionAlert>
  );
}

function SubscriptionAlert({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  const { showCustomerDialog } = useCustomerSupport();

  return (
    <Alert icon={<Info />} severity="critical">
      <Stack>
        <Typography variant="body1">{title}</Typography>
        <Typography>{children}</Typography>
        <Inline space="small">
          <Link
            onClick={(e) => {
              e.preventDefault();
              showCustomerDialog();
            }}
            component="button"
          >
            Contact Support
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://superdispatch.com/terms-of-service/"
          >
            Terms & Conditions
          </Link>
        </Inline>
      </Stack>
    </Alert>
  );
}
