import { useSnackbarStack } from '@superdispatch/ui';
import { apiInstance } from 'shared/api/API';
import { useAPIMutation } from 'shared/api/APIMutation';
import { isAPIError } from 'shared/errors/APIError';

export function useResendMfaCode({ onSuccess }: { onSuccess: () => void }) {
  const { addSnackbar } = useSnackbarStack();

  const { mutate, isLoading } = useAPIMutation(() => resendMfaCode(), {
    onSuccess: () => {
      addSnackbar('Code resent successfully', { variant: 'success' });
      onSuccess();
    },
    onError: (error) => {
      if (isAPIError(error)) {
        addSnackbar(error.message, { variant: 'error' });
      } else {
        addSnackbar('Failed to resend code', { variant: 'error' });
      }
    },
  });

  return {
    resend: mutate,
    isLoading,
  };
}

function resendMfaCode() {
  return apiInstance.request(
    'POST /internal/central-dispatch/credentials/verification/code/send',
  );
}
