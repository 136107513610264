import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSnackbarStack, useUID } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { ErrorItems } from './ErrorItems';

export type APIErrorDetails =
  | string
  | Record<string, string | APIOrderErrorResponse>;

// TODO move to shared/API
export interface APIOrderErrorResponse {
  details: APIErrorDetails;
  error_id: string;
  message: string;
}

const subtitleNotVisibleErrorIds = [
  'TOO_MANY_OFFERS_TO_SEND',
  'COMBINED_INVOICE_ORDERS_SIZE',
];

export function isOrderError(error: unknown): error is APIOrderErrorResponse {
  return error instanceof Error && 'details' in error;
}

interface OrderErrorModalProps {
  onCancel: () => void;
  error: unknown;
}

export function OrderErrorModal({ error, onCancel }: OrderErrorModalProps) {
  const uid = useUID();
  const { addSnackbar } = useSnackbarStack();

  useEffect(() => {
    if (isOrderError(error)) {
      return;
    }

    if (error instanceof Error) {
      addSnackbar(error.message, { variant: 'error' });
    }
  }, [error, addSnackbar]);

  if (!isOrderError(error)) {
    return null;
  }

  const isSubtitleVisible = !subtitleNotVisibleErrorIds.includes(
    error.error_id,
  );

  const { details, message } = error;
  const subtitle =
    message ||
    'The following orders must be corrected before proceeding further:';

  return (
    <Dialog
      open={(!!details && Object.keys(details).length > 0) || !!message}
      aria-labelledby={uid}
      onClose={onCancel}
    >
      <DialogTitle>
        <Typography id={uid} variant="h2">
          Some Errors Occurred
        </Typography>
      </DialogTitle>

      <DialogContent>
        {isSubtitleVisible && subtitle && <p>{subtitle}</p>}
        <ErrorItems error={error} />
      </DialogContent>

      <DialogActions>
        <Button variant="primary" onClick={onCancel}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
