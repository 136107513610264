import { Link, LinkProps } from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderFormValues } from '../../orders/core/form/OrderForm';
import { SuperPayCancelOrderEditDialog } from './SuperPayCancelDialog';

const PointerLink = styled(Link)`
  cursor: pointer;
`;

export function CancelSuperPayLinkButton({
  color,
  order,
  onClose,
}: {
  color?: LinkProps['color'];
  order: OrderFormValues | Order;
  onClose?: () => void;
}) {
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  return (
    <>
      <PointerLink color={color} onClick={() => setOpenCancelDialog(true)}>
        Cancel SuperPay
      </PointerLink>
      <SuperPayCancelOrderEditDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onSuccess={onClose}
        order={order}
      />
    </>
  );
}

export function CancelSuperPayButton({ order }: { order: OrderFormValues }) {
  const [isOpenCancelDialog, setOpenCancelDialog] = useState<boolean>(false);

  return (
    <>
      <Button variant="neutral" onClick={() => setOpenCancelDialog(true)}>
        Cancel SuperPay
      </Button>
      <SuperPayCancelOrderEditDialog
        open={isOpenCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        order={order}
      />
    </>
  );
}
