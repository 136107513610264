import {
  yupArray,
  yupNumber,
  yupObject,
  yupString,
  yupStringDefaultValue,
} from 'shared/utils/YupUtils';
import { array, InferType, string } from 'yup';

const CARRIER_RATING_TYPES = ['POSITIVE', 'NEGATIVE'] as const;

export type CarrierRatingType = typeof CARRIER_RATING_TYPES[number];

const CARRIER_POSITIVE_RATING_TAG = [
  'ON_TIME_TRANSPORT',
  'GOOD_COMMUNICATION',
  'GOOD_CUSTOMER_SERVICE',
] as const;

export type CarrierPositiveRatingTag =
  typeof CARRIER_POSITIVE_RATING_TAG[number];

const CARRIER_NEGATIVE_RATING_TAG = [
  'LATE_TRANSPORT',
  'DAMAGE',
  'BAD_COMMUNICATION',
  'BAD_CUSTOMER_SERVICE',
  'LATE_CANCELLATION_OR_NO_SHOW',
  'DOUBLE_BROKERING',
] as const;

export const CARRIER_RATING_TAGS = [
  ...CARRIER_POSITIVE_RATING_TAG,
  ...CARRIER_NEGATIVE_RATING_TAG,
] as const;

export type CarrierRatingTag = typeof CARRIER_RATING_TAGS[number];

export const CARRIER_RATING_STATUS = ['PENDING', 'PUBLISHED'] as const;

export type CarrierRatingStatus = typeof CARRIER_RATING_STATUS[number];

export type RateCarrierPayloadDTO = InferType<
  typeof rateCarrierPayloadSchema
> & {
  tags: CarrierRatingTag[]; // Yup cannot set this type. So we had to override it
};

export const rateCarrierPayloadSchema = yupObject({
  rating: yupString().oneOf(CARRIER_RATING_TYPES),
  comment: yupString().nullable(),
  tags: yupArray(yupString().oneOf(CARRIER_RATING_TAGS)).default([]).defined(),
});

export type CarrierRatingDTO = InferType<typeof carrierRatingSchema>;

export const carrierRatingSchema = yupObject({
  guid: yupStringDefaultValue(),
  rated_at: yupStringDefaultValue(),
  comment: yupString().nullable(),
  rating: yupStringDefaultValue().oneOf(CARRIER_RATING_TYPES),
  shipper_region: yupStringDefaultValue(),
  overall_moved_vehicles_count: yupNumber().nullable(),
  tags: array().of(string().oneOf(CARRIER_RATING_TAGS)).defined().default([]),
  status: yupString().oneOf(CARRIER_RATING_STATUS),
  response: yupString().nullable(),
  response_date: yupString().nullable(),
});

export const formatRatingTag = (tag: CarrierRatingTag) => {
  switch (tag) {
    case 'ON_TIME_TRANSPORT':
      return 'On-Time Transport';
    case 'GOOD_COMMUNICATION':
      return 'Good Communication';
    case 'GOOD_CUSTOMER_SERVICE':
      return 'Good Customer Service';
    case 'LATE_TRANSPORT':
      return 'Late Transport';
    case 'DAMAGE':
      return 'Damage';
    case 'BAD_COMMUNICATION':
      return 'Bad Communication';
    case 'BAD_CUSTOMER_SERVICE':
      return 'Bad Customer Service';
    case 'LATE_CANCELLATION_OR_NO_SHOW':
      return 'Late Cancellation or No Show';
    case 'DOUBLE_BROKERING':
      return 'Double Brokering';
  }
};

export type RatingStatsDTO = InferType<typeof ratingStatsSchema>;

export const ratingStatsSchema = yupObject({
  total_rating_count: yupNumber().nullable(),
  overall_rating: yupNumber().nullable(),
});

export type LastRatedAtDTO = InferType<typeof lastRatedAtSchema>;

export const lastRatedAtSchema = yupObject({
  rated_at: yupString().nullable(),
});

export type CarrierRatingTagStatsDTO = InferType<
  typeof carrierRatingTagStatsSchema
>;

export const carrierRatingTagStatsSchema = yupObject({
  ON_TIME_TRANSPORT: yupNumber(),
  GOOD_CUSTOMER_SERVICE: yupNumber(),
  GOOD_COMMUNICATION: yupNumber(),
});

export type UnratedCarriersCountDTO = InferType<
  typeof unratedCarriersCountSchema
>;

export const unratedCarriersCountSchema = yupObject({
  unrated_count: yupNumber().default(0),
});
