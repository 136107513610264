import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { CUSTOMER_TYPES, formatCustomerType } from '@superdispatch/sdk';
import {
  ColorDynamic,
  InfoTooltip,
  Inline,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { get } from 'lodash';
import { FC, memo, useEffect, useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useCanExecute } from 'shared/data/UserPermissions';
import { CounterpartyContactDTO } from 'shared/dto/CounterpartyContactDTO';
import { isCustomerDTO } from 'shared/dto/CustomerDTO';
import { required } from 'shared/utils/ValidatorUtils';
import { OrderFormValues } from '../OrderForm';
import { CounterpartyAddress } from './CounterpartyAddress';
import { CounterpartyContactChangeReason } from './CounterpartyContactAutocomplete';
import { CounterpartyContactFields } from './CounterpartyContactFields';
import {
  CustomerAutocomplete,
  CustomerChangeReason,
  CustomerContact,
  TerminalAutocompleteValue,
  useCustomerAutocompleteProps,
} from './CustomerAutocomplete';

function validateCounterparty(value: TerminalAutocompleteValue | undefined) {
  const error = required(value?.name);
  if (error) return { name: error };
  return undefined;
}

export interface CustomerCounterpartyFieldsProps {
  isOrderCreate: boolean;
  customer: OrderFormValues['customer'];
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean,
  ) => void;
}

export const CustomerCounterpartyFields: FC<CustomerCounterpartyFieldsProps> =
  memo(({ isOrderCreate, customer, setFieldValue }) => {
    const canManageCustomer = useCanExecute('CUSTOMER_MANAGEMENT');
    const { user } = useUserState();
    const { contact, counterparty_guid } = customer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialCustomer = useMemo(() => customer, []);

    const isNewCounterParty =
      !!get(customer, 'save_as_new') && !get(customer, 'save_as_recent');
    const isNewContact = get(customer, 'save_as_new_contact');

    const autocompleteProps = useCustomerAutocompleteProps();
    const contacts = useMemo(() => {
      const option = autocompleteProps.options.find(
        (x) => x.guid === counterparty_guid,
      );

      return option?.contacts;
    }, [autocompleteProps.options, counterparty_guid]);

    useEffect(() => {
      const counterparty = autocompleteProps.options.find(
        (x) => x.guid === counterparty_guid,
      );

      if (counterparty && isOrderCreate && !!autocompleteProps.loading) {
        handleCounterpartyChange(counterparty, 'select-option');
      }
    }, [autocompleteProps.options, counterparty_guid]);

    const setFormValue = (
      name: string,
      value?: string | number | boolean | null | CustomerContact,
    ) => {
      setFieldValue(name, value);
    };

    function handleCounterpartyChange(
      counterparty: TerminalAutocompleteValue,
      reason: CustomerChangeReason,
    ) {
      if (reason === 'clear') {
        setFormValue('instructions', null);
        setFormValue('loadboard_instructions', null);
        return;
      }

      const { contacts: customerContacts = [] } = counterparty;
      const nextContact =
        customerContacts.length === 1
          ? customerContacts[0]
          : customerContacts.find((contactItem) => contactItem.is_primary);

      setFormValue('customer.counterparty_guid', counterparty.guid);

      if (
        user &&
        !user.order_form_settings.customer.is_contact_mobile_visible &&
        nextContact
      ) {
        nextContact.mobile_phone = '';
      }

      if (
        user &&
        !user.order_form_settings.customer.is_contact_email_visible &&
        nextContact
      ) {
        nextContact.email = '';
      }

      if (
        user &&
        !user.order_form_settings.customer.is_contact_title_visible &&
        nextContact
      ) {
        nextContact.title = '';
      }

      setFormValue(
        'customer.contact',
        reason === 'select-option' ? nextContact : counterparty.contact,
      );

      setFormValue(
        'customer.address',
        user?.order_form_settings.customer.is_address_visible || !isOrderCreate
          ? counterparty.address
          : null,
      );
      setFormValue(
        'customer.city',
        user?.order_form_settings.customer.is_address_visible || !isOrderCreate
          ? counterparty.city
          : null,
      );
      setFormValue(
        'customer.state',
        user?.order_form_settings.customer.is_address_visible || !isOrderCreate
          ? counterparty.state
          : null,
      );
      setFormValue(
        'customer.zip',
        user?.order_form_settings.customer.is_address_visible || !isOrderCreate
          ? counterparty.zip
          : null,
      );

      setFormValue(
        'customer.phone',
        user?.order_form_settings.customer.is_phone_visible || !isOrderCreate
          ? counterparty.phone
          : null,
      );
      setFormValue(
        'customer.email',
        user?.order_form_settings.customer.is_email_visible || !isOrderCreate
          ? counterparty.email
          : null,
      );
      setFormValue(
        'customer.notes',
        user?.order_form_settings.customer.is_notes_visible || !isOrderCreate
          ? counterparty.notes
          : null,
      );

      setFormValue(
        'customer.business_type',
        user?.order_form_settings.customer.is_business_type_visible
          ? counterparty.business_type
          : null,
      );
      setFormValue(
        'customer.bill_the_delivery',
        counterparty.bill_the_delivery,
      );

      if (canManageCustomer) {
        setFormValue('customer.save_as_new', !counterparty.guid);
      }

      if (isOrderCreate) {
        setFormValue(
          'customer.selected_from_recent',
          counterparty.save_as_recent,
        );

        if (
          typeof counterparty.save_as_recent !== 'undefined' &&
          !get(customer, 'save_as_recent')
        ) {
          setFormValue('customer.save_as_new', false);
        }
      }

      if (isCustomerDTO(counterparty)) {
        setFormValue(
          'instructions',
          user?.order_form_settings.is_instructions_visible
            ? counterparty.order_instructions
            : '',
        );
        setFormValue(
          'loadboard_instructions',
          user?.order_form_settings.is_loadboard_instructions_visible
            ? counterparty.loadboard_instructions
            : '',
        );
      }
    }

    function handleContactChange(
      counterpartyContact: Partial<CounterpartyContactDTO>,
      reason: CounterpartyContactChangeReason,
    ) {
      if (reason === 'clear') {
        setFormValue('customer.save_as_new_contact', false);
      } else {
        setFormValue('customer.save_as_new_contact', !counterpartyContact.id);
      }
    }
    const styleLabelText = (text: string) => (
      <Typography color="textPrimary">{text}</Typography>
    );
    return (
      <Stack space="small">
        <Grid container={true} spacing={2} wrap="wrap">
          <Grid item={true} xs={12} md={8}>
            <CustomerAutocomplete
              {...autocompleteProps}
              name="customer"
              isNew={isNewCounterParty}
              onChange={handleCounterpartyChange}
              validate={
                isNewCounterParty || !!contact?.name
                  ? validateCounterparty
                  : undefined
              }
              label={
                <Box display="flex" justifyContent="space-between">
                  <Inline>
                    {styleLabelText('Business Name')}
                    {isNewCounterParty && (
                      <Tag variant="subtle" color="blue">
                        New Customer
                      </Tag>
                    )}
                  </Inline>
                </Box>
              }
            />
          </Grid>

          {(user?.order_form_settings.customer.is_business_type_visible ||
            (!isOrderCreate && initialCustomer.business_type)) && (
            <Grid item={true} xs={12} md={4}>
              <FormikTextField
                label={styleLabelText('Type')}
                fullWidth={true}
                select={true}
                disabled={!canManageCustomer}
                name="customer.business_type"
              >
                {CUSTOMER_TYPES.map((value) => (
                  <MenuItem key={value} value={value}>
                    {formatCustomerType(value)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
          )}
        </Grid>

        {(user?.order_form_settings.customer.is_address_visible ||
          initialCustomer.address ||
          initialCustomer.city ||
          initialCustomer.state ||
          initialCustomer.zip) && (
          <CounterpartyAddress
            name="customer"
            disabled={!canManageCustomer}
            setFieldValue={setFieldValue}
          />
        )}

        {(user?.order_form_settings.customer.is_email_visible ||
          user?.order_form_settings.customer.is_phone_visible ||
          initialCustomer.email ||
          initialCustomer.phone) && (
          <Grid container={true} spacing={2} wrap="wrap">
            {(user?.order_form_settings.customer.is_phone_visible ||
              initialCustomer.phone) && (
              <Grid item={true} md={12} lg={6}>
                <FormikTextField
                  label={styleLabelText('Phone')}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 50,
                  }}
                  disabled={!canManageCustomer}
                  name="customer.phone"
                />
              </Grid>
            )}
            {(user?.order_form_settings.customer.is_email_visible ||
              initialCustomer.email) && (
              <Grid item={true} md={12} lg={6}>
                <FormikTextField
                  type="email"
                  fullWidth={true}
                  label={styleLabelText('Billing Email')}
                  disabled={!canManageCustomer}
                  name="customer.email"
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}

        <CounterpartyContactFields
          contacts={contacts}
          isNew={isNewContact ?? false}
          name="customer"
          onChange={handleContactChange}
          title={customer.contact?.title}
          phone={customer.contact?.mobile_phone}
          email={customer.contact?.email}
        />

        {(user?.order_form_settings.customer.is_notes_visible ||
          initialCustomer.notes) && (
          <Box p={2} bgcolor={ColorDynamic.Blue50}>
            <FormikTextField
              rows={3}
              multiline={true}
              fullWidth={true}
              inputProps={{
                maxLength: 2000,
              }}
              name="customer.notes"
              InputProps={{ style: { background: ColorDynamic.White } }}
              label={
                <Box display="flex" justifyContent="space-between">
                  <InfoTooltip
                    title="Visible to your customer inside the Customer Portal product"
                    TextProps={{ color: 'textPrimary' }}
                  >
                    Notes for Customer
                  </InfoTooltip>

                  <TextBox color="secondary">
                    {`${customer.notes?.length || 0} of 2000`}
                  </TextBox>
                </Box>
              }
            />
          </Box>
        )}
      </Stack>
    );
  });

CustomerCounterpartyFields.displayName = 'CustomerCounterpartyFields';
