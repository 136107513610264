import { Toolbar, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { InvoicesTable } from './InvoicesTable';
import { SubscriptionAlerts } from './SubscriptionAlerts';
import { SubscriptionMonthlyUsageCard } from './SubscriptionMonthlyUsageCard/SubscriptionMonthlyUsageCard';
import { SubscriptionPaymentInformationCard } from './SubscriptionPaymentInformationCard';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 32px;
  max-width: calc(736px + 64px);
  gap: 16px;
`;

export function SubscriptionDetailsPage() {
  const { user } = useUserState();
  const userRole = user?.user_roles.map((role) => role.role.name).join(', ');
  const shipperType = user?.shipper.shipper_type;

  useEffect(() => {
    trackEvent('Shipper Visited Subscription Details', {
      utm_medium: 'Subscription Details',
      utm_source: 'Web STMS',
      user_roles: userRole,
      shipper_type: shipperType,
    });
  }, []);

  return (
    <PageLayout
      header={
        <Toolbar>
          <Box marginLeft="small" marginRight="small">
            <Typography variant="h2">Subscription Details</Typography>
          </Box>
        </Toolbar>
      }
    >
      <StyledContainer>
        <SubscriptionAlerts />
        <Stack space="small">
          <SubscriptionPaymentInformationCard />
          <SubscriptionMonthlyUsageCard />
          <InvoicesTable />
        </Stack>
      </StyledContainer>
    </PageLayout>
  );
}
