import { formatPaymentMethod, formatPaymentTerm } from '@superdispatch/sdk';
import { OverflowText } from '@superdispatch/ui';
import { SuperPayIcon } from 'shared/icons/SuperPayIcon';

interface Props {
  terms?: string | null;
  method?: string | null;
}

export function PostingPaymentTerm({
  method: methodProp,
  terms: termsProp,
}: Props) {
  const method =
    methodProp &&
    (methodProp === 'superpay' ? (
      <SuperPayIcon />
    ) : (
      formatPaymentMethod(methodProp, { fallback: methodProp })
    ));

  const terms =
    termsProp &&
    formatPaymentTerm(termsProp, {
      fallback: termsProp,
      short: true,
    });

  if (!method && !terms) return null;

  return (
    <OverflowText>
      {method}
      {method && terms && ' · '}
      {terms}
    </OverflowText>
  );
}
