import { AppBar, Toolbar } from '@material-ui/core';
import { Image, Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useUserState } from 'shared/data/AppUserState';
import LogoWithBrandDark from 'shared/icons/logo-with-brand-dark.svg';
import LogoWithBrand from 'shared/icons/logo-with-brand.svg';
import styled from 'styled-components';
import { trackClickedLogout } from '../PaywallAnalyticsEvents';

const Container = styled.div`
  margin: auto;
  max-width: 1024px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function PaywallHeader() {
  const { logout } = useUserState();

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Container>
          <Inline verticalAlign="center" space="xsmall">
            <Image
              src={LogoWithBrand}
              srcDark={LogoWithBrandDark}
              alt="Super Dispatch Logo"
            />
          </Inline>

          <Button
            onClick={() => {
              logout();
              trackClickedLogout(() => {
                window.location.href = '/signin';
              });
            }}
            variant="neutral"
          >
            Logout
          </Button>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
