import {
  Avatar,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {
  ColorDynamic,
  Column,
  Columns,
  Image,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { AnchorButton, Box } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import styled from 'styled-components';
import privateCarrierIllustration from '../assets/private_carrier_illustration.png';
import privateCarrierIllustrationDark from '../assets/private_carrier_illustration_dark.png';

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  background: ${ColorDynamic.Silver200};
`;

export function PrivateNetworkAccess() {
  return (
    <Container>
      <Box width="655px" marginTop="xxlarge">
        <Columns space="large">
          <Column width="content">
            <Image
              src={privateCarrierIllustration}
              srcDark={privateCarrierIllustrationDark}
              width="220"
              alt=""
            />
          </Column>

          <Column width="fluid">
            <Stack space="large">
              <Stack space="small">
                <Typography variant="h2">
                  Do you have trusted carriers <br /> you prefer doing business
                  with?
                </Typography>
                <Typography color="textSecondary">
                  Unlock your Private Network today to give your preferred{' '}
                  <br />
                  carriers priority access to your orders.
                </Typography>
              </Stack>

              <Stack space="xsmall">
                <Typography variant="h3" color="textSecondary">
                  How it works
                </Typography>

                <List>
                  <ListItem disableGutters={true}>
                    <ListItemAvatar>
                      <Avatar>1</Avatar>
                    </ListItemAvatar>
                    <Typography variant="h5" color="textSecondary">
                      Add trusted carriers to your Private Network
                    </Typography>
                  </ListItem>

                  <ListItem disableGutters={true} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar>2</Avatar>
                    </ListItemAvatar>
                    <Stack space="xxsmall">
                      <ListItemText
                        primaryTypographyProps={{ color: 'textSecondary' }}
                        primary="Post orders to your Private Loadboard"
                      />

                      <Typography color="textSecondary">
                        Loads posted to your Private Loadboard are only visible
                        to your Private Network for the duration you specify. By
                        default, this is set to 2 hours, before being posted
                        publicly on the Super Loadboard.
                      </Typography>
                    </Stack>
                  </ListItem>
                </List>

                <Stack />

                <Inline verticalAlign="center" space="small">
                  <AnchorButton
                    target="_blank"
                    variant="neutral"
                    size="large"
                    href="https://superdispatch.com/private-loadboard/?utm_source=product&utm_medium=stms&utm_campaign=privateloadboard"
                    onClick={() => {
                      trackEvent(
                        'Shipper Clicked Request Private Loadboard Access',
                        { utm_medium: 'Private Network Tab' },
                      );
                    }}
                  >
                    Request Access
                  </AnchorButton>

                  <Link
                    color="primary"
                    rel="noreferrer"
                    target="_blank"
                    href="https://support.superdispatch.com/en/articles/9066625-private-network"
                  >
                    Learn more
                  </Link>
                </Inline>
              </Stack>
            </Stack>
          </Column>
        </Columns>
      </Box>
    </Container>
  );
}
