import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { ColorDynamic } from '@superdispatch/ui';
import { ChangeEvent } from 'react';
import { useTerminalsNavigation } from '../TerminalsRouteHelpers';

export function TerminalsSidebarSearch() {
  const { terminalsSearchParams, navigateToTerminals } =
    useTerminalsNavigation();

  function clearSearch() {
    navigateToTerminals({
      ...terminalsSearchParams,
      query: null,
    });
  }

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    navigateToTerminals(
      {
        ...terminalsSearchParams,
        query: event.target.value,
      },
      {
        replace: true,
      },
    );
  }

  return (
    <TextField
      fullWidth={true}
      placeholder="3 symbols to search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color="action" />
          </InputAdornment>
        ),
        endAdornment: terminalsSearchParams.query && (
          <InputAdornment position="end">
            <IconButton onClick={clearSearch}>
              <Close htmlColor={ColorDynamic.Dark300} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={terminalsSearchParams.query || ''}
      onChange={handleSearch}
    />
  );
}
