import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { useInitialValues } from 'shared/form/AppFormik';
import { camelCaseFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { PhoneVerificationForm } from '../core/PhoneVerificationForm';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import {
  usePhoneVerification,
  useVerificationApplicationAPI,
} from '../data/VerificationApplicationAPI';
import { phoneVerificationSchema } from '../data/VerificationApplicationDTO';
import {
  VerificationApplicationStepsFormBox,
  VerificationApplicationStepsWrapper,
} from './VerificationApplicationLayout';
import { VerificationStepper } from './VerificationStepper';

interface VerificationApplicationPhoneVerificationProps {
  onSubmitSuccess: () => void;
  onGoBack: () => void;
}

export function VerificationApplicationPhoneVerification({
  onSubmitSuccess,
  onGoBack,
}: VerificationApplicationPhoneVerificationProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data, isLoading } = usePhoneVerification();
  const { updatePhoneVerification } = useVerificationApplicationAPI();

  const initialValues = useInitialValues(phoneVerificationSchema, data);

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: phoneVerificationSchema,
    getFormErrors: camelCaseFormErrorsGetter,
    onSubmit: (values) => {
      return updatePhoneVerification(values);
    },
    onSubmitSuccess,
    onSubmitFailure() {
      addSnackbar(
        'Please ensure all required fields are filled out correctly',
        { variant: 'error' },
      );
    },
  });

  return (
    <FormikProvider value={formik}>
      <VerificationApplicationStepsWrapper
        step={3}
        onGoBack={() => {
          onGoBack();
          trackVerificationApplicationEvent({
            name: 'Shipper Clicked Back',
            utm_content: 'Phone Verification',
          });
        }}
      >
        <Stack space="small">
          <VerificationStepper step={3} />
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <Form>
              <VerificationApplicationStepsFormBox>
                <PhoneVerificationForm />
              </VerificationApplicationStepsFormBox>
            </Form>
          )}
        </Stack>
      </VerificationApplicationStepsWrapper>
    </FormikProvider>
  );
}
