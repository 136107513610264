import { startCase } from 'lodash';
import { User } from 'shared/types/user';
import { getRevisionChanges } from './RevisionChangesUtils';
import { OrderRevision } from './types/OrderRevision';
import { OrderRevisionNormalized } from './types/OrderRevisionNormalized';

export function getRevisionUser(revision: OrderRevision, users: User[]) {
  const user = users.find(({ id }) => id === revision.user_id);
  return revision.carrier_name
    ? `Carrier Name: ${revision.carrier_name}`
    : user?.username;
}

export function getRevisionSubject({
  order,
  vehicle,
}: OrderRevision): OrderRevisionNormalized['subject'] {
  switch (true) {
    case !!order:
      return 'order';
    case !!vehicle:
      return 'vehicle';
    default:
      return undefined;
  }
}

export function getRevisionType({
  revision_type,
}: OrderRevision): OrderRevisionNormalized['type'] {
  switch (revision_type) {
    case 'ADD':
      return 'added';
    case 'MOD':
      return 'modified';
    case 'DEL':
      return 'deleted';
  }
}

export function getRevisionAction({
  action_type,
}: OrderRevision): string | undefined {
  switch (action_type) {
    case 'MARKED_AS_NOT_PICKED_UP':
      return 'Carrier marked vehicle as Not Picked Up';
    default:
      return undefined;
  }
}

export function normalizeRevisions(
  revisions: OrderRevision[],
  users: User[],
): OrderRevisionNormalized[] {
  return revisions.map((revision) => {
    const type = getRevisionType(revision);
    const action = getRevisionAction(revision);
    const subject = getRevisionSubject(revision);
    const title = startCase(`${String(subject)} ${type}`);

    return {
      title,
      type,
      action,
      subject,
      id: revision.rev_id,
      date: revision.revision_date,
      username: getRevisionUser(revision, users),
      changes: getRevisionChanges(revision, revisions),
    };
  });
}
