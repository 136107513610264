import { Box, BoxProps, Link, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { ColorDynamic } from '@superdispatch/ui';
import { parseURITemplate } from '@superdispatch/uri';
import * as React from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { getCurrentSource } from 'shared/helpers/SourceManagmentLegacy';
import { CopyToClipboardTooltip } from 'shared/ui/CopyToClipboardTooltip';
import { ViewRoute } from 'shared/ui/ViewRoute';
import { makeAddressCityStateZip } from 'shared/utils/AddressUtils';
import styled from 'styled-components';

const StyledInfo = styled(Typography)`
  color: ${ColorDynamic.Dark300};
`;

const StyledAddress = styled(Typography)`
  color: ${ColorDynamic.Dark500};
`;

interface VenueProps extends BoxProps {
  name?: string | null;
  guid?: string | null;
  type?: 'CUSTOMER' | 'TERMINAL' | 'CARRIER' | null;
  info?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  phone?: string;
  email?: string;
  route?: [string, string];
}

export const OrderViewVenue: React.ComponentType<VenueProps> = ({
  name,
  info,
  address,
  city,
  state,
  zip,
  phone,
  email,
  route,
  guid,
  type,
  ...props
}) => {
  const fullAddress = makeAddressCityStateZip({ address, city, state, zip });
  const { user } = useUserState();
  const [medium, content] = getCurrentSource() ?? [];

  const isBroker = user?.shipper.shipper_type === 'BROKER';
  const path = type ? type.toLowerCase() : null;
  const counterpartyLink =
    isBroker && name && guid && path
      ? `/${path}s?current_${path}_guid=${guid}`
      : null;
  const carrierLink =
    isBroker && name && guid && path
      ? parseURITemplate(`/manage-carriers/${guid}{?utm_medium,utm_content}`, {
          utm_medium: medium,
          utm_content: content,
        })
      : null;
  const link = type === 'CARRIER' ? carrierLink : counterpartyLink;
  return name || info || address || city || state || zip || phone || email ? (
    <Box display="flex" {...props}>
      <Box mr={1}>
        <Typography variant="h4">
          <BusinessIcon fontSize="inherit" htmlColor={ColorDynamic.Dark100} />
        </Typography>
      </Box>

      <Box>
        <Typography variant="body1" aria-label="venue name">
          {link ? <Link href={link}>{name}</Link> : name || 'Location'}
        </Typography>

        {info && (
          <Box mt="4px">
            <StyledInfo variant="body2" aria-label="venue info">
              {info}
            </StyledInfo>
          </Box>
        )}

        {phone && (
          <Box mt="4px">
            <Link href={`tel:${phone}`} aria-label="venue phone">
              {phone}
            </Link>
          </Box>
        )}

        {email && (
          <Box mt="4px">
            <Link href={`mailto:${email}`} aria-label="venue email">
              {email}
            </Link>
          </Box>
        )}

        {(address || city || state || zip) && (
          <Box mt="4px">
            <StyledAddress variant="body2">
              <CopyToClipboardTooltip text={fullAddress} title="Copy Address">
                <Link
                  target="_blank"
                  rel="noreferrer"
                  aria-label="venue address"
                  href={`https://www.google.com/maps/search/?api=1&query=${fullAddress}`}
                >
                  {fullAddress}
                </Link>
              </CopyToClipboardTooltip>
            </StyledAddress>
          </Box>
        )}
        {route && (
          <Box pt={1}>
            <ViewRoute origin={route[0]} destination={route[1]} />
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
};
