import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ViewWeek } from '@material-ui/icons';
import { ColorDynamic } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import * as React from 'react';
import { useState } from 'react';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';

export interface Column {
  key: string;
  label?: string;
  isVisible?: boolean;
  content: React.ReactNode;
  isSortable?: boolean;
  sortKey?: string;
}

export interface ManageColumnsProps {
  columns: Column[];
  onToggleColumn: (key: string, isVisible: boolean) => void;
}

export function ManageColumns({ columns, onToggleColumn }: ManageColumnsProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <div>
      <Button
        variant="neutral"
        aria-label="manage columns"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <ViewWeek style={{ color: ColorDynamic.Dark300 }} />
      </Button>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {columns
          .filter((column) => column.key !== 'number')
          .map((column) => (
            <MenuItem
              key={column.key}
              dense={true}
              onClick={() => {
                const checked = !column.isVisible;

                onToggleColumn(column.key, checked);

                trackEventLegacy('Toggled order list column', {
                  column: String(column.content),
                  visibility: checked ? 'visible' : 'hidden',
                });
              }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={column.isVisible}
                  disableRipple={true}
                  inputProps={{ 'aria-labelledby': column.key }}
                />
              </ListItemIcon>

              <ListItemText id={column.key} primary={column.content} />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
