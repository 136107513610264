import { Card, CardContent, Collapse, Tooltip } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { formatDate, parseDate } from '@superdispatch/dates';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, Button, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useRecentLoads } from 'orders/data/OrderPriceAPI';
import { useMemo, useState } from 'react';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { CalendarOut } from 'shared/icons/CalendarOut';
import Order from 'shared/types/order';
import { PaymentMethodIcon } from 'shared/ui/PaymentMethodIcon';
import styled from 'styled-components';
import { PricingInsightsRecentLoadDTO } from '../../data/dto/PricingInsightsRecentPostings';
import { PostingVehicle } from '../posting/LoadVehicle';
import { LoadWaypoints } from '../posting/LoadWaypoints';
import { PostingPaymentTerm } from '../posting/PostingPaymentTerm';
import { PostingVehiclesSummary } from '../posting/PostingVehiclesSummary';

const StyledCardContent = styled(CardContent)`
  display: grid;
  padding: 12px 16px;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;

  @media (min-width: 768px) {
    grid-row-gap: 8px;
    grid-column-gap: 16px;
    grid-template-columns: repeat(3, minmax(0, 1fr)) auto;
  }
`;

interface RecentLoadProps {
  load: PricingInsightsRecentLoadDTO;
}

export function RecentLoad({ load }: RecentLoadProps) {
  const screen = useResponsiveValue('mobile', 'mobile', 'desktop');
  return (
    <Card>
      <StyledCardContent>
        <LoadWaypoints
          pickup={{
            city: load.pickup_city,
            state: load.pickup_state,
            zip: load.pickup_zip,
          }}
          delivery={{
            city: load.delivery_city,
            state: load.delivery_state,
            zip: load.delivery_zip,
          }}
          distanceMiles={load.distance_miles}
        />

        {screen === 'mobile' ? (
          <TextBox>
            {load.vehicles.length}{' '}
            {formatPlural(load.vehicles.length, 'Vehicle', 'Vehicles')}
          </TextBox>
        ) : (
          <Stack space="xsmall">
            {load.vehicles[0] && <PostingVehicle vehicle={load.vehicles[0]} />}

            {load.vehicles.length > 1 && (
              <PostingVehiclesSummary
                title={`+ ${load.vehicles.length - 1} more`}
                vehicles={load.vehicles.slice(1)}
              />
            )}
          </Stack>
        )}

        <Stack>
          <DescriptionItem
            icon={<PaymentMethodIcon paymentMethod={load.payment_method} />}
          >
            <PostingPaymentTerm
              terms={load.payment_terms}
              method={load.payment_method}
            />
          </DescriptionItem>

          {screen === 'desktop' && (
            <DescriptionItem label="Posted" icon={<CalendarOut />}>
              {formatDate(
                parseDate(load.posted_to_loadboard_at, {
                  format: 'JodaISO',
                }),
                { variant: 'Date' },
              )}
            </DescriptionItem>
          )}

          <DescriptionItem label="Available" icon={<CalendarOut />}>
            {formatDate(
              parseDate(load.available_at, {
                format: 'JodaISO',
              }),
              { variant: 'Date' },
            )}
          </DescriptionItem>
        </Stack>

        <Stack space="xxsmall" align={['left', 'left', 'right']}>
          <TextBox variant="heading-4">
            {formatCurrency(load.price, { maximumFractionDigits: 0 })}
          </TextBox>

          {load.price_per_mile_per_vehicle != null && (
            <TextBox color="secondary">
              {formatCurrency(load.price_per_mile_per_vehicle, {
                maximumFractionDigits: 2,
              })}
              /mi
            </TextBox>
          )}
        </Stack>
      </StyledCardContent>
    </Card>
  );
}

interface RecentLoadsListProps {
  loads: PricingInsightsRecentLoadDTO[];
}

export function RecentLoadsList({ loads }: RecentLoadsListProps) {
  const [averagePrice, averagePricePerMile] = useMemo(() => {
    let totalPrice = 0;
    let totalPricePerMile = 0;

    if (!loads.length) {
      return [0, 0];
    }

    for (let item of loads) {
      totalPrice += item.price_per_vehicle || 0;
      totalPricePerMile += item.price_per_mile_per_vehicle || 0;
    }

    return [
      totalPrice / Math.max(loads.length, 1),
      totalPricePerMile / Math.max(loads.length, 1),
    ];
  }, [loads]);

  return (
    <Box
      padding="medium"
      backgroundColor="White"
      borderRadius="small"
      borderWidth="small"
      borderColor="Silver400"
      maxHeight="670px"
      overflow="auto"
    >
      <Stack space="small">
        <TextBox variant="heading-4">
          Review currently posted loads on this lane
        </TextBox>

        <Columns space="small">
          <Column>
            <Inline space={['xsmall', 'large']}>
              <Inline>
                <TextBox color="secondary">Avg price/vehicle</TextBox>
                <TextBox>
                  {formatCurrency(averagePrice, { maximumFractionDigits: 0 })}
                </TextBox>
              </Inline>

              <Inline>
                <TextBox color="secondary">Avg price/mi</TextBox>
                <TextBox>
                  {formatCurrency(averagePricePerMile, {
                    maximumFractionDigits: 2,
                  })}
                </TextBox>
              </Inline>
            </Inline>
          </Column>
        </Columns>

        {loads.map((load) => (
          <RecentLoad key={load.guid} load={load} />
        ))}
      </Stack>
    </Box>
  );
}

export function OrderRequestsRecentPostings({ order }: { order: Order }) {
  const [shouldShowSimilarPostings, showSimilarPostings] = useState(false);
  const { data: recentPostings } = useRecentLoads({ type: 'postings', order });

  return (
    <Stack space="small">
      {!!recentPostings?.objects.length && (
        <Collapse in={shouldShowSimilarPostings} unmountOnExit={true}>
          <RecentLoadsList loads={recentPostings.objects} />
        </Collapse>
      )}

      {shouldShowSimilarPostings ? (
        <Button
          variant="neutral"
          endIcon={<KeyboardArrowUp />}
          onClick={() => showSimilarPostings(false)}
        >
          Hide Postings on This Lane
        </Button>
      ) : (
        <Tooltip
          title={
            !recentPostings?.objects.length
              ? 'No loads posted on this lane'
              : ''
          }
        >
          <span>
            <Button
              variant="neutral"
              endIcon={<KeyboardArrowDown />}
              disabled={!recentPostings?.objects.length}
              onClick={() => showSimilarPostings(true)}
            >
              Browse Postings on This Lane
            </Button>
          </span>
        </Tooltip>
      )}
    </Stack>
  );
}
