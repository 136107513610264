import { Link, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  useUID,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import { BlurredBackdrop } from 'shared/ui/BlurredBackdrop';
import styled, { css } from 'styled-components';

export const CustomColumns = styled(Columns)`
  width: auto;
`;

export const IndicatorCardBase = styled.div`
  padding: 16px;
  border-radius: 4px;

  border: 1px solid ${ColorDynamic.Silver200};
  color: ${ColorDynamic.Dark300};
  transition: 0.2s ease-in-out;
`;

const SelectableIndicator = css`
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }
`;

const GoodIndicator = css`
  border: 1px solid ${ColorDynamic.Green50};
  color: ${ColorDynamic.Green300};

  &:hover {
    border-color: ${ColorDynamic.Green300};
  }
`;

const CanImproveIndicator = css`
  border: 1px solid ${ColorDynamic.Yellow50};
  color: ${ColorDynamic.Yellow300};

  &:hover {
    border-color: ${ColorDynamic.Yellow300};
  }
`;

export const IndicatorCard = styled(IndicatorCardBase)<{
  selectable: boolean;
  canImprove: boolean;
}>`
  ${({ selectable }) => selectable && SelectableIndicator}
  ${({ canImprove, selectable }) =>
    selectable && (canImprove ? CanImproveIndicator : GoodIndicator)}
`;

export const IndicatorNumbers = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
`;

export const Caption = styled(Typography).attrs({ variant: 'caption' })`
  color: ${ColorDynamic.Dark100};
`;

export const IndicatorValue = styled(Typography).attrs({
  variant: 'h2',
  color: 'inherit',
})`
  font-weight: 900;
`;

interface IndicatorProps {
  label: string;
  canImprove: boolean;
  meanRate: number | null;
  generalCountCaption: string;
  onClick?: () => void;
}

export function Indicator({
  label,
  meanRate,
  canImprove,
  generalCountCaption,
  onClick,
}: IndicatorProps) {
  const uid = useUID();

  return (
    <IndicatorCard
      selectable={meanRate !== null}
      canImprove={canImprove}
      onClick={meanRate !== null ? onClick : undefined}
    >
      <Stack space="small">
        <Typography id={uid} variant="body1" color="inherit">
          {label}
        </Typography>

        <IndicatorNumbers>
          {meanRate !== null ? (
            <Inline verticalAlign="center" space="xsmall" noWrap={true}>
              <IndicatorValue aria-labelledby={uid}>
                {formatNumber(meanRate, { maximumFractionDigits: 0 })}%
              </IndicatorValue>
              <Caption>({generalCountCaption})</Caption>
            </Inline>
          ) : (
            <Caption aria-labelledby={uid}>(Not enough data)</Caption>
          )}
        </IndicatorNumbers>
      </Stack>
    </IndicatorCard>
  );
}

export function NotVerifiedInfo() {
  return (
    <BlurredBackdrop
      blur={5}
      blurredElements={
        <Columns>
          <Column>
            <Indicator
              label="Provided Pickup ETA"
              canImprove={true}
              meanRate={null}
              generalCountCaption=""
            />
          </Column>
          <Column>
            <Indicator
              label="Provided Pickup ETA"
              canImprove={true}
              meanRate={null}
              generalCountCaption=""
            />
          </Column>
          <Column>
            <Indicator
              label="Provided Delivery ETA"
              canImprove={true}
              meanRate={null}
              generalCountCaption=""
            />
          </Column>
        </Columns>
      }
    >
      <Stack align="center">
        <CustomColumns align="center" space="xxsmall">
          <Column width="content">
            <Info fontSize="small" htmlColor={ColorDynamic.Blue500} />
          </Column>
          <Column width="adaptive">
            <TextBox as="div" variant="caption">
              Performance metrics calculated only for{' '}
              <Link
                color="textPrimary"
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.superdispatch.com/hc/articles/38186330348179-How-Does-Super-Dispatch-Monitor-Carrier-Compliance"
              >
                Verified Carriers.
              </Link>
            </TextBox>
          </Column>
        </CustomColumns>
      </Stack>
    </BlurredBackdrop>
  );
}
