import { Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { ColorDynamic } from '@superdispatch/ui';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${ColorDynamic.Yellow50};
  margin: 8px 0 0 0;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export function PrivateNetworkStatusChangeWarningBox() {
  return (
    <Container aria-label="PNWarning">
      <WarningRounded htmlColor={ColorDynamic.Yellow300} fontSize="small" />
      <Typography>
        Changing Approved status will disable their Private Loadboard access and
        remove from any groups they belong to.
      </Typography>
    </Container>
  );
}
