import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { apiInstance } from 'shared/api/API';
import { yupObject, yupString } from 'shared/utils/YupUtils';

const validationSchema = yupObject({
  verification_code: yupString().required('Required.').defined(),
});

export function useCentralDispatchMfaCodeFormik({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { addSnackbar } = useSnackbarStack();

  return useFormikEnhanced({
    initialValues: { verification_code: '' },
    validationSchema,
    onSubmit: ({ verification_code }) => submitMfaCode(verification_code),
    onSubmitSuccess: () => {
      addSnackbar('Profile updated successfully', { variant: 'success' });
      onSuccess();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });
}

function submitMfaCode(code: string) {
  return apiInstance.request(
    'POST /internal/central-dispatch/credentials/verification/code/submit',
    {
      json: {
        code,
      },
    },
  );
}
