import { Box, Paper, Popover, Typography } from '@material-ui/core';
import { PopoverProps } from '@material-ui/core/Popover';
import { useUID } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import * as React from 'react';
import { useState } from 'react';

interface TriggerProps {
  showConfirmation: (event: React.MouseEvent<HTMLElement>) => void;
}

interface ConfirmationProps {
  title: React.ReactNode;
  cancelText?: string;
  processText?: string;
  onProcess: (hideConfirmation: () => void) => void;
  isProcessing?: boolean;
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
  trigger: ({ showConfirmation }: TriggerProps) => React.ReactNode;
}

/** @deprecated Use ConfirmPopover*/
export const Confirmation: React.ComponentType<ConfirmationProps> = ({
  title,
  cancelText,
  processText,
  onProcess,
  isProcessing,
  anchorOrigin,
  transformOrigin,
  trigger,
}) => {
  const uid = useUID();
  const [confirmationAnchor, setConfirmationAnchor] =
    useState<HTMLElement | null>(null);
  const showConfirmation = (event: React.MouseEvent<HTMLElement>) => {
    setConfirmationAnchor(event.currentTarget);
  };
  const hideConfirmation = () => {
    setConfirmationAnchor(null);
  };

  return (
    <>
      {trigger({ showConfirmation })}

      <Popover
        open={!!confirmationAnchor}
        anchorEl={confirmationAnchor}
        onClose={hideConfirmation}
        PaperProps={{ 'aria-labelledby': uid }}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'right',
          }
        }
      >
        <Paper>
          <Box m={2}>
            <Box id={uid}>
              {typeof title === 'string' ? (
                <Typography variant="body2">{title}</Typography>
              ) : (
                title
              )}
            </Box>

            <Box display="flex" mt={2}>
              <Box>
                <Button
                  variant="neutral"
                  disabled={isProcessing}
                  onClick={hideConfirmation}
                >
                  {cancelText || 'No'}
                </Button>
              </Box>

              <Box ml={1}>
                <Button
                  variant="critical"
                  pending={isProcessing}
                  onClick={() => onProcess(hideConfirmation)}
                  autoFocus={true}
                >
                  {processText || 'Yes'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};
