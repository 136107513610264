import { ColorDynamic } from '@superdispatch/ui';
import { useMemo } from 'react';
import styled from 'styled-components';

export function calcProgressValue(a: number, b: number): string {
  if (a === 0) {
    return '1';
  }
  const result = (a / b) * 100;
  const adjustedResult = Math.min(result, 100);
  return adjustedResult.toFixed(2);
}

const Root = styled.div`
  height: 4px;
  background: ${ColorDynamic.Silver400};
  border-radius: 0;
`;

const ProgressValue = styled.div`
  height: 4px;
  border-radius: 0;
`;

interface SuperPayProgressBarProps {
  color: string;
  count: number;
  total: number;
}

/*
 * TODO:
 * Just copy of DashboardCardProgressBar
 * Should be replaced by something common
 */
export const SuperPayProgressBar = ({
  count,
  total,
  color,
}: SuperPayProgressBarProps) => {
  const progress = useMemo(
    () => calcProgressValue(count, total),
    [count, total],
  );
  return (
    <Root>
      <ProgressValue
        style={{
          width: `${progress}%`,
          backgroundColor: color,
        }}
      />
    </Root>
  );
};
