import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { VerificationApplicationSidebar } from './VerificationApplicationSidebar';
import { VerificationOverlay } from './VerificationOverlay';

interface VerificationApplicationMobilePromoDialogProps {
  open: boolean;
  onGetVerifiedClicked: () => void;
  onClose: () => void;
}

export function VerificationApplicationMobilePromoDialog({
  open,
  onGetVerifiedClicked,
  onClose,
}: VerificationApplicationMobilePromoDialogProps) {
  return (
    <VerificationOverlay showHeader={false} open={open} onClose={onClose}>
      <VerificationApplicationSidebar
        actions={
          <Stack>
            <Button fullWidth={true} onClick={onGetVerifiedClicked}>
              Get Verified Now
            </Button>
            <Button fullWidth={true} variant="neutral" onClick={onClose}>
              Do it later
            </Button>
          </Stack>
        }
      />
    </VerificationOverlay>
  );
}
