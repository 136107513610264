import { Typography } from '@material-ui/core';
import { formatRelativeTime } from '@superdispatch/dates';
import { ColorDynamic, Inline, Stack } from '@superdispatch/ui';
import { DateTime } from 'luxon';
import { Fragment, useMemo } from 'react';
import Order from 'shared/types/order';
import styled from 'styled-components';
import {
  abbreviateRelativeTimeText,
  getOrderStepRelativeTime,
  isOrderAgeDelayed,
  isOrderStepDelayed,
} from './DelayedOrderHelpers';

interface OrderRedStatusesProps {
  statuses: string[];
  inline?: boolean;
}

const Wrapper = styled.div<{ inline?: boolean }>`
  min-width: ${({ inline }) => (inline ? 'unset' : '115px')};
  color: ${ColorDynamic.Red500};
`;

export function OrderAlertStatuses({
  statuses,
  inline,
}: OrderRedStatusesProps) {
  return (
    <Wrapper inline={inline}>
      {inline ? (
        <Inline space="xxsmall">
          {statuses.map((status, index) => (
            <Fragment key={index}>
              <Typography noWrap={true} variant="caption">
                {status}
              </Typography>
              {index !== statuses.length - 1 && ' · '}
            </Fragment>
          ))}
        </Inline>
      ) : (
        <Stack space="none">
          {statuses.map((status, index) => (
            <Typography noWrap={true} key={index} variant="caption">
              {status}
            </Typography>
          ))}
        </Stack>
      )}
    </Wrapper>
  );
}

export function useOrderDelayStatus(order: Order) {
  return useMemo(() => {
    const agingDelay = isOrderAgeDelayed(order)
      ? abbreviateRelativeTimeText(
          formatRelativeTime(
            order.created_at,
            { base: DateTime.local() },
            { format: 'JodaISO' },
          ),
        )
      : undefined;

    const pickupDelay = isOrderStepDelayed(order.pickup)
      ? abbreviateRelativeTimeText(getOrderStepRelativeTime(order.pickup))
      : undefined;

    const deliveryDelay = isOrderStepDelayed(order.delivery)
      ? abbreviateRelativeTimeText(getOrderStepRelativeTime(order.delivery))
      : undefined;

    return { agingDelay, pickupDelay, deliveryDelay };
  }, [order]);
}
