import { useAtom } from 'jotai';
import { PopupModal } from 'react-calendly';
import { useUserState } from 'shared/data/AppUserState';
import { createGlobalStyle } from 'styled-components';
import { isCalendlyOpenAtom } from './useHandleOnboardingAction';

const CalendlyPopupStyles = createGlobalStyle`
  .calendly-overlay .calendly-popup.calendly-popup {
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    transform: none;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }
`;

export function OnboardingCalendly() {
  const [isCalendlyOpen, setIsCalendlyOpen] = useAtom(isCalendlyOpenAtom);
  const { user } = useUserState();

  if (!user || !user.shipper.meeting_link) {
    return null;
  }

  return (
    <>
      <CalendlyPopupStyles />
      <PopupModal
        url={user.shipper.meeting_link}
        utm={{
          utmCampaign: 'Web STMS',
          utmMedium: 'Assisted Onboarding',
        }}
        prefill={{
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email ?? undefined,
          date: new Date(),
        }}
        onModalClose={() => setIsCalendlyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById('root') as HTMLElement}
      />
    </>
  );
}
