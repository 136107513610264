import {
  Box,
  Button as LinkButton,
  Grid,
  Link,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { WorkOutline } from '@material-ui/icons';
import { FormikNumberField, FormikTextField } from '@superdispatch/forms';
import {
  formatPaymentTerm,
  PaymentMethod,
  PaymentTerm,
} from '@superdispatch/sdk';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Alert, TextBox } from '@superdispatch/ui-lab';
import {
  isSuperPayStatusAvailable,
  usePaymentTermUtils,
} from 'core/PaymentUtils';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { FormikPaymentMethodField } from 'shared/form/FormikPaymentMethodField';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import {
  SelectSuperPayHelperText,
  useSuperPayEnabled,
  useSuperPaySuspend,
} from 'shared/helpers/superpay/SuperPayHelpers';
import { Load } from 'shared/types/load';
import { Order } from 'shared/types/order';
import { Waypoints } from 'shared/ui/Waypoints';
import { makeCityStateZip } from 'shared/utils/AddressUtils';
import { required } from 'shared/utils/ValidatorUtils';
import { useShipperProfile } from 'shipper-profile/data/ShipperProfileAPI';
import styled from 'styled-components';
import { ViewOrderRoute } from '../../ViewOrderRoute';
import { BuildLoadsFormValues } from './BuildLoadsDrawer';

const LoadIdTitle = styled(Typography)`
  color: ${Color.Dark100};
`;

const StyledWarning = styled(Typography)`
  color: ${Color.Yellow500};
`;

const AlertWrap = styled.div`
  & .MuiAlert-action {
    display: none;
  }
`;

const styleLabelText = (text: string) => (
  <Typography color="textPrimary">{text}</Typography>
);

export function CarrierPaymentForm({
  load,
  index,
  order,
  name,
}: {
  load: Load;
  index: number;
  order?: Order;
  name: string;
}) {
  const isSuperPaySuspend = useSuperPaySuspend();
  const isSuperPayEnabled = useSuperPayEnabled();
  const { user } = useUserState();
  const { data: shipper } = useShipperProfile({ refetchOnWindowFocus: false });
  const { values, setFieldValue } = useFormikContext<BuildLoadsFormValues>();

  const { getPaymentTerms, getValidPaymentTerm } = usePaymentTermUtils();
  const paymentTerms = getPaymentTerms(
    values.loads[index]?.payment?.method,
    values.loads[index]?.payment?.terms as PaymentTerm | undefined,
  );

  const pickupAddress = makeCityStateZip(load.pickup.venue);
  const deliveryAddress = makeCityStateZip(load.delivery.venue);

  const pickupWaypoints = {
    title: (
      <Inline verticalAlign="center">
        {pickupAddress ? (
          <>
            <Typography variant="body1" component="div">
              {pickupAddress}
            </Typography>

            {load.pickup.venue?.business_type && (
              <Typography color="textSecondary">
                {load.pickup.venue.business_type}
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Pickup address not specified
          </Typography>
        )}
      </Inline>
    ),
  };

  const deliveryWaypoints = {
    title: (
      <Inline verticalAlign="center">
        {deliveryAddress ? (
          <>
            <Typography variant="body1" component="div">
              {deliveryAddress}
            </Typography>

            {load.delivery.venue?.business_type && (
              <Typography color="textSecondary">
                {load.delivery.venue.business_type}
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Delivery address not specified
          </Typography>
        )}
      </Inline>
    ),
  };

  const onPaymentMethodChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const terms = values.loads[index]?.payment?.terms;

    void setFieldValue(
      `${name}.terms`,
      getValidPaymentTerm(value as PaymentMethod, terms as PaymentTerm),
    );
  };

  const handleSameAsOriginalClick = () => {
    const payment = {
      price: order?.price,
      terms: order?.payment?.terms,
      notes: order?.payment?.notes,
      method: order?.payment?.method,
      reference_number: order?.payment?.reference_number,
    };

    setFieldValue(name, payment);

    trackEventLegacy('[Build Loads] Clicked same as original carrier payment');
  };

  return (
    <Stack space="large">
      <Stack space="small">
        <Columns align="center">
          <Column width="fluid">
            <Inline verticalAlign="center">
              <Typography variant="h3">Carrier Payment {index + 1}</Typography>
              <LoadIdTitle variant="h4">{load.number}</LoadIdTitle>
            </Inline>
          </Column>

          <Column width="content">
            <LinkButton
              color="primary"
              variant="text"
              type="button"
              startIcon={<WorkOutline />}
              onClick={handleSameAsOriginalClick}
            >
              Same as Original
            </LinkButton>
          </Column>
        </Columns>

        <Stack space="xsmall">
          <Waypoints from={pickupWaypoints} to={deliveryWaypoints} />

          {load.pickup.venue && load.delivery.venue && (
            <ViewOrderRoute
              origin={load.pickup.venue}
              destination={load.delivery.venue}
            />
          )}
        </Stack>
      </Stack>

      <Stack space="small">
        {values.loads[index]?.payment.method === 'superpay' && (
          <AlertWrap>
            <Alert severity="caution">
              <StyledWarning>
                SuperPay method requires Advanced inspection. This inspection
                type requires drivers to take at least 6 photos at Pickup and
                Delivery. Inspection type changed automatically.
              </StyledWarning>
            </Alert>
          </AlertWrap>
        )}

        <Stack>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} md={6}>
              <FormikNumberField
                name={`${name}.price`}
                disabled={isSuperPayStatusAvailable(order)}
                label={
                  <Typography display="inline" color="textPrimary">
                    Total Carrier Price
                  </Typography>
                }
                fullWidth={true}
              />
            </Grid>

            <Grid item={true} xs={12} md={6}>
              <FormikTextField
                select={true}
                fullWidth={true}
                defaultValue="other"
                validate={required}
                name={`${name}.terms`}
                disabled={isSuperPayStatusAvailable(order)}
                label={styleLabelText('Terms')}
                helperText={
                  !shipper?.default_payment_terms && (
                    <Link
                      to="/profile/payment-info/edit?source=order_form"
                      target="_blank"
                      rel="noopener noreferrer"
                      component={RouterLink}
                      color="textSecondary"
                      variant="body2"
                    >
                      Set default terms
                    </Link>
                  )
                }
              >
                {paymentTerms.map((value) => (
                  <MenuItem key={value} value={value}>
                    {formatPaymentTerm(value)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
          </Grid>

          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} md={6}>
              <FormikPaymentMethodField
                disabled={isSuperPayStatusAvailable(order)}
                fullWidth={true}
                name={`${name}.method`}
                onChange={onPaymentMethodChange}
                label={styleLabelText('Payment Method')}
                helperText={
                  values.loads[index]?.payment?.method === 'superpay'
                    ? 'Advanced inspection will be applied'
                    : !isSuperPayStatusAvailable(order) &&
                      !isSuperPaySuspend &&
                      isSuperPayEnabled && (
                        <SelectSuperPayHelperText
                          source="Build Load"
                          onClick={() => {
                            void setFieldValue(`${name}.method`, 'superpay');
                            void setFieldValue(`${name}.terms`, '1_3_days');
                          }}
                        />
                      )
                }
              />
            </Grid>

            {user?.order_form_settings.payment.is_reference_number_visible && (
              <Grid item={true} xs={12} md={6}>
                <FormikTextField
                  name={`${name}.reference_number`}
                  label={styleLabelText('Reference Number')}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Grid>
            )}

            <Grid item={true} xs={12}>
              <FormikTextField
                name={`${name}.notes`}
                label={
                  <Box display="flex" justifyContent="space-between">
                    <Box>{styleLabelText('Notes')}</Box>
                    <TextBox color="secondary">
                      {`${
                        values.loads[index]?.payment.notes?.length || 0
                      } of 6000`}
                    </TextBox>
                  </Box>
                }
                fullWidth={true}
                multiline={true}
                inputProps={{
                  maxLength: 6000,
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
}
