import { Card, Typography } from '@material-ui/core';
import { ColorDynamic, Image, Stack } from '@superdispatch/ui';
import styled from 'styled-components';
import usageIcon from '../assets/ic-usage.svg';
import { DisplayCard } from '../DisplayCard';
import { OverviewSection } from './OverviewSection';
import { PricingSection } from './PricingSection';
import { useUsageDetails } from './useUsageDetails';

const StyledEmptyCard = styled(Card)`
  padding: 48px;
  color: ${ColorDynamic.Dark100};
`;

export function SubscriptionMonthlyUsageCard() {
  const { usageDetails } = useUsageDetails();

  if (!usageDetails?.usages.length) {
    return (
      <StyledEmptyCard>
        <Stack space="small" align="center">
          <Image src={usageIcon} />
          <Typography variant="h3">No Usages yet</Typography>
        </Stack>
      </StyledEmptyCard>
    );
  }
  return (
    <DisplayCard title="Monthly Usage">
      <Stack space="medium">
        <OverviewSection />
        <PricingSection />
      </Stack>
    </DisplayCard>
  );
}
