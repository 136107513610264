import { createSvgIcon } from './IconUtils';

export const InvoiceSendIcon = createSvgIcon(
  'InvoiceSend',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M15.8333 10.8333C16.4167 10.8333 16.975 10.9333 17.5 11.1167V4.5C17.5 3.39543 16.6046 2.5 15.5 2.5H4.5C3.39543 2.5 2.5 3.39543 2.5 4.5V17.3991C2.5 17.7984 2.94507 18.0366 3.27735 17.8151L4.72265 16.8516C4.8906 16.7396 5.1094 16.7396 5.27735 16.8516L7.22265 18.1484C7.3906 18.2604 7.6094 18.2604 7.77735 18.1484L9.72372 16.8509C9.89113 16.7392 10.1091 16.7389 10.2769 16.8499L11.0833 17.3833C10.9167 16.9 10.8333 16.375 10.8333 15.8333C10.8333 13.075 13.075 10.8333 15.8333 10.8333ZM15.8333 17.6481C15.8333 18.0777 16.3393 18.3073 16.6626 18.0244L18.7366 16.2096C18.9643 16.0104 18.9643 15.6563 18.7366 15.457L16.6626 13.6423C16.3393 13.3594 15.8333 13.589 15.8333 14.0186V15H13C12.7239 15 12.5 15.2239 12.5 15.5V16.1667C12.5 16.4428 12.7239 16.6667 13 16.6667H15.8333V17.6481ZM5.54444 7.36111C5.54444 7.88889 5.95 8.22778 7.21111 8.55555C8.47222 8.88333 9.82222 9.42222 9.82222 11C9.82222 12.1389 8.96111 12.7667 7.87778 12.9722V14.1667H6.21111V12.9611C5.14444 12.7333 4.23333 12.05 4.16667 10.8333H5.38889C5.45 11.4889 5.9 12 7.04444 12C8.27222 12 8.54444 11.3889 8.54444 11.0056C8.54444 10.4889 8.26667 10 6.87778 9.66667C5.32778 9.29444 4.26667 8.65556 4.26667 7.37222C4.26667 6.3 5.13333 5.6 6.21111 5.36667V4.16667H7.87778V5.38333C9.03889 5.66667 9.62222 6.54444 9.66111 7.5H8.43333C8.4 6.80556 8.03333 6.33333 7.04444 6.33333C6.10556 6.33333 5.54444 6.75556 5.54444 7.36111Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 20 20', height: 20, width: 20 },
);
