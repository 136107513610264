import { createSvgIcon } from './IconUtils';

export const DispatchToCarrierIcon = createSvgIcon(
  'DispatchToCarrier',
  <g id="ic-dispatch-to-carrier">
    <path
      id="Vector"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83333 10.0002L10 6.66683L14.1667 10.0002H11.6667V14.1668H8.33333V10.0002H5.83333ZM10.5893 2.74424C10.433 2.58796 10.221 2.50016 10 2.50016C9.77899 2.50016 9.56702 2.58796 9.41074 2.74424C9.25446 2.90052 9.16667 3.11248 9.16667 3.3335C9.16667 3.55451 9.25446 3.76647 9.41074 3.92275C9.56702 4.07903 9.77899 4.16683 10 4.16683C10.221 4.16683 10.433 4.07903 10.5893 3.92275C10.7455 3.76647 10.8333 3.55451 10.8333 3.3335C10.8333 3.11248 10.7455 2.90052 10.5893 2.74424ZM12.35 2.50016H15.8333C16.2754 2.50016 16.6993 2.67576 17.0118 2.98832C17.3244 3.30088 17.5 3.7248 17.5 4.16683V15.8335C17.5 16.2755 17.3244 16.6994 17.0118 17.012C16.6993 17.3246 16.2754 17.5002 15.8333 17.5002H4.16667C3.72464 17.5002 3.30072 17.3246 2.98816 17.012C2.67559 16.6994 2.5 16.2755 2.5 15.8335V4.16683C2.5 3.7248 2.67559 3.30088 2.98816 2.98832C3.30072 2.67576 3.72464 2.50016 4.16667 2.50016H7.65C8 1.5335 8.91667 0.833496 10 0.833496C11.0833 0.833496 12 1.5335 12.35 2.50016Z"
    />
  </g>,
  { viewBox: '0 0 20 20' },
);
