import { Drawer, IconButton, Typography } from '@material-ui/core';
import { ChevronRight, Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { AnchorButton, Box, Button } from '@superdispatch/ui-lab';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';

export interface PhoneVerificationHelpDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function PhoneVerificationHelpDrawer({
  open,
  onClose,
}: PhoneVerificationHelpDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Learn more"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <DrawerContent>
        <Box maxWidth="400px">
          <Stack space="xlarge">
            <Stack space="small">
              <Typography variant="h2">
                Why do we need your phone number?
              </Typography>
              <Typography>
                Security is essential to the operation of Super Dispatch. To
                ensure a safer, more reliable experience for everyone, we
                personally verify each shipper on our platform through a phone
                check to prevent fraud.
              </Typography>
            </Stack>
            <Typography>
              Help us streamline the process by sharing accurate dates and times
              — we’ll ensure it gets done quickly.
            </Typography>
            <Stack space="small">
              <Typography variant="h4">
                Is the number displayed incorrect?
              </Typography>
              <Stack space="xxsmall">
                <AnchorButton
                  variant="text"
                  endIcon={<ChevronRight />}
                  target="_blank"
                  href="https://support.superdispatch.com/hc/articles/38186411472403-Update-Your-Phone-Number-With-The-FMCSA"
                  onClick={() => {
                    trackVerificationApplicationEvent({
                      name: 'Shipper Clicked on Read Our Article',
                      utm_content: 'Phone Verification',
                    });
                  }}
                >
                  Change your number on the FMCSA
                </AnchorButton>
                <AnchorButton
                  variant="text"
                  endIcon={<ChevronRight />}
                  target="_blank"
                  href="https://support.superdispatch.com/hc/articles/38186411472403-Update-Your-Phone-Number-With-The-FMCSA"
                  onClick={() => {
                    trackVerificationApplicationEvent({
                      name: 'Shipper Clicked on Read Our Article',
                      utm_content: 'Phone Verification',
                    });
                  }}
                >
                  Get a new FMCSA PIN
                </AnchorButton>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </DrawerContent>
      <DrawerActions>
        <Button onClick={onClose}>Ok</Button>
      </DrawerActions>
    </Drawer>
  );
}
