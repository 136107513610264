import { createSvgIcon } from './IconUtils';

export const CelebrationIcon = createSvgIcon(
  'CelebrationIcon',
  <path
    d="M1.33301 16.6667L4.66634 7.33333L10.6663 13.3333L1.33301 16.6667ZM9.69967 10.3667L8.99967 9.66667L12.733 5.93333C13.0886 5.57778 13.5163 5.4 14.0163 5.4C14.5163 5.4 14.9441 5.57778 15.2997 5.93333L15.6997 6.33333L14.9997 7.03333L14.5997 6.63333C14.4441 6.47778 14.2497 6.4 14.0163 6.4C13.783 6.4 13.5886 6.47778 13.433 6.63333L9.69967 10.3667ZM7.03301 7.7L6.33301 7L6.73301 6.6C6.88856 6.44444 6.96634 6.25556 6.96634 6.03333C6.96634 5.81111 6.88856 5.62222 6.73301 5.46667L6.29967 5.03333L6.99967 4.33333L7.43301 4.76667C7.78856 5.12222 7.96634 5.54444 7.96634 6.03333C7.96634 6.52222 7.78856 6.94444 7.43301 7.3L7.03301 7.7ZM8.36634 9.03333L7.66634 8.33333L10.0663 5.93333C10.2219 5.77778 10.2997 5.58333 10.2997 5.35C10.2997 5.11667 10.2219 4.92222 10.0663 4.76667L8.99967 3.7L9.69967 3L10.7663 4.06667C11.1219 4.42222 11.2997 4.85 11.2997 5.35C11.2997 5.85 11.1219 6.27778 10.7663 6.63333L8.36634 9.03333ZM11.033 11.7L10.333 11L11.3997 9.93333C11.7552 9.57778 12.183 9.4 12.683 9.4C13.183 9.4 13.6108 9.57778 13.9663 9.93333L15.033 11L14.333 11.7L13.2663 10.6333C13.1108 10.4778 12.9163 10.4 12.683 10.4C12.4497 10.4 12.2552 10.4778 12.0997 10.6333L11.033 11.7Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 18', style: { width: 16, height: 16 } },
);
