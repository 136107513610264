import { Tooltip } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import {
  OrderRequestDTO,
  OrderRequestStatus,
} from 'orders/data/dto/OrderRequestDTO';
import { RequestDeclineReasons } from './OrderRequestDeclineReasons';

export const requestStatusInfo: Record<
  Exclude<OrderRequestStatus, 'REQUEST_PENDING'>,
  { text: string; color?: 'green' | 'blue' }
> = {
  REQUEST_DECLINED_BY_BROKER: { text: 'Declined' },
  REQUEST_CANCELED: { text: 'Carrier Canceled Request' },
  OFFER_SENT: { text: 'Offer Sent', color: 'green' },
  COUNTER_OFFER_SENT: { text: 'Counter Offer Sent', color: 'green' },
  BID_COUNTER_OFFER: {
    text: 'Carrier Placed Bid on Counter Offer',
    color: 'blue',
  },
  OFFER_ACCEPTED: { text: 'Carrier Accepted Offer', color: 'green' },
  OFFER_CANCELED: { text: 'Offer Canceled' },
  OFFER_CANCELED_BY_CARRIER: { text: 'Carrier Canceled Order' },
  OFFER_DECLINED: { text: 'Carrier Declined Offer' },
  UNPOSTED: { text: 'Request Auto-Declined' },
};

function shouldShowPrice(status: OrderRequestStatus) {
  return [
    'OFFER_SENT',
    'COUNTER_OFFER_SENT',
    'OFFER_ACCEPTED',
    'OFFER_DECLINED',
    'OFFER_CANCELED_BY_CARRIER',
  ].includes(status);
}

interface RequestStatusProps {
  status: OrderRequestStatus;
  price: number;
  date: string;
  bidCounterOfferPrice: number | null;
}

export function RequestStatus({
  status,
  price,
  date,
  bidCounterOfferPrice,
}: RequestStatusProps) {
  const statusInfo = requestStatusInfo[status];

  if (!statusInfo) return null;

  return (
    <Columns collapseBelow="tablet" space="small">
      <Column width="content">
        <Box width="546px">
          <Inline>
            {status !== 'UNPOSTED' ? (
              <Tag color={statusInfo.color ?? 'grey'} variant="subtle">
                {statusInfo.text}
                {shouldShowPrice(status) && ` for $${price}`}
              </Tag>
            ) : (
              <Tooltip title="Request was auto-declined as the load offer was sent to another carrier or load was unposted.">
                <Tag color="grey" variant="subtle">
                  {statusInfo.text}
                </Tag>
              </Tooltip>
            )}
            <Box>
              <Inline>
                <TextBox color="secondary" variant="caption">
                  <FormattedDate variant="Time" format="JodaISO" date={date} />,
                </TextBox>
                <TextBox color="secondary" variant="caption">
                  <FormattedDate
                    variant="ShortDate"
                    format="JodaISO"
                    date={date}
                  />
                </TextBox>
              </Inline>
            </Box>
          </Inline>
        </Box>
      </Column>
      {status === 'BID_COUNTER_OFFER' && (
        <Column width="content">
          <Inline space="xxsmall" verticalAlign="center">
            <TextBox variant="body-semibold" color="secondary">
              <Price value={bidCounterOfferPrice} />
            </TextBox>
            <TextBox variant="hint" color="secondary">
              · Your counter offer
            </TextBox>
          </Inline>
        </Column>
      )}
    </Columns>
  );
}

function getRequestStatus(request: OrderRequestDTO) {
  const {
    status,
    offer_cancel_reasons,
    offer_cancel_comment,
    parent_request_counter_offer_price,
  } = request;

  if (
    status === 'OFFER_CANCELED' &&
    (!!offer_cancel_reasons?.length || !!offer_cancel_comment)
  ) {
    return 'OFFER_CANCELED_BY_CARRIER';
  }

  if (status === 'REQUEST_PENDING' && !!parent_request_counter_offer_price) {
    return 'BID_COUNTER_OFFER';
  }

  return status;
}

export function OrderRequestStatusDetails({
  request,
}: {
  request: OrderRequestDTO;
}) {
  const {
    price,
    changed_at,
    counter_offer_price,
    parent_request_counter_offer_price,
  } = request;

  const requestStatus = getRequestStatus(request);

  return (
    <Stack space="xsmall">
      {requestStatusInfo[requestStatus] && (
        <RequestStatus
          status={requestStatus}
          date={changed_at}
          price={counter_offer_price || price}
          bidCounterOfferPrice={parent_request_counter_offer_price}
        />
      )}
      <RequestDeclineReasons status={requestStatus} request={request} />
    </Stack>
  );
}
