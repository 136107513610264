import { Box, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { ColorDynamic } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { ExecutePermission } from '../../shared/data/UserPermissions';
import { EmptyState } from '../../shared/ui/EmptyState';
import { CustomersImport } from '../core/CustomersImport';
import { useCustomersNavigation } from '../CustomersRouteHelpers';

export function CustomersEmptyList() {
  const { navigateToCustomersCreate } = useCustomersNavigation();

  return (
    <EmptyState title="No customers yet">
      <ExecutePermission entity="CUSTOMER_MANAGEMENT">
        <Box mt={3}>
          <Button
            variant="text"
            size="large"
            startIcon={<Add />}
            onClick={navigateToCustomersCreate}
          >
            Create New
          </Button>
        </Box>

        <Box mt={4} display="flex" alignItems="center">
          <Box width="60px" height="1px" bgcolor={ColorDynamic.Silver400} />

          <Box mx={2}>
            <Typography color="textSecondary">or import customers</Typography>
          </Box>

          <Box width="60px" height="1px" bgcolor={ColorDynamic.Silver400} />
        </Box>

        <Box mt={2}>
          <CustomersImport />
        </Box>
      </ExecutePermission>
    </EmptyState>
  );
}
