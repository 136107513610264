import { Dialog } from '@material-ui/core';
import { Box, Flex } from '@rebass/grid';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { CarrierAutocompleteField } from 'core/CarrierAutocomplete';
import { Form, FormikProvider } from 'formik';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { SourceManagerLegacy } from 'shared/helpers/SourceManagmentLegacy';
import { Carrier, CarrierFullInfo } from 'shared/types/carrier';
import Order from 'shared/types/order';
import { useSingleOrderActionAPI } from '../../data/OrderActionAPI';

interface MarkAsPendingDialogFormValues {
  carrier: Carrier | CarrierFullInfo | null;
}

interface MarkAsPendingDialogProps {
  order: Order | undefined;
  onClose: () => void;
  onSubmitSuccess: (order: Order) => void;
}

export default function MarkAsPendingDialog({
  order,
  onClose,
  onSubmitSuccess,
}: MarkAsPendingDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { markAsPending } = useSingleOrderActionAPI();

  const isOpen = !!order;

  const form = useFormikEnhanced<MarkAsPendingDialogFormValues, Order>({
    key: isOpen,
    initialValues: { carrier: null },
    validate({ carrier }) {
      if (!carrier) {
        return { carrier: { guid: 'Please select carrier' } };
      }

      return undefined;
    },
    onSubmit({ carrier }) {
      if (order && carrier) {
        const offer = Carrier.generateOrderOffer(carrier);
        return markAsPending(order.id, offer);
      }

      return Promise.reject(new Error('Order not found'));
    },
    onSubmitSuccess(response, { carrier }) {
      if (order && carrier) {
        const offer = Carrier.generateOrderOffer(carrier);

        trackEventLegacy('Marked as Pending', { order, offer });
        addSnackbar('Status changed successfully', { variant: 'success' });
        onSubmitSuccess(response);
      }
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, { variant: 'error' });
    },
  });

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      fullWidth={true}
      onClose={form.isSubmitting ? undefined : onClose}
      PaperProps={{ style: { overflow: 'initial' } }}
    >
      <FormikProvider value={form}>
        <Form>
          <Flex flexDirection="column" pt={24} px={24}>
            <Box>
              <h3>Mark as Pending</h3>
            </Box>

            <Box my={2}>
              <div>ID: {order?.number}</div>
            </Box>
          </Flex>

          <Flex p={24}>
            <Box flex={1}>
              <SourceManagerLegacy primarySource="Mark as Pending Dialog">
                <CarrierAutocompleteField name="carrier" withFMCSA={false} />
              </SourceManagerLegacy>
            </Box>
          </Flex>

          <Flex flexDirection="row-reverse" alignItems="flex-end" p={24}>
            <Box pl={2}>
              <Button
                type="submit"
                variant="primary"
                pending={form.isSubmitting}
              >
                Save
              </Button>
            </Box>
            <Box>
              <Button
                variant="neutral"
                onClick={onClose}
                disabled={form.isSubmitting}
              >
                Cancel
              </Button>
            </Box>
          </Flex>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
