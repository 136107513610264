import { Divider, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ColorDynamic, SuperDispatchTheme } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTimezones } from './data/GeneralSettingsAPI';
import { TimeZoneWithOffsetsDTO } from './data/GeneralSettingsDTO';

const TimeZoneField = styled(TextField)(
  ({ theme }: { theme: SuperDispatchTheme }) => css`
    &&& {
      max-width: 368px;
      ${theme.breakpoints.down('md')} {
        max-width: 100%;
      }
    }
  `,
);
const StyledDivider = styled(Divider)`
  border-bottom: 1px solid ${ColorDynamic.Silver400};
  width: calc(
    100% + 32px
  ); /* Added width and margin to make Divider fullwidth*/
  margin-left: -16px;
`;
function formatTimezone(timezone: TimeZoneWithOffsetsDTO): string {
  return `(${timezone.offset}) ${timezone.timezone}`;
}
interface Props {
  name: string;
  disabled: boolean;
}
export function TimeZoneAutocomplete({ name, ...props }: Props) {
  const [field, _, { setValue }] = useField<string | undefined>(name);
  const { data: timezones } = useTimezones();

  const timezonemap = useMemo(() => {
    const map: Record<string, TimeZoneWithOffsetsDTO> = {};
    timezones?.objects
      .filter((timezone) => timezone.is_pinned)
      .map((option) => (map[option.timezone] = option));
    map.__divider__ = { timezone: '', offset: '', is_pinned: false };
    timezones?.objects
      .filter((val) => !val.is_pinned)
      .map((option) => (map[option.timezone] = option));
    return map;
  }, [timezones]);

  return (
    <Autocomplete
      {...props}
      {...field}
      multiple={false}
      fullWidth={true}
      disableClearable={true}
      onChange={(_event, nextValue) => nextValue !== '' && setValue(nextValue)}
      options={Object.keys(timezonemap)}
      getOptionDisabled={(option) => option === '__divider__'}
      renderInput={(params) => {
        const { value: inputValue } = params.inputProps as { value?: string };
        const timezoneVal = inputValue && timezonemap[inputValue];

        return (
          <TimeZoneField
            {...params}
            inputProps={{
              ...params.inputProps,
              value: timezoneVal ? formatTimezone(timezoneVal) : inputValue,
            }}
          />
        );
      }}
      renderOption={(option) => {
        const value = timezonemap[option];
        if (option === '__divider__') {
          return <StyledDivider />;
        }

        return <TextBox>{value && formatTimezone(value)}</TextBox>;
      }}
    />
  );
}
