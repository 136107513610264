import { FunctionComponent, memo } from 'react';
import { PageLayout } from 'shared/layout/PageLayout';
import { Prompt } from 'shared/routing/Prompt';
import { AttachmentWithFile } from 'shared/types/attachment';
import { Order } from 'shared/types/order';
import { OrderContact, Step, Venue } from 'shared/types/step';
import { OrderFormVehicleDTO } from './data/OrderFormVehicleDTO';
import { Fields, FieldsProps } from './Fields';
import { OrderFormType } from './helpers/orderFormHelpers';
import { ScrollToError } from './helpers/ScrollToError';
import { OrderFormHeader } from './OrderFormHeader';

export interface OrderFormProps {
  title: string;
  isLoading: boolean;
  order: Partial<Order>;
  isSubmitDisabled: boolean;
  onCancelForm: () => void;
  users: FieldsProps['users'];
  source: 'order_edit_page' | 'order_create_page';
  isFormEdited?: boolean;
  onSave: (actionType: OrderFormType, args?: Record<string, unknown>) => void;
}

export interface OrderFormStep extends Omit<Step, 'venue'> {
  venue: Venue & {
    contact?: OrderContact;
  };
}

export type OrderFormCustomer = Order['customer'] & {
  contact?: OrderContact;
};

//Need to make transformer for this
export type OrderFormValues = Omit<Order, 'vehicles'> & {
  attachments: AttachmentWithFile[];
  price: string | number | undefined;
  vehicles: OrderFormVehicleDTO[];
  pickup: OrderFormStep;
  delivery: OrderFormStep;
  customer: OrderFormCustomer;
};

/**
 * OrderForm component is responsible for rendering the form for creating and editing orders.
 * It uses the `useOrderFormInitialValues` hook to handle the form initial values.
 * It also uses the `OrderFormHeader` component to render the header of the form.
 * It uses the `Fields` component to render the form fields.
 * It uses the `LegacyLayout` component to render the form.
 * It uses the `Prompt` component to show a confirmation dialog when the user tries to leave the form with unsaved changes.
 */
export const OrderForm: FunctionComponent<OrderFormProps> = memo((props) => {
  const {
    title,
    isLoading,
    order,
    isSubmitDisabled,
    onSave,
    onCancelForm,
    users,
    source,
    isFormEdited = false,
  } = props;

  if (isLoading) {
    return <PageLayout isContentLoading={true} />;
  }

  return (
    <>
      <ScrollToError />
      <Prompt
        message="Discard unsaved changes?"
        when={({ currentLocation, nextLocation }) => {
          const isSamePath = currentLocation.pathname === nextLocation.pathname;
          return !isSamePath && !isSubmitDisabled && isFormEdited;
        }}
      />
      <PageLayout
        header={
          <OrderFormHeader
            title={title}
            order={order}
            source={source}
            isSubmitDisabled={isSubmitDisabled}
            onSave={onSave}
            onBack={onCancelForm}
            isOfferValidating={false}
          />
        }
      >
        <Fields source={source} users={users} order={order} />
      </PageLayout>
    </>
  );
});

OrderForm.displayName = 'OrderForm';
