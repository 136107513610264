import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const Circle1Icon = createSvgIcon(
  'Circle1Icon',
  <>
    <rect width="24" height="24" rx="12" fill={ColorDynamic.Blue50} />
    <path
      d="M13.5462 6.81818V17H11.7017V8.61293H11.642L9.26065 10.1342V8.44389L11.7912 6.81818H13.5462Z"
      fill={ColorDynamic.Blue300}
    />
  </>,
);
