import { Divider, Typography } from '@material-ui/core';
import { Error, WarningRounded } from '@material-ui/icons';
import { Color, ColorDynamic, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderFormValues } from '../../orders/core/form/OrderForm';
import { CancelSuperPayLinkButton } from '../cancel/SuperPayCancel';

const DashedSeparate = styled(Box)`
  border-bottom: 1px dashed;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
  border-color: ${ColorDynamic.Silver400};
`;

const TitleNoWrap = styled(Typography)`
  white-space: nowrap;
`;

const BoxBaseLine = styled(Box)`
  align-items: baseline;
`;

interface SuperPayEditDrawerResultProps {
  superPayChargedAmount: number;
  totalCarrierPrice: number;
  totalCarrierPriceMinusFee: number;
  expeditedPayFeeAmount: number;
  refundAmount: number;
  isTotalCarrierPriceNotBeZero: boolean;
  canIncreasePrice: boolean;
  order: OrderFormValues | Order;
  onClose: () => void;
}

export function SuperPayEditDrawerResult({
  superPayChargedAmount,
  totalCarrierPrice,
  totalCarrierPriceMinusFee,
  expeditedPayFeeAmount,
  refundAmount,
  isTotalCarrierPriceNotBeZero,
  canIncreasePrice,
  order,
  onClose,
}: SuperPayEditDrawerResultProps) {
  const showRefundText =
    superPayChargedAmount !== totalCarrierPriceMinusFee && !!refundAmount;

  const isExceededInitialPrice =
    !canIncreasePrice && totalCarrierPriceMinusFee > superPayChargedAmount;

  return (
    <Box paddingLeft="none" paddingRight="none">
      <Stack space="small">
        {showRefundText && !isTotalCarrierPriceNotBeZero && (
          <Box backgroundColor="Yellow50" padding="xsmall" borderRadius="small">
            <Columns space="xsmall" align="top">
              <Column width="content">
                <Box top={['auto', '2px']} position="relative">
                  <WarningRounded
                    fontSize="small"
                    htmlColor={Color.Yellow300}
                  />
                </Box>
              </Column>
              <Column>
                <Stack space="small">
                  <Typography>
                    Since SuperPay charge was initiated and the combined vehicle
                    price is less than the original Total Carrier Price, a
                    refund will be issued.
                  </Typography>
                  <Typography>
                    Refunds take 1-3 business days to complete.
                  </Typography>
                </Stack>
              </Column>
            </Columns>
          </Box>
        )}
        <Divider />
        <Stack space="xsmall">
          <Box display="flex" aria-label="superpay total price">
            <BoxBaseLine width="270px" display="flex">
              <TitleNoWrap variant="body1" color="initial">
                Total Carrier Price
              </TitleNoWrap>
              <DashedSeparate />
            </BoxBaseLine>
            <Typography variant="body1" color="initial">
              {formatCurrency(totalCarrierPrice)}
            </Typography>
          </Box>

          {!!expeditedPayFeeAmount && (
            <Box display="flex" aria-label="superpay total price">
              <BoxBaseLine width="270px" display="flex">
                <TitleNoWrap>Expedite Fee</TitleNoWrap>
                <DashedSeparate />
              </BoxBaseLine>
              <Typography>{formatCurrency(expeditedPayFeeAmount)}</Typography>
            </Box>
          )}

          {!!superPayChargedAmount && (
            <Box display="flex" aria-label="superpay charged">
              <BoxBaseLine width="270px" display="flex">
                <TitleNoWrap>SuperPay Charged</TitleNoWrap>
                <DashedSeparate />
              </BoxBaseLine>
              <Typography color="textSecondary">
                {formatCurrency(superPayChargedAmount)}
              </Typography>
            </Box>
          )}

          {isTotalCarrierPriceNotBeZero ? (
            <Box backgroundColor="Red50" padding="xsmall" borderRadius="small">
              <Columns space="xsmall">
                <Column width="content">
                  <Box top={['auto', '2px']} position="relative">
                    <Error fontSize="small" htmlColor={Color.Red300} />
                  </Box>
                </Column>
                <Column>
                  <Stack space="small">
                    <Typography>
                      Total Carrier Price must be greater than $0 for SuperPay
                      charge to be initiated.
                    </Typography>
                    <Typography>
                      To proceed with a $0 price, first{' '}
                      <CancelSuperPayLinkButton
                        order={order}
                        color="primary"
                        onClose={onClose}
                      />
                    </Typography>
                  </Stack>
                </Column>
              </Columns>
            </Box>
          ) : showRefundText ? (
            <Stack space="small">
              <Box display="flex" aria-label="superpay refund">
                <BoxBaseLine width="270px" display="flex">
                  <TitleNoWrap>Refund</TitleNoWrap>
                  <DashedSeparate />
                </BoxBaseLine>
                <Typography color="textSecondary">
                  {formatCurrency(refundAmount)}
                </Typography>
              </Box>
              <TextBox>
                Refund will be processed when the carrier payment is initiated.
                Refund completion may take 1-3 business days.
              </TextBox>
            </Stack>
          ) : (
            isExceededInitialPrice && (
              <Box maxWidth={['initial', '75%']}>
                <Typography color="error">
                  {`Total Carrier Price can NOT be over the initial ${formatCurrency(
                    superPayChargedAmount,
                    { minimumFractionDigits: 0 },
                  )} value.`}
                </Typography>
              </Box>
            )
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
