import { MouseEvent } from 'react';
import Order from 'shared/types/order';
import { isSuperpayPayment } from '../../../../core/PaymentUtils';
import { useSingleOrderActionAPI } from '../../../data/OrderActionAPI';
import { OrderActionSource } from '../OrderActionsAnalytics';
import { trackScheduleEvent } from './scheduleAnanlytics';
import {
  SuperPayNowGroupButtons,
  SuperPayNowMenuButtons,
} from './SuperPayNowButton';

interface SuperPaySingleNowButtonProps {
  onSuperPayNow: (event: MouseEvent) => void;
  canScheduleSuperPay: boolean;
  order: Order;
  variant?: 'group' | 'menu';
  onScheduleSuccess?: () => void;
  source: OrderActionSource;
  disabled?: boolean;
}

function useSuperPaySingleNowButton(order: Order) {
  const { superPaySchedule } = useSingleOrderActionAPI();
  const isScheduleDialogDisabled =
    ['accepted', 'delivered'].includes(order.status) ||
    isSuperpayPayment(order.payment);

  const onScheduleSuperPayNow = (expectedDate: string) =>
    superPaySchedule(order.id, expectedDate);

  return {
    isScheduleDialogDisabled,
    onScheduleSuperPayNow,
  };
}

export function SuperPaySingleNowGroupButton({
  onSuperPayNow,
  order,
  canScheduleSuperPay,
  onScheduleSuccess,
  source,
}: SuperPaySingleNowButtonProps) {
  const { onScheduleSuperPayNow, isScheduleDialogDisabled } =
    useSuperPaySingleNowButton(order);

  return (
    <SuperPayNowGroupButtons
      canScheduleSuperPay={canScheduleSuperPay}
      totalCharge={order.price}
      onSuperPayNow={onSuperPayNow}
      onScheduleSuperPayNow={onScheduleSuperPayNow}
      canScheduleConfirmDialog={!isScheduleDialogDisabled}
      onScheduleSuccess={() => {
        trackScheduleEvent('Shipper Scheduled SuperPay', [order], source);
        onScheduleSuccess?.();
      }}
      onOpenScheduleCalendar={() => {
        trackScheduleEvent(
          'Shipper Clicked Schedule SuperPay',
          [order],
          source,
        );
      }}
    />
  );
}

export function SuperPaySingleNowMenuButton({
  onSuperPayNow,
  order,
  canScheduleSuperPay,
  onScheduleSuccess,
  source,
  disabled,
}: SuperPaySingleNowButtonProps) {
  const { onScheduleSuperPayNow, isScheduleDialogDisabled } =
    useSuperPaySingleNowButton(order);

  return (
    <SuperPayNowMenuButtons
      canScheduleSuperPay={canScheduleSuperPay}
      totalCharge={order.price}
      onSuperPayNow={onSuperPayNow}
      onScheduleSuperPayNow={onScheduleSuperPayNow}
      canScheduleConfirmDialog={!isScheduleDialogDisabled}
      onScheduleSuccess={() => {
        trackScheduleEvent('Shipper Scheduled SuperPay', [order], source);
        onScheduleSuccess?.();
      }}
      onOpenScheduleCalendar={() => {
        trackScheduleEvent(
          'Shipper Clicked Schedule SuperPay',
          [order],
          source,
        );
      }}
      disabled={disabled}
    />
  );
}
