import { useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { invoiceSchema } from '../InvoicesDTO';

export function useInvoices() {
  const { requestPage } = useAPI();

  return useAPIPageQuery(['subscription', 'invoices'], () =>
    requestPage(`/internal/billing/invoices`, (data) =>
      invoiceSchema.cast(data),
    ),
  );
}
