import { DateType, PaymentTerm } from '@superdispatch/sdk';
import { TerminalAutocompleteValue } from 'orders/core/form/counterparty/CustomerAutocomplete';
import { VehicleMake } from 'orders/core/form/data/OrderFormVehicleDTO';
import { useMemo } from 'react';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { InspectionType } from 'shared/types/order';
import { removeStorageItem, useStorageValue } from './LocalStorage';

export const ORDER_RECENT_SELECTIONS_KEY = 'order_recent_selections';
export const ORDER_RECENT_SEARCH_PERIOD_KEY = 'order_search_period';
export const ORDER_RECENT_SEARCH_TYPE_KEY = 'order_search_type';

export const updateRecentArray = (
  array?: TerminalAutocompleteValue[],
  venue?: CustomerDTO,
) => {
  let updatedArray = array ? Array.from(array) : [];

  if (venue?.name) {
    let indexOfVenue = updatedArray.findIndex(
      (item: TerminalAutocompleteValue) => item.name === venue.name,
    );
    if (indexOfVenue !== -1) {
      updatedArray[indexOfVenue] = venue;
    } else {
      if (updatedArray.length >= 5) {
        updatedArray.splice(-1, 1);
      }

      updatedArray.unshift(venue);
    }
  }

  return updatedArray;
};

export const updateRecentVehicleMakers = (
  array?: VehicleMake[],
  vehicles?: VehicleDTO[],
) => {
  let updatedArray = array ? Array.from(array) : [];

  if (vehicles && vehicles.length > 0) {
    vehicles.forEach((vehicle: VehicleDTO) => {
      if (vehicle.make) {
        let indefOfVehicleMake = updatedArray.findIndex(
          (item: VehicleMake) => item.name === vehicle.make,
        );
        if (indefOfVehicleMake !== -1) {
          updatedArray[indefOfVehicleMake] = { name: vehicle.make };
        } else {
          if (updatedArray.length >= 5) {
            updatedArray.splice(-1, 1);
          }

          updatedArray.unshift({ name: vehicle.make });
        }
      }
    });
  }

  return updatedArray;
};

export interface OrderRecentSelections {
  customers?: TerminalAutocompleteValue[];

  pickupVenues?: TerminalAutocompleteValue[];

  deliveryVenues?: TerminalAutocompleteValue[];

  inspection_type?: InspectionType;

  pickup_date_type?: DateType;

  delivery_date_type?: DateType;

  customer_payment_terms?: PaymentTerm;

  vehicle_makers?: VehicleMake[];
}

export function useRecentSelections(): OrderRecentSelections {
  const value = useStorageValue(ORDER_RECENT_SELECTIONS_KEY);

  return useMemo(() => {
    if (value) {
      return JSON.parse(value) as OrderRecentSelections;
    }

    return {};
  }, [value]);
}

export function clearRecentSelections() {
  removeStorageItem(ORDER_RECENT_SELECTIONS_KEY);
}

export function clearRecentSearchType() {
  removeStorageItem(ORDER_RECENT_SEARCH_TYPE_KEY);
}

export function clearRecentSearchPeriod() {
  removeStorageItem(ORDER_RECENT_SEARCH_PERIOD_KEY);
}

export function clearRecentSearchFilters() {
  clearRecentSearchType();
  clearRecentSearchPeriod();
}
