import { Image } from '@superdispatch/ui';
import styled from 'styled-components';
import superPayLabel from './assets/superpay.png';
import superPayLabelDark from './assets/superpay_dark.png';

const StyledImg = styled(Image)`
  vertical-align: middle;
`;

export function SuperPayIcon({
  height = 14,
  width = 70,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <StyledImg
      height={height}
      width={width}
      src={superPayLabel}
      srcDark={superPayLabelDark}
      aria-label="SuperPay label"
    />
  );
}
