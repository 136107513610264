import {
  DateFormat,
  NullableDateString,
  stringifyDate,
} from '@superdispatch/dates';
import { PhoneService, PhoneValidationRules } from '@superdispatch/phones';
import {
  addMethod,
  array,
  boolean,
  BooleanSchema,
  lazy,
  mixed,
  MixedSchema,
  NotRequiredArraySchema,
  number,
  NumberSchema,
  object,
  ObjectSchema,
  ObjectSchemaDefinition,
  Schema,
  setLocale,
  string,
  StringSchema,
} from 'yup';

setLocale({
  mixed: {
    default: 'Invalid value',
    required: 'This field is required',
  },
  string: {
    length: 'This field must have exactly ${length} characters',
    min: 'This field must have at least ${min} characters',
    max: 'Maximum length is ${max} characters',
    email: 'Enter a valid email',
    url: 'Value must be a valid URL',
  },
  number: {
    min: 'Value must be greater than or equal to ${min}',
    max: 'Value must be less than or equal to ${max}',
    lessThan: 'Value must be less than ${less}',
    moreThan: 'Value must be greater than ${more}',
    positive: 'Value must be a positive number',
    negative: 'Value must be a negative number',
    integer: 'Value must be an integer',
  },
  date: {
    min: 'Date must be later than ${min}',
    max: 'Date must be at earlier than ${max}',
  },
});

/**
 * Reusable `object` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `noUnknown` to strip unknown properties.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function yupObject<T extends object>(
  fields: ObjectSchemaDefinition<T>,
): ObjectSchema<T> {
  return object(fields).defined().noUnknown().nullable(false);
}

/**
 * Reusable `never` schema that allows to remove properties from
 * the `objectSchema`
 */
export const yupOmit: Schema<never | undefined> = mixed<never>()
  .optional()
  .transform(() => undefined);

/**
 * Reusable `array` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `ensure` casts `null` and `undefined` values to an empty array.
 */
export function yupArray<T>(schema: Schema<T>): NotRequiredArraySchema<T> {
  return array(schema).ensure();
}

/**
 * Reusable `string` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 */
export function yupString(): StringSchema<string> {
  return string().defined();
}

/**
 * Reusable `number` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 */
export function yupNumber(): NumberSchema<null | number> {
  return number().defined().nullable().default(null);
}

export function yupNumberDefaultValue(defaultValue = 0) {
  return number()
    .defined()
    .default(defaultValue)
    .nullable(false)
    .transform((value) =>
      typeof value !== 'number' || !value || Number.isNaN(value)
        ? defaultValue
        : value,
    );
}

export function yupStringDefaultValue(defaultValue = '') {
  return string()
    .defined()
    .default(defaultValue)
    .nullable(false)
    .transform((value) =>
      typeof value !== 'string' || !value ? defaultValue : value,
    );
}

/**
 * Reusable `boolean` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `default` casts `undefined` values to `false`.
 * 3. `transform` casts `null` values to `boolean`.
 */
export function yupBoolean(): BooleanSchema<null | boolean> {
  return boolean().defined().nullable().default(false);
}

/**
 * Reusable `enum` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `default` casts `undefined` values to `false`.
 * 3. `transform` casts `null` values to `boolean`.
 */
export function yupEnum<T extends null | string>(
  allowedValues: readonly T[],
  defaultValue: T,
): MixedSchema<T> {
  if (allowedValues.length === 0) {
    throw new Error('[yupEnum] "allowedValues" array can not be empty.');
  }

  return mixed<T>()
    .defined()
    .default(defaultValue)
    .transform(function normalize(value: T) {
      return allowedValues.includes(value) ? value : this.default();
    });
}

/**
 * Reusable `DateString` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `default` casts `undefined` values to `null`.
 * 3. `nullable` allows `null` inputs.
 * 4. Transforms all inputs to `NullableDateString`.
 */
export function yupDateString(
  format: DateFormat,
): StringSchema<NullableDateString> {
  return string<NullableDateString>()
    .defined()
    .nullable()
    .default(null)
    .transform(
      (_, originalValue) => stringifyDate(originalValue, { format }) ?? null,
    );
}

export type YupPhoneOptions = Omit<
  PhoneValidationRules,
  'required' | 'requiredMessage'
>;

/**
 * Reusable `phone` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `ensure` casts `null` and `undefined` values to `string`.
 * 3. `trim` value.
 * 4. `test` phone number value.
 */
export function yupPhone(options?: YupPhoneOptions): StringSchema<string> {
  return yupString()
    .trim()
    .test(
      'phone-number',
      'Invalid Phone Number',
      function testPhoneNumber(value) {
        // Skip sync validation.
        if ('sync' in this.options) {
          return true;
        }

        return PhoneService.validate(value, options).then((message) => {
          if (!message) {
            return true;
          }

          return this.createError({ message });
        });
      },
    );
}

/**
 * Reusable `string` schema with useful defaults:
 * 1. `trim` search param.
 * 2. `nullable` allows `null` inputs.
 * 3. `default` will cast `undefined` to `null`.
 * 4. `transform` non `string` or empty strings to `null`.
 */
export function yupStringParam(): StringSchema<null | undefined | string> {
  return string()
    .trim()
    .nullable()
    .default(null)
    .transform(function normalize(value) {
      return typeof value == 'string' && value.length > 0
        ? value
        : this.default();
    });
}

/**
 * Reusable `number` schema with useful defaults:
 * 1. `nullable` allows `null` inputs.
 * 2. `default` will cast `undefined` to `null`.
 * 3. `transform` non finite numbers to `default`.
 */
export function yupNumberParam(): NumberSchema<null | undefined | number> {
  return number()
    .nullable()
    .default(null)
    .transform(function normalize(value) {
      return typeof value == 'number' && Number.isFinite(value)
        ? value
        : this.default();
    });
}

/**
 * Reusable `boolean` schema with useful defaults:
 * 1. `nullable` allows `null` inputs.
 * 2. `default` will cast `undefined` to `null`.
 * 3. `transform` non `boolean` or non finite numbers to `default`.
 */
export function yupBooleanParam(): BooleanSchema<null | undefined | boolean> {
  return boolean()
    .nullable()
    .default(null)
    .transform(function normalize(value) {
      return typeof value === 'boolean' ? value : this.default();
    });
}

/**
 * Reusable for strip empty / meta fields
 *
 * As example:
 * We sending Contact entity to server without guid when creating
 * Or when we sending meta fields like selected_from_recent, we strip this
 */
export const lazyAndStripEmpty = () =>
  lazy((value) =>
    value === undefined || value === null
      ? mixed().strip(true)
      : mixed().nullable(false),
  );

interface MultipleEmailsValidatorProps {
  delimiter?: string;
  message?: string;
}
declare module 'yup' {
  interface StringSchema {
    multipleEmails: (props?: MultipleEmailsValidatorProps) => StringSchema;
  }
}

addMethod<StringSchema>(
  string,
  'multipleEmails',
  function multipleEmails({
    delimiter = ',',
    message = 'Enter valid email(s)',
  }: MultipleEmailsValidatorProps = {}) {
    const emailsSchema = yupArray(yupString().email().required());

    return this.test('all-emails-valid', message, (value) => {
      if (!value || typeof value !== 'string') {
        return true;
      }

      const emails = value
        .trim()
        .split(delimiter)
        .map((email) => email.trim());

      return emailsSchema.isValidSync(emails);
    });
  },
);
/** Concatenate array of schemas into one schema. Yup.concat supports only chaining */
export function yupConcatSchemas<T extends ObjectSchema[]>(...schemas: T) {
  return schemas.reduce((mergedSchemas, schema) =>
    mergedSchemas.concat(schema),
  );
}
