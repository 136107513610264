import { Box, IconButton, OutlinedInput } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { ColorDynamic, Column, Columns, Stack } from '@superdispatch/ui';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { OrderListSearchPeriod } from './OrderListSearchPeriod';
import { OrderListSearchType } from './OrderListSearchType';

interface OrderListHeaderSearchCompact {
  query?: string;
  onSearch?: (query: string) => void;
  setSearchVisibility: (visibility: boolean) => void;
}

const StyledSearchBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${ColorDynamic.White};
`;

export function OrderListHeaderSearchCompact({
  onSearch,
  query,
  setSearchVisibility,
}: OrderListHeaderSearchCompact) {
  function handleChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    onSearch?.(value);
  }

  return (
    <Box
      style={{
        minHeight: 164,
      }}
    >
      <StyledSearchBox px={2} display="flex" alignItems="center">
        <Columns align="center" space="small">
          <Column width="content">
            <IconButton
              onClick={() => {
                setSearchVisibility(false);
                onSearch?.('');
              }}
            >
              <ArrowBack />
            </IconButton>
          </Column>

          <Column>
            <Stack>
              <OrderListSearchType fullWidth={true} />

              <OutlinedInput
                type="search"
                value={query}
                onChange={handleChange}
                autoFocus={true}
                fullWidth={true}
                placeholder="3 symbols to search"
                labelWidth={70}
              />

              <OrderListSearchPeriod fullWidth={true} />
            </Stack>
          </Column>
        </Columns>
      </StyledSearchBox>
    </Box>
  );
}
