import { PaletteType } from '@material-ui/core';
import {
  ColorDynamic,
  SuperDispatchTheme,
  ThemeProvider,
} from '@superdispatch/ui';
import { ReactNode, useEffect } from 'react';
import {
  useStorageWithDefaultValue,
  writeStorageItem,
} from 'shared/helpers/LocalStorage';

function getScrollbarCss() {
  if (!navigator.appVersion.includes('Win')) {
    return {};
  }

  return {
    '::-webkit-scrollbar': {
      width: 10,
      height: 10,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      // Color.Dark400
      backgroundColor: 'rgba(50, 60, 78, 0.2)',
      borderRadius: '100vw',
      marginBottom: '100px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(50, 60, 78, 0.8)',
    },
  };
}

function modifyTheme(theme: SuperDispatchTheme) {
  theme.breakpoints.values.md = 1025;

  return {
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiCssBaseline: {
        '@global': {
          ...getScrollbarCss(),
        },
      },
      MuiTableCell: {
        ...theme.overrides.MuiTableCell,
        root: {
          ...theme.overrides.MuiTableCell?.root,
          borderBottomColor: ColorDynamic.Silver400, // TODO move to sd/ui
        },
      },
    },
  };
}

export type AppThemeModeSetting = PaletteType | 'system';

const APP_THEME_MODE_SETTING = 'app_theme_mode_setting';
const APP_THEME_MODE = 'app_theme_mode';

export function useAppThemeMode(): PaletteType {
  return useStorageWithDefaultValue(APP_THEME_MODE, 'light') as PaletteType;
}

export function useThemeModeSetting(): AppThemeModeSetting {
  return useStorageWithDefaultValue(
    APP_THEME_MODE_SETTING,
    'light',
  ) as AppThemeModeSetting;
}

export function setThemeModeSetting(value: AppThemeModeSetting) {
  writeStorageItem(APP_THEME_MODE_SETTING, value);
}

export function setAppThemeMode(value: PaletteType) {
  writeStorageItem(APP_THEME_MODE, value);
}

function useThemeMode() {
  const appThemeMode = useAppThemeMode();
  const themeModeSetting = useThemeModeSetting();

  useEffect(() => {
    if (themeModeSetting !== 'system') {
      setAppThemeMode(themeModeSetting);
      return;
    }
    const preferredColor = window.matchMedia('(prefers-color-scheme: dark)');

    setAppThemeMode(preferredColor.matches ? 'dark' : 'light');
    const handleChange = (event: MediaQueryListEvent) => {
      setAppThemeMode(event.matches ? 'dark' : 'light');
    };

    preferredColor.addEventListener('change', handleChange);
    return () => {
      preferredColor.removeEventListener('change', handleChange);
    };
  }, [themeModeSetting]);

  return appThemeMode;
}

export function AppTheme({ children }: { children: ReactNode }) {
  const mode = useThemeMode();

  return (
    <ThemeProvider mode={mode} modifier={modifyTheme}>
      {children}
    </ThemeProvider>
  );
}
