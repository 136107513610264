import { Link, Typography, useMediaQuery } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { Box, Flex, FlexProps } from '@rebass/grid';
import { ColorDynamic } from '@superdispatch/ui';
import { TextOverflow } from 'shared/ui/TextOverflow';
import styled from 'styled-components';

const InfoText = styled(Typography)`
  color: ${ColorDynamic.Dark300};
`;

interface ContactProps {
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  mobilePhone?: string | null;
  info?: string | null;
  ContainerProps?: FlexProps;
}

export function OrderViewContactInfo({
  name,
  phone,
  email,
  mobilePhone,
  info,
  ContainerProps,
}: ContactProps) {
  const matches = useMediaQuery('(min-width:1280px) and (max-width: 1360px)');
  if (!(name || info || mobilePhone || phone || email)) {
    return null;
  }

  return (
    <Flex {...ContainerProps}>
      <Box mr={2}>
        <Typography variant="h4">
          <PersonIcon fontSize="inherit" htmlColor={ColorDynamic.Dark100} />
        </Typography>
      </Box>

      <Box>
        <Typography variant="body1" aria-label="contact name">
          {name || 'Contact'}
        </Typography>

        {info && (
          <Box mt={1}>
            <InfoText variant="body2" aria-label="contact info">
              {info}
            </InfoText>
          </Box>
        )}

        <Flex mt={1} flexDirection="column" alignItems="flex-start">
          {phone && (
            <Link href={`tel:${phone}`} aria-label="contact phone">
              {phone}
            </Link>
          )}

          {mobilePhone && (
            <div aria-label="contact mobile phone">
              <Link href={`tel:${mobilePhone}`}>{mobilePhone}</Link>{' '}
              <InfoText display="inline" variant="body2">
                &bull; mobile
              </InfoText>
            </div>
          )}

          {email && (
            <Link href={`mailto:${email}`} aria-label="contact email">
              {matches ? (
                <TextOverflow size={175}>{email}</TextOverflow>
              ) : (
                email
              )}
            </Link>
          )}
        </Flex>
      </Box>
    </Flex>
  );
}
