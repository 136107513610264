import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { parseDate, stringifyDate } from '@superdispatch/dates';
import { Image, Inline, Stack } from '@superdispatch/ui';
import { AnchorButton, Button } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { ExitTransitionPlaceholder } from 'shared/ui/ExitTransitionPlaceholder';
import styled from 'styled-components';
import authorityRevocationDark from '../assets/authority-revocation-dark.png';
import authorityRevocation from '../assets/authority-revocation.png';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { useVerificationApplication } from '../data/VerificationApplicationAPI';

const CustomDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 480px;
  }

  & .MuiDialogActions-root {
    justify-content: center;
  }
`;

const MainIllustration = styled(Image)`
  width: 150px;
`;

interface AuthorityRevocationDialogContentProps {
  onClose?: () => void;
}

function AuthorityRevocationDialogContent({
  onClose,
}: AuthorityRevocationDialogContentProps) {
  return (
    <>
      <DialogContent>
        <Stack align="center" space="large">
          <MainIllustration
            src={authorityRevocation}
            alt="authority revocation"
            srcDark={authorityRevocationDark}
          />
          <Stack>
            <Typography align="center" variant="h2">
              Verified Shipper status revoked due to inactive Operating
              Authority
            </Typography>
            <Typography align="center">
              You can&apos;t post orders until your authority is restored.
              Unbooked orders were unposted but can be reposted from the
              &apos;New&apos; tab once you&apos;re verified again. Your Super
              Dispatch subscription remains active.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Inline space="small">
          <AnchorButton
            href="https://support.superdispatch.com/hc/articles/35653714314131-Reinstating-Broker-Authority"
            target="_blank"
            variant="neutral"
          >
            Learn More
          </AnchorButton>
          <Button onClick={onClose}>I understand</Button>
        </Inline>
      </DialogActions>
    </>
  );
}

interface VerificationRevokedDialogContentProps {
  onClose?: () => void;
}

function VerificationRevokeDialogContent({
  onClose,
}: VerificationRevokedDialogContentProps) {
  return (
    <>
      <DialogContent>
        <Stack align="center" space="large">
          <MainIllustration
            src={authorityRevocation}
            alt="authority revocation"
            srcDark={authorityRevocationDark}
          />
          <Stack>
            <Typography align="center" variant="h2">
              Your Shipper TMS account has been revoked.
            </Typography>
            <Typography align="center">
              You will not be permitted to send direct load offers to carriers
              or post loads on the Super Dispatch Loadboard. Your access to the
              Management System will remain open, and your Super Dispatch
              subscription remains active.
            </Typography>
            <Typography align="center">
              Please send any questions at:
              <br />
              <b>legal@superdispatch.com</b>
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Inline space="small">
          <AnchorButton
            href="https://support.superdispatch.com/hc/en-us/articles/39891370977427-Understanding-Account-Revocation-in-Super-Dispatch"
            target="_blank"
            variant="neutral"
          >
            Learn More
          </AnchorButton>
          <Button onClick={onClose}>I understand</Button>
        </Inline>
      </DialogActions>
    </>
  );
}

interface AuthorityRevocationDialogProps {
  open?: boolean;
  onClose?: () => void;
  isGlobalDialog?: boolean;
  action?: string;
  actionType?: string;
}

export function VerificationRevokedDialog({
  open,
  onClose,
  isGlobalDialog,
  action,
  actionType,
}: AuthorityRevocationDialogProps) {
  const { data: verification } = useVerificationApplication();

  useEffect(() => {
    if (open) {
      trackVerificationApplicationEvent({
        name:
          verification?.revoke_reason === 'LOSS_OF_AUTHORITY'
            ? 'Shipper Viewed Authority Revocation Dialog'
            : 'Shipper Viewed Verification Revoked Dialog',
        utm_content: isGlobalDialog ? 'Automatic' : 'From Order Actions',
        action,
        action_type: actionType,
      });
    }
  }, [open, isGlobalDialog, action, actionType, verification]);

  return (
    <CustomDialog open={!!open} onClose={onClose} fullWidth={true}>
      <ExitTransitionPlaceholder in={!!open}>
        {open &&
          (verification?.revoke_reason === 'LOSS_OF_AUTHORITY' ? (
            <AuthorityRevocationDialogContent onClose={onClose} />
          ) : (
            <VerificationRevokeDialogContent onClose={onClose} />
          ))}
      </ExitTransitionPlaceholder>
    </CustomDialog>
  );
}

const AUTHORITY_REVOCATION_GLOBAL_STORAGE_KEY = 'authority-revocation-showed';

export function VerificationRevokedGlobalDialog() {
  const authorityRevocationShowed = useStorageValue(
    AUTHORITY_REVOCATION_GLOBAL_STORAGE_KEY,
  );

  const { data: verification } = useVerificationApplication();

  const isOpen = useMemo(() => {
    const revocationDateFromStorage = parseDate(authorityRevocationShowed);
    const revocationDate = parseDate(verification?.revocation_date);
    //revocationDateFromStorage is invalid if dialog was never shown
    const revocationTimeIsValid =
      !revocationDateFromStorage.isValid ||
      revocationDate > revocationDateFromStorage;

    return verification?.status === 'REVOKED' && revocationTimeIsValid;
  }, [verification, authorityRevocationShowed]);

  const handleClose = () => {
    writeStorageItem(
      AUTHORITY_REVOCATION_GLOBAL_STORAGE_KEY,
      verification?.revocation_date ||
        stringifyDate(DateTime.local(), { format: 'DateISO' }) ||
        '',
    );
  };

  return (
    <VerificationRevokedDialog
      open={isOpen}
      onClose={handleClose}
      isGlobalDialog={true}
    />
  );
}
