import { createSvgIcon } from 'shared/icons/IconUtils';

export const PaymentCashIcon = createSvgIcon(
  'PaymentCashIcon',
  <path
    d="M1.3335 3.33334H14.6668V12.2222H1.3335V3.33334ZM8.00016 5.55557C8.58953 5.55557 9.15476 5.78969 9.57151 6.20644C9.98826 6.62319 10.2224 7.18842 10.2224 7.77779C10.2224 8.36716 9.98826 8.93239 9.57151 9.34914C9.15476 9.76588 8.58953 10 8.00016 10C7.41079 10 6.84556 9.76588 6.42881 9.34914C6.01207 8.93239 5.77794 8.36716 5.77794 7.77779C5.77794 7.18842 6.01207 6.62319 6.42881 6.20644C6.84556 5.78969 7.41079 5.55557 8.00016 5.55557ZM4.29646 4.81483C4.29646 5.20774 4.14038 5.58456 3.86254 5.86239C3.58471 6.14022 3.20789 6.29631 2.81498 6.29631V9.25927C3.20789 9.25927 3.58471 9.41535 3.86254 9.69319C4.14038 9.97102 4.29646 10.3478 4.29646 10.7408H11.7039C11.7039 10.3478 11.86 9.97102 12.1378 9.69319C12.4156 9.41535 12.7924 9.25927 13.1853 9.25927V6.29631C12.7924 6.29631 12.4156 6.14022 12.1378 5.86239C11.86 5.58456 11.7039 5.20774 11.7039 4.81483H4.29646Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 16' },
);
