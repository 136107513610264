import { createSvgIcon } from 'shared/icons/IconUtils';

export const PaymentVenmoIcon = createSvgIcon(
  'PaymentVenmoIcon',
  <path
    d="M13.0403 2H3.02536C2.47812 2 2 2.39375 2 2.93455V12.9714C2 13.5154 2.47812 14 3.02536 14H13.0373C13.5875 14 14 13.5125 14 12.9717V2.93455C14.0032 2.39375 13.5875 2 13.0403 2ZM8.58929 11.5089H5.81214L4.69866 4.85107L7.12946 4.62018L7.71875 9.35777C8.26866 8.46179 8.94768 7.0542 8.94768 6.09339C8.94768 5.56786 8.85768 5.20946 8.71705 4.91482L10.9304 4.46696C11.1864 4.88964 11.3016 5.32411 11.3016 5.87509C11.3013 7.62955 9.80375 9.90875 8.58929 11.5089Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 16' },
);
