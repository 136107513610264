import { Card, CardContent, MenuItem, Typography } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { CUSTOMER_TYPES, formatCustomerType } from '@superdispatch/sdk';
import {
  CheckboxField,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { Box, Button, SidebarContent, TextBox } from '@superdispatch/ui-lab';
import { Field, Form, FormikProvider } from 'formik';
import { useMemo, useState } from 'react';
import {
  terminalContactDTO,
  TerminalContactDTO,
} from 'shared/dto/TerminalContactDTO';
import { terminalDTO, TerminalDTO } from 'shared/dto/TerminalDTO';
import { useTerminalsNavigation } from 'terminals/TerminalsRouteHelpers';
import { email, required } from '../../shared/utils/ValidatorUtils';
import { useTerminalContactAPI } from '../data/TerminalContactAPI';
import { useTerminalsAPI } from '../data/TerminalsAPI';

interface TerminalWithContactFormData {
  terminal: Partial<TerminalDTO>;
  contact?: Partial<TerminalContactDTO>;
}

export function TerminalCreatePage() {
  const uid = useUID();
  const { addSnackbar } = useSnackbarStack();
  const { createTerminal } = useTerminalsAPI();
  const { navigateToTerminals } = useTerminalsNavigation();
  const { createTerminalContact } = useTerminalContactAPI();

  const [showRequiredFieldsOnly, setShowRequiredFieldsOnly] = useState(false);

  const initialValues = useMemo<TerminalWithContactFormData>(
    () => ({
      terminal: { name: '', business_type: 'BUSINESS' },
      contact: { email: '', is_primary: true },
    }),
    [],
  );

  const form = useFormikEnhanced<TerminalWithContactFormData, TerminalDTO>({
    initialValues,
    validateOnMount: true,
    async onSubmit({ contact, terminal }) {
      const terminalPayload = terminalDTO.cast(terminal);
      const createdTerminal = await createTerminal(terminalPayload);

      if (contact?.name) {
        const contactPayload = terminalContactDTO.cast(contact);
        await createTerminalContact(createdTerminal.id, contactPayload);
      }

      return createdTerminal;
    },
    onSubmitSuccess(createdTerminal) {
      addSnackbar('Terminal created');
      navigateToTerminals({
        current_terminal_guid: createdTerminal.guid as string,
      });
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <SidebarContent
      title="New Terminal"
      openOnMount={true}
      onBack={() => navigateToTerminals()}
      action={
        <Inline>
          <CheckboxField
            label="Only show required fields"
            color="primary"
            value={showRequiredFieldsOnly}
            checked={showRequiredFieldsOnly}
            onChange={(_, checked) => setShowRequiredFieldsOnly(checked)}
          />

          <Button
            form={uid}
            variant="primary"
            type="submit"
            pending={form.isSubmitting}
          >
            Save
          </Button>
        </Inline>
      }
    >
      <FormikProvider value={form}>
        <Form id={uid}>
          <Box width="440px" marginLeft="auto" marginRight="auto">
            <Card>
              <CardContent>
                <Stack space="small">
                  <Typography variant="h3">Terminal Info</Typography>

                  <FormikTextField
                    name="terminal.name"
                    validate={required}
                    fullWidth={true}
                    label={
                      <Inline space="xxsmall">
                        <TextBox>Business Name</TextBox>
                        <TextBox color="secondary">(Required)</TextBox>
                      </Inline>
                    }
                  />

                  {!showRequiredFieldsOnly && (
                    <Stack space="large">
                      <Columns space="small">
                        <Column width="1/2">
                          <FormikTextField
                            label="Type"
                            select={true}
                            fullWidth={true}
                            name="terminal.business_type"
                          >
                            {CUSTOMER_TYPES.map((value) => (
                              <MenuItem key={value} value={value}>
                                {formatCustomerType(value)}
                              </MenuItem>
                            ))}
                          </FormikTextField>
                        </Column>
                        <Column width="1/2">
                          <FormikTextField
                            label="Phone"
                            name="terminal.phone"
                            fullWidth={true}
                          />
                        </Column>
                      </Columns>

                      <Stack space="small">
                        <Typography variant="h4">Address</Typography>

                        <FormikTextField
                          label="Street Address"
                          name="terminal.address"
                          fullWidth={true}
                        />

                        <Columns space="small">
                          <Column width="1/2">
                            <FormikTextField
                              label="City"
                              name="terminal.city"
                              fullWidth={true}
                            />
                          </Column>

                          <Column width="1/4">
                            <FormikTextField
                              label="State"
                              name="terminal.state"
                              fullWidth={true}
                            />
                          </Column>

                          <Column width="1/4">
                            <FormikTextField
                              label="ZIP Code"
                              name="terminal.zip"
                              fullWidth={true}
                            />
                          </Column>
                        </Columns>
                      </Stack>

                      <Stack space="small">
                        <Typography variant="h4">Contact</Typography>

                        <FormikTextField
                          label="Contact Name"
                          name="contact.name"
                          fullWidth={true}
                        />

                        <FormikTextField
                          label="Title"
                          name="contact.title"
                          fullWidth={true}
                        />

                        <Columns space="small">
                          <Column width="1/2">
                            <FormikTextField
                              label="Phone"
                              name="contact.phone"
                              fullWidth={true}
                            />
                          </Column>

                          <Column width="1/2">
                            <FormikTextField
                              label="Mobile"
                              name="contact.mobile_phone"
                              fullWidth={true}
                            />
                          </Column>
                        </Columns>

                        <FormikTextField
                          label="Email"
                          name="contact.email"
                          fullWidth={true}
                          validate={email}
                        />
                      </Stack>
                    </Stack>
                  )}

                  <Field
                    component="input"
                    type="checkbox"
                    name="contact.is_primary"
                    hidden={true}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </FormikProvider>
    </SidebarContent>
  );
}
