import { useFormikEnhanced } from '@superdispatch/forms';
import { DrawerActions, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { DrawerForm } from 'shared/form/DrawerForm';
import { useInitialValues } from '../../shared/form/AppFormik';
import { camelCaseFormErrorsGetter } from '../../shared/helpers/FormHelpers';
import { InsuranceForm } from '../core/InsuranceForm';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import {
  useVerificationApplication,
  useVerificationApplicationAPI,
} from '../data/VerificationApplicationAPI';
import {
  VerificationInsuranceDTO,
  verificationInsuranceSchema,
} from '../data/VerificationApplicationDTO';

type VerificationInsuranceForm = Omit<
  VerificationInsuranceDTO,
  'insurance_certificate_url' | 'company_insurance_type'
> & {
  insurance_certificate_url: string | File | null;
  company_insurance_type:
    | VerificationInsuranceDTO['company_insurance_type']
    | null;
};

interface BondInsuranceEditDrawerProps {
  open: boolean;
  onClose: () => void;
  onUpdate?: () => void;
}

export function BondInsuranceEditDrawer({
  open,
  onClose,
  onUpdate,
}: BondInsuranceEditDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data } = useVerificationApplication({
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
  const { updateInsurance, uploadFile } = useVerificationApplicationAPI();

  const initialValues = useInitialValues(verificationInsuranceSchema, data);

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: verificationInsuranceSchema,
    getFormErrors: camelCaseFormErrorsGetter,
    async onSubmit(values: VerificationInsuranceForm) {
      if (values.insurance_certificate_url instanceof File) {
        const url = await uploadFile(values.insurance_certificate_url);
        values = { ...values, insurance_certificate_url: url };
      }

      return updateInsurance(values as VerificationInsuranceDTO);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedProfileValues('Bond Insurance', {
        bond_company: updatedValues.bond_company_name,
        bond_agent: updatedValues.bond_agent_name,
        bond_expiration_date: updatedValues.bond_expiration_date,
        agent_phone: updatedValues.bond_agent_phone,
      });

      addSnackbar('Profile updated successfully', { variant: 'success' });
      onClose();
      onUpdate?.();
    },
  });

  const { dirty, isSubmitting, isValid, status, handleSubmit } = formik;

  return (
    <DrawerForm
      title="Edit Insurance"
      isDirty={dirty}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      disableCloseOnNavigation={true}
      disableNavigationPrompt={status.type === 'submitted'}
      actions={
        <DrawerActions>
          <Button
            type="submit"
            variant="primary"
            disabled={!dirty || !isValid || isSubmitting}
          >
            Save
          </Button>
        </DrawerActions>
      }
    >
      <FormikProvider value={formik}>
        <Stack space="small">
          <InsuranceForm />
        </Stack>
      </FormikProvider>
    </DrawerForm>
  );
}
