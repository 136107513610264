import { PopupOnboardingAction } from './onboardingMap';
import {
  useHandleOnboardingAction,
  UseHandleOnboardingActionProps,
} from './useHandleOnboardingAction';

export function useHandleOnboardingPopupAction(
  props: UseHandleOnboardingActionProps,
) {
  const { handleAction, markCurrentStepAsDone, isAsyncActionLoading } =
    useHandleOnboardingAction(props);

  function handlePopupAction(action: PopupOnboardingAction) {
    if (action.type === 'CLOSE_POPUP') {
      if (action.shouldCompleteStep) {
        markCurrentStepAsDone();
      }

      props.onComplete?.();
    } else {
      handleAction(action);
    }
  }

  return {
    handlePopupAction,
    isAsyncActionLoading,
  };
}
