import { MEDIA_SERVICE_API_HOST } from 'shared/config/ServerConstants';
import { apiInstance } from '../api/API';
import { APIMutationResult, useAPIMutation } from '../api/APIMutation';

interface SignedMediaDTO {
  signed_url: string;
}

export function useSignMedia(): APIMutationResult<string, SignedMediaDTO> {
  return useAPIMutation((url: string) =>
    apiInstance.requestJSON('POST /v1/sign-url/', {
      baseURL: MEDIA_SERVICE_API_HOST,
      json: { url },
      headers: {
        'X-Auth-Scheme': 'shipper',
      },
    }),
  );
}
