import { Link, Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Snackbar, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Alert, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { trackUpdatedSuperLoadboardSettingsValues } from 'loadboard-settings/data/SuperLoadboardAnalytics';
import { useMemo } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import Order from 'shared/types/order';
import {
  useShipperProfile,
  useShipperProfileAPI,
} from 'shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from 'shipper-profile/data/ShipperProfileDTO';
import styled from 'styled-components';

const AlertSnackbar = styled(Snackbar)`
  padding: 0;
  background: none;
  max-width: 512px;

  & .SDSnackbar-message {
    padding: 0;
    display: block;
    & > span {
      padding: 0;
    }
  }

  @media print {
    display: none;
  }
`;

export function AutoRepostNotifications({
  order,
  onClose,
}: {
  order?: Order;
  onClose: () => void;
}) {
  const { data: profile } = useShipperProfile();
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');
  const isAutoRepostEnabled = useFeatureToggle(
    'auto-repost-recommendation.enabled.ui',
  );

  const showAutoRepostNotification = useMemo(() => {
    if (
      !isAutoRepostEnabled ||
      !canUpdateProfile ||
      !order ||
      !['canceled', 'declined'].includes(order.status)
    ) {
      return false;
    }

    if (order.status === 'canceled') {
      return (
        !profile?.is_auto_repost_for_cancel_offers_enabled &&
        !order.offer_cancel_comment &&
        !order.offer_cancel_reasons?.includes('UNIT_NOT_READY') &&
        !order.offer_cancel_reasons?.includes('UNKNOWN_INOPERABLE_VEHICLE')
      );
    }

    return !profile?.is_auto_repost_for_decline_offers_enabled;
  }, [order, profile, canUpdateProfile, isAutoRepostEnabled]);

  if (!showAutoRepostNotification) {
    return null;
  }

  return (
    <AlertSnackbar
      open={true}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      ContentProps={{
        style: {
          padding: 0,
          background: 'none',
        },
      }}
    >
      {order?.status === 'canceled' ? (
        <NotificationContentForCanceledOrder onClose={onClose} />
      ) : (
        <NotificationContentForDeclinedOrder onClose={onClose} />
      )}
    </AlertSnackbar>
  );
}

function NotificationContentForCanceledOrder({
  onClose,
}: {
  onClose: () => void;
}) {
  const { data: profile, refetch } = useShipperProfile();
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();

  const formik = useFormikEnhanced({
    initialValues: { ...profile },
    onSubmit: (values) => {
      return updateLoadboardSettings({
        ...values,
        is_auto_repost_for_cancel_offers_enabled: true,
      } as ShipperProfileDTO);
    },
    onSubmitSuccess: () => {
      addSnackbar('Auto-repost of Canceled Orders turned on.');
      trackUpdatedSuperLoadboardSettingsValues({
        name: 'Auto Repost Cancelled Automatically',
        is_auto_repost_for_cancel_offers_enabled: true,
        utm_medium: 'Alert Banner to Enabled Auto-Repost',
      });

      void refetch();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Alert severity="info" onClose={onClose}>
          <Stack align="left" space="small">
            <Typography>
              Auto-Repost canceled orders–get your load rebooked faster by
              another carrier.
            </Typography>
            <Inline space="small" verticalAlign="center">
              <Button
                variant="neutral"
                size="small"
                type="submit"
                pending={formik.isSubmitting}
              >
                Auto-Repost Canceled Orders
              </Button>
              <Link
                target="_blank"
                rel="noreferrer"
                underline="none"
                color="primary"
                href="https://support.superdispatch.com/en/articles/10058400-how-to-set-up-shipper-tms-automatic-load-reposting"
              >
                Learn more
              </Link>
            </Inline>
          </Stack>
        </Alert>
      </Form>
    </FormikProvider>
  );
}

function NotificationContentForDeclinedOrder({
  onClose,
}: {
  onClose: () => void;
}) {
  const { data: profile, refetch } = useShipperProfile();
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();

  const formik = useFormikEnhanced({
    initialValues: { ...profile },
    onSubmit: (values) => {
      return updateLoadboardSettings({
        ...values,
        is_auto_repost_for_decline_offers_enabled: true,
      } as ShipperProfileDTO);
    },
    onSubmitSuccess: () => {
      addSnackbar('Auto-repost of Declined Orders turned on.');
      trackUpdatedSuperLoadboardSettingsValues({
        name: 'Auto Repost Declined Automatically',
        is_auto_repost_for_declined_offers_enabled: true,
        utm_medium: 'Alert Banner to Enabled Auto-Repost',
      });

      void refetch();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Alert severity="info" onClose={onClose}>
          <Stack align="left" space="small">
            <Typography>
              Auto-Repost declined orders–get your load rebooked faster by
              another carrier.
            </Typography>
            <Inline space="small" verticalAlign="center">
              <Button
                variant="neutral"
                size="small"
                type="submit"
                pending={formik.isSubmitting}
              >
                Auto-Repost Declined Orders
              </Button>
              <Link
                target="_blank"
                rel="noreferrer"
                underline="none"
                color="primary"
                href="https://support.superdispatch.com/en/articles/10058400-how-to-set-up-shipper-tms-automatic-load-reposting"
              >
                Learn more
              </Link>
            </Inline>
          </Stack>
        </Alert>
      </Form>
    </FormikProvider>
  );
}
