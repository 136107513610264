export const SHIPPER_API_HOST =
  import.meta.env.VITE_TARGET === 'production'
    ? 'https://api.shipper.superdispatch.com'
    : 'https://api.staging.shipper.superdispatch.org';

export const CARRIER_APP_HOST =
  import.meta.env.VITE_TARGET === 'production'
    ? 'https://carrier.superdispatch.com'
    : 'https://staging.carrier.superdispatch.org';

export const MEDIA_SERVICE_API_HOST =
  import.meta.env.VITE_TARGET === 'production'
    ? 'https://media.superdispatch.com'
    : 'https://staging.media.superdispatch.org';
