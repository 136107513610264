import { ratingStatsSchema } from 'core/ratings/data/CarrierRatingDTO';
import {
  carrierBrokerPreferencesSchema,
  CARRIER_LOGISTICS_TYPES,
} from 'shared/types/carrier';
import {
  yupArray,
  yupBoolean,
  yupEnum,
  yupNumber,
  yupNumberDefaultValue,
  yupObject,
  yupString,
  yupStringDefaultValue,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type OrderRequestDTO = InferType<typeof orderRequestSchema>;
const ORDER_REQUEST_STATUS = [
  'REQUEST_PENDING',
  'REQUEST_DECLINED_BY_BROKER',
  'REQUEST_CANCELED',
  'OFFER_SENT',
  'COUNTER_OFFER_SENT',
  'OFFER_ACCEPTED',
  'OFFER_CANCELED',
  'OFFER_DECLINED',
  'UNPOSTED',
] as const;

type orderRequestStatus = typeof ORDER_REQUEST_STATUS[number];

export type OrderRequestStatus =
  | orderRequestStatus
  | 'OFFER_CANCELED_BY_CARRIER'
  | 'BID_COUNTER_OFFER';

export const orderRequestSchema = yupObject({
  broker_records: carrierBrokerPreferencesSchema.optional().nullable(),
  id: yupString().nullable(),
  created_at: yupStringDefaultValue(),
  changed_at: yupStringDefaultValue(),
  pickup_date: yupString().nullable(),
  delivery_date: yupString().nullable(),
  is_insurance_expired: yupBoolean(),
  is_super_carrier: yupBoolean(),
  is_book_request: yupBoolean(),
  is_super_pay_enabled: yupBoolean(),
  status: yupStringDefaultValue().oneOf(ORDER_REQUEST_STATUS),
  unposted_date: yupString().nullable(),
  request_declined_date: yupString().nullable(),
  request_canceled_date: yupString().nullable(),
  offer_sent_date: yupString().nullable(),
  offer_accepted_date: yupString().nullable(),
  offer_declined_date: yupString().nullable(),
  offer_canceled_date: yupString().nullable(),
  offer_cancel_reasons: yupArray(yupString()).nullable(),
  offer_decline_reasons: yupString().nullable().optional(),
  offer_decline_comment: yupString().nullable(),
  offer_cancel_comment: yupString().nullable(),
  broker_decline_reason: yupString().nullable(),
  counter_offer_price: yupNumberDefaultValue(),
  parent_request_counter_offer_price: yupNumberDefaultValue(),
  carrier_guid: yupStringDefaultValue(),
  carrier_email: yupStringDefaultValue(),
  carrier_phone: yupStringDefaultValue(),
  carrier_name: yupStringDefaultValue(),
  carrier_contact_name: yupStringDefaultValue(),
  carrier_address: yupString().nullable(),
  carrier_city: yupString().nullable(),
  carrier_state: yupString().nullable(),
  carrier_zip: yupString().nullable(),
  carrier_usdot: yupString().nullable(),
  cargo_insurance: yupString().nullable(),
  cargo_limit: yupNumber().nullable(),
  cargo_deductible: yupNumber().nullable(),
  largest_trailer_capacity: yupNumber().nullable(),
  insurance_requirements_met: yupBoolean(),
  logistics_type: yupEnum(CARRIER_LOGISTICS_TYPES, null).nullable(),
  load_guid: yupString().nullable(),
  guid: yupStringDefaultValue(),
  price: yupNumberDefaultValue(),
  carrier_rating_stats: ratingStatsSchema.optional().nullable(),
  has_limited_experience: yupBoolean(),
});
