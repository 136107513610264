import { Tab, Tabs, Typography } from '@material-ui/core';
import { PaymentMethod } from '@superdispatch/sdk';
import {
  ColorDynamic,
  Inline,
  Stack,
  Tag,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { usePartnerCarrier } from 'shared/api/LinkedBrokersAPI';
import {
  usePreviouslyDispatchedCarriers,
  useSuggestedCarriers,
} from 'shared/api/LoadMatchingAPI';
import { useProductTiers } from 'shared/data/TiersUtils';
import { launchIntercomTour } from 'shared/helpers/customer-support/intercom/Intercom';
import { Carrier } from 'shared/types/carrier';
import Order from 'shared/types/order';
import { mileToKm } from 'shared/utils/MeasurementUtils';
import { SendOfferFormValues } from '../SingleSendOfferDrawer';
import {
  CarrierNetworkTab,
  SuggestedCarrierNetworkTab,
} from './CarrierNetworkTab';
import { RecencyAndRadiusSelectors } from './RadiusAndRecencySelectors';
import { useCarrierNetworkTab } from './UseCarrierNetworkTab';

export interface CarrierNetworkTabsProps {
  order: Order;
  carrier: Carrier | null;
  onSuggestedSelect: (
    carrier: Carrier,
    isPreviouslyDispatched: boolean,
  ) => void;
  onPreviouslyDispatchedSelect: (carrier: Carrier) => void;
  onPartnerCarriersSelect: (carrier: Carrier | null) => void;
  paymentMethod?: PaymentMethod | null;
}
export function CarrierNetworkTabs({
  order,
  carrier,
  onPreviouslyDispatchedSelect,
  onSuggestedSelect,
  onPartnerCarriersSelect,
  paymentMethod,
}: CarrierNetworkTabsProps) {
  const { isAdvancedTier } = useProductTiers();
  const { data: partnerCarriers, isLoading } = usePartnerCarrier();
  const { tabValue, changeTab } = useCarrierNetworkTab({
    hasPartnerCarriers: partnerCarriers && partnerCarriers.objects.length > 0,
  });
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isDisablePartnerTab = paymentMethod === 'superpay';
  const isMobile = platform === 'mobile';

  useEffect(() => {
    if (isDisablePartnerTab && carrier?.carrier_type === 'BROKER') {
      onPartnerCarriersSelect(null);
    }
  }, [carrier?.carrier_type, isDisablePartnerTab, onPartnerCarriersSelect]);

  useEffect(() => {
    launchIntercomTour('load_matching_intercom_tour_view_count');
  }, []);

  const {
    values: { search_radius, search_recency },
  } = useFormikContext<SendOfferFormValues>();

  const {
    data: { objects: previouslyDispatchedCarriers = [] } = {},
    isLoading: isPreviouslyDispatchedCarriersLoading,
  } = usePreviouslyDispatchedCarriers(order.guid, {
    search_radius_km: mileToKm(search_radius),
    days: search_recency,
  });

  const {
    data: { objects: suggestedCarriers = [] } = {},
    isLoading: isSuggestedCarriersLoading,
  } = useSuggestedCarriers(order.guid, {
    radius: Math.round(mileToKm(search_radius)),
    number_of_days: search_recency,
  });

  if (!isAdvancedTier) {
    return (
      <div>
        <Box
          paddingTop="small"
          paddingBottom="xsmall"
          paddingLeft={isMobile ? 'small' : 'large'}
          paddingRight={isMobile ? 'small' : 'large'}
          data-intercom-target="Suggested Tab Radius and Recency Selectors"
        >
          <Stack space="xxsmall">
            <Inline space="xxsmall" verticalAlign="center">
              <Typography variant="h4">Suggested</Typography>
              {suggestedCarriers.length > 0 && (
                <Tag color="blue" variant="subtle">
                  {suggestedCarriers.length}
                </Tag>
              )}
            </Inline>

            <Typography color="textSecondary">
              Carriers who frequently move loads within this lane.
            </Typography>
          </Stack>
        </Box>

        <SuggestedCarrierNetworkTab
          tabName="suggested"
          selectedCarrierGuid={carrier?.guid}
          order={order}
          carriers={suggestedCarriers}
          previouslyDispatchedCarriers={[]}
          isLoading={isSuggestedCarriersLoading}
          onSelect={(selectedCarrier) =>
            onSuggestedSelect(selectedCarrier, false)
          }
        />
      </div>
    );
  }

  return (
    <div>
      <Tabs
        style={{ margin: isMobile ? '0 16px' : '0 32px' }}
        value={tabValue}
        onChange={(_, next) => changeTab(next)}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          data-intercom-target="Suggested Tab"
          value="suggested"
          label={
            <Inline space="xxsmall">
              <Typography variant="body1">Suggested</Typography>
              {suggestedCarriers.length > 0 && (
                <Tag
                  color={tabValue === 'suggested' ? 'blue' : 'grey'}
                  variant="subtle"
                >
                  {suggestedCarriers.length}
                </Tag>
              )}
            </Inline>
          }
        />

        <Tab
          value="previously_dispatched"
          label={
            <Inline space="xxsmall" verticalAlign="center">
              <Typography variant="body1">Previously Dispatched</Typography>
              {previouslyDispatchedCarriers.length > 0 && (
                <Tag
                  color={tabValue === 'previously_dispatched' ? 'blue' : 'grey'}
                  variant="subtle"
                >
                  {previouslyDispatchedCarriers.length}
                </Tag>
              )}
            </Inline>
          }
        />

        {!!partnerCarriers?.objects.length && (
          <Tab
            value="partner"
            label={
              <Inline space="xxsmall">
                <Typography variant="body1">Broker Partners</Typography>
                <Tag
                  color={tabValue === 'partner' ? 'grey' : 'blue'}
                  variant="subtle"
                >
                  {partnerCarriers.objects.length}
                </Tag>
              </Inline>
            }
          />
        )}
      </Tabs>

      {!!partnerCarriers?.objects.length && (
        <TabPanel value={tabValue} tabKey="partner">
          {isDisablePartnerTab ? (
            <Box
              color="Red500"
              paddingLeft={['medium', 'large']}
              paddingRight={['medium', 'large']}
              paddingTop="small"
              paddingBottom="xxsmall"
            >
              Orders with SuperPay method cannot be sent to Brokers.
            </Box>
          ) : (
            <CarrierNetworkTab
              tabName="partner"
              carriers={partnerCarriers.objects}
              isLoading={isLoading}
              selectedCarrierGuid={carrier?.guid}
              onSelect={(selectedCarrier) =>
                onPartnerCarriersSelect(selectedCarrier)
              }
            />
          )}
        </TabPanel>
      )}

      <TabPanel value={tabValue} tabKey="previously_dispatched">
        <Box
          paddingTop="small"
          paddingBottom="xsmall"
          paddingLeft={isMobile ? 'small' : 'large'}
          paddingRight={isMobile ? 'small' : 'large'}
        >
          <Stack space="small">
            <Typography style={{ color: ColorDynamic.Dark300 }}>
              Carriers you have previously used to move loads within this lane.
            </Typography>
            <RecencyAndRadiusSelectors />
          </Stack>
        </Box>

        <CarrierNetworkTab
          tabName="previously_dispatched"
          carriers={previouslyDispatchedCarriers}
          isLoading={isPreviouslyDispatchedCarriersLoading}
          selectedCarrierGuid={carrier?.guid}
          onSelect={(selectedCarrier) =>
            onPreviouslyDispatchedSelect(selectedCarrier)
          }
        />
      </TabPanel>

      <TabPanel value={tabValue} tabKey="suggested">
        <Box
          paddingTop="small"
          paddingBottom="xsmall"
          paddingLeft={isMobile ? 'small' : 'large'}
          paddingRight={isMobile ? 'small' : 'large'}
          data-intercom-target="Suggested Tab Radius and Recency Selectors"
        >
          <Stack space="small">
            <Typography style={{ color: ColorDynamic.Dark300 }}>
              Carriers who frequently move loads within this lane.
            </Typography>
          </Stack>
        </Box>

        <SuggestedCarrierNetworkTab
          tabName="suggested"
          selectedCarrierGuid={carrier?.guid}
          order={order}
          carriers={suggestedCarriers}
          previouslyDispatchedCarriers={previouslyDispatchedCarriers}
          isLoading={isSuggestedCarriersLoading}
          onSelect={(selectedCarrier) =>
            onSuggestedSelect(
              selectedCarrier,
              previouslyDispatchedCarriers.some(
                (c) => c.guid === selectedCarrier.guid,
              ),
            )
          }
        />
      </TabPanel>
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  tabKey: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, tabKey, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== tabKey}
      id={`tabpanel-${tabKey}`}
      aria-labelledby={`tab-${tabKey}`}
      {...other}
    >
      {value === tabKey && children}
    </div>
  );
}
