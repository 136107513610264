import { useQueryClient } from 'react-query';
import { apiInstance } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { yupObject, yupString } from 'shared/utils/YupUtils';

const ONBOARDING_NEGATIVE_FLOW_STEP_KEYS = [
  'USER_DID_NOT_CREATE_OR_POST_ORDER_IN_7_DAYS',
  'USER_DID_NOT_ACCEPT_OR_DECLIDE_FIRST_LOAD_REQUEST',
  'CARRIER_ACCEPTED_OFFER_BUT_PICKUP_DATE_PASSED',
  'LOAD_WAS_PICKED_UP_BUT_DELIVERY_DATE_PASSED',
  'LOAD_WAS_INVOICED_BUT_IS_NOT_MOVED_TO_PAID',
] as const;

export function isNegativeFlowOnboardingStep(stepKey: OnboardingStepKey) {
  return ONBOARDING_NEGATIVE_FLOW_STEP_KEYS.includes(
    stepKey as typeof ONBOARDING_NEGATIVE_FLOW_STEP_KEYS[number],
  );
}
const SUPERPAY_NUDGING_STEP_KEYS = [
  'NUDGE_SUPERPAY_1ST_ATTEMPT',
  'NUDGE_SUPERPAY_2ND_ATTEMPT',
  'NUDGE_SUPERPAY_3RD_ATTEMPT',
] as const;

export function isSuperPayNudgingStep(stepKey: OnboardingStepKey) {
  return SUPERPAY_NUDGING_STEP_KEYS.includes(
    stepKey as typeof SUPERPAY_NUDGING_STEP_KEYS[number],
  );
}

const ONBOARDING_STEP_KEYS = [
  'USER_ONBOARDING_INITIATED',
  'USER_CREATED_FIRST_ORDER',
  'USER_POSTED_FIRST_ORDER',
  'USER_SENT_FIRST_DIRECT_OFFER',
  'USER_RECEIVED_FIRST_LOAD_REQUEST',
  'USER_RESPONDED_TO_FIRST_LOAD_REQUEST',
  'CARRIER_ACCEPTED_USERS_FIRST_LOAD_OFFER',
  'CARRIER_PICKED_UP_USERS_FIRST_LOAD',
  'CARRIER_DELIVERED_USERS_FIRST_LOAD',
  'CARRIER_INVOICED_USERS_FIRST_LOAD',
  'SHIPPER_FIRST_SUPERPAY_PAYMENT_COMPLETED',
  ...ONBOARDING_NEGATIVE_FLOW_STEP_KEYS,
  ...SUPERPAY_NUDGING_STEP_KEYS,
] as const;

export type OnboardingStepKey = typeof ONBOARDING_STEP_KEYS[number];

const onboardingStepSchema = yupObject({
  notify_for: yupObject({
    step: yupString().oneOf(ONBOARDING_STEP_KEYS).required(),
    order_guid: yupString().nullable(),
    online_bol_url: yupString().nullable(),
  }).nullable(),
});

const ONE_MINUTE_IN_MS = 60 * 1000;

const ONBOARDING_STEP_QUERY_KEY = ['onboarding', 'step'] as const;

export function useCurrentOnboardingStep() {
  const { data } = useAPIQuery(
    ONBOARDING_STEP_QUERY_KEY,
    () => getOnboardingStep(),
    {
      refetchInterval: ONE_MINUTE_IN_MS,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
    },
  );

  return {
    step: data?.notify_for,
    stepKey: data?.notify_for?.step,
    stepOrderGuid: data?.notify_for?.order_guid ?? undefined,
    stepOnlineBolUrl: data?.notify_for?.online_bol_url ?? undefined,
  };
}

export function useMarkOnboardingStepsAsDone() {
  const queryClient = useQueryClient();

  return {
    markOnboardingStepsAsDone: async (stepKeys: OnboardingStepKey[]) => {
      await Promise.all(stepKeys.map(markStepAsDone));

      invalidateOnboardingSteps();
    },
  };

  function invalidateOnboardingSteps() {
    void queryClient.invalidateQueries({
      queryKey: ONBOARDING_STEP_QUERY_KEY,
    });
  }
}

export function useInvalidateCurrentOnboardingStep() {
  const queryClient = useQueryClient();

  return () => {
    void queryClient.invalidateQueries({
      queryKey: ONBOARDING_STEP_QUERY_KEY,
    });
  };
}

function getOnboardingStep() {
  return apiInstance.requestResource(
    'GET /internal/users/onboarding/steps',
    (data) => onboardingStepSchema.cast(data),
  );
}

export function markStepAsDone(key: OnboardingStepKey) {
  return apiInstance.request(
    'POST /internal/users/onboarding/steps/{step}/done',
    {
      step: key,
    },
  );
}
