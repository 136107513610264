import { Box, Typography } from '@material-ui/core';
import { Color, ColorDynamic, Column, Columns } from '@superdispatch/ui';
import styled from 'styled-components';

const GutterPoint = styled.div`
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  &[data-start='true'] {
    background: ${Color.Yellow300};
  }
  &[data-end='true'] {
    background: ${Color.Green300};
  }
`;

const GutterLine = styled.div`
  width: 23px;
  height: 1px;
  border-bottom: 1px dashed ${Color.Dark100};
  );
`;

const Separator = styled.div`
  color: ${ColorDynamic.Dark300};
`;

interface Waypoint {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
}

export interface InlineWaypointsProps {
  from: Waypoint;
  to: Waypoint;
  separator?: string | JSX.Element;
}

export function InlineWaypoints({ from, to, separator }: InlineWaypointsProps) {
  return (
    <Columns align="center" space="xsmall">
      <Column width="content">
        <Columns align="center" space="xxsmall">
          <Column width="content">
            <GutterPoint data-start="true" />
          </Column>

          <Column>
            <Box display="flex" alignItems="center">
              <Typography
                noWrap={true}
                color="textPrimary"
                component="div"
                aria-label="waypoint start title"
              >
                {from.title}
              </Typography>

              {separator && (
                <Separator>
                  <Typography variant="body2">{separator}</Typography>
                </Separator>
              )}

              <Typography
                noWrap={true}
                variant="body2"
                aria-label="waypoint start subtitle"
                component="div"
              >
                {from.subtitle}
              </Typography>
            </Box>
          </Column>
        </Columns>
      </Column>

      <Column width="content">
        <GutterLine />
      </Column>

      <Column width="content">
        <Columns align="center" space="xxsmall">
          <Column width="content">
            <GutterPoint data-end="true" />
          </Column>

          <Column>
            <Box display="flex">
              <Typography
                noWrap={true}
                component="div"
                color="textPrimary"
                aria-label="waypoint end title"
              >
                {to.title}
              </Typography>

              {separator && (
                <Separator>
                  <Typography variant="body2">{separator}</Typography>
                </Separator>
              )}

              <Typography
                noWrap={true}
                variant="body2"
                aria-label="waypoint end subtitle"
                component="div"
              >
                {to.subtitle}
              </Typography>
            </Box>
          </Column>
        </Columns>
      </Column>
    </Columns>
  );
}
