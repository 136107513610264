import { Typography } from '@material-ui/core';
import {
  DrawerActions,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import {
  PriceNegotiationDTO,
  PriceNegotiationFormDTO,
  priceNegotiationFormSchema,
} from 'orders/data/dto/PriceNegotiationDTO';
import { useOrderCache } from 'orders/data/OrderAPI';
import {
  usePriceNegotiationAPI,
  usePriceNegotiationDisableMutation,
} from 'orders/data/PriceNegotiationAPI';
import { useAppFormik } from 'shared/form/AppFormik';
import { Order } from 'shared/types/order';
import {
  OrderActionSource,
  trackOderActionEvent,
} from '../OrderActionsAnalytics';
import { PriceNegotiationDrawerForm } from './PriceNegotiationDrawerForm';
import { PriceNegotiationDrawerSummary } from './PriceNegotiationDrawerSummary';

interface UpdatePriceNegotiationDrawerFormProps {
  order: Order;
  source: OrderActionSource;
  priceNegotiation: PriceNegotiationDTO;
  onSubmitSuccess: (response: Order) => void;
}

export function UpdatePriceNegotiationDrawerForm({
  order,
  source,
  priceNegotiation,
  onSubmitSuccess,
}: UpdatePriceNegotiationDrawerFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const { enablePriceNegotiation, updatePriceNegotiation } =
    usePriceNegotiationAPI();

  const { invalidateOrder } = useOrderCache();

  const updateForm = useAppFormik<PriceNegotiationFormDTO, PriceNegotiationDTO>(
    {
      initialValues: priceNegotiation,
      validationSchema: priceNegotiationFormSchema,
      async onSubmit(values) {
        const response = await updatePriceNegotiation(
          priceNegotiation.guid,
          values,
        );
        if (!priceNegotiation.active) {
          await enablePriceNegotiation(priceNegotiation.guid);
        }
        return response;
      },
      getSuccessMessage: () => 'Price Negotiation is updated.',
      onSubmitSuccess(response) {
        trackOderActionEvent({
          name: 'Shipper Updated Price Negotiation',
          order,
          source,
          priceNegotiation: response,
        });
        onSubmitSuccess(order);
      },
    },
  );

  const {
    isLoading: isPriceNegotiationDisabling,
    mutate: disablePriceNegotiation,
  } = usePriceNegotiationDisableMutation({
    onSuccess() {
      trackOderActionEvent({
        name: 'Shipper Disabled Price Negotiation',
        order,
        source,
        priceNegotiation,
      });
      void invalidateOrder(order.guid);
      addSnackbar('Price Negotiation is disabled.', { variant: 'success' });
      onSubmitSuccess(order);
    },
    onError({ message }) {
      addSnackbar(message, { variant: 'error' });
    },
  });

  return (
    <PriceNegotiationDrawerForm
      order={order}
      form={updateForm}
      actions={
        <DrawerActions>
          <Box paddingTop="small" paddingBottom="small">
            <Stack space="large">
              <PriceNegotiationDrawerSummary
                order={order}
                currentValues={updateForm.values}
              />

              <Inline space="small" verticalAlign="center">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isPriceNegotiationDisabling}
                  pending={updateForm.isSubmitting}
                >
                  {priceNegotiation.active ? 'Update' : 'Enable Negotiation'}
                </Button>

                {priceNegotiation.active && (
                  <Button
                    disabled={updateForm.isSubmitting}
                    pending={isPriceNegotiationDisabling}
                    onClick={() => {
                      disablePriceNegotiation(priceNegotiation.guid);
                    }}
                    variant="neutral"
                  >
                    Disable
                  </Button>
                )}

                {priceNegotiation.active && (
                  <Typography color="textSecondary" variant="caption">
                    Disabling cancels counter <br /> offers.
                  </Typography>
                )}
              </Inline>
            </Stack>
          </Box>
        </DrawerActions>
      }
    />
  );
}
