import { Box, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { ColorDynamic } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { EmptyState } from 'shared/ui/EmptyState';
import { useTerminalsNavigation } from 'terminals/TerminalsRouteHelpers';
import { TerminalsDropZone } from './TerminalsDropZone';

export function TerminalEmptyState() {
  const { navigateToTerminalsCreate } = useTerminalsNavigation();

  return (
    <EmptyState title="No terminals yet">
      <Box mt={3}>
        <Button
          variant="text"
          size="large"
          startIcon={<Add />}
          onClick={navigateToTerminalsCreate}
        >
          Create New
        </Button>
      </Box>

      <Box mt={4} display="flex" alignItems="center">
        <Box width="60px" height="1px" bgcolor={ColorDynamic.Silver400} />

        <Box mx={2}>
          <Typography color="textSecondary">or import terminals</Typography>
        </Box>

        <Box width="60px" height="1px" bgcolor={ColorDynamic.Silver400} />
      </Box>

      <TerminalsDropZone />
    </EmptyState>
  );
}
