import { Collapse } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Button } from '@superdispatch/ui-lab';
import * as React from 'react';
import { useState } from 'react';

const useCollapse = (initiallyExpanded?: boolean) => {
  const [expanded, setExpanded] = useState(!!initiallyExpanded);
  const toggleExpand = () => setExpanded(!expanded);

  return { expanded, toggleExpand };
};

interface CollapseButtonProps {
  expanded: boolean;
  onClick: () => void;
}

const CollapseButton: React.ComponentType<CollapseButtonProps> = ({
  expanded,
  onClick,
}) => (
  <Button
    variant="text"
    onClick={onClick}
    startIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
  >
    {expanded ? 'Show less' : 'Show more'}
  </Button>
);

export { Collapse, CollapseButton, useCollapse };
