import { Box, Fade, IconButton, Link, Typography } from '@material-ui/core';
import { Edit, Person, Star, StarBorder } from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { TerminalContactDTO } from 'shared/dto/TerminalContactDTO';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { TerminalContactDeleteConfirmation } from './TerminalContactDeleteConfirmation';
import { TerminalContactMarkAsPrimary } from './TerminalContactMarkAsPrimary';

interface Props {
  terminal: TerminalDTO;
  contact: TerminalContactDTO;
  onEdit: () => void;
}

export function TerminalContactItem({ onEdit, contact, terminal }: Props) {
  const [isHovered, setHovered] = useState(false);
  const {
    name,
    title,
    phone,
    mobile_phone,
    email,
    is_primary,
    phone_extension,
  } = contact;

  return (
    <div
      aria-label="contact item"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Columns space="xxsmall">
        <Column width="content">
          {is_primary ? (
            <IconButton size="small" disabled={true}>
              <Star
                fontSize="small"
                htmlColor={ColorDynamic.Yellow500}
                aria-label="primary contact"
              />
            </IconButton>
          ) : (
            <TerminalContactMarkAsPrimary
              terminal={terminal}
              contact={contact}
              icon={
                isHovered ? (
                  <StarBorder fontSize="small" color="action" />
                ) : (
                  <Person fontSize="small" color="action" />
                )
              }
            />
          )}
        </Column>

        <Column>
          <Stack space="xxsmall">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1">{name}</Typography>

              <Fade in={isHovered}>
                <div>
                  <Inline>
                    <IconButton
                      size="small"
                      onClick={onEdit}
                      aria-label="edit contact"
                    >
                      <Edit fontSize="small" />
                    </IconButton>

                    <TerminalContactDeleteConfirmation
                      terminalId={terminal.id}
                      contactId={contact.id}
                    />
                  </Inline>
                </div>
              </Fade>
            </Box>

            {title && <TextBox color="secondary">{title}</TextBox>}

            {phone && (
              <Inline>
                <SuspendedPhoneLink
                  phone={phone}
                  fallback={<Link href={`tel:${phone}`}>{phone}</Link>}
                />
                <TextBox color="secondary">‧</TextBox>
                <TextBox color="secondary">phone</TextBox>
              </Inline>
            )}

            {phone_extension && (
              <Box mt="4px">
                <Inline>
                  <Typography>{phone_extension}</Typography>
                  <Typography color="textSecondary">‧</Typography>
                  <Typography color="textSecondary">extension</Typography>
                </Inline>
              </Box>
            )}

            {mobile_phone && (
              <Inline>
                <SuspendedPhoneLink
                  phone={mobile_phone}
                  fallback={
                    <Link href={`tel:${mobile_phone}`}>{mobile_phone}</Link>
                  }
                />
                <TextBox color="secondary">‧</TextBox>
                <TextBox color="secondary">mobile</TextBox>
              </Inline>
            )}

            {email && <Link href={`mailto:${email}`}>{email}</Link>}
          </Stack>
        </Column>
      </Columns>
    </div>
  );
}
