import { Duration } from 'luxon';
import { useEffect, useState } from 'react';
import { useCallbackRef } from 'shared/hooks/useCallbackRef';

export function Timer({
  duration = 60,
  onComplete,
}: {
  duration?: number;
  onComplete?: () => void;
}) {
  const [timeLeft, setTimeLeft] = useState(duration);
  const onCompleteCallback = useCallbackRef(onComplete);

  useEffect(() => {
    setTimeLeft(duration);
  }, [duration]);

  useEffect(() => {
    if (timeLeft <= 0) {
      onCompleteCallback();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onCompleteCallback]);

  return <>{Duration.fromObject({ seconds: timeLeft }).toFormat('mm:ss')}</>;
}
