import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const AlertIcon = createSvgIcon(
  'AlertIcon',
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M0.815193 42.3603C-0.633968 44.8617 1.17517 48 4.06427 48H50.9359C53.8342 48 55.6341 44.8617 54.1849 42.3603L30.7584 1.87606C29.3092 -0.625355 25.7001 -0.625355 24.251 1.87606L0.815193 42.3603ZM30.223 38.0497C30.223 39.5543 29.0046 40.7727 27.5001 40.7727C25.9955 40.7727 24.7771 39.5543 24.7771 38.0497C24.7771 36.5452 25.9955 35.3268 27.5001 35.3268C29.0046 35.3268 30.223 36.5452 30.223 38.0497ZM30.223 29.0317C30.223 30.5362 29.0046 31.7546 27.5001 31.7546C25.9955 31.7546 24.7771 30.5362 24.7771 29.0317V17.9461C24.7771 16.4415 25.9955 15.2231 27.5001 15.2231C29.0046 15.2231 30.223 16.4415 30.223 17.9461V29.0317Z"
    fill={ColorDynamic.Yellow500}
  />,
  { viewBox: '0 0 55 48' },
);
