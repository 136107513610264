import { Lock } from '@material-ui/icons';
import { Inline } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useVerificationApplication } from './VerificationApplicationAPI';

interface ActionRestrictedProps {
  children: ReactNode;
  availableForInternationalShippers?: boolean;
}

export function ActionRestrictedForUnverifiedShipper({
  children,
  availableForInternationalShippers,
}: ActionRestrictedProps) {
  const isVerificationOptimizationEnabled = useFeatureToggle(
    'verification-optimization.enabled.ui',
  );
  const { data: verificationInfo } = useVerificationApplication();

  const isAvailable =
    verificationInfo?.status === 'VERIFIED' ||
    (availableForInternationalShippers &&
      verificationInfo?.status === 'INTERNATIONAL_SHIPPER');

  return (
    <Inline space="xsmall" verticalAlign="center">
      {isVerificationOptimizationEnabled && !isAvailable && (
        <Lock color="action" fontSize="small" />
      )}
      {children}
    </Inline>
  );
}
