import {
  yupArray,
  yupBoolean,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type AdditionalEmailPreferencesDTO = InferType<
  typeof additionalEmailPreferencesScheme
>;

export const additionalEmailPreferencesScheme = yupObject({
  email: yupString().nullable(),
  is_load_offers_enabled: yupBoolean().required(),
  is_load_requests_enabled: yupBoolean().required(),
  is_status_and_modifications_enabled: yupBoolean().required(),
  is_billing_enabled: yupBoolean().required(),
  is_unsubscribed: yupBoolean().required(),
});

export type NotificationSettingsDTO = InferType<
  typeof notificationSettingsScheme
>;

export const notificationSettingsScheme = yupObject({
  personal_email: yupString().nullable(),
  is_personal_email_notifications_enabled: yupBoolean().required(),
  is_personal_email_unsubscribed: yupBoolean().required(),
  is_personal_push_notifications_enabled: yupBoolean().required(),
  company_email: yupString().nullable(),
  is_company_email_notifications_enabled: yupBoolean().required(),
  is_company_email_unsubscribed: yupBoolean().required(),
  additional_email_preferences: yupArray(additionalEmailPreferencesScheme)
    .default([])
    .required(),
  sms_notification_phone_number: yupString().nullable(),
});
