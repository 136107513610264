import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { apiInstance } from 'shared/api/API';
import { useAPIQuery, UseAPIQueryOptions } from 'shared/api/APIQuery';
import {
  phoneVerificationSchema,
  PhoneVerificationSchemaDTO,
  VerificationApplicationDTO,
  verificationApplicationSchema,
  VerificationCompanyInformationDTO,
  verificationCompanyInformationSchema,
  VerificationIdentificationDTO,
  verificationIdentificationSchema,
  VerificationInsuranceDTO,
  verificationInsuranceSchema,
} from './VerificationApplicationDTO';

export function useVerificationApplication(
  options?: UseAPIQueryOptions<VerificationApplicationDTO>,
) {
  return useAPIQuery(
    ['shippers', 'verification_info'],
    () =>
      apiInstance.requestResource(
        'GET /internal/shippers/verification_info',
        (data) => data as VerificationApplicationDTO,
      ),
    { ...options, schema: verificationApplicationSchema },
  );
}

export function usePhoneVerification(
  options?: UseAPIQueryOptions<PhoneVerificationSchemaDTO>,
) {
  return useAPIQuery(
    ['shippers', 'verification_phone_info'],
    () =>
      apiInstance.requestResource(
        'GET /internal/shippers/verification_info/phone',
        (data) => data as PhoneVerificationSchemaDTO,
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      schema: phoneVerificationSchema,
      ...options,
    },
  );
}

export function useVerificationApplicationAPI() {
  const queryClient = useQueryClient();

  function invalidateVerificationApplication() {
    void queryClient.invalidateQueries(['shippers', 'verification_info']);
    void queryClient.invalidateQueries(['shippers', 'profile']);
  }

  return useMemo(
    () => ({
      updateCompanyInformation: (data: VerificationCompanyInformationDTO) =>
        apiInstance
          .request('PATCH /internal/shippers/verification_info', {
            json: verificationCompanyInformationSchema.cast(data),
          })
          .then((response) => {
            invalidateVerificationApplication();
            return response;
          }),
      updateIdentification: (data: VerificationIdentificationDTO) =>
        apiInstance
          .request('PATCH /internal/shippers/verification_info', {
            json: verificationIdentificationSchema.cast(data),
          })
          .then((response) => {
            invalidateVerificationApplication();
            return response;
          }),
      updatePhoneVerification: (data: PhoneVerificationSchemaDTO) =>
        apiInstance
          .request('PUT /internal/shippers/verification_info/phone', {
            json: phoneVerificationSchema.cast(data),
          })
          .then((response) => {
            invalidateVerificationApplication();
            void queryClient.invalidateQueries([
              'shippers',
              'verification_phone_info',
            ]);
            return response;
          }),
      updateInsurance: (data: VerificationInsuranceDTO) =>
        apiInstance
          .request('PATCH /internal/shippers/verification_info', {
            json: verificationInsuranceSchema.cast(data),
          })
          .then((response) => {
            invalidateVerificationApplication();
            return response;
          }),
      uploadFile: (file: File) => {
        const body = new FormData();
        body.append('file', file);

        return apiInstance
          .request('POST /internal/shippers/verification_info/file', {
            body,
          })
          .then((response) => response.text());
      },
    }),
    [queryClient],
  );
}
