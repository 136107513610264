import { apiInstance } from 'shared/api/API';
import { OrderFieldsDTO, orderFieldsSchema } from './OrderFieldsDTO';

export function useOrderFieldsAPI() {
  const { request } = apiInstance;

  return {
    updateFields: (values: OrderFieldsDTO) =>
      request('PUT /internal/shippers/order_form_settings', {
        json: orderFieldsSchema.cast(values),
      }),
  };
}
