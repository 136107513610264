import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { ColorDynamic, useSnackbarStack } from '@superdispatch/ui';
import { Confirmation } from 'shared/ui/Confirmation';
import { useCustomersAPI } from '../data/CustomerAPI';

interface Props {
  selectedCustomers: Set<number>;
  onDeleteSuccess: () => void;
}

export function CustomersDeleteConfirmationForm({
  onDeleteSuccess,
  selectedCustomers,
}: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { deleteCustomers } = useCustomersAPI();

  const deleteForm = useFormikEnhanced({
    initialValues: {},
    onSubmit() {
      return deleteCustomers(Array.from(selectedCustomers));
    },
    onSubmitSuccess() {
      addSnackbar('Customers deleted', { variant: 'success' });
      onDeleteSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Confirmation
      title={`Delete this ${
        selectedCustomers.size > 1
          ? `${selectedCustomers.size} customers`
          : 'customer'
      } ?`}
      cancelText="No"
      processText="Yes, Delete"
      onProcess={(hideConfirmation) => {
        hideConfirmation();
        deleteForm.handleSubmit();
      }}
      trigger={({ showConfirmation }) => (
        <Tooltip title="Delete">
          <IconButton
            onClick={showConfirmation}
            disabled={deleteForm.isSubmitting}
          >
            {deleteForm.isSubmitting ? (
              <CircularProgress size="24px" />
            ) : (
              <Delete
                aria-label="delete selected customers"
                htmlColor={ColorDynamic.Dark300}
              />
            )}
          </IconButton>
        </Tooltip>
      )}
    />
  );
}
