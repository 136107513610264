import { Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Color, Inline, Stack, Tag, TagProps } from '@superdispatch/ui';
import Order from 'shared/types/order';
import styled from 'styled-components';

const OfferDeclineComment = styled(Typography)`
  font-size: 13px;
  color: ${Color.Silver500};
`;

export function PostedTag({ order }: { order: Order }) {
  if (order.is_posted_to_private_loadboard) {
    const groups = order.private_network_groups
      ?.map((x) => `'${x}'`)
      .join(', ');
    return (
      <Tooltip
        title={
          groups?.length
            ? `Posted for ${groups} group.`
            : 'Posted for all Private Network.'
        }
      >
        <Tag aria-label="order status" color="yellow" variant="subtle">
          Posted to Private Loadboard
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Tag aria-label="order status" color="yellow" variant="subtle">
      {order.is_posted_to_centraldispatch && order.is_posted_to_loadboard
        ? 'Posted to SLB, CD'
        : order.is_posted_to_centraldispatch
        ? 'Posted to CD'
        : 'Posted to SLB'}
    </Tag>
  );
}

export function OrderStatusTag({ order }: { order: Order }) {
  let appearance: TagProps['color'] = 'blue';
  let orderStatus = 'New';

  if (order.status === 'pending') {
    appearance = 'purple';
    orderStatus = 'Pending';
  }

  if (order.status === 'declined') {
    appearance = 'red';
    orderStatus = 'Declined';
  }

  if (order.status === 'accepted') {
    appearance = 'grey';
    orderStatus = 'Accepted';
  }

  if (order.status === 'canceled') {
    orderStatus = 'Offer Canceled';
  }
  if (order.status === 'picked_up') {
    appearance = 'yellow';
    orderStatus = 'Picked Up';
  }

  if (order.status === 'delivered') {
    appearance = 'green';
    orderStatus = 'Delivered';
  }

  if (order.status === 'invoiced') {
    appearance = 'blue';
    orderStatus = 'Invoiced';
  }

  if (order.status === 'paid') {
    appearance = 'blue';
    orderStatus = 'Paid';
  }

  if (order.status === 'delivery_verified') {
    appearance = 'blue';
    orderStatus = 'Delivery Verified';
  }

  if (order.is_archived) {
    appearance = 'purple';
    orderStatus = 'Archived';
  }

  if (order.status === 'submitted') {
    orderStatus = 'Submitted';
  }

  if (order.status === 'scheduled') {
    appearance = 'grey';
    orderStatus = 'Scheduled';
  }

  if (order.status === 'completed') {
    orderStatus = 'Completed';
  }

  if (order.is_on_hold) {
    appearance = 'grey';
    orderStatus = 'On Hold';
  }

  if (order.status === 'order_canceled') {
    appearance = 'grey';
    orderStatus = 'Canceled';
  }

  if (order.is_offer_canceled_by_carrier) {
    appearance = 'red';
    orderStatus = 'Canceled by Carrier';
  }

  const isOrderCanceledByCustomer = order.is_canceled_by_customer;

  return (
    <Inline space="xxsmall">
      {(order.status !== 'order_canceled' || !isOrderCanceledByCustomer) && (
        <Tag aria-label="order status" color={appearance} variant="subtle">
          {orderStatus}
        </Tag>
      )}
      {isOrderCanceledByCustomer && (
        <Tag aria-label="canceled by customer" color="red" variant="subtle">
          Canceled by Customer
        </Tag>
      )}
    </Inline>
  );
}

export function OfferDeclineTooltip({
  reason,
  comment,
  carrierName,
}: {
  reason: string | undefined;
  comment: string | undefined;
  carrierName: string | undefined | null;
}) {
  return (
    <Tooltip
      placement="right"
      title={
        <Stack space="small">
          {carrierName && <Typography variant="h4">{carrierName}</Typography>}
          <Stack space="xxsmall">
            {reason && <Typography>{reason}</Typography>}
            {comment && <OfferDeclineComment>{comment}</OfferDeclineComment>}
          </Stack>
        </Stack>
      }
    >
      <Info
        fontSize="small"
        color="action"
        aria-label="order-status-info-pin"
      />
    </Tooltip>
  );
}
