import { useCentralDispatchMfaError } from 'shared/api/centralDispatchMfaError';
import { CentralDispatchMfaDialog } from './CentralDispatchMfaDialog';

export function CentralDispatchMfaErrorDialog() {
  const [hasMfaError, setHasMfaError] = useCentralDispatchMfaError();

  return (
    <CentralDispatchMfaDialog
      open={hasMfaError}
      onClose={() => setHasMfaError(false)}
      onSuccess={() => {
        setHasMfaError(false);
      }}
    />
  );
}
