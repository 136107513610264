import { Box, Link, Typography } from '@material-ui/core';
import { Image } from '@superdispatch/ui';
import styled, { css, keyframes } from 'styled-components';
import IconContactPlaceholderDark from './assets/selected-terminals-dark.png';
import IconContactPlaceholder from './assets/selected-terminals.png';
/* Styled components / language=SCSS */
const BounceInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }

  80% {
    opacity: 1;
    transform: scale(0.89);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
`;

const image = css`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;

  animation: ${BounceInAnimation} 500ms;

  img {
    width: 100%;
  }
`;

const Image1 = styled.div`
  ${image};
  z-index: 10;
`;

const Image2 = styled.div`
  ${image};
  z-index: 5;

  img {
    transform: rotate(5deg);
  }
`;

const Image3 = styled.div`
  ${image};
  z-index: 0;

  img {
    transform: rotate(-5deg);
  }
`;

interface Props {
  count: number;
  onClear: () => void;
}

export function TerminalsSelection({ count, onClear }: Props) {
  return (
    <Box flex={1} textAlign="center">
      <Box mt={12}>
        <Typography variant="h2">{count} terminals selected</Typography>
      </Box>

      <Box mt={1}>
        <Link
          component="button"
          color="textPrimary"
          onClick={onClear}
          variant="body2"
        >
          Clear selection
        </Link>
      </Box>

      <Box mt={10} mx="auto" position="relative" width="400px" height="334px">
        <Image1>
          <Image
            alt="Contact"
            src={IconContactPlaceholder}
            srcDark={IconContactPlaceholderDark}
          />
        </Image1>

        {count > 1 && (
          <Image2>
            <Image
              alt="Contact"
              src={IconContactPlaceholder}
              srcDark={IconContactPlaceholderDark}
            />
          </Image2>
        )}

        {count > 2 && (
          <Image3>
            <Image
              alt="Contact"
              src={IconContactPlaceholder}
              srcDark={IconContactPlaceholderDark}
            />
          </Image3>
        )}
      </Box>
    </Box>
  );
}
