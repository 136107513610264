import { isObject } from 'lodash';
import { tryParseJSON } from 'shared/utils/DataUtils';
import { MoovRequestError } from '../data/MoovDTO';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const NOT_AVAILABLE_ERROR_MESSAGE =
  'SuperPay is not available in your location.';

type ErrorData = Record<string, string | Record<string, string>>;

function formatUpperCase(value: string) {
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}

function concatErrorFields(errorData: ErrorData) {
  const fields: string[] = [];

  function processData(data: ErrorData) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (typeof value === 'string') {
        const upperCaseField = formatUpperCase(key);
        fields.push(upperCaseField);
      } else if (value && isObject(value)) {
        processData(value);
      }
    });
  }

  processData(errorData);
  return fields;
}

function wrapErrorFieldMessage(value: string[]) {
  return `Invalid information in the ${value.join(
    ', ',
  )} field(s). Please correct them.`;
}

export function getMessageError(errorData: ErrorData): string {
  try {
    if ('error' in errorData && typeof errorData.error === 'string') {
      const message = errorData.error;
      const value = message.includes('routing number')
        ? wrapErrorFieldMessage(['RoutingNumber'])
        : formatUpperCase(message);
      return value || DEFAULT_ERROR_MESSAGE;
    }

    const fields = concatErrorFields(errorData);

    if (!fields.length) {
      return DEFAULT_ERROR_MESSAGE;
    }

    return wrapErrorFieldMessage(fields);
  } catch {
    return DEFAULT_ERROR_MESSAGE;
  }
}

function isMoovRequestError(error: unknown): error is MoovRequestError {
  return (
    !!error &&
    isObject(error) &&
    !!(
      (error as MoovRequestError).statusCode ||
      (error as MoovRequestError).error
    )
  );
}

export function getError(reason: unknown) {
  if (!isMoovRequestError(reason)) {
    return reason;
  }

  const { statusCode, error } = reason;
  let messageValue =
    error && typeof error === 'string' ? error : DEFAULT_ERROR_MESSAGE;

  if (statusCode === 422 || statusCode === 409) {
    const errorData = tryParseJSON<ErrorData>(messageValue);
    messageValue = errorData
      ? getMessageError(errorData)
      : DEFAULT_ERROR_MESSAGE;
  }

  if (
    statusCode === 401 ||
    statusCode === 403 ||
    messageValue.includes('request error')
  ) {
    messageValue = DEFAULT_ERROR_MESSAGE;
  }

  return new Error(messageValue || DEFAULT_ERROR_MESSAGE);
}
