import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useAPI } from 'shared/api/API';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useUserState } from 'shared/data/AppUserState';
import { APIError } from 'shared/errors/APIError';
import { SubscriptionPlanCode } from 'shared/subscription-plans/useSubscriptionPlans';
import {
  BillingStatusDTO,
  billingStatusSchema,
  SubscriptionStatusDTO,
  subscriptionStatusSchema,
} from './PaywallDTO';

const subscriptionStatusQueryKey = ['subscription', 'status'] as const;

export function useSubscriptionDetails(
  options?: UseQueryOptions<SubscriptionStatusDTO, APIError>,
) {
  const { requestResource } = useAPI();

  return useQuery<SubscriptionStatusDTO, APIError>(
    subscriptionStatusQueryKey,
    () =>
      requestResource('GET /internal/subscription/status', (data) =>
        subscriptionStatusSchema.cast(data),
      ),
    options,
  );
}

export function useInvalidateSubscriptionDetails() {
  const queryClient = useQueryClient();

  return () => {
    void queryClient.invalidateQueries(subscriptionStatusQueryKey);
  };
}

export function useBillingStatus(
  options?: UseQueryOptions<BillingStatusDTO, APIError>,
) {
  const { requestResource } = useAPI();
  const { user } = useUserState();

  return useQuery<BillingStatusDTO, APIError>(
    ['billing', 'status'],
    () =>
      requestResource('GET /internal/billing/status', (data) =>
        billingStatusSchema.cast(data),
      ),
    { ...options, enabled: !!user?.shipper.is_self_serve, staleTime: Infinity },
  );
}

interface SubscriptionPurchaseDTO {
  card_number?: string;
  token_id: string;
  country: string;
  state: string;
  zip: string;
  address: string;
  address_second_line?: string;
  city: string;
  plan_code: SubscriptionPlanCode;
}

export type ReasonCode =
  | 'Trial expired'
  | 'Not Enough Carriers'
  | 'Lack of Features'
  | 'Out of Business'
  | 'No Budget'
  | 'Lost to Competition'
  | 'Other';

export interface SubscriptionCancelDTO {
  reason_code: ReasonCode;
  cancellation_feedback: string | null;
}

export function usePaywallAPI() {
  const { request } = useAPI();
  const invalidateSubscriptionDetails = useInvalidateSubscriptionDetails();

  return {
    purchaseSubscription: (values: SubscriptionPurchaseDTO) =>
      request('POST /internal/subscription/purchase', {
        json: values,
      }).then((data) => {
        invalidateSubscriptionDetails();
        return data;
      }),
    cancelSubscription: ({
      reason_code,
      cancellation_feedback,
    }: SubscriptionCancelDTO) =>
      request('POST /internal/subscription/cancel', {
        json: { reason_code, cancellation_feedback },
      }),
    reactivate: ({ plan_code }: { plan_code: SubscriptionPlanCode }) =>
      request('POST /internal/subscription/activate', {
        json: { plan_code },
      }),
  };
}

export function useReactivateSubscriptionMutation(
  options?: APIMutationOptions<{ plan_code: SubscriptionPlanCode }>,
) {
  const { reactivate } = usePaywallAPI();

  return useAPIMutation((plan_code) => reactivate(plan_code), options);
}
