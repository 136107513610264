import { apiInstance } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { Order } from 'shared/types/order';
import { DashboardOrderPageParamsDTO } from './OrderListPageParamsDTO';

export interface DashboardOrdersResponse {
  content: Order[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    page_number: number;
    page_size: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  total_elements: number;
  total_pages: number;
  first: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  number_of_elements: number;
  size: number;
  number: number;
  empty: boolean;
}

export type DashboardOrdersType =
  | 'aged'
  | 'pickup_delayed'
  | 'delivery_delayed'
  | 'pickup_today'
  | 'delivery_today';

export function useDashboardOrders(
  type: DashboardOrdersType,
  values: DashboardOrderPageParamsDTO,
) {
  const { requestJSON } = apiInstance;

  const params = {
    size: values.pageSize,
    page: values.page,
    sort: values.sort.filter(Boolean).join(','),
  };

  return useAPIQuery(['dashboard', 'orders', { type, params }], () => {
    return requestJSON<DashboardOrdersResponse>(
      '/internal/dashboard/{type}_orders{?params*}',
      {
        type,
        params,
      },
    );
  });
}
