import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { AccessRestrictedDialog } from './core/actions/AccessRestrictedDialog';
import { OrderCreatePage } from './create/OrderCreatePage';
import { OrderEditPage } from './edit/OrderEditPage';
import { OrderImportPage } from './import/OrderImportPage';
import { OrdersListPage } from './list/OrdersListPage';
import { OrderRequestsPage } from './order-requests/OrderRequestsPage';
import { OrderPreviewPage } from './preview/OrderPreviewPage';
import { OrderViewPage } from './view/OrderViewPage';

const CombineOrdersPageLazy = lazy(
  () => import('./combine-orders/CombineOrdersPage'),
);

const OrderSplitLoadsPageLazy = lazy(
  () => import('./split-loads/OrderSplitLoadsPage'),
);

const OrderSplitPageLazy = lazy(() => import('./order-split/OrderSplitPage'));

export const ordersRoute: RouteObject = {
  path: 'orders',
  Component: OrdersContainer,
  children: [
    {
      path: 'create',
      Component: OrderCreatePage,
    },
    {
      path: 'edit/:guid',
      Component: OrderEditPage,
    },
    {
      path: 'view/:guid/*',
      element: <OrderViewPage isActive={true} path="/orders/view" />,
    },
    {
      path: 'inactive/view/:guid/*',
      element: <OrderViewPage isActive={false} path="/orders/inactive/view" />,
    },
    {
      path: 'split/:guid',
      Component: OrderSplitPageLazy,
    },
    {
      path: 'split-loads/:guid',
      element: <OrderSplitLoadsPageLazy />,
    },
    {
      path: 'combine',
      Component: CombineOrdersPageLazy,
    },
    {
      path: 'preview/:type/:orderID',
      Component: OrderPreviewPage,
    },
    {
      path: 'import',
      Component: OrderImportPage,
    },
    {
      path: 'requests',
      Component: OrderRequestsPage,
    },
    {
      path: ':status?',
      Component: OrdersListPage,
    },
  ],
};

function OrdersContainer() {
  useTrackEvent('[STMS] Viewed Orders Page');
  return (
    <>
      <Outlet />
      <AccessRestrictedDialog />
    </>
  );
}
