import carrierAcceptedImageDark from './assets/carrier-accepted-img-dark.png';
import carrierAcceptedImage from './assets/carrier-accepted-img.png';
import carrierInvoicedImageDark from './assets/carrier-invoiced-img-dark.svg';
import carrierInvoicedImage from './assets/carrier-invoiced-img.svg';
import carrierDeliveredImageDark from './assets/load-delivered-dark.svg';
import carrierDeliveredImage from './assets/load-delivered.svg';
import carrierPickedUpImageDark from './assets/picked-up-img-dark.png';
import carrierPickedUpImage from './assets/picked-up-img.png';
import postToSlbImageDark from './assets/post-to-slb-img-dark.png';
import postToSlbImage from './assets/post-to-slb-img.png';
import requestReceivedImageDark from './assets/review-requests-img-dark.png';
import requestReceivedImage from './assets/review-requests-img.png';
import { OnboardingStepKey } from './OnboardingAPI';

export const popupImageMap = {
  USER_CREATED_FIRST_ORDER: {
    src: postToSlbImage,
    srcDark: postToSlbImageDark,
    width: '212px',
    alt: 'post to slb',
  },
  USER_SENT_FIRST_DIRECT_OFFER: {
    src: postToSlbImage,
    srcDark: postToSlbImageDark,
    width: '212px',
    alt: 'post to slb',
  },
  USER_RECEIVED_FIRST_LOAD_REQUEST: {
    src: requestReceivedImage,
    srcDark: requestReceivedImageDark,
    width: '131px',
    alt: 'request received',
  },
  CARRIER_ACCEPTED_USERS_FIRST_LOAD_OFFER: {
    src: carrierAcceptedImage,
    srcDark: carrierAcceptedImageDark,
    width: '240px',
    alt: 'carrier accepted',
  },
  CARRIER_PICKED_UP_USERS_FIRST_LOAD: {
    src: carrierPickedUpImage,
    srcDark: carrierPickedUpImageDark,
    width: '268px',
    alt: 'carrier picked up',
  },
  CARRIER_DELIVERED_USERS_FIRST_LOAD: {
    src: carrierDeliveredImage,
    srcDark: carrierDeliveredImageDark,
    width: '268px',
    alt: 'carrier delivered',
  },
  CARRIER_INVOICED_USERS_FIRST_LOAD: {
    src: carrierInvoicedImage,
    srcDark: carrierInvoicedImageDark,
    width: '131px',
    alt: 'invoice received',
  },
} as unknown as Record<
  OnboardingStepKey,
  {
    src: string;
    width: string;
    alt: string;
  }
>;

export function getPopupImage(stepKey: OnboardingStepKey) {
  return popupImageMap[stepKey];
}
