import { Skeleton } from '@material-ui/lab';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';

export function OrderRequestsLoadingState() {
  return (
    <Box padding="small">
      <Columns space="small">
        <Column width="content">
          <Box width="300px">
            <Stack>
              <Skeleton height={25} />
              <Skeleton height={25} />
              <Skeleton height={25} />
              <Skeleton height={25} />
              <Skeleton height={25} />
            </Stack>
          </Box>
        </Column>
        <Column>
          <Stack>
            <Skeleton height={25} />
            <Skeleton height={25} />
            <Skeleton height={25} />
          </Stack>
        </Column>
        <Column>
          <Stack>
            <Skeleton height={25} />
            <Skeleton height={25} />
            <Skeleton height={25} />
          </Stack>
        </Column>
      </Columns>
    </Box>
  );
}
