import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import { ColorDynamic } from '@superdispatch/ui';
import * as React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  min-width: 960px;
  margin: 0;

  thead,
  tbody {
    border: none;
  }

  tbody > tr {
    border-top: 1px solid ${ColorDynamic.Silver400};
  }

  th,
  td {
    padding: 12px 8px;
    font-size: 14px;
    vertical-align: top;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }

  th {
    color: ${ColorDynamic.Dark300};
    white-space: nowrap;
    text-align: left;
  }
`;

const Container = styled.div`
  overflow: auto;
`;

export interface TableColumn {
  header: string;
  field: string;
  color?: TypographyProps['color'];
  align?: TypographyProps['align'];
  width?: string;
  hidden?: boolean;
}

export type Row = Record<string, React.ReactNode>;

interface TableProps {
  columns: TableColumn[];
  rows: Row[];
  noContent?: string;
}

export const OrderViewTable: React.ComponentType<TableProps> = ({
  columns,
  rows,
  noContent = '-',
}) => (
  <Container>
    <StyledTable>
      <thead>
        <tr>
          {columns
            .filter(({ hidden }) => !hidden)
            .map(({ header, align, width }, idx) => (
              <th key={idx} style={{ width: width || 'initial' }}>
                <Typography variant="body1" color="inherit" align={align}>
                  {header}
                </Typography>
              </th>
            ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, rowIdx) => (
          <tr key={rowIdx}>
            {columns
              .filter(({ hidden }) => !hidden)
              .map(({ field, color, align }, columnIdx) => (
                <td key={columnIdx}>
                  <Typography
                    variant="body2"
                    component="div"
                    color={row[field] ? color : 'textSecondary'}
                    align={align}
                    aria-label={field}
                  >
                    {row[field] || noContent}
                  </Typography>
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  </Container>
);
