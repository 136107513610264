import { Dialog, Link, Typography } from '@material-ui/core';
import { Image, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import styled from 'styled-components';
import createOrderBackgroundDark from './assets/create-order-bg-dark.svg';
import createOrderBackground from './assets/create-order-bg.svg';
import createOrderImageDark from './assets/create-order-img-dark.png';
import createOrderImage from './assets/create-order-img.png';
import { OnboardingPopupComponentProps } from './onboardingMap';

const OnboardingBox = styled(Box)(({ theme }) => {
  const isDark = theme.palette.type === 'dark';
  const url = isDark ? createOrderBackgroundDark : createOrderBackground;
  return {
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
  };
});

export function CreateOrderDialog({
  open,
  onAction,
}: OnboardingPopupComponentProps) {
  return (
    <Dialog
      open={open}
      onClose={() =>
        onAction?.({
          type: 'CLOSE_POPUP',
          shouldCompleteStep: true,
        })
      }
    >
      <OnboardingBox
        padding="large"
        paddingLeft="xlarge"
        paddingRight="xlarge"
        maxWidth="492px"
      >
        <Stack space="large" align="center">
          <Image
            src={createOrderImage}
            srcDark={createOrderImageDark}
            width="287px"
          />
          <Stack space="small" align="center">
            <Typography
              variant="h4"
              align="center"
              color="textSecondary"
              noWrap={false}
            >
              You’re a Verified Shipper!
            </Typography>

            <Typography variant="h2" align="center">
              Create and Post Your First Order
            </Typography>
            <Stack space="xsmall" align="center">
              <Typography align="center" color="textSecondary">
                Get fast responses from our network of reliable, trusted
                carriers.
              </Typography>
              <Link
                color="primary"
                href="https://support.superdispatch.com/hc/en-us/articles/35696862649875-How-to-Create-and-Post-an-Order-in-Shipper-TMS#h_d145182b65"
                target="_blank"
                rel="noreferrer"
              >
                Learn How to Create and Post
              </Link>
            </Stack>

            <Inline space="small">
              <Button
                variant="neutral"
                size="large"
                fullWidth={true}
                onClick={() => {
                  onAction?.({
                    type: 'CLOSE_POPUP',
                    shouldCompleteStep: true,
                  });
                  trackEvent(
                    '[Onboarding] Shipper Dismissed Onboarding Modal',
                    {
                      utm_medium: 'Create Order Modal',
                    },
                  );
                }}
              >
                Do it Later
              </Button>
              <Button
                size="large"
                fullWidth={true}
                onClick={() => {
                  onAction?.({
                    type: 'NAVIGATE',
                    to: '/orders/create',
                    shouldCompleteStep: true,
                  });
                  trackEvent(
                    '[Onboarding] Shipper Clicked Create Order Button',
                    {
                      utm_medium: 'Create and Post Your First Order Modal',
                    },
                  );
                }}
              >
                Get Started!
              </Button>
            </Inline>
          </Stack>
        </Stack>
      </OnboardingBox>
    </Dialog>
  );
}
