import { Divider, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Color, Inline, Tag, useUID } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useIsEscalationAvailable } from 'orders/core/actions/escalation/EscalationUtils';
import { IconBox } from 'shared/icons/IconBox';
import { OutlineCheckIcon } from 'shared/icons/OutlineCheckIcon';
import Order from 'shared/types/order';
import { InlineBulletItems } from 'shared/ui/BulletTextItem';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import styled from 'styled-components';
import { NoData } from './NoData';

const ScrollableContainer = styled.div`
  max-height: 500px;
  overflow: auto;
  margin: 0 -16px;
  padding: 0 16px;
  scrollbar-gutter: stable;
`;

const escalationReasons = new Map([
  ['CHARGES_DUE', 'Charges Due'],
  ['VEHICLE_IS_NOT_AT_LOCATION', 'Vehicle is Not at Location'],
  ['INCORRECT_PHONE_NUMBER', 'Incorrect Phone Number'],
  ['LOCATION_WILL_NOT_RELEASE', 'Location Will Not Release'],
  ['LOCATION_CHANGED', 'Location Changed'],
  ['NO_RETURNED_CALLS_OR_EMAILS', 'No Returned Calls/Emails'],
  ['OTHER', 'Other'],
]);

const StyledEscalationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 16px;
`;

const StyledEscalationCard = styled.div<{ hasGap?: boolean }>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: ${(props) => (props.hasGap ? '16px' : '0')};
`;

const StyledEscalationBox = styled.div<{ isSent?: string }>`
  border-radius: 4px;
  background: ${(props) => props.isSent};

  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const StyledEscalationReasonItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const StyledSubmitedTo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

const StyledDivider = styled(Divider)`
  margin: 16px 0;
`;

export interface EscalationsProps {
  order: Order;
}

export const OrderViewEscalations: React.FunctionComponent<EscalationsProps> =
  ({ order }) => {
    const uid = useUID();
    const { isBroker } = useIsEscalationAvailable();

    return (
      <StyledEscalationCard
        aria-labelledby={uid}
        aria-label="Escalations"
        hasGap={!!order.escalations}
      >
        <Typography variant="h3">Escalations</Typography>

        {order.escalations?.length ? (
          <ScrollableContainer>
            {order.escalations.map((escalation, index) => (
              <>
                {escalation.resolved_by_user && (
                  <StyledEscalationWrapper>
                    <Inline>
                      <InlineBulletItems>
                        <TextBox variant="body-semibold">
                          {isBroker
                            ? escalation.receiver_name
                            : escalation.resolved_by_user_name}
                        </TextBox>
                        <TextBox color="secondary">
                          <DetailedFormattedDate
                            date={escalation.resolved_at}
                          />
                        </TextBox>
                      </InlineBulletItems>
                    </Inline>
                    <StyledEscalationBox
                      key={`${String(escalation.guid)}-resolved`}
                      isSent={isBroker ? Color.Silver200 : Color.Blue50}
                    >
                      {Object.entries(escalation.resolve_reasons || {}).map(
                        ([reason, description]) => (
                          <StyledEscalationReasonItem key={reason}>
                            <TextBox variant="body-semibold">
                              {escalationReasons.get(reason) || reason}
                            </TextBox>
                            {description && (
                              <Typography variant="body2" color="textSecondary">
                                {description}
                              </Typography>
                            )}
                          </StyledEscalationReasonItem>
                        ),
                      )}
                    </StyledEscalationBox>
                    <Tag color="green" variant="subtle" fontWeight="regular">
                      <IconBox>
                        <Check fontSize="small" />
                      </IconBox>
                      Resolved
                    </Tag>
                  </StyledEscalationWrapper>
                )}
                {escalation.submitted_by_user && (
                  <StyledEscalationWrapper>
                    <Inline>
                      <InlineBulletItems>
                        <TextBox variant="body-semibold">
                          {isBroker
                            ? escalation.submitted_by_user_name
                            : escalation.sender_name}
                        </TextBox>
                        <TextBox color="secondary">
                          <DetailedFormattedDate date={escalation.created_at} />
                        </TextBox>
                      </InlineBulletItems>
                    </Inline>
                    <StyledEscalationBox
                      key={`${String(escalation.guid)}-submitted`}
                      isSent={isBroker ? Color.Blue50 : Color.Silver200}
                    >
                      {Object.entries(escalation.reasons || {}).map(
                        ([reason, description]) => (
                          <StyledEscalationReasonItem key={reason}>
                            <TextBox variant="body-semibold">
                              {escalationReasons.get(reason) || reason}
                            </TextBox>
                            {description && (
                              <Typography variant="body2" color="textSecondary">
                                {description}
                              </Typography>
                            )}
                          </StyledEscalationReasonItem>
                        ),
                      )}
                    </StyledEscalationBox>
                    {isBroker && (
                      <StyledSubmitedTo>
                        <OutlineCheckIcon
                          fontSize="small"
                          htmlColor={Color.Dark100}
                        />
                        <TextBox variant="hint" color="secondary">
                          Submitted to {escalation.receiver_name}
                        </TextBox>
                      </StyledSubmitedTo>
                    )}
                  </StyledEscalationWrapper>
                )}

                {order.escalations && index < order.escalations.length - 1 && (
                  <StyledDivider />
                )}
              </>
            ))}
          </ScrollableContainer>
        ) : (
          <NoData my={3} text="No escalations yet" />
        )}
      </StyledEscalationCard>
    );
  };
