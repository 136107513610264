import { yupBoolean, yupObject } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const vehiclesFieldsSchema = yupObject({
  is_color_visible: yupBoolean(),
  is_lot_number_visible: yupBoolean(),
});

const customerFieldsSchema = yupObject({
  is_business_type_visible: yupBoolean(),
  is_address_visible: yupBoolean(),
  is_phone_visible: yupBoolean(),
  is_email_visible: yupBoolean(),
  is_notes_visible: yupBoolean(),
  is_contact_title_visible: yupBoolean(),
  is_contact_mobile_visible: yupBoolean(),
  is_contact_email_visible: yupBoolean(),
});

const venueFieldsSchema = yupObject({
  is_business_type_visible: yupBoolean(),
  is_contact_title_visible: yupBoolean(),
  is_contact_email_visible: yupBoolean(),
  is_contact_mobile_phone_visible: yupBoolean(),
});

const pickupFieldsSchema = yupObject({
  venue: venueFieldsSchema,
  is_first_available_pickup_date_visible: yupBoolean(),
  is_scheduled_at_by_customer_visible: yupBoolean(),
  is_adjusted_date_visible: yupBoolean(),
});

const deliveryFieldsSchema = yupObject({
  venue: venueFieldsSchema,
  is_scheduled_at_by_customer_visible: yupBoolean(),
  is_adjusted_date_visible: yupBoolean(),
});

const paymentFieldsSchema = yupObject({
  is_broker_fee_visible: yupBoolean(),
  is_amount_visible: yupBoolean(),
  is_reference_number_visible: yupBoolean(),
  is_sent_date_visible: yupBoolean(),
});

const customerPaymentFieldsSchema = yupObject({
  is_deposit_visible: yupBoolean(),
  is_amount_visible: yupBoolean(),
  is_method_visible: yupBoolean(),
  is_reference_number_visible: yupBoolean(),
  is_received_date_visible: yupBoolean(),
});

export type OrderFieldsDTO = InferType<typeof orderFieldsSchema>;
export const orderFieldsSchema = yupObject({
  is_purchase_order_number_visible: yupBoolean(),
  is_transport_type_visible: yupBoolean(),
  vehicle: vehiclesFieldsSchema,
  is_loadboard_instructions_visible: yupBoolean(),
  is_instructions_visible: yupBoolean(),
  is_customer_block_visible: yupBoolean(),
  customer: customerFieldsSchema,
  pickup: pickupFieldsSchema,
  delivery: deliveryFieldsSchema,
  payment: paymentFieldsSchema,
  customer_payment: customerPaymentFieldsSchema,
});
