import { MenuItem, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { formatPaymentTerm, PaymentMethod } from '@superdispatch/sdk';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { getPaymentMethodOptions } from 'shared/form/FormikPaymentMethodField';
import Order from 'shared/types/order';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { usePaymentTermUtils } from '../../core/PaymentUtils';
import { OrderFormValues } from '../../orders/core/form/OrderForm';
import { Source, useSuperPayCancelForm } from './useSuperPayCancelForm';

interface SuperPayCancelDialogContentProps {
  open: boolean;
  onClose: () => void;
  order?: Order | OrderFormValues;
  source: Source;
  onSuccess?: () => void;
}
function SuperPayCancelDialogContent({
  open,
  onClose,
  order,
  source,
  onSuccess,
}: SuperPayCancelDialogContentProps) {
  const { form, isLoading } = useSuperPayCancelForm({
    order,
    onClose,
    source,
    onSuccess,
  });

  const { getPaymentTerms, getValidPaymentTerm } = usePaymentTermUtils();
  const paymentTerms = getPaymentTerms(form.values.method, form.values.terms);

  return (
    <ConfirmDialog
      maxWidth={false}
      fullWidth={false}
      open={open}
      onClose={isLoading ? undefined : onClose}
      title="Cancel SuperPay Payment?"
      cancelButtonProps={{
        children: 'Cancel',
        disabled: isLoading,
        onClick: onClose,
      }}
      confirmButtonProps={{
        children: 'Confirm',
        isLoading,
        onClick: () => form.handleSubmit(),
        autoFocus: false,
      }}
    >
      <FormikProvider value={form}>
        <Form>
          <Box maxWidth={['auto', '360px']}>
            <Stack space="small">
              <Typography color="textSecondary">
                To proceed, select a new payment method. Refunds take 1–3
                business days, and SuperPay can’t be reselected.
              </Typography>
              <Columns space="small">
                <Column>
                  <FormikTextField
                    select={true}
                    fullWidth={true}
                    name="method"
                    label="Payment Method"
                    SelectProps={{ displayEmpty: true }}
                    onChange={({ target }) => {
                      const { value } = target;

                      void form.setFieldValue(
                        'terms',
                        getValidPaymentTerm(
                          value as PaymentMethod,
                          form.values.terms,
                        ),
                      );
                    }}
                  >
                    <MenuItem value="">
                      <Typography color="textSecondary">
                        Select a method
                      </Typography>
                    </MenuItem>
                    {getPaymentMethodOptions(true)}
                  </FormikTextField>
                </Column>
                <Column>
                  <FormikTextField
                    select={true}
                    fullWidth={true}
                    name="terms"
                    label="Terms"
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">
                      <Typography color="textSecondary">
                        Select a term
                      </Typography>
                    </MenuItem>
                    {paymentTerms.map((value) => (
                      <MenuItem key={value} value={value}>
                        {formatPaymentTerm(value)}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </Column>
              </Columns>
            </Stack>
          </Box>
        </Form>
      </FormikProvider>
    </ConfirmDialog>
  );
}

interface SuperPayCancelOrderEditDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  order: OrderFormValues | Order;
}

export function SuperPayCancelOrderEditDialog({
  open,
  onClose,
  order,
  onSuccess,
}: SuperPayCancelOrderEditDialogProps) {
  return (
    <SuperPayCancelDialogContent
      open={open}
      onClose={onClose}
      order={order}
      source="Order Edit"
      onSuccess={onSuccess}
    />
  );
}

interface SuperPayCancelActionDialogProps {
  onClose: () => void;
  order?: Order | OrderFormValues;
}
export function SuperPayCancelActionDialog({
  onClose,
  order,
}: SuperPayCancelActionDialogProps) {
  return (
    <SuperPayCancelDialogContent
      open={!!order}
      onClose={onClose}
      order={order}
      source="Order List"
    />
  );
}
