import { useMemo } from 'react';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  yupArray,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const USAGE_NAMES = [
  'VIN_DELIVERED',
  'VIN_POSTED',
  'SUPERPAY_PAID',
  'PRICING_INSIGHTS_API',
] as const;

const usageSchema = yupObject({
  name: yupString().oneOf(USAGE_NAMES).required(),
  usage: yupNumber().required().default(0),
  limit: yupNumber().required().default(0),
  overage_price: yupNumber().required().default(0),
});

const usageDetailsSchema = yupObject({
  usages: yupArray(usageSchema).required(),
  current_term_start: yupString().nullable(),
  current_term_end: yupString().nullable(),
  last_updated_at: yupString().nullable(),
});

export type UsageDTO = InferType<typeof usageSchema>;
export type UsageDetails = InferType<typeof usageDetailsSchema>;
export type UsageName = typeof USAGE_NAMES[number];

const CACHE_KEY = ['subscription-details', 'plan-usages'] as const;

function sortUsages(usages: UsageDTO[]) {
  return usages
    .slice(0)
    .sort((a, b) => USAGE_NAMES.indexOf(a.name) - USAGE_NAMES.indexOf(b.name));
}

export function useUsageDetails() {
  const { requestResource } = useAPI();
  const { data, isLoading } = useAPIQuery(
    CACHE_KEY,
    () =>
      requestResource(
        'GET /internal/subscription/plan/usages{?is_self_serve}',
        (rawData) => rawData as UsageDetails,
        { is_self_serve: false },
      ),
    { schema: usageDetailsSchema },
  );

  const usageDetailsWithSortedUsages = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return {
      ...data,
      usages: sortUsages(data.usages),
    };
  }, [data]);

  return {
    usageDetails: usageDetailsWithSortedUsages,
    isUsageDetailsLoading: isLoading,
  };
}
