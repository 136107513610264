import { Outlet, RouteObject } from 'react-router-dom';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { CreateInternalCarrierPage } from './create/CreateInternalCarrierPage';
import { EditInternalCarrierPage } from './edit/EditInternalCarrierPage';
import { ManageCarriersListPage } from './list/ManageCarriersListPage';
import { CarrierProfilePage } from './view/CarrierProfilePage';

export const manageCarriersRoute: RouteObject = {
  path: 'manage-carriers',
  Component: ManageCarriersContainer,
  children: [
    {
      path: 'usdot/:usdot',
      Component: CarrierProfilePage,
    },
    {
      path: ':guid',
      Component: CarrierProfilePage,
    },
    {
      path: 'create',
      handle: { isProtected: true },
      Component: CreateInternalCarrierPage,
    },
    {
      path: 'edit/:guid',
      handle: { isProtected: true },
      Component: EditInternalCarrierPage,
    },
    {
      index: true,
      handle: { isProtected: true },
      Component: ManageCarriersListPage,
    },
  ],
};

export function ManageCarriersContainer() {
  useDocumentTitle('Manage Carriers');
  useTrackEvent('[STMS] Viewed Manage Carriers');

  return <Outlet />;
}
