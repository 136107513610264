import { IconButton, Typography } from '@material-ui/core';
import { CheckCircle, Close } from '@material-ui/icons';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Color,
  ColorDynamic,
  DrawerActions,
  DrawerTitle,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { FormikDrawer } from 'shared/form/FormikDrawer';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderActionSource } from '../OrderActionsAnalytics';
import { useEscalationAPI } from './data/EscalationAPI';
import {
  ResolveEscalationDTO,
  resolveEscalationSchema,
} from './data/EscalationDTO';

const escalationReasons = [
  { name: 'CHARGES_DUE', label: 'Charges Due' },
  { name: 'VEHICLE_IS_NOT_AT_LOCATION', label: 'Vehicle is Not at Location' },
  { name: 'INCORRECT_PHONE_NUMBER', label: 'Incorrect Phone Number' },
  { name: 'LOCATION_WILL_NOT_RELEASE', label: 'Location Will Not Release' },
  { name: 'LOCATION_CHANGED', label: 'Location Changed' },
  { name: 'NO_RETURNED_CALLS_OR_EMAILS', label: 'No Returned Calls/Emails' },
  { name: 'OTHER', label: 'Other' },
] as const;

const StyledBox = styled.div`
  display: flex;
  padding: 16px 32px 16px 0;
  align-items: center;
  align-self: stretch;
`;

const StyledActionsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  align-self: stretch;
`;

const StyledEscalationReasonBox = styled.div`
  display: flex;
  padding: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background-color: ${ColorDynamic.Silver200};
`;

const StyledEscalationReasonBoxContent = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const StyledEscalationReasonBoxText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

interface ResolveEscalationDrawerContentProps {
  order: Order;
  source: OrderActionSource;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

function ResolveEscalationDrawerContent({
  order,
  source,
  onClose,
  onSubmitSuccess,
}: ResolveEscalationDrawerContentProps) {
  const { resolveEscalation } = useEscalationAPI();
  const { addSnackbar } = useSnackbarStack();
  const brokerName =
    order.escalation?.submitted_by_user_name ||
    order.escalations?.[0]?.submitted_by_user_name;
  const escalationData = order.escalation || order.escalations?.[0];
  const escalationGuid = escalationData?.guid || order.escalation?.guid;

  const formik = useFormikEnhanced<ResolveEscalationDTO, unknown>({
    initialValues: {
      reasons: escalationData?.reasons
        ? Object.keys(escalationData.reasons).reduce(
            (acc, key) => ({ ...acc, [key]: undefined }),
            {},
          )
        : {},
    },
    validationSchema: resolveEscalationSchema,
    onSubmit: (values) => {
      if (!escalationGuid) {
        addSnackbar("Couldn't find escalation information for the order.", {
          variant: 'error',
        });
        onClose();
        return;
      }

      return resolveEscalation(escalationGuid, values);
    },
    onSubmitSuccess: (_, values) => {
      onSubmitSuccess?.();
      onClose();
      addSnackbar('Escalation was successfully resolved', {
        variant: 'success',
      });
      trackEventLegacy('Shipper Resolved Escalation', {
        utm_source: source,
        utm_medium: 'Review and Resolve Escalation Form',
        values,
      });
    },
    onSubmitFailure: (error) => {
      onClose();
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <DrawerTitle
          title="Resolve Escalation"
          endAction={
            <IconButton edge="end" onClick={onClose}>
              <Close aria-label="close" />
            </IconButton>
          }
        />

        <StyledBox>
          <Stack space="xsmall">
            <Box paddingLeft="large" marginBottom="-xsmall">
              {brokerName}
            </Box>
            {escalationReasons.map(
              (reason) =>
                escalationData?.reasons?.[reason.name] !== undefined && (
                  <Stack key={reason.name} space="xsmall">
                    <Box paddingLeft="large">
                      <StyledEscalationReasonBox>
                        <StyledEscalationReasonBoxContent>
                          <StyledEscalationReasonBoxText>
                            <TextBox variant="body-semibold">
                              {reason.label}
                            </TextBox>
                            {escalationData.reasons[reason.name] && (
                              <TextBox variant="body">
                                {escalationData.reasons[reason.name]}
                              </TextBox>
                            )}
                          </StyledEscalationReasonBoxText>
                          {formik.values.reasons[reason.name] && (
                            <CheckCircle
                              fontSize="small"
                              htmlColor={Color.Green300}
                            />
                          )}
                        </StyledEscalationReasonBoxContent>
                      </StyledEscalationReasonBox>
                      <FormikMaxLengthTextField
                        name={`reasons.${reason.name}`}
                        maxLength={500}
                        fullWidth={true}
                        multiline={true}
                        label="Details"
                        rows={4}
                        disabled={formik.isSubmitting}
                      />
                    </Box>
                  </Stack>
                ),
            )}
          </Stack>
        </StyledBox>

        <DrawerActions>
          <StyledActionsBox>
            <Typography color="textSecondary">
              By resolving this escalation, the broker will be notified and
              updated with your response.
            </Typography>

            <Button
              type="submit"
              pending={formik.isSubmitting}
              disabled={!formik.dirty || !formik.isValid}
            >
              Resolve and Send
            </Button>
          </StyledActionsBox>
        </DrawerActions>
      </Form>
    </FormikProvider>
  );
}

interface ResolveEscalationDrawerProps {
  order?: Order;
  source: OrderActionSource;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

export function ResolveEscalationDrawer({
  order,
  source,
  onClose,
  onSubmitSuccess,
}: ResolveEscalationDrawerProps) {
  const open = !!order;

  return (
    <FormikDrawer open={open} onClose={onClose}>
      {open && (
        <ResolveEscalationDrawerContent
          order={order}
          source={source}
          onClose={onClose}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
    </FormikDrawer>
  );
}
