import {
  CircularProgress,
  Link,
  MenuItem,
  Popper,
  PopperProps,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import { SuspendedPhoneText } from '@superdispatch/phones';
import {
  Button,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { BasicCarrierProfile } from 'core/BasicCarrierProfile';
import {
  ApprovedTag,
  PrivateNetworkTag,
  UnverifiedTag,
  VerifiedTag,
} from 'core/CarrierTags';
import { isCarrierInsuranceExpired } from 'core/insurance/ExpirationDates';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { get, identity } from 'lodash';
import * as React from 'react';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import {
  SearchType,
  useCarrierSearch,
  useCarrierSearchAPI,
} from 'shared/api/CarrierSearchAPI';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { Carrier, CarrierFullInfo, FmcsaInfo } from 'shared/types/carrier';
import styled from 'styled-components';
import { CarrierAcceptsSuperPay } from '../superpay/CarrierAcceptsSuperPay';
import { CarrierRequestsSuperPay } from '../superpay/CarrierRequestsSuperPay';

function isUSDOT(text: string) {
  return /^\d{3,}$/.test(text);
}

const formatSearchType = (searchType: string, query: string): SearchType =>
  searchType === 'nameUsdot'
    ? query.length > 5 && isUSDOT(query)
      ? 'usdot'
      : 'name'
    : (searchType as SearchType);

function getOptionLabel(option: Carrier | string) {
  return typeof option === 'string'
    ? option
    : `${option.dba_name || option.name} ` +
        `${option.us_dot ? `USDOT: ${option.us_dot}` : ''} ` +
        `${option.phone_numbers ? `${option.phone_numbers}` : ''} ` +
        `${option.email ? `${option.email}` : ''} `;
}

const NewCarrierWrapper = styled.div`
  padding: 8px;
  margin-top: 4px;
  margin-bottom: 2px;
  border-radius: 4px;
  background: ${ColorDynamic.Yellow50};
`;

const StyledPopper = styled(Popper)`
  & .MuiAutocomplete-paper {
    margin: 0 0 4px;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  & .MuiAutocomplete-option {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const StyledBox = styled.div`
  background: ${ColorDynamic.White};
  border: 1px solid ${ColorDynamic.Silver400};
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px 0 8px;
  margin-top: 4px;
`;

function CarrierOption({ carrier }: { carrier: Carrier }) {
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );
  const uid = useUID();

  return (
    <Stack aria-labelledby={uid} space="xsmall">
      <Inline verticalAlign="center" space="xxsmall">
        <Typography id={uid} variant="h5">
          {carrier.name}
        </Typography>
        {carrier.is_verified ? <VerifiedTag /> : <UnverifiedTag />}
        {carrier.broker_records?.approved && <ApprovedTag />}
        {carrier.broker_records?.is_in_private_network &&
          carrier.broker_records.private_network_groups && (
            <PrivateNetworkTag
              groups={carrier.broker_records.private_network_groups}
            />
          )}
      </Inline>
      <Stack space="xxsmall">
        <Stack space="none">
          {!!carrier.dba_name && (
            <Typography color="textSecondary">
              DBA name: {carrier.dba_name}
            </Typography>
          )}
          {!!carrier.us_dot && (
            <Typography color="textSecondary">
              USDOT: {carrier.us_dot}
            </Typography>
          )}
          {!!carrier.mc_number && (
            <Typography color="textSecondary">
              MC: {carrier.mc_number}
            </Typography>
          )}
          <Typography color="textSecondary">
            {carrier.email}
            {carrier.email && carrier.phone_numbers ? ' · ' : null}
            <SuspendedPhoneText phone={carrier.phone_numbers} />
          </Typography>
          <Typography color="textSecondary">
            {carrier.city && `${carrier.city}, `}{' '}
            {carrier.state && `${carrier.state}`}
          </Typography>
        </Stack>

        {isCarrierRequestsSuperPay &&
        carrier.broker_records?.superpay_requested ? (
          <CarrierRequestsSuperPay source="Carrier Search" compact={true} />
        ) : (
          carrier.is_super_pay_enabled && (
            <CarrierAcceptsSuperPay source="Carrier Search" compact={true} />
          )
        )}
        {!carrier.has_signed_up && (
          <NewCarrierWrapper>
            <Typography variant="inherit">
              Carrier is not using Super Dispatch. Send an offer to invite them.
            </Typography>
          </NewCarrierWrapper>
        )}
      </Stack>
    </Stack>
  );
}

interface SearchFMCSAButtonProps {
  onClick: () => void;
}

function SearchFMCSAButton({ onClick }: SearchFMCSAButtonProps) {
  return (
    <Button
      variant="text"
      onClick={onClick}
      startIcon={<SearchIcon style={{ color: ColorDynamic.Dark300 }} />}
      onMouseDown={(event) => {
        // Prevent blur
        event.preventDefault();
      }}
    >
      Search from FMCSA
    </Button>
  );
}

function NoCarrierFoundByUSDOT() {
  return (
    <Typography>
      Carrier not found. If carrier exists in{' '}
      <Link
        target="_blank"
        color="primary"
        rel="noopener noreferrer"
        href="https://safer.fmcsa.dot.gov/CompanySnapshot.aspx"
        onMouseDown={(event: MouseEvent<HTMLAnchorElement>) => {
          // Prevent blur
          event.preventDefault();
          window.open(event.currentTarget.href, '_blank');
        }}
      >
        SAFER
      </Link>
      , please contact customer support.
    </Typography>
  );
}

export type CarrierAutocompleteValue =
  | FmcsaInfo
  | CarrierFullInfo
  | Carrier
  | null;

interface CarrierAutocompleteProps {
  error?: string;
  hideLabel?: boolean;
  withFMCSA: boolean;
  collapse?: boolean;

  value: CarrierAutocompleteValue;
  onChange: (value: CarrierAutocompleteValue) => void;
}

interface CustomPopperProps {
  props: PopperProps;
  showApproved: boolean;
  onShowApproved: (checked: boolean) => void;
}

const CustomPopper = ({
  props,
  showApproved,
  onShowApproved,
}: CustomPopperProps) => {
  return (
    <StyledPopper {...props} placement="bottom">
      <StyledBox
        onMouseDown={(event: MouseEvent) => {
          event.preventDefault();
        }}
      >
        <Inline verticalAlign="center" space="none">
          <Switch
            checked={showApproved}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onShowApproved(event.target.checked);
              writeStorageItem(
                'search_approved_carriers',
                event.target.checked ? 'true' : 'false',
              );
            }}
          />
          <Typography>My approved carriers</Typography>
        </Inline>
      </StyledBox>
      {props.children}
    </StyledPopper>
  );
};

export function CarrierAutocomplete({
  value,
  onChange,
  withFMCSA,
  hideLabel,
  error: errorProp,
  collapse = true,
}: CarrierAutocompleteProps) {
  const uid = useUID();
  const { addSnackbar } = useSnackbarStack();
  const { parseAndFetchAuthority } = useCarrierSearchAPI();
  const [options, setOptions] = useState<
    Array<FmcsaInfo | CarrierFullInfo | Carrier>
  >([]);

  const searchApproved = useStorageValue('search_approved_carriers');
  const [showApproved, setShowApproved] = useState(searchApproved === 'true');

  const searchType = useStorageValue('carrier_search_type') || 'nameUsdot';
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query, 500).trim();

  const canUpdateCarrierProfile = useCanExecute(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
  );

  const isApproved =
    canUpdateCarrierProfile || !value
      ? true
      : Boolean(value.broker_records?.approved);
  const insuranceExpired =
    value?.broker_records &&
    isCarrierInsuranceExpired(value.broker_records.insurance_expires_at);

  const { error, isFetching, data } = useCarrierSearch(
    query.length > 0 && debouncedQuery.length > 2 ? debouncedQuery : undefined,
    formatSearchType(searchType, debouncedQuery),
    withFMCSA,
    showApproved,
  );

  const searchUSDOT = useFormikEnhanced<{ usdot: string }, FmcsaInfo>({
    initialValues: { usdot: query },
    onSubmit: ({ usdot }) => parseAndFetchAuthority(usdot),
    onSubmitSuccess(fmcsaCarrier) {
      fmcsaCarrier.carrier_type = 'FMCSA';
      setOptions([fmcsaCarrier]);
    },
    onSubmitFailure() {
      // Error throw when response is empty
      setOptions([]);
    },
  });

  useEffect(() => {
    if (data) {
      setOptions(data.objects);
    }
  }, [setOptions, data]);

  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
    }
  }, [error, addSnackbar]);

  function handleChange(
    _: ChangeEvent<{}>,
    autocompleteValue: CarrierAutocompleteValue,
  ) {
    onChange(autocompleteValue);
  }

  function handleInputChange(
    _: ChangeEvent<{}>,
    text: string,
    reason: 'input' | 'reset' | 'clear',
  ) {
    if (reason !== 'reset') {
      setQuery(text);
    }
  }

  function getOptionSelected(
    option: Carrier,
    optionValue: FmcsaInfo | CarrierFullInfo | Carrier,
  ) {
    return (
      option.guid === optionValue.guid &&
      option.name === optionValue.name &&
      optionValue.email === option.email &&
      option.us_dot === optionValue.us_dot
    );
  }

  function handleFMCSASearch() {
    searchUSDOT.handleSubmit();
  }

  return (
    <>
      {!hideLabel && (
        <Box paddingBottom="xsmall">
          <Typography id={uid} variant="body1">
            Search Carrier
          </Typography>
        </Box>
      )}

      <Columns space="xsmall" collapseBelow={collapse ? 'desktop' : undefined}>
        <Column width="content">
          <SearchTypeSelect
            searchType={searchType}
            onSelect={(val: string) => {
              writeStorageItem('carrier_search_type', val);
              setQuery('');
            }}
          />
        </Column>
        <Column>
          <Autocomplete
            open={open}
            value={value}
            options={options}
            blurOnSelect={false}
            fullWidth={true}
            onChange={handleChange}
            filterOptions={identity}
            onInputChange={handleInputChange}
            disabled={searchUSDOT.isSubmitting}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={getOptionSelected}
            loading={isFetching || searchUSDOT.isSubmitting}
            loadingText={<Typography>Loading...</Typography>}
            getOptionLabel={getOptionLabel}
            renderOption={(option: Carrier) => (
              <CarrierOption carrier={option} />
            )}
            PopperComponent={(props: PopperProps) => (
              <CustomPopper
                showApproved={showApproved}
                onShowApproved={setShowApproved}
                props={props}
              />
            )}
            noOptionsText={
              <Typography>
                {query.length <= 2 ? (
                  searchType === 'mcNumber' ? (
                    'MC-1234567'
                  ) : (
                    '3 symbols to search'
                  )
                ) : withFMCSA && isUSDOT(debouncedQuery) ? (
                  searchUSDOT.status.type === 'initial' ? (
                    <SearchFMCSAButton onClick={handleFMCSASearch} />
                  ) : (
                    <NoCarrierFoundByUSDOT />
                  )
                ) : (
                  'No Option'
                )}
              </Typography>
            }
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus={true}
                fullWidth={true}
                helperText={errorProp}
                variant="outlined"
                placeholder={
                  searchType === 'mcNumber'
                    ? 'MC-1234567'
                    : '3 symbols to search'
                }
                error={!isApproved || insuranceExpired || !!errorProp}
                inputProps={{
                  ...params.inputProps,
                  'aria-labelledby': uid,
                }}
                InputProps={
                  searchUSDOT.isSubmitting
                    ? {
                        endAdornment: (
                          <CircularProgress
                            variant="indeterminate"
                            color="primary"
                            size={20}
                          />
                        ),
                      }
                    : params.InputProps
                }
              />
            )}
          />
        </Column>
      </Columns>
      {value && value.carrier_type !== 'BROKER' && (
        <Box marginTop="small">
          <BasicCarrierProfile carrier={value} profile={value.broker_records} />
        </Box>
      )}
    </>
  );
}

interface CarrierAutocompleteFieldProps {
  name: string;
  withFMCSA: boolean;
  validate?: FieldValidator;
  onSelect?: () => void;
}

export function CarrierAutocompleteField({
  name,
  withFMCSA,
  validate,
  onSelect,
}: CarrierAutocompleteFieldProps) {
  const [{ value }, , { setValue }] = useField<CarrierAutocompleteValue | null>(
    { name, validate },
  );
  const { errors } = useFormikContext();
  const error = get(errors, `${name}.guid`);

  return (
    <CarrierAutocomplete
      error={error}
      value={value}
      withFMCSA={withFMCSA}
      onChange={(autocompleteValue) => {
        if (onSelect != null && autocompleteValue) onSelect();
        setValue(autocompleteValue);
      }}
    />
  );
}

interface SearchTypeSelectProps {
  searchType: string;
  onSelect: (val: string) => void;
}

export function SearchTypeSelect({
  searchType,
  onSelect,
}: SearchTypeSelectProps) {
  return (
    <TextField
      select={true}
      variant="outlined"
      fullWidth={true}
      value={searchType}
      onChange={(event) => {
        onSelect(event.target.value);
      }}
    >
      <MenuItem value="nameUsdot">Name & USDOT</MenuItem>
      <MenuItem value="mcNumber">MC Number</MenuItem>
      <MenuItem value="email">Email</MenuItem>
      <MenuItem value="phone">Phone</MenuItem>
    </TextField>
  );
}
