import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Form } from 'formik';
import { useInitialValues } from 'shared/form/AppFormik';
import { camelCaseFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { VerificationFormikProvider } from '../core/VerificationCloseConfirmation';
import { YourCompanyForm } from '../core/YourCompanyForm';
import {
  useVerificationApplication,
  useVerificationApplicationAPI,
} from '../data/VerificationApplicationAPI';
import { verificationCompanyInformationSchema } from '../data/VerificationApplicationDTO';
import {
  VerificationApplicationStepsFormBox,
  VerificationApplicationStepsWrapper,
} from './VerificationApplicationLayout';
import { VerificationStepper } from './VerificationStepper';

interface VerificationApplicationCompanyProps {
  onSubmitSuccess: () => void;
}

export function VerificationApplicationCompany({
  onSubmitSuccess,
}: VerificationApplicationCompanyProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data, isLoading } = useVerificationApplication({
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
  const { updateCompanyInformation } = useVerificationApplicationAPI();

  const initialValues = useInitialValues(
    verificationCompanyInformationSchema,
    data,
  );

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: verificationCompanyInformationSchema,
    getFormErrors: camelCaseFormErrorsGetter,
    onSubmit(values) {
      return updateCompanyInformation(values);
    },
    onSubmitSuccess() {
      onSubmitSuccess();
    },
    onSubmitFailure() {
      addSnackbar(
        'Please ensure all required fields are filled out correctly',
        { variant: 'error' },
      );
    },
  });

  return (
    <VerificationFormikProvider value={formik}>
      <VerificationApplicationStepsWrapper step={1}>
        <VerificationStepper step={1} />
        {isLoading ? (
          <LoadingContainer />
        ) : (
          <VerificationApplicationStepsFormBox>
            <Form>
              <YourCompanyForm />
            </Form>
          </VerificationApplicationStepsFormBox>
        )}
      </VerificationApplicationStepsWrapper>
    </VerificationFormikProvider>
  );
}
