import { SidebarContainer } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { Outlet, RouteObject, useOutletContext } from 'react-router-dom';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { TerminalCreatePage } from './create/TerminalCreatePage';
import { TerminalDetailsPage } from './details/TerminalDetailsPage';
import { TerminalEmptyState } from './list/TerminalEmptyState';
import { TerminalsList } from './list/TerminalsList';
import { useTerminals } from './list/TerminalsListState';
import { TerminalsSelection } from './list/TerminalsSelection';
import { TerminalsImportPage } from './TerminalsImportPage';
import { useTerminalsNavigation } from './TerminalsRouteHelpers';

export const terminalsRoute: RouteObject = {
  path: 'terminals',
  Component: TerminalsPage,
  children: [
    {
      index: true,
      element: <TerminalsIndexPage />,
    },
    {
      path: 'create',
      element: <TerminalCreatePage />,
    },
    {
      path: 'import',
      element: <TerminalsImportPage />,
    },
  ],
};

interface TerminalsPageContext {
  terminals: TerminalDTO[];
  selectedTerminals: Set<number>;
  setSelectedTerminals: (terminals: Set<number>) => void;
}

function useTerminalsPageContext() {
  return useOutletContext<TerminalsPageContext>();
}

function TerminalsIndexPage() {
  const { terminals, selectedTerminals, setSelectedTerminals } =
    useTerminalsPageContext();

  const {
    terminalsSearchParams: { query },
  } = useTerminalsNavigation();

  if (selectedTerminals.size > 0) {
    return (
      <TerminalsSelection
        count={selectedTerminals.size}
        onClear={() => {
          setSelectedTerminals(new Set());
        }}
      />
    );
  }

  if (!query && terminals.length === 0) {
    return <TerminalEmptyState />;
  }

  return <TerminalDetailsPage terminals={terminals} />;
}

function TerminalsPage() {
  useDocumentTitle('Terminals');
  useTrackEvent('[STMS] Viewed Terminals Page');

  const {
    data,
    list: terminals = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useTerminals();

  const [selectedTerminals, setSelectedTerminals] = useState<Set<number>>(
    new Set(),
  );

  function getTotalTerminalsCount() {
    const lastPage = data?.pages[data.pages.length - 1];
    return lastPage?.pagination.total_objects ?? 0;
  }

  return (
    <PageLayout>
      <SidebarContainer
        sidebar={
          <TerminalsList
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            terminals={terminals}
            total={getTotalTerminalsCount()}
            selectedTerminals={selectedTerminals}
            onSelectedTerminalsChange={setSelectedTerminals}
          />
        }
      >
        <Outlet
          context={
            {
              terminals,
              selectedTerminals,
              setSelectedTerminals,
            } as TerminalsPageContext
          }
        />
      </SidebarContainer>
    </PageLayout>
  );
}
