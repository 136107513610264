import { set } from 'lodash';

export function ensureError(value: unknown): Error {
  if (value instanceof Error) {
    return value;
  }

  const error = new Error();

  if (value && typeof value === 'object' && !Array.isArray(value)) {
    Object.assign(error, value);
  } else if (typeof value === 'string') {
    error.message = value;
  } else {
    set(error, 'data', value);
  }

  return error;
}
