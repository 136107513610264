import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  CheckboxField,
  DrawerActions,
  DrawerTitle,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProps, FormikProvider } from 'formik';
import { useEffect } from 'react';
import { FormikDrawer } from 'shared/form/FormikDrawer';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderActionSource } from '../OrderActionsAnalytics';
import { useEscalationAPI } from './data/EscalationAPI';
import {
  SubmitEscalationDTO,
  submitEscalationSchema,
} from './data/EscalationDTO';

const escalationReasons = [
  { name: 'CHARGES_DUE', label: 'Charges Due' },
  { name: 'VEHICLE_IS_NOT_AT_LOCATION', label: 'Vehicle is Not at Location' },
  { name: 'INCORRECT_PHONE_NUMBER', label: 'Incorrect Phone Number' },
  { name: 'LOCATION_WILL_NOT_RELEASE', label: 'Location Will Not Release' },
  { name: 'LOCATION_CHANGED', label: 'Location Changed' },
  { name: 'NO_RETURNED_CALLS_OR_EMAILS', label: 'No Returned Calls/Emails' },
  { name: 'OTHER', label: 'Other' },
] as const;

const StyledBox = styled.div`
  display: flex;
  padding: 16px 32px;
  align-items: center;
  align-self: stretch;
`;

const StyledActionsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  align-self: stretch;
`;

interface ReasonCheckboxFieldProps {
  reason: typeof escalationReasons[number];
  formik: FormikProps<SubmitEscalationDTO>;
}

function ReasonCheckboxField({ reason, formik }: ReasonCheckboxFieldProps) {
  return (
    <CheckboxField
      onChange={(event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
          void formik.setFieldValue('reasons', {
            ...formik.values.reasons,
            [reason.name]: '',
          });
        } else {
          const { [reason.name]: _, ...rest } = formik.values.reasons;
          void formik.setFieldValue('reasons', rest);
        }
      }}
      name={reason.name}
      label={<Typography>{reason.label}</Typography>}
      disabled={formik.isSubmitting}
    />
  );
}

interface SubmitEscalationDrawerContentProps {
  order: Order;
  source: OrderActionSource;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

function SubmitEscalationDrawerContent({
  order,
  source,
  onClose,
  onSubmitSuccess,
}: SubmitEscalationDrawerContentProps) {
  const { submitEscalation } = useEscalationAPI();
  const { addSnackbar } = useSnackbarStack();
  const shipperName = order.customer?.name;

  const formik = useFormikEnhanced<SubmitEscalationDTO, unknown>({
    initialValues: {
      reasons: {},
      order_id: order.id,
    },
    validationSchema: submitEscalationSchema,
    onSubmit: (values) => {
      return submitEscalation(values);
    },
    onSubmitSuccess: (_, values) => {
      onSubmitSuccess?.();
      onClose();
      addSnackbar('Escalation was successfully submitted', {
        variant: 'success',
      });
      trackEventLegacy('Broker Submited Escalation', {
        utm_source: source,
        values,
      });
    },
    onSubmitFailure: (error) => {
      onClose();
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <DrawerTitle
          title="Submit Escalation"
          endAction={
            <IconButton edge="end" onClick={onClose}>
              <Close aria-label="close" />
            </IconButton>
          }
        />

        <StyledBox>
          <Stack space="xsmall">
            <TextBox variant="heading-6" color="secondary">
              What is the reason?
            </TextBox>
            {escalationReasons.map((reason) => (
              <Stack key={reason.name} space="xsmall">
                <ReasonCheckboxField reason={reason} formik={formik} />
                {Object.keys(formik.values.reasons).includes(reason.name) && (
                  <Box paddingLeft="large">
                    <FormikMaxLengthTextField
                      name={`reasons.${reason.name}`}
                      label="Details"
                      maxLength={500}
                      fullWidth={true}
                      multiline={true}
                      rows={4}
                      disabled={formik.isSubmitting}
                    />
                  </Box>
                )}
              </Stack>
            ))}
          </Stack>
        </StyledBox>

        <DrawerActions>
          <StyledActionsBox>
            <Typography color="textSecondary">
              The escalation will be submitted to {shipperName}, and the shipper
              will be notified.
            </Typography>

            <Button
              type="submit"
              pending={formik.isSubmitting}
              disabled={!formik.dirty}
            >
              Save & Submit
            </Button>
          </StyledActionsBox>
        </DrawerActions>
      </Form>
    </FormikProvider>
  );
}

interface SubmitEscalationDrawerProps {
  order?: Order;
  source: OrderActionSource;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

export function SubmiteEscalationDrawer({
  order,
  source,
  onClose,
  onSubmitSuccess,
}: SubmitEscalationDrawerProps) {
  const open = !!order;

  useEffect(() => {
    if (order?.id) {
      trackEventLegacy('Broker Clicked Submit Escalation Option', {
        order_id: order.id,
      });
    }
  }, [order]);

  return (
    <FormikDrawer open={open} onClose={onClose}>
      {open && (
        <SubmitEscalationDrawerContent
          order={order}
          source={source}
          onClose={onClose}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
    </FormikDrawer>
  );
}
