import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { countryFieldOptions } from 'shared/form/CountryFieldOptions';
import {
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType, string } from 'yup';
import { useInvalidateSubscriptionPlanQuery } from './useSubscriptionPlan';

export const billingAddressSchema = yupObject({
  address: string().required(),
  address_second_line: string().nullable().optional(),
  country: yupEnum([null, ...countryFieldOptions.keys()], null).required(),
  city: string().required(),
  state: string().required(),
  zip: string().required(),
});

export const paymentMethodSchema = yupObject({
  card_number_masked: yupString().transform((maskedCardNumber: string) => {
    const last4 = maskedCardNumber.slice(-4);
    return `**** **** **** ${last4}`;
  }),
  expiry_month: yupNumber(),
  expiry_year: yupNumber(),
});

export type BillingAddress = InferType<typeof billingAddressSchema>;
export type PaymentMethod = InferType<typeof paymentMethodSchema>;

const BILLING_ADDRESS_CACHE_KEY = [
  'payment-details',
  'billing-address',
] as const;

const PAYMENT_METHOD_CACHE_KEY = ['payment-details', 'payment-method'] as const;

export function useInvalidatePaymentMethodQuery() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(PAYMENT_METHOD_CACHE_KEY);
}

export function usePaymentDetails() {
  const { request, requestResource } = useAPI();
  const queryClient = useQueryClient();
  const invalidateSubscriptionPlanQuery = useInvalidateSubscriptionPlanQuery();

  const { data: paymentMethod, isLoading: isPaymentMethodLoading } =
    useAPIQuery(
      PAYMENT_METHOD_CACHE_KEY,
      () =>
        requestResource(
          'GET /internal/billing/payment-method',
          (data) => data as PaymentMethod,
        ),
      {
        schema: paymentMethodSchema,
        refetchOnWindowFocus: false,
      },
    );

  const { data: billingAddress, isLoading: isBillingAddressLoading } =
    useAPIQuery(
      BILLING_ADDRESS_CACHE_KEY,
      () =>
        requestResource(
          'GET /internal/billing/address',
          (data) => data as BillingAddress,
        ),
      {
        schema: billingAddressSchema,
        refetchOnWindowFocus: false,
      },
    );

  const updateBilingAddress = (values: BillingAddress) =>
    request('PUT /internal/billing/address', {
      json: values,
    }).then(() => {
      void queryClient.invalidateQueries(BILLING_ADDRESS_CACHE_KEY);
      void invalidateSubscriptionPlanQuery();
    });

  return {
    paymentMethod,
    isPaymentMethodLoading,
    billingAddress,
    isBillingAddressLoading,
    updateBilingAddress,
  };
}
