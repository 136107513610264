import mitt from 'mitt';
import { useEffect, useState } from 'react';
import { APIErrorResponse } from './API';

const emitter = mitt();

const MFA_ERROR_ID = 'CENTRAL_DISPATCH_MFA_REQUIRED';
const EVENT_NAME = 'central-dispatch-mfa-required';

export function maybeEmitCentralDispatchMfaError(error: APIErrorResponse) {
  if (error.data.error_id !== MFA_ERROR_ID) {
    return;
  }

  emitter.emit(EVENT_NAME, error);
}

export function useCentralDispatchMfaError() {
  const [hasCentralDispatchMfaError, setHasCentralDispatchMfaError] =
    useState(false);

  useEffect(() => {
    const handleEvent = () => {
      setHasCentralDispatchMfaError(true);
    };

    emitter.on(EVENT_NAME, handleEvent);

    return () => {
      emitter.off(EVENT_NAME, handleEvent);
    };
  }, []);

  return [hasCentralDispatchMfaError, setHasCentralDispatchMfaError] as const;
}
