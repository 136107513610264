import { Card, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ColorDynamic, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { NumericFormat } from 'react-number-format';
import { useDynamicStatistics } from 'shared/helpers/superpay/SuperPayAPI';
import styled from 'styled-components';
import { SuperPayDynamicStatisticsDTO } from '../data/SuperPayDTO';

const StatisticMeasurement = styled.div`
  font-size: 32px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 32px;
  }
`;

const StatisticValue = styled.div`
  font-size: 56px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 40px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 56px;
  }
`;

const StatisticTitle = styled.div`
  background-image: linear-gradient(270deg, #e053b9 -0.59%, #fd4b4b 100%);
  display: flex;
  align-items: baseline;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  line-height: 1.1;
`;

const CardStyled = styled(Card)`
  border-radius: 8px;
  min-height: 252px;
  padding: 24px;
`;

const SkeletonStyled = styled(Skeleton)<{ $borderRadius?: string }>`
  background-color: ${ColorDynamic.Dark300};
  border-radius: ${({ $borderRadius = '6px' }) => $borderRadius};
`;

function StatisticCard({ children }: { children: JSX.Element }) {
  return (
    <Column width="1/2">
      <CardStyled>{children}</CardStyled>
    </Column>
  );
}

function LoadingCard() {
  return (
    <Box marginTop="medium">
      <Stack space="medium">
        <SkeletonStyled width="40%" height="32px" $borderRadius="24px" />
        <Box>
          <SkeletonStyled width="100%" height="12px" />
          <SkeletonStyled width="70%" height="12px" />
          <SkeletonStyled width="80%" height="12px" />
          <SkeletonStyled width="40%" height="12px" />
        </Box>
      </Stack>
    </Box>
  );
}

function LoadingContent() {
  return (
    <>
      <StatisticCard>
        <LoadingCard />
      </StatisticCard>
      <StatisticCard>
        <LoadingCard />
      </StatisticCard>
    </>
  );
}

function StaticContent() {
  return (
    <>
      <StatisticCard>
        <Stack space="xxsmall">
          <StatisticTitle>
            <StatisticValue>7.7</StatisticValue>
            <StatisticMeasurement>K</StatisticMeasurement>
          </StatisticTitle>
          <Stack>
            <Typography variant="h3">Carriers use SuperPay</Typography>
            <Typography color="textSecondary">
              Streamline payment processing with over{' '}
              <TextBox variant="body-semibold">7.7K carriers</TextBox> already
              setup to receive payments with SuperPay.
            </Typography>
          </Stack>
        </Stack>
      </StatisticCard>
      <StatisticCard>
        <Stack space="xxsmall">
          <StatisticTitle>
            <StatisticValue>40</StatisticValue>
            <StatisticMeasurement>hrs</StatisticMeasurement>
          </StatisticTitle>
          <Stack>
            <Typography variant="h3">
              of Your Time Back with Integrated Payments
            </Typography>
            <Typography color="textSecondary">
              Shippers using SuperPay save this much time{' '}
              <TextBox variant="body-semibold">on average every month</TextBox>{' '}
              by streamlining their payment process.
            </Typography>
          </Stack>
        </Stack>
      </StatisticCard>
    </>
  );
}

function DynamicContent({ value }: { value: SuperPayDynamicStatisticsDTO }) {
  const hours = value.saved_hours >= 40 ? '40+' : value.saved_hours;
  return (
    <>
      <StatisticCard>
        <Stack space="xxsmall">
          <StatisticTitle>
            <StatisticValue>
              {value.onboarded_carriers_percentage}
            </StatisticValue>
            <StatisticMeasurement>%</StatisticMeasurement>
          </StatisticTitle>
          <Stack>
            <Typography variant="h3">of Your Carriers use SuperPay</Typography>
            <Typography color="textSecondary">
              Process payments seamlessly with{' '}
              <TextBox variant="body-semibold">
                <NumericFormat
                  displayType="text"
                  value={value.onboarded_carriers_count}
                  thousandSeparator={true}
                />{' '}
                of your carriers
              </TextBox>{' '}
              already using SuperPay to receive payments.
            </Typography>
          </Stack>
        </Stack>
      </StatisticCard>
      <StatisticCard>
        <Stack space="xxsmall">
          <StatisticTitle>
            <StatisticValue>{hours}</StatisticValue>
            <StatisticMeasurement>hrs</StatisticMeasurement>
          </StatisticTitle>
          <Stack>
            <Typography variant="h3">
              of Your Time Back with Integrated Payments
            </Typography>
            <Typography color="textSecondary">
              This is how much time SuperPay would have saved you over the last
              30 days on your{' '}
              <TextBox variant="body-semibold">
                <NumericFormat
                  displayType="text"
                  value={value.delivered_orders_count}
                  thousandSeparator={true}
                />{' '}
                delivered orders.
              </TextBox>
            </Typography>
          </Stack>
        </Stack>
      </StatisticCard>
    </>
  );
}

export function SuperPayStatistics() {
  const { data, isLoading } = useDynamicStatistics();
  return (
    <Box width="100%">
      <Columns space="small" collapseBelow="tablet">
        {isLoading ? (
          <LoadingContent />
        ) : data?.is_dynamic ? (
          <DynamicContent value={data} />
        ) : (
          <StaticContent />
        )}
      </Columns>
    </Box>
  );
}
