import { useOrderRequests } from 'orders/data/OrderRequestsAPI';
import { useEffect } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { OrderRequestRecommendations } from './OrderRequestRecommendations';
import {
  OrderRequestsEmpty,
  OrderRequestsWhatHappensNext,
} from './OrderRequestsEmpty';
import { OrderRequestsList } from './OrderRequestsList';
import { OrderRequestsLoadingState } from './OrderRequestsLoadingState';

interface OrderRequestsProps {
  order: Order;
  onSubmitSuccess: (order: Order) => void;
}

export function OrderRequests({ order, onSubmitSuccess }: OrderRequestsProps) {
  const { data: requests, isLoading } = useOrderRequests(order.guid);

  useEffect(() => {
    if (requests) {
      trackEvent('Shipper Opened Carrier Requests Drawer', {
        requests_count: requests.objects.length,
      });
    }
  }, [requests]);

  if (isLoading) {
    return <OrderRequestsLoadingState />;
  }

  if (requests?.objects.length) {
    return (
      <>
        <OrderRequestsList order={order} onSubmitSuccess={onSubmitSuccess} />
        {order.is_posted_to_loadboard && (
          <OrderRequestRecommendations order={order} />
        )}
      </>
    );
  }

  if (order.is_posted_to_loadboard) {
    return (
      <>
        <OrderRequestsWhatHappensNext />
        <OrderRequestRecommendations order={order} />
      </>
    );
  }

  return <OrderRequestsEmpty />;
}
