import { Link } from '@material-ui/core';
import { Inline, Stack, Tag } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import {
  CanInstantlyBookTag,
  PrivateNetworkTag,
  UnverifiedTag,
  VerifiedTag,
} from 'core/CarrierTags';
import { Link as RouterLink } from 'react-router-dom';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { addSourceParams } from 'shared/helpers/SourceManagmentLegacy';
import { ApprovedIcon } from 'shared/icons/ApprovedIcon';
import { CertificateIcon } from 'shared/icons/CertificateIcon';
import { ErrorInfoIcon } from 'shared/icons/ErrorInfoIcon';
import { IconBox } from 'shared/icons/IconBox';
import { Carrier, CarrierBrokerPreferences } from 'shared/types/carrier';
import { TextOverflow } from 'shared/ui/TextOverflow';
import { CarrierAcceptsSuperPay } from '../superpay/CarrierAcceptsSuperPay';
import { CarrierRequestsSuperPay } from '../superpay/CarrierRequestsSuperPay';
import { getInsuranceInfo } from './insurance/CargoInsuranceUtils';
import { isCarrierInsuranceExpired } from './insurance/ExpirationDates';
import { CertificateExpirationDetails } from './insurance/InsuranceExpirationInfo';
import { RatingStats } from './ratings/RatingStats';

export interface BasicCarrierProfileProps {
  profile?: CarrierBrokerPreferences | null;
  carrier: Carrier | null;
}

function NotApprovedCarrier() {
  return (
    <TextBox color="red">
      Carrier needs to be Approved. Request manager to mark as Approved.{' '}
      <Link
        color="primary"
        target="_blank"
        rel="noopener noreferrer"
        href="https://support.superdispatch.com/hc/articles/35693050620179-Understanding-Carrier-Statuses-in-Shipper-TMS"
      >
        Learn more
      </Link>
    </TextBox>
  );
}

const BrokerRecords = ({ carrier }: { carrier?: Carrier | null }) => {
  const canUpdateCarrierProfile = useCanExecute(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
  );

  const { insuranceExpiresAt, insuranceCertFileUrl } =
    getInsuranceInfo(carrier);

  const profile = carrier?.broker_records;

  if (!profile) return !canUpdateCarrierProfile ? <NotApprovedCarrier /> : null;

  const internalInsuranceExpired = isCarrierInsuranceExpired(
    profile.insurance_expires_at,
  );

  return (
    <Stack space="small">
      <Inline>
        {profile.approved && (
          <Tag color="grey" variant="subtle">
            <IconBox>
              <ApprovedIcon />
            </IconBox>
            Approved
          </Tag>
        )}

        {!profile.approved && !canUpdateCarrierProfile && (
          <NotApprovedCarrier />
        )}

        {profile.preferred && <CanInstantlyBookTag />}

        {profile.is_in_private_network && profile.private_network_groups && (
          <PrivateNetworkTag groups={profile.private_network_groups} />
        )}

        {profile.insurance_certificate_holder && (
          <Tag color="grey" variant="subtle">
            <IconBox>
              <CertificateIcon />
            </IconBox>
            Certificate Holder
          </Tag>
        )}

        {internalInsuranceExpired && (
          <Tag color="grey" variant="subtle">
            <IconBox>
              <ErrorInfoIcon />
            </IconBox>
            Expired Insurance
          </Tag>
        )}

        {profile.in_blacklist && (
          <Tag color="grey" variant="subtle">
            <IconBox>
              <ErrorInfoIcon />
            </IconBox>
            Blocklisted
          </Tag>
        )}
      </Inline>

      {!!insuranceExpiresAt && (
        <CertificateExpirationDetails
          insuranceExpiresAt={insuranceExpiresAt}
          certificateFileURl={insuranceCertFileUrl}
        />
      )}
    </Stack>
  );
};

export function BasicCarrierProfile({
  profile,
  carrier,
}: BasicCarrierProfileProps) {
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );
  const canUpdateCarrierProfile = useCanExecute(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
  );

  const { insuranceExpiresAt } = getInsuranceInfo(carrier);
  const internalInsuranceExpired = isCarrierInsuranceExpired(
    profile?.insurance_expires_at,
  );

  const hasBrokerRecords =
    profile?.approved ||
    (!profile?.approved && !canUpdateCarrierProfile) ||
    profile?.preferred ||
    profile?.insurance_certificate_holder ||
    internalInsuranceExpired ||
    profile?.in_blacklist ||
    insuranceExpiresAt;

  return (
    <Stack space="small">
      {carrier && (
        <Stack space="xxsmall">
          <Inline>
            <TextOverflow size={280}>
              <Link
                component={RouterLink}
                aria-label="View Carrier Profile"
                target="_blank"
                variant="h5"
                to={addSourceParams(
                  carrier.guid
                    ? `/manage-carriers/${carrier.guid}`
                    : `/manage-carriers/usdot/${carrier.us_dot || ''}`,
                )}
              >
                {carrier.name}
              </Link>
            </TextOverflow>
            {carrier.is_super ? <VerifiedTag /> : <UnverifiedTag />}
            {carrier.carrier_type !== 'INTERNAL' && (
              <RatingStats
                ratingStats={carrier.rating_stats}
                carrierGuid={carrier.guid}
              />
            )}
          </Inline>
          {isCarrierRequestsSuperPay &&
          carrier.broker_records?.superpay_requested ? (
            <CarrierRequestsSuperPay source="Single Send Offer Drawer" />
          ) : (
            carrier.is_super_pay_enabled && (
              <CarrierAcceptsSuperPay source="Single Send Offer Drawer" />
            )
          )}
        </Stack>
      )}

      {hasBrokerRecords && <BrokerRecords carrier={carrier} />}
    </Stack>
  );
}
