import { Drawer, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Box } from '@rebass/grid';
import { useFormikEnhanced } from '@superdispatch/forms';
import { PaymentMethod, PaymentTerm } from '@superdispatch/sdk';
import {
  ColorDynamic,
  Column,
  Columns,
  DrawerActions,
  DrawerTitle,
  Inline,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { CarrierAutocompleteField } from 'core/CarrierAutocomplete';
import { isCarrierInsuranceExpired } from 'core/insurance/ExpirationDates';
import { Form, FormikProvider } from 'formik';
import { useInvalidateCurrentOnboardingStep } from 'onboarding/OnboardingAPI';
import { Collapse } from 'orders/view/Collapse';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCarrierHasLimitedExperience } from 'shared/api/CarrierSearchAPI';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { trackDurationAnalyticsLegacy } from 'shared/helpers/AnalyticsHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { SourceManagerLegacy } from 'shared/helpers/SourceManagmentLegacy';
import { Carrier } from 'shared/types/carrier';
import { CreateOfferPayload, Offer, OfferFormParams } from 'shared/types/offer';
import Order from 'shared/types/order';
import { Step } from 'shared/types/step';
import styled from 'styled-components';
import { useSingleOrderActionAPI } from '../../data/OrderActionAPI';
import {
  OrderActionSource,
  SendOfferCarrierSource,
  trackOderActionEvent,
} from '../actions/OrderActionsAnalytics';
import { UnverifiedCarrierWarningDialog } from '../actions/UnverifiedCarrierWarningDialog';
import { LimitedExperienceDialog } from '../order-requests/LimitedExperienceDialog';
import { CarrierNetworkTabs } from './carrier-network/CarrierNetworkTabs';
import { InspectionTypeWarning } from './InspectionTypeWarning';
import { SendOfferFields } from './SendOfferFields';
import { SendOfferNewCarrierFields } from './SendOfferNewCarrierFields';

export const createOffer = (
  formParams: OfferFormParams,
): CreateOfferPayload => ({
  carrier_guid: formParams.guid,
  carrier_type: formParams.carrier_type,
  carrier_name: formParams.dba_name || formParams.name,
  carrier_contact_name: formParams.contact_name,
  carrier_email: formParams.email,
  carrier_phone: formParams.phone_numbers,
  carrier_usdot: formParams.us_dot,
  carrier_address: formParams.address,
  carrier_city: formParams.city,
  carrier_state: formParams.state,
  carrier_zip: formParams.zip,
  pickup: formParams.pickup,
  delivery: formParams.delivery,
  price: formParams.price,
  payment_terms: formParams.payment_terms,
  payment_method: formParams.payment_method,
});

export interface SendOfferFormValues {
  carrier: Carrier | null;
  pickup: Partial<Step>;
  delivery: Partial<Step>;
  price?: number;
  payment_terms?: PaymentTerm | null;
  payment_method?: PaymentMethod | null;
  search_radius: number;
  search_recency: number;
}

interface SendOfferDrawerProps {
  order: Order | undefined;
  onClose: () => void;
  onSubmitSuccess: () => void;
  source: OrderActionSource;
}

const DividerLine = styled.div`
  height: 1px;
  background: ${ColorDynamic.Silver400};
`;

const DividerWrap = styled.div`
  padding: 24px 0 16px;
`;

const Divider = () => (
  <DividerWrap>
    <Columns align="center">
      <Column width="fluid">
        <DividerLine />
      </Column>
      <Column width="content">
        <Typography color="textSecondary" style={{ padding: '0 16px' }}>
          or select Carrier below
        </Typography>
      </Column>
      <Column width="fluid">
        <DividerLine />
      </Column>
    </Columns>
  </DividerWrap>
);

const StyledDrawer = styled(Drawer)`
  & .SD-DrawerTitle-toolbar.MuiToolbar-gutters {
    padding-left: 16px;
  }

  @media (min-width: 600px) {
    & .MuiDrawer-paper {
      min-width: 600px;
    }

    & .SD-DrawerTitle-toolbar.MuiToolbar-gutters {
      padding-left: 32px;
    }
  }
`;

export function SingleSendOfferDrawer({
  source,
  order,
  onClose,
  onSubmitSuccess,
}: SendOfferDrawerProps) {
  const formID = useUID();
  const { user } = useUserState();
  const { addSnackbar } = useSnackbarStack();
  const { sendOffer } = useSingleOrderActionAPI();
  const isDatesExpanded = useStorageValue('send_offer_dates_expanded');
  const [expanded, toggleExpand] = useState(isDatesExpanded === 'true');
  const drawerRef = useRef<HTMLDivElement>(null);
  const [currentModal, setCurrentModal] = useState<
    'unverified-carrier' | 'limited-experience' | undefined
  >();
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';

  const canUpdateCarrierProfile = useCanExecute(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
  );

  const initialValues = useMemo<SendOfferFormValues>(
    () => ({
      carrier: null,
      price: order?.price,
      payment_terms:
        !order?.payment?.terms && order?.payment?.method === 'superpay'
          ? '1_3_days'
          : order?.payment?.terms,
      payment_method: order?.payment?.method,
      pickup: {
        date_type: order?.pickup?.date_type,
        scheduled_at: order?.pickup?.scheduled_at,
      },
      delivery: {
        date_type: order?.delivery?.date_type,
        scheduled_at: order?.delivery?.scheduled_at,
      },
      search_radius: 100,
      search_recency: 90,
    }),
    [order],
  );

  const [openedTime, setOpenedTime] = useState<number | undefined>(undefined);
  const carrierSelectedTimeRef = useRef<number>();
  const carrierSelectedSourceRef = useRef<SendOfferCarrierSource>();
  const carrierIsPreviustlyDispatchedRef = useRef<boolean>();
  const isPaymentMethodAndTermsAdoptionEnabled = useFeatureToggle(
    'payment_method_terms_adoption.enabled.ui',
  );

  useEffect(() => {
    setOpenedTime(Date.now());
  }, [order?.guid]);

  const invalidateCurrentOnboardingStep = useInvalidateCurrentOnboardingStep();

  const form = useFormikEnhanced<SendOfferFormValues, Offer>({
    key: order,
    initialValues,
    onSubmit({ carrier, search_radius, search_recency, ...values }) {
      if (isPaymentMethodAndTermsAdoptionEnabled) {
        if (!values.payment_method) {
          return Promise.reject(new Error('Payment method is required'));
        }

        if (!values.payment_terms) {
          return Promise.reject(new Error('Payment terms are required'));
        }
      }

      if (carrier != null && order) {
        const formParams = { ...carrier, ...values };

        const offer = createOffer(formParams);

        return sendOffer(order.id, offer);
      }

      return Promise.reject(new Error('Order not found'));
    },
    onSubmitSuccess(response, { carrier }) {
      if (order && carrier) {
        trackOderActionEvent({
          name: '[STMS] Sent Load Offer',
          order,
          carrier: {
            ...carrier,
            is_previously_dispatched: carrierIsPreviustlyDispatchedRef.current,
          },
          source,
          user,
          offer: response,
          carrierSource: carrierSelectedSourceRef.current,
          datesExpanded: expanded,
        });

        addSnackbar('Offer Sent', {
          variant: 'success',
        });
        onSubmitSuccess();

        invalidateCurrentOnboardingStep();
      }
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });

      if (error.message.includes('pickup.scheduledAt')) {
        form.setFieldError('pickup.scheduled_at', ' ');
        writeStorageItem('send_offer_dates_expanded', 'true');
        toggleExpand(true);
      }
    },
  });

  const {
    setFieldValue,
    isValid,
    values: { carrier, search_radius, search_recency, payment_method },
  } = form;

  const { data: limitedExperience } = useCarrierHasLimitedExperience(
    carrier?.guid,
  );

  const insuranceExpired =
    carrier?.broker_records &&
    isCarrierInsuranceExpired(carrier.broker_records.insurance_expires_at);

  const notApprovedOrInsuranceExpiredOrBlacklisted = Boolean(
    !carrier ||
      !carrier.broker_records?.approved ||
      insuranceExpired ||
      carrier.broker_records.in_blacklist,
  );

  function checkIsSendButtonDisabled() {
    if (!isValid) {
      return true;
    }

    if (
      !canUpdateCarrierProfile &&
      notApprovedOrInsuranceExpiredOrBlacklisted
    ) {
      return true;
    }

    if (!carrier) {
      return true;
    }

    if (carrier.has_signed_up) {
      return !carrier.guid;
    }

    return !carrier.phone_numbers || !carrier.email;
  }

  function handleSubmit() {
    void form.submitForm();
    trackDurationAnalyticsLegacy(
      'Clicked Send Load Offer',
      carrierSelectedTimeRef.current as number,
      {
        is_previous_dispatched_carrier:
          carrierIsPreviustlyDispatchedRef.current,
        carrier_selected_from: carrierSelectedSourceRef.current,
        is_posted_to_loadboard: order?.is_posted_to_loadboard,
        is_posted_to_centraldispatch: order?.is_posted_to_centraldispatch,
        searchRadius: search_radius,
        searchRecency: search_recency,
      },
    );
  }

  function handleCarrierSelect(selectedCarrier: Carrier | null) {
    setFieldValue(
      'carrier',
      !selectedCarrier || selectedCarrier.guid === carrier?.guid
        ? null
        : selectedCarrier,
    );
  }

  const trackSelectedCarrier = (
    name: string,
    carrierSource: SendOfferCarrierSource,
    properties?: Record<string, unknown>,
  ) => {
    if (openedTime) {
      trackDurationAnalyticsLegacy(name, openedTime, properties);
    }

    carrierSelectedTimeRef.current = Date.now();
    carrierSelectedSourceRef.current = carrierSource;
  };

  useEffect(() => {
    if (carrier?.guid) {
      drawerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [carrier?.guid]);

  return (
    <>
      <UnverifiedCarrierWarningDialog
        open={currentModal === 'unverified-carrier'}
        onClose={() => setCurrentModal(undefined)}
        onContinue={() => {
          setCurrentModal(undefined);
          handleSubmit();
        }}
      />

      <LimitedExperienceDialog
        isOpen={currentModal === 'limited-experience'}
        source="Send Offer"
        onConfirm={() => {
          setCurrentModal(undefined);
          handleSubmit();
        }}
        onCancel={() => {
          setCurrentModal(undefined);
        }}
      />

      <StyledDrawer
        open={!!order}
        onClose={onClose}
        aria-label="Single Send Offer"
        PaperProps={{
          ref: drawerRef,
        }}
      >
        <DrawerTitle
          title={
            <Typography variant="h3">
              Send offer{' '}
              {order ? (
                <Typography
                  component="span"
                  variant="h3"
                  color="textSecondary"
                >{` for ${order.number}`}</Typography>
              ) : (
                ''
              )}
            </Typography>
          }
          titleTypographyProps={{ id: formID }}
          endAction={
            <IconButton
              edge="end"
              aria-label="close"
              onClick={onClose}
              disabled={form.isSubmitting}
            >
              <Close />
            </IconButton>
          }
        />

        <FormikProvider value={form}>
          <Form id={formID} noValidate={true}>
            <Box py={16} px={isMobile ? 16 : 32}>
              <Stack space="xsmall">
                <SourceManagerLegacy
                  primarySource="Single Send Offer Drawer"
                  secondarySource="Carrier Search"
                >
                  <CarrierAutocompleteField
                    name="carrier"
                    withFMCSA={true}
                    onSelect={() => {
                      trackSelectedCarrier(
                        'Selected Carrier',
                        'search_carriers',
                      );
                    }}
                  />
                </SourceManagerLegacy>

                {carrier && !carrier.has_signed_up && (
                  <SendOfferNewCarrierFields />
                )}

                <Stack space={expanded ? 'small' : carrier ? 'none' : 'xsmall'}>
                  <Collapse in={expanded}>
                    <>
                      <Typography
                        variant="h4"
                        style={{ marginTop: '24px', marginBottom: '16px' }}
                      >
                        Dates & Payment
                      </Typography>
                      <Stack space="small">
                        <SendOfferFields order={order} source={source} />
                        {payment_method === 'superpay' &&
                          order?.inspection_type !== 'advanced' && (
                            <InspectionTypeWarning />
                          )}
                      </Stack>
                    </>
                  </Collapse>

                  <Box
                    aria-label="send offer expand dates"
                    onClick={() => {
                      writeStorageItem(
                        'send_offer_dates_expanded',
                        expanded ? 'false' : 'true',
                      );
                      toggleExpand((prev) => !prev);
                    }}
                  >
                    <Inline verticalAlign="center">
                      {expanded ? (
                        <ArrowDropUpIcon
                          style={{ color: ColorDynamic.Dark100 }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          style={{ color: ColorDynamic.Dark100 }}
                        />
                      )}

                      <Typography
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        {expanded
                          ? 'Hide Dates & Payment'
                          : 'Show Dates & Payment'}
                      </Typography>
                    </Inline>
                  </Box>
                </Stack>

                <Divider />
              </Stack>
            </Box>

            {order && (
              <CarrierNetworkTabs
                order={order}
                carrier={carrier}
                paymentMethod={payment_method}
                onSuggestedSelect={(val, isPreviouslyDispatched) => {
                  carrierIsPreviustlyDispatchedRef.current =
                    isPreviouslyDispatched;
                  handleCarrierSelect(val);
                  trackSelectedCarrier(
                    'Selected Suggested Carrier',
                    'suggested_carriers',
                    {
                      is_previously_dispatched_carrier: isPreviouslyDispatched,
                    },
                  );
                }}
                onPreviouslyDispatchedSelect={(val) => {
                  carrierIsPreviustlyDispatchedRef.current = true;
                  handleCarrierSelect(val);
                  trackSelectedCarrier(
                    'Selected Previously Dispatched Carrier',
                    'previously_dispatched_carriers',
                  );
                }}
                onPartnerCarriersSelect={(val) => {
                  carrierIsPreviustlyDispatchedRef.current = false;
                  handleCarrierSelect(val);
                  trackSelectedCarrier('Selected Carrier', 'partner_brokers');
                }}
              />
            )}
          </Form>
        </FormikProvider>

        <DrawerActions>
          <Button
            variant="primary"
            type="submit"
            form={formID}
            disabled={checkIsSendButtonDisabled()}
            pending={form.isSubmitting}
            onClick={() => {
              const shouldShowLimitedExperienceDialog =
                limitedExperience?.has_limited_experience &&
                !carrier?.broker_records?.approved;
              const isVerified = carrier?.is_verified || carrier?.is_super;

              if (
                carrier?.broker_records?.approved ||
                carrier?.carrier_type === 'BROKER' ||
                (isVerified && !shouldShowLimitedExperienceDialog)
              ) {
                handleSubmit();
              } else if (isVerified && shouldShowLimitedExperienceDialog) {
                setCurrentModal('limited-experience');
              } else {
                setCurrentModal('unverified-carrier');
              }
            }}
          >
            Send Offer
          </Button>
        </DrawerActions>
      </StyledDrawer>
    </>
  );
}
