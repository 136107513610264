import { DateConfigProvider } from '@superdispatch/dates';
import { FormsProvider } from '@superdispatch/forms';
import { renderChildren } from '@superdispatch/ui';
import { dequal } from 'dequal';
import { ReactNode, useLayoutEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { enableTokenRefresh } from 'shared/api/API';
import { AuthProvider } from 'shared/data/AuthProvider';
import { useFeatureTogglePublic } from 'shared/data/FeatureToggle';
import { defaultFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { LoadingContainer } from './LoadingContainer';
import { AppErrorBoundary } from './shared/errors/AppErrorBoundary';
import { AppTheme } from './shared/theme/AppTheme';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryOnMount: false,
      isDataEqual: dequal,
      staleTime: 10 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  },
});

export function RootProvider({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AppErrorBoundary>
        <AppTheme>
          <DateConfigProvider format="JodaISO">
            <FormsProvider getFormErrors={defaultFormErrorsGetter}>
              {!('Cypress' in window) &&
                import.meta.env.MODE === 'development' && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}

              {process.env.NODE_ENV !== 'test' ? (
                <RefreshTokenFeatureToggleGuard>
                  <AuthProvider>{children}</AuthProvider>
                </RefreshTokenFeatureToggleGuard>
              ) : (
                <AuthProvider>{children}</AuthProvider>
              )}
            </FormsProvider>
          </DateConfigProvider>
        </AppTheme>
      </AppErrorBoundary>
    </QueryClientProvider>
  );
}

/**
 * This prevents user sessions from expiring if some other request
 * is fired before refershing if token is expired.
 */
function RefreshTokenFeatureToggleGuard({ children }: { children: ReactNode }) {
  const { data } = useFeatureTogglePublic('stms.refresh-token.enabled');

  useLayoutEffect(() => {
    if (data?.is_enabled) {
      enableTokenRefresh();
    }
  }, [data]);

  if (!data) {
    return <LoadingContainer />;
  }

  return renderChildren(children);
}
