import {
  FormControlLabel,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core';
import { Close, Lock } from '@material-ui/icons';
import {
  FormikRadioGroupField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  ColorDynamic,
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  ExitTransitionPlaceholder,
  Image,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useInitialValues } from 'shared/form/AppFormik';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { logInfo } from 'shared/helpers/MonitoringService';
import { CarrierFullInfo } from 'shared/types/carrier';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import styled from 'styled-components';
import { APIErrorMessage } from '../../shared/errors/APIErrorMessage';
import reportCarrierIllustrationDark from '../assets/carrier-report-banner-dark.png';
import reportCarrierIllustration from '../assets/carrier-report-banner.png';
import reportConfirmationIllustrationDark from '../assets/report-confirmation-illustration-dark.svg';
import reportConfirmationIllustration from '../assets/report-confirmation-illustration.svg';
import { carrierReportSchema } from '../data/CarrierReportDTO';
import { useCarrier, useCarrierProfileAPI } from '../data/ManageCarriersAPI';
import { useCarrierInternalRecordsEditForm } from './CarrierInternalRecordsEditForm';
import { useReportedCarriers } from './ReportedCarriers';

const CustomDrawerContent = styled(DrawerContent)`
  height: 100%;
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmationContainer = styled.div`
  width: 368px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReportCarrierIllustration = styled(Image)`
  width: 100%;
`;

const GreenSubText = styled.span`
  color: ${ColorDynamic.Green500};
`;

const REPORT_REASONS_LABELS = {
  DOUBLE_BROKERING: 'Double-Brokering',
  IDENTITY_THEFT: 'Identity Theft',
  STOLEN_VEHICLE: 'Stolen Vehicle',
  OTHER: 'Other',
} as const;

export interface ReportedCarrier {
  name: string;
  guid: string;
}

interface ReportCarrierDrawerContentProps {
  carrier: ReportedCarrier;
  onSubmitSuccess: () => void;
}

export function ReportCarrierDrawerContent({
  carrier,
  onSubmitSuccess,
}: ReportCarrierDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { user } = useUserState();
  const [_, addReportedCarrierGUID] = useReportedCarriers();
  const { reportCarrier } = useCarrierProfileAPI();

  const initialValues = useInitialValues(carrierReportSchema, {});
  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: carrierReportSchema,
    onSubmit(values) {
      return reportCarrier(carrier.guid, values);
    },
    onSubmitSuccess(_response, values) {
      const details = {
        fraud_type: REPORT_REASONS_LABELS[values.reason],
        contact: values.contact,
        additional_details: values.description,
        user_name: `${user?.username || ''} (${user?.email || ''})`,
        shipper_id: user?.shipper.id,
        shipper_name: user?.shipper.name,
        carrier_guid: carrier.guid,
        carrier_name: carrier.name,
      };
      trackEvent('Shipper Reported Carrier', details);
      logInfo('Shipper Reported Carrier', {
        namespace: 'trust.reporting.shipper-reported-carrier',
        ...details,
      });
      addReportedCarrierGUID(carrier.guid);
      onSubmitSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, {
        variant: 'error',
      });
    },
  });

  return (
    <FormikDrawerContent
      formik={formik}
      title="Report Fraud"
      actions={
        <DrawerActions>
          <Button
            type="submit"
            pending={formik.isSubmitting}
            disabled={!formik.dirty}
            endIcon={<Lock />}
          >
            Report Fraud
          </Button>
        </DrawerActions>
      }
    >
      <Stack space="small">
        <ReportCarrierIllustration
          src={reportCarrierIllustration}
          srcDark={reportCarrierIllustrationDark}
          alt="report carrier illustration"
        />

        <Typography variant="h3">
          This report is <GreenSubText>private</GreenSubText>, and will not be
          shared with anyone without your consent.
        </Typography>

        <FormikRadioGroupField
          name="reason"
          label={
            <Typography variant="h5">1. What are you reporting?</Typography>
          }
        >
          {Object.entries(REPORT_REASONS_LABELS).map(([value, label]) => (
            <FormControlLabel
              key={value}
              value={value}
              label={label}
              control={<Radio />}
            />
          ))}
        </FormikRadioGroupField>

        <Stack>
          <Typography variant="h5">2. How should we contact you?</Typography>
          <FormikTextField
            name="contact"
            label="Preferred contact method"
            helperText="Please enter your preferred email address or phone number"
            fullWidth={true}
          />
        </Stack>

        <FormikTextField
          name="description"
          multiline={true}
          fullWidth={true}
          label={
            <Typography variant="h5">
              3. Please provide a detailed description of the incident that
              prompted you to report fraud.
            </Typography>
          }
        />
      </Stack>
    </FormikDrawerContent>
  );
}

interface ReportCarrierConfirmationScreenWrapperProps {
  onClose: () => void;
  carrier: ReportedCarrier;
}

export function ReportCarrierConfirmationScreenWrapper({
  onClose,
  carrier,
}: ReportCarrierConfirmationScreenWrapperProps) {
  const { data } = useCarrier({ guid: carrier.guid });

  if (!data) {
    return <LoadingContainer />;
  }

  return <ReportCarrierConfirmationScreen carrier={data} onClose={onClose} />;
}

interface ReportCarrierConfirmationScreenProps {
  onClose: () => void;
  carrier: CarrierFullInfo;
}

export function ReportCarrierConfirmationScreen({
  onClose,
  carrier,
}: ReportCarrierConfirmationScreenProps) {
  const { addSnackbar } = useSnackbarStack();

  const formik = useCarrierInternalRecordsEditForm({
    carrier,
    onSubmitSuccess() {
      addSnackbar('Successfully updated internal records', {
        variant: 'success',
      });
      onClose();
    },
    onSubmitFailure() {
      addSnackbar('Failed to updated internal records', {
        variant: 'error',
      });
    },
  });

  return (
    <>
      <DrawerTitle
        title="Report Fraud"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <CustomDrawerContent>
        <ConfirmationContainer>
          <Stack align="center" space="medium">
            <Image
              src={reportConfirmationIllustration}
              srcDark={reportConfirmationIllustrationDark}
              alt="report confirmation illustration"
            />
            <Stack align="center">
              <Typography align="center" variant="h3">
                Thank you for helping us keep Super Dispatch safe
              </Typography>
              <Typography align="center">
                We take all fraud reports seriously. A member of our
                investigation team will reach out to you shortly.
              </Typography>
            </Stack>
          </Stack>
        </ConfirmationContainer>
      </CustomDrawerContent>
      <DrawerActions>
        <Inline space="small">
          <Button onClick={onClose}>Finish</Button>
          {!carrier.broker_records.in_blacklist && (
            <Button
              pending={formik.isSubmitting}
              variant="neutral"
              onClick={async () => {
                await formik.setFieldValue('status', 'IN_BLACKLIST');
                void formik.submitForm();
              }}
            >
              Finish and Blocklist
            </Button>
          )}
        </Inline>
      </DrawerActions>
    </>
  );
}

interface ReportCarrierDrawerProps {
  carrier?: ReportedCarrier;
  isOpen: boolean;
  onClose: () => void;
}

export function ReportCarrierDrawer({
  carrier,
  isOpen,
  onClose,
}: ReportCarrierDrawerProps) {
  const [currentScreen, setCurrentScreen] = useState<'form' | 'confirmation'>(
    'form',
  );

  function handleClose() {
    setCurrentScreen('form');
    onClose();
  }

  return (
    <FormikDrawer open={isOpen} onClose={handleClose} confirmClose={true}>
      <ExitTransitionPlaceholder in={isOpen}>
        {currentScreen === 'form' && carrier && (
          <ReportCarrierDrawerContent
            carrier={carrier}
            onSubmitSuccess={() => {
              setCurrentScreen('confirmation');
            }}
          />
        )}
        {currentScreen === 'confirmation' && carrier && (
          <ReportCarrierConfirmationScreenWrapper
            carrier={carrier}
            onClose={handleClose}
          />
        )}
      </ExitTransitionPlaceholder>
    </FormikDrawer>
  );
}
