import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { useMemo } from 'react';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import {
  ConfirmPopover,
  ConfirmPopoverProps,
} from 'shared/ui/ConfirmationPopup';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';

interface BulkCancelOfferPopoverProps
  extends Omit<ConfirmPopoverProps, 'open'> {
  orders: Order[] | undefined;
  onSubmitSuccess: () => void;
}

export function BulkCancelOfferPopover({
  orders,
  anchorEl,
  onClose,
  onSubmitSuccess,
  ...props
}: BulkCancelOfferPopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkCancelOffer } = useBulkOrderActionAPI();

  const { hasSuperPayStatus, hasExpeditedPayFeeAmount } = useMemo(() => {
    return {
      hasSuperPayStatus: orders?.some(
        (order) => !!order.payment?.super_pay?.status,
      ),
      hasExpeditedPayFeeAmount: orders?.some(
        (order) => !!order.payment?.super_pay?.expedited_pay_fee_amount,
      ),
    };
  }, [orders]);

  const { handleSubmit, isSubmitting } = useFormikEnhanced({
    key: !!orders,
    initialValues: {},
    onSubmit() {
      if (orders) {
        const ids = orders.map((x) => x.id);
        return bulkCancelOffer(ids);
      }

      return Promise.reject(new Error('Order not selected'));
    },
    onSubmitSuccess() {
      if (orders) {
        addSnackbar('Offer(s) canceled', { variant: 'success' });
        trackEventLegacy('Bulk Cancel Offer', {
          count: orders.length,
          orders: orders.map((order) => ({
            status: order.status,
            carrier_name: order.active_offer?.carrier_name,
          })),
        });
        onSubmitSuccess();
      }
    },
    onSubmitFailure({ message }) {
      addSnackbar(message, { variant: 'error' });
    },
  });
  return (
    <ConfirmPopover
      {...props}
      onClose={isSubmitting ? undefined : onClose}
      anchorEl={anchorEl}
      open={!!orders && !!anchorEl}
      title={orders ? `Cancel ${orders.length} offer(s)?` : ''}
      cancelButtonProps={{
        children: 'No',
        onClick: onClose,
        disabled: isSubmitting,
      }}
      confirmButtonProps={{
        children: 'Yes, Cancel Offer',
        pending: isSubmitting,
        onClick: () => handleSubmit(),
      }}
    >
      {hasSuperPayStatus && (
        <>
          <Typography>SuperPay payment will be put on hold.</Typography>
          {hasExpeditedPayFeeAmount && (
            <Typography>
              For orders with expedited payment SuperPay will be canceled and
              trigger refund.
            </Typography>
          )}
        </>
      )}
    </ConfirmPopover>
  );
}
