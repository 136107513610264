import { apiInstance } from 'shared/api/API';
import { useAPIMutation } from 'shared/api/APIMutation';
import { useAPIQuery } from 'shared/api/APIQuery';
import { BusinessContactsDTO } from './BusinessDTO';
import { MoovScope, MoovTokenResponse } from './MoovDTO';
import {
  addendumSchema,
  SuperPayCancelDTO,
  SuperPayTermsConditionsDTO,
  superPayTermsConditionsSchema,
  SuperPayUpdateSettingsDTO,
} from './SuperPayDTO';

export function generateMoovTokenAndGetAccount(scope: MoovScope) {
  const { requestResource } = apiInstance;

  return requestResource<MoovTokenResponse>(
    'POST /internal/payments/moov/generate-token',
    (data) => data as MoovTokenResponse,
    { json: { scope } },
  );
}

export function useUpdateBusinessContact() {
  const { request } = apiInstance;
  return useAPIMutation((params: BusinessContactsDTO) =>
    request('PUT /internal/shippers/business_details', {
      json: {
        address: params.streetAddress,
        city: params.city,
        state: params.state,
        zip: params.zip,
        contact_phone: params.phoneNumber,
        personal_page_url: params.website,
      },
    }),
  );
}

export function useSuperPayAddendum() {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'addendum'],
    () =>
      requestResource('GET /internal/payments/setup_settings', (data) =>
        addendumSchema.cast(data),
      ),
    { refetchOnWindowFocus: false },
  );
}

export function useSuperPayTermsConditionsToken() {
  const { requestResource } = apiInstance;
  return useAPIMutation<void, SuperPayTermsConditionsDTO>(() => {
    return requestResource('GET /internal/payments/acceptance_token', (data) =>
      superPayTermsConditionsSchema.cast(data),
    );
  });
}

export function useUpdateSuperPayTermsConditionsAccept() {
  const { request } = apiInstance;
  return useAPIMutation((termsConditionsToken: string) => {
    const params = {
      accepted_date: new Date().toISOString(),
      acceptance_token: termsConditionsToken,
    };
    return request('PUT /internal/payments/accept_superpay_addendum', {
      json: params,
    });
  });
}

export function useUpdateSuperPaySettings() {
  const { requestResource } = apiInstance;
  return useAPIMutation((params: SuperPayUpdateSettingsDTO) =>
    requestResource(
      'PUT /internal/payments/super_pay_settings',
      (data) => data,
      { json: params },
    ),
  );
}

export function useSyncSuperPayOnboardingStep() {
  const { request } = apiInstance;
  return useAPIMutation(
    (values: {
      moov_account_id: string | null;
      moov_bank_account_id: string;
      scope: MoovScope;
    }) =>
      request('POST /internal/payments/sync_onboarding_step', {
        json: values,
      }),
  );
}
export function useSuperPayValidationIncrement() {
  const { requestResource } = apiInstance;
  return useAPIMutation(
    (bank_account_guid: string) =>
      requestResource(
        'POST /internal/payments/increment_fail_count',
        () => true,
        { json: { bank_account_guid } },
      ).catch(() => undefined), // the fall of the method should not affect
  );
}

export function useSuperPayCancel() {
  const { requestResource } = apiInstance;
  return useAPIMutation(
    (params: { orderId: number; payment: SuperPayCancelDTO }) => {
      const { orderId, payment } = params;
      return requestResource(
        'PUT /internal/orders/{orderId}/cancel_payment',
        (data) => data,
        { orderId, json: payment },
      );
    },
  );
}
