import { Link, Typography } from '@material-ui/core';
import { AttachMoney, Person, Phone, PictureAsPdf } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import { Insurance } from 'shared/types/carrier';
import styled from 'styled-components';

const ResponsivePaddedItem = styled.div`
  margin-left: 32px;

  @media (min-width: 600px) {
    margin-left: 24px;
  }

  @media (min-width: 960px) {
    margin-left: 0;
  }
`;

interface CarrierInsuranceProps {
  insurances: Insurance[] | null;
}

export function CarrierInsurance({ insurances }: CarrierInsuranceProps) {
  const currentInsurance = insurances?.find(
    (insurance) => insurance.is_current,
  );

  if (!currentInsurance) {
    return (
      <Stack space="small">
        <Typography variant="h4">Cargo Insurance</Typography>
        <Typography color="textSecondary">No Information Provided</Typography>
      </Stack>
    );
  }

  return (
    <Stack space="small">
      <Typography variant="h4">Cargo Insurance</Typography>

      {currentInsurance.file_url && (
        <DescriptionItem icon={<PictureAsPdf />}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            aria-label="insurance certificate"
            href={currentInsurance.file_url}
            onClick={() =>
              trackEvent('[STMS] Clicked Carrier Insurance Certificate')
            }
          >
            Insurance Certificate
          </Link>
        </DescriptionItem>
      )}

      <Stack space={['xxsmall', 'xsmall']}>
        {currentInsurance.policy_expiration_date && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<CalendarIcon />}>
                <Typography color="textSecondary" component="span">
                  Expiration Date
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem aria-label="Expiration Date">
                <DescriptionItem>
                  <FormattedDate
                    variant="Date"
                    format="DateISO"
                    date={currentInsurance.policy_expiration_date}
                  />
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}

        {currentInsurance.cargo_limit != null && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<AttachMoney />}>
                <Typography color="textSecondary" component="span">
                  Cargo Limit
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem aria-label="Cargo Limit">
                <DescriptionItem>
                  {formatCurrency(Number(currentInsurance.cargo_limit), {
                    minimumFractionDigits: 0,
                  })}
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}

        {currentInsurance.deductible_amount != null && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<AttachMoney />}>
                <Typography color="textSecondary" component="span">
                  Deductible
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem aria-label="Deductible">
                <DescriptionItem>
                  {formatCurrency(Number(currentInsurance.deductible_amount), {
                    minimumFractionDigits: 0,
                  })}
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}

        {currentInsurance.auto_policy_number && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<HashtagIcon />}>
                <Typography color="textSecondary" component="span">
                  Auto Policy
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem>
                <DescriptionItem>
                  {currentInsurance.auto_policy_number}
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}

        {currentInsurance.cargo_policy_number && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<HashtagIcon />}>
                <Typography color="textSecondary" component="span">
                  Cargo Policy
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem>
                <DescriptionItem>
                  {currentInsurance.cargo_policy_number}
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}
      </Stack>

      {!!currentInsurance.agent_name && !!currentInsurance.agent_phone && (
        <Stack space="xsmall">
          <Typography variant="h6" color="textSecondary">
            Agent
          </Typography>

          <Stack space="xxsmall">
            {currentInsurance.agent_name && (
              <DescriptionItem icon={<Person />}>
                <Typography aria-label="cargo agent name">
                  {currentInsurance.agent_name}
                </Typography>
              </DescriptionItem>
            )}
            {currentInsurance.agent_phone && (
              <DescriptionItem icon={<Phone />}>
                <SuspendedPhoneLink
                  aria-label="cargo agent phone"
                  phone={currentInsurance.agent_phone}
                  fallback={currentInsurance.agent_phone}
                />
              </DescriptionItem>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
