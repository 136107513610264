// eslint-disable-next-line you-dont-need-lodash-underscore/map
import { ColorDynamic } from '@superdispatch/ui';
import { map } from 'lodash';
import { List } from 'shared/ui/List';
import { APIErrorValidationDetails } from '../api/API';
import { APIError } from './APIError';

interface APIValidationErrorMessageProps {
  error: APIError<APIErrorValidationDetails>;
}

export function APIValidationErrorMessage({
  error: { details = {} },
}: APIValidationErrorMessageProps) {
  return (
    <List color={ColorDynamic.Red500}>
      {map(details, (errorText, key) => (
        <li key={`${key}`}>{errorText}</li>
      ))}
    </List>
  );
}
