import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { stringifySearchQuery } from 'shared/helpers/RouteHelpers';
import {
  DictionaryCarrierDTO,
  dictionaryCarrierSchema,
} from './dictionary/data/DictionaryDTO';

interface Props {
  carrier: DictionaryCarrierDTO;
  loadsCount: number | undefined | null;
  source: 'load_offer_drawer' | 'carrier_requests_drawer' | 'shipper_profile';
}

export function TotalOrdersDispatchedLink({
  carrier,
  loadsCount,
  source,
}: Props) {
  const count = loadsCount || 0;
  return (
    <Link
      component={RouterLink}
      color="textSecondary"
      target="_blank"
      to={`/orders?${stringifySearchQuery({
        filters: [
          {
            type: 'statuses',
            value: ['accepted', 'picked_up', 'delivered', 'invoiced', 'paid'],
          },
          {
            type: 'carriers',
            value: [dictionaryCarrierSchema.cast(carrier)],
          },
        ],
      })}`}
      onClick={() =>
        trackEventLegacy('[STMS] Clicked on Total Orders Dispatched by You', {
          utm_medium: source,
        })
      }
    >
      {`${count} total ${formatPlural(
        count,
        'order',
        'orders',
      )} dispatched by you`}
    </Link>
  );
}
