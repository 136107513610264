import { Drawer, IconButton, Typography } from '@material-ui/core';
import { ChevronRight, Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { AnchorButton, Box, Button } from '@superdispatch/ui-lab';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';

export interface ShipperTypeHelpDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function ShipperTypeHelpDrawer({
  open,
  onClose,
}: ShipperTypeHelpDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Learn more"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <DrawerContent>
        <Box maxWidth="400px">
          <Stack space="xlarge">
            <Stack space="small">
              <Typography variant="h2">
                Why do we ask for your Shipper Type?
              </Typography>
              <Typography>
                Super Dispatch supports various shipper types, so this helps us
                identify the documentation our compliance team needs to verify
                your account.
              </Typography>
            </Stack>
            <AnchorButton
              variant="text"
              endIcon={<ChevronRight />}
              href="https://support.superdispatch.com/hc/en-us/articles/35663145878291-Verified-Shipper-Requirements"
              target="_blank"
              onClick={() => {
                trackVerificationApplicationEvent({
                  name: 'Shipper Clicked on Read Our Article',
                  utm_content: 'Shipper Type',
                });
              }}
            >
              Read our Article
            </AnchorButton>
          </Stack>
        </Box>
      </DrawerContent>
      <DrawerActions>
        <Button onClick={onClose}>Ok</Button>
      </DrawerActions>
    </Drawer>
  );
}
