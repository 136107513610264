import { useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { getNudgingDialogProps } from 'onboarding/getNudgingDialogProps';
import { useCurrentOnboardingStep } from 'onboarding/OnboardingAPI';
import {
  BannerOnboardingAction,
  OnboardingBanner,
  onboardingMap,
  OnboardingPopup,
  OnboardingPopupData,
} from 'onboarding/onboardingMap';
import { OnboardingNudgingDialog } from 'onboarding/OnboardingNudgingDialog';
import {
  useHandleOnboardingAction,
  UseHandleOnboardingActionProps,
} from 'onboarding/useHandleOnboardingAction';
import { useHandleOnboardingPopupAction } from 'onboarding/useHandleOnboardingPopupAction';
import { useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { getUserRoleNamesString } from 'shared/helpers/UserHelpers';
import { HeaderBanner } from '../ui/HeaderBanner';

export function OnboardingBannerManager() {
  const { stepKey, stepOrderGuid, stepOnlineBolUrl } =
    useCurrentOnboardingStep();

  if (!stepKey) {
    return null;
  }

  const { banner, popup } = onboardingMap[stepKey];

  if (!banner) {
    return null;
  }

  return (
    <AdaptedOnboardingBanner
      key={stepKey}
      stepKey={stepKey}
      orderGuid={stepOrderGuid}
      onlineBolUrl={stepOnlineBolUrl}
      banner={banner}
      popup={popup}
    />
  );
}

function AdaptedOnboardingBanner({
  stepKey,
  orderGuid,
  onlineBolUrl,
  banner,
  popup,
}: UseHandleOnboardingActionProps & {
  banner: OnboardingBanner;
  popup?: OnboardingPopup;
}) {
  const isMobile = useResponsiveValue(true, false);
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [isBannerDismissed, setIsBannerDismissed] = useState(false);
  const { handleAction, markCurrentStepAsDone, isAsyncActionLoading } =
    useHandleOnboardingAction({
      stepKey,
      orderGuid,
      onlineBolUrl,
    });

  const { user } = useUserState();
  const userRole = getUserRoleNamesString(user);

  function handleBannerAction(action: BannerOnboardingAction) {
    if (action.type === 'TRIGGER_POPUP') {
      setIsPopupOpened(true);
    } else if (action.type === 'DISMISS_BANNER') {
      setIsBannerDismissed(true);
      markCurrentStepAsDone();
    } else {
      handleAction(action);
    }
    if (action.analyticsEvent) {
      trackEvent(action.analyticsEvent.name, {
        attempt: action.analyticsEvent.additionalData?.attempt,
        user_role: userRole,
        utm_source: 'Web STMS',
      });
    }
  }

  if (isBannerDismissed) {
    return null;
  }

  return (
    <>
      <HeaderBanner
        icon={
          banner.IconComponent ? (
            <banner.IconComponent fontSize="inherit" />
          ) : banner.iconImage ? (
            <img src={banner.iconImage} width="25" height="25" />
          ) : null
        }
        text={banner.text}
        actions={
          <>
            {banner.secondaryAction && (
              <Button
                variant="neutral"
                size="small"
                fullWidth={isMobile}
                onClick={() =>
                  handleBannerAction(
                    banner.secondaryAction as BannerOnboardingAction,
                  )
                }
              >
                {banner.secondaryActionText}
              </Button>
            )}

            {banner.primaryAction && (
              <Button
                size="small"
                fullWidth={isMobile}
                pending={isAsyncActionLoading}
                onClick={() =>
                  banner.primaryAction
                    ? handleBannerAction(banner.primaryAction)
                    : undefined
                }
              >
                {banner.primaryActionText}
              </Button>
            )}
          </>
        }
      />

      {popup && (
        <OnboardingBannerPopup
          stepKey={stepKey}
          orderGuid={orderGuid}
          onlineBolUrl={onlineBolUrl}
          popup={popup}
          open={isPopupOpened}
          onClose={() => setIsPopupOpened(false)}
        />
      )}
    </>
  );
}

function OnboardingBannerPopup({
  open,
  onClose,
  popup,
  ...props
}: UseHandleOnboardingActionProps & {
  popup: OnboardingPopup;
  open: boolean;
  onClose: () => void;
}) {
  const { handlePopupAction, isAsyncActionLoading } =
    useHandleOnboardingPopupAction({
      ...props,
      onComplete: onClose,
    });

  const PopupComponent = popup.Component;

  if (PopupComponent) {
    return (
      <PopupComponent
        open={open}
        onClose={onClose}
        onAction={handlePopupAction}
      />
    );
  }

  return (
    <OnboardingNudgingDialog
      {...getNudgingDialogProps({
        popupData: popup.data as OnboardingPopupData,
        handlePopupAction,
        isAsyncActionLoading,
      })}
      open={open}
      onClose={onClose}
    />
  );
}
