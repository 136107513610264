import { QueryClient } from 'react-query';
import { apiInstance } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { zendeskDTO } from './ZendeskDTO';

const NINE_DAYS_IN_MS = 9 * 24 * 60 * 60 * 1000;

export function useZendeskToken(enabled = false) {
  const { requestResource } = apiInstance;

  return useAPIQuery(
    ['zendesk', 'loginUser'],
    () =>
      requestResource('GET /internal/zendesk/generate_token', (data) =>
        zendeskDTO.cast(data),
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: NINE_DAYS_IN_MS,
      cacheTime: NINE_DAYS_IN_MS,
      enabled,
    },
  );
}

export function removeZendeskTokenCache() {
  const queryClient = new QueryClient();
  queryClient.removeQueries(['zendesk', 'loginUser']);
}
