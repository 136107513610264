import { MonetizationOn } from '@material-ui/icons';
import { Stack, Tag } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { PrivateNetworkTag } from 'core/CarrierTags';
import { isCarrierInsuranceExpired } from 'core/insurance/ExpirationDates';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { ApprovedIcon } from 'shared/icons/ApprovedIcon';
import { CertificateIcon } from 'shared/icons/CertificateIcon';
import { ErrorInfoIcon } from 'shared/icons/ErrorInfoIcon';
import { FlashIcon } from 'shared/icons/FlashIcon';
import { IconBox } from 'shared/icons/IconBox';
import { CarrierFullInfo, hasCarrierBrokerStatus } from 'shared/types/carrier';

export interface CarriersListStatusProps {
  carrier: CarrierFullInfo;
}

export function CarriersListStatus({ carrier }: CarriersListStatusProps) {
  const isInsuranceExpired = isCarrierInsuranceExpired(
    carrier.broker_records.insurance_expires_at,
  );
  const hasStatus = hasCarrierBrokerStatus(carrier.broker_records);

  const {
    approved,
    preferred,
    can_expedite_payment,
    is_in_private_network,
    insurance_certificate_holder,
    in_blacklist,
    private_network_groups,
  } = carrier.broker_records;

  const isCarrierEligibility = useFeatureToggle(
    'payments.expedited.pay.carrier.eligibility.access.ui',
  );
  const { user } = useUserState();
  const isCanExpeditePaymentTag =
    user?.shipper.super_pay_settings?.can_expedite_payment_tab_available &&
    isCarrierEligibility &&
    can_expedite_payment;

  return !hasStatus ? (
    <TextBox color="secondary">No status</TextBox>
  ) : (
    <Stack>
      {approved && (
        <Tag variant="subtle" color="grey">
          <IconBox>
            <ApprovedIcon />
          </IconBox>
          Approved
        </Tag>
      )}
      {preferred && (
        <Tag variant="subtle" color="grey">
          <IconBox>
            <FlashIcon />
          </IconBox>
          Can Instantly Book
        </Tag>
      )}
      {isCanExpeditePaymentTag && (
        <Tag variant="subtle" color="grey">
          <IconBox>
            <MonetizationOn fontSize="small" color="primary" />
          </IconBox>
          Can Expedite Payment
        </Tag>
      )}
      {is_in_private_network && private_network_groups && (
        <PrivateNetworkTag variant="vertical" groups={private_network_groups} />
      )}
      {insurance_certificate_holder && (
        <Tag variant="subtle" color="grey">
          <IconBox>
            <CertificateIcon />
          </IconBox>
          Certificate Holder
        </Tag>
      )}
      {isInsuranceExpired && (
        <Tag variant="subtle" color="grey">
          <IconBox>
            <ErrorInfoIcon />
          </IconBox>
          Expired Insurance
        </Tag>
      )}
      {in_blacklist && (
        <Tag variant="subtle" color="grey">
          <IconBox>
            <ErrorInfoIcon />
          </IconBox>
          Blocklisted
        </Tag>
      )}
    </Stack>
  );
}
