import {
  Box,
  CircularProgress,
  Fade,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import { Delete, Edit, Person, Star, StarBorder } from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { ColorDynamic, Inline, useSnackbarStack } from '@superdispatch/ui';
import { useState } from 'react';
import { CustomerContactDTO } from 'shared/dto/CustomerContactDTO';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { useMarkAsPrimaryMutation } from '../data/CustomerContactAPI';
import { ContactDeleteDialog } from './ContactDeleteDialog';

interface CustomerDetailsContactsItemProps {
  customer: CustomerDTO;
  contact: CustomerContactDTO;
  onEdit: () => void;
}

export function CustomerDetailsContactsItem({
  customer,
  contact,
  onEdit,
}: CustomerDetailsContactsItemProps) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const {
    name,
    title,
    phone,
    mobile_phone,
    email,
    is_primary,
    phone_extension,
  } = contact;
  const [isHovered, setIsHovered] = useState(false);
  const { addSnackbar } = useSnackbarStack();

  const { mutate: markAsPrimary, isLoading } = useMarkAsPrimaryMutation({
    onSuccess() {
      addSnackbar('Marked as primary', { variant: 'success' });
    },
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Box
      py={1}
      px={2}
      aria-label="contact item"
      display="flex"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box pr="4px">
        {is_primary ? (
          <Box m="3px">
            <Star
              fontSize="small"
              htmlColor={ColorDynamic.Yellow500}
              aria-label="primary contact"
            />
          </Box>
        ) : (
          <IconButton
            size="small"
            disabled={isLoading}
            onClick={() => {
              markAsPrimary({ customerID: customer.id, contactID: contact.id });
            }}
          >
            {isLoading ? (
              <CircularProgress size="16px" />
            ) : isHovered ? (
              <StarBorder
                fontSize="small"
                color="action"
                aria-label="mark as primary"
              />
            ) : (
              <Person fontSize="small" color="action" />
            )}
          </IconButton>
        )}
      </Box>

      <Box flexGrow="1">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" aria-label="contact name">
            {name}
          </Typography>

          <Fade in={isHovered}>
            <Box display="flex">
              <Box ml={1}>
                <IconButton
                  size="small"
                  onClick={onEdit}
                  aria-label="edit contact"
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Box>

              <Box ml={1}>
                <IconButton
                  size="small"
                  aria-label="delete contact"
                  onClick={(event) => setAnchor(event.currentTarget)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Fade>
        </Box>

        {title && (
          <Typography color="textSecondary" aria-label="contact title">
            {title}
          </Typography>
        )}

        {phone && (
          <Box mt="4px">
            <Inline>
              <SuspendedPhoneLink
                phone={phone}
                fallback={<Link href={`tel:${phone}`}>{phone}</Link>}
              />
              <Typography color="textSecondary">‧</Typography>
              <Typography color="textSecondary">phone</Typography>
            </Inline>
          </Box>
        )}
        {phone_extension && (
          <Box mt="4px">
            <Inline>
              <Typography>{phone_extension}</Typography>
              <Typography color="textSecondary">‧</Typography>
              <Typography color="textSecondary">phone extension</Typography>
            </Inline>
          </Box>
        )}
        {mobile_phone && (
          <Box mt="4px">
            <Inline>
              <SuspendedPhoneLink
                phone={mobile_phone}
                fallback={
                  <Link href={`tel:${mobile_phone}`}>{mobile_phone}</Link>
                }
              />
              <Typography color="textSecondary">‧</Typography>
              <Typography color="textSecondary">mobile</Typography>
            </Inline>
          </Box>
        )}

        {email && (
          <Box>
            <Link href={`mailto:${email}`}>{email}</Link>
          </Box>
        )}
      </Box>

      <ContactDeleteDialog
        anchor={anchor}
        contact={contact}
        customer={customer}
        onClose={() => setAnchor(null)}
      />
    </Box>
  );
}
