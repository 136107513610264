import { Tooltip, Typography } from '@material-ui/core';
import { LinkOff } from '@material-ui/icons';
import {
  ColorDynamic,
  Column,
  Columns,
  InfoTooltip,
  Inline,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { InspectionType } from 'core/InspectionType';
import { TransportType } from 'core/TransportType';
import { useUserState } from 'shared/data/AppUserState';
import { InfoIcon } from 'shared/icons/InfoIcon';
import Order from 'shared/types/order';
import {
  DefinitionList,
  DefinitionListItem,
  DescriptionStackItem,
} from 'shared/ui/DefinitionList';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import { TextOverflow } from 'shared/ui/TextOverflow';
import { OrderViewInstantBooking } from './OrderViewInstantBooking';

export interface InfoProps {
  order: Order;
}

export function OrderViewInfo({ order }: InfoProps) {
  const { user } = useUserState();
  const shipperType = user?.shipper.shipper_type;
  const isDispatcherLinked = order.dispatcher_user_id;

  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');

  return platform !== 'desktop' ? (
    <Box backgroundColor="White" padding="small">
      <DefinitionList flexDirection="column" style={{ gap: '16px' }}>
        <DefinitionListItem
          flexDirection="row"
          label="PO Number"
          guideHeight="18px"
          value={
            <TextOverflow size={250}>
              {order.purchase_order_number}
            </TextOverflow>
          }
          hidden={
            !(
              (user?.order_form_settings.is_purchase_order_number_visible &&
                order.purchase_order_number) ||
              order.purchase_order_number
            )
          }
        />

        <DefinitionListItem
          flexDirection="row"
          label="Transport Type"
          guideHeight="18px"
          value={<TransportType order={order} />}
          hidden={
            !(
              user?.order_form_settings.is_transport_type_visible &&
              order.transport_type
            )
          }
        />

        {shipperType === 'BROKER' && (
          <>
            <DefinitionListItem
              flexDirection="row"
              label={
                <Inline space="xxsmall">
                  <Typography>Inspection Type</Typography>

                  <ResponsiveTooltip
                    title={
                      <>
                        Standard inspection doesn&apos;t have any required steps
                        for drivers.
                        <br />
                        <br />
                        Advanced inspection requires drivers to take at least 6
                        photos at Pickup and Delivery. This type is required if
                        SuperPay is selected as payment method.
                        <br />
                        <br />
                        AIAG inspection is used for OEM customers and requires 2
                        photos per damage.
                      </>
                    }
                    placement="bottom"
                  >
                    <InfoIcon fontSize="small" />
                  </ResponsiveTooltip>
                </Inline>
              }
              guideHeight="18px"
              value={<InspectionType order={order} />}
              hidden={!order.inspection_type}
            />

            <DefinitionListItem
              flexDirection="row"
              label="Instant Booking"
              guideHeight="18px"
              value={
                <OrderViewInstantBooking
                  isBookable={order.can_be_instantly_booked}
                />
              }
              hidden={!user?.shipper.is_instant_booking_allowed}
            />

            <DefinitionListItem
              flexDirection="row"
              label="Dispatcher"
              guideHeight="18px"
              value={
                <TextOverflow size={250}>{order.dispatcher_name}</TextOverflow>
              }
              hidden={!order.dispatcher_name}
            />

            <DefinitionListItem
              flexDirection="row"
              label="Salesperson"
              guideHeight="18px"
              value={
                <TextOverflow size={250}>
                  {order.sales_representative}
                </TextOverflow>
              }
              hidden={!order.sales_representative}
            />

            <DefinitionListItem
              flexDirection="row"
              label="Contact"
              guideHeight="18px"
              value={
                <TextOverflow size={250}>
                  {order.customer?.contact_name}
                </TextOverflow>
              }
              hidden={!order.customer?.contact_name}
            />
          </>
        )}
      </DefinitionList>
    </Box>
  ) : (
    <Box
      backgroundColor="White"
      paddingTop="xsmall"
      paddingBottom="xsmall"
      paddingLeft="large"
      paddingRight="large"
    >
      <Columns>
        <Column>
          <Columns space="large">
            {((user?.order_form_settings.is_purchase_order_number_visible &&
              order.purchase_order_number) ||
              order.purchase_order_number) && (
              <Column width="content">
                <DescriptionStackItem label="PO Number">
                  <TextOverflow size={150}>
                    {order.purchase_order_number}
                  </TextOverflow>
                </DescriptionStackItem>
              </Column>
            )}

            {user?.order_form_settings.is_transport_type_visible &&
              order.transport_type && (
                <Column width="content">
                  <DescriptionStackItem label="Transport Type">
                    <TransportType order={order} />
                  </DescriptionStackItem>
                </Column>
              )}

            {shipperType === 'BROKER' && (
              <Column width="content" aria-label="inspection type">
                <DescriptionStackItem
                  label={
                    <InfoTooltip
                      title={
                        <>
                          Standard inspection doesn&apos;t have any required
                          steps for drivers.
                          <br />
                          <br />
                          Advanced inspection requires drivers to take at least
                          6 photos at Pickup and Delivery. This type is required
                          if SuperPay is selected as payment method.
                          <br />
                          <br />
                          AIAG inspection is used for OEM customers and requires
                          2 photos per damage.
                        </>
                      }
                    >
                      Inspection Type
                    </InfoTooltip>
                  }
                >
                  <InspectionType order={order} />
                </DescriptionStackItem>
              </Column>
            )}

            {user?.shipper.is_instant_booking_allowed && (
              <Column width="content">
                <DescriptionStackItem label="Instant Booking">
                  <OrderViewInstantBooking
                    isBookable={order.can_be_instantly_booked}
                  />
                </DescriptionStackItem>
              </Column>
            )}
          </Columns>
        </Column>

        {shipperType === 'BROKER' && (
          <Column width="content">
            <Columns space="large">
              {order.dispatcher_name && (
                <Column width="content">
                  <DescriptionStackItem
                    label="Dispatcher"
                    icon={
                      !isDispatcherLinked && (
                        <Tooltip
                          placement="top"
                          title={
                            <>
                              Not connected to Dispatcher <br /> in Super
                              Dispatch
                            </>
                          }
                        >
                          <LinkOff
                            fontSize="small"
                            htmlColor={ColorDynamic.Dark100}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {isDispatcherLinked ? (
                      <TextOverflow size={150}>
                        {order.dispatcher_name}
                      </TextOverflow>
                    ) : (
                      order.dispatcher_name
                    )}
                  </DescriptionStackItem>
                </Column>
              )}

              {order.sales_representative && (
                <Column width="content">
                  <DescriptionStackItem label="Salesperson">
                    <TextOverflow size={150}>
                      {order.sales_representative}
                    </TextOverflow>
                  </DescriptionStackItem>
                </Column>
              )}

              {order.customer?.contact_name && (
                <Column width="content">
                  <DescriptionStackItem label="Contact">
                    <TextOverflow size={150}>
                      {order.customer.contact_name}
                    </TextOverflow>
                  </DescriptionStackItem>
                </Column>
              )}
            </Columns>
          </Column>
        )}
      </Columns>
    </Box>
  );
}
