import { Popover } from '@material-ui/core';
import { PopoverProps } from '@material-ui/core/Popover';
import { stringifyDate } from '@superdispatch/dates';
import { FormikDateField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import Order from 'shared/types/order';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';
import { OrderErrorModal } from '../error-modal/OrderErrorModal';

interface BulkFirstAvailablePickupDateUpdatePopoverProps
  extends Omit<PopoverProps, 'open'> {
  orders: Order[] | undefined;
  onSubmitSuccess: () => void;
}

export function BulkFirstAvailablePickupDateUpdatePopover({
  orders,
  anchorEl,
  onClose,
  onSubmitSuccess,
  ...props
}: BulkFirstAvailablePickupDateUpdatePopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const [error, setError] = useState<unknown>();
  const { bulkUpdateFirstAvailPickupDate } = useBulkOrderActionAPI();

  const form = useFormikEnhanced<
    {
      first_available_pickup_date?: DateTime;
    },
    unknown
  >({
    key: !!orders,
    initialValues: {
      first_available_pickup_date: undefined,
    },
    onSubmit({ first_available_pickup_date }) {
      if (orders) {
        const ids = orders.map((x) => x.id);
        const stringifiedFirstAvailablePickup = stringifyDate(
          first_available_pickup_date,
          { format: 'JodaISO' },
        );
        return bulkUpdateFirstAvailPickupDate(ids, {
          first_available_pickup_date: stringifiedFirstAvailablePickup,
        });
      }

      return Promise.reject(new Error('Order not selected'));
    },
    onSubmitSuccess() {
      if (orders) {
        addSnackbar('Date is Updated', { variant: 'success' });
        onSubmitSuccess();
      }
    },
    onSubmitFailure(failureError) {
      setError(failureError);
    },
  });

  return (
    <>
      <Popover
        {...props}
        anchorEl={anchorEl}
        open={!!orders && !!anchorEl}
        onClose={form.isSubmitting ? undefined : onClose}
      >
        <FormikProvider value={form}>
          <Form noValidate={true}>
            <Box margin="small">
              <Stack space="small">
                <FormikDateField
                  name="first_available_pickup_date"
                  label="1st Avail. Pickup Date"
                  validate={(value) =>
                    !value.stringValue ? 'Please fill this field' : undefined
                  }
                  placeholder="Select date"
                />
                <Inline horizontalAlign="right">
                  <Button
                    size="small"
                    type="submit"
                    variant="primary"
                    pending={form.isSubmitting}
                  >
                    Done
                  </Button>
                </Inline>
              </Stack>
            </Box>
          </Form>
        </FormikProvider>
      </Popover>

      <OrderErrorModal error={error} onCancel={() => setError(undefined)} />
    </>
  );
}
