import { Dialog, DialogActions, Link, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';

export function PaywallSubscriptionNotFoundDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { showCustomerDialog } = useCustomerSupport();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="large" width="552px">
        <Stack space="small">
          <Typography variant="h3">Subscription not found</Typography>
          <Typography>
            Please{' '}
            <Link
              color="primary"
              underline="none"
              href="#"
              onClick={() => {
                showCustomerDialog();
              }}
            >
              contact support
            </Link>{' '}
            to resolve the issue.
          </Typography>
        </Stack>
      </Box>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
