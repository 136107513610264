import { useResponsiveValue } from '@superdispatch/ui';
import { useEffect, useState } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useVerificationApplication } from '../data/VerificationApplicationAPI';
import { VerificationApplicationDialog } from '../verification-application-steps/VerificationApplicationDialog';
import { VerificationActionRequiredDialog } from './VerificationActionRequiredDialog';
import { VerificationApplicationDialogLegacy } from './VerificationApplicationDialogLegacy';
import { VerificationApplicationMobilePromoDialog } from './VerificationApplicationMobilePromoDialog';
import { VerificationPendingDialog } from './VerificationPendingDialog';
import { VerificationRevokedDialog } from './VerificationRevokedDialog';

export type DialogType =
  | 'get-verified'
  | 'get-verified-legacy'
  | 'revoked'
  | 'get-verified-promo'
  | 'pending-dialog'
  | 'waiting-for-customer';

interface VerificationDialogsControllerProps {
  open: boolean;
  onClose: () => void;
  action?: string;
  actionType?: string;
}

export function VerificationDialogsController({
  open,
  onClose,
  action,
  actionType,
}: VerificationDialogsControllerProps) {
  const isMobile = useResponsiveValue(true, false);
  const [currentDialog, setCurrentDialog] = useState<DialogType | null>(null);
  const isVerificationOptimizationEnabled = useFeatureToggle(
    'verification-optimization.enabled.ui',
  );

  const { data: verification } = useVerificationApplication();

  useEffect(() => {
    if (!open) {
      setCurrentDialog(null);
      return;
    }

    if (verification?.status === 'REVOKED') {
      setCurrentDialog('revoked');
    }

    if (!isVerificationOptimizationEnabled) {
      setCurrentDialog('get-verified-legacy');
    }

    if (verification?.status === 'NOT_SUBMITTED') {
      setCurrentDialog(isMobile ? 'get-verified-promo' : 'get-verified');
    } else if (verification?.status === 'PENDING') {
      setCurrentDialog('pending-dialog');
    } else if (verification?.status === 'WAITING_FOR_CUSTOMER') {
      setCurrentDialog('waiting-for-customer');
    } else if (
      verification?.status === 'REJECTED' ||
      verification?.status === 'INTERNATIONAL_SHIPPER'
    ) {
      setCurrentDialog('get-verified-legacy');
    }
  }, [verification, isVerificationOptimizationEnabled, open, isMobile]);

  return (
    <VerificationDialogs
      onClose={onClose}
      currentDialog={currentDialog}
      onDialogChange={setCurrentDialog}
      action={action}
      actionType={actionType}
    />
  );
}

interface VerificationDialogsProps {
  onClose: () => void;
  currentDialog: DialogType | null;
  onDialogChange: (dialog: DialogType) => void;
  action?: string;
  actionType?: string;
}

export function VerificationDialogs({
  currentDialog,
  onDialogChange,
  ...props
}: VerificationDialogsProps) {
  return (
    <>
      <VerificationRevokedDialog
        open={currentDialog === 'revoked'}
        {...props}
      />

      <VerificationApplicationDialogLegacy
        open={currentDialog === 'get-verified-legacy'}
        {...props}
      />

      <VerificationApplicationMobilePromoDialog
        open={currentDialog === 'get-verified-promo'}
        onGetVerifiedClicked={() => onDialogChange('get-verified')}
        onClose={props.onClose}
      />

      <VerificationApplicationDialog
        open={currentDialog === 'get-verified'}
        onClose={props.onClose}
        onSubmitSuccess={() => {
          onDialogChange('pending-dialog');
        }}
      />

      <VerificationPendingDialog
        open={currentDialog === 'pending-dialog'}
        onClose={props.onClose}
      />

      <VerificationActionRequiredDialog
        open={currentDialog === 'waiting-for-customer'}
        onClose={props.onClose}
      />
    </>
  );
}
