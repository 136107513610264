import { LinearProgress } from '@material-ui/core';
import { ColorDynamic, Column, Columns, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import styled from 'styled-components';

const StyledLinearProgress = styled(LinearProgress)`
  border-radius: 8px;
  height: 8px;
  background-color: ${ColorDynamic.Silver400};
`;

export function RecommendationsProgressBar({
  completedStepsCount,
  totalStepsCount,
}: {
  completedStepsCount: number;
  totalStepsCount: number;
}) {
  if (totalStepsCount === 0) return null;

  const hasCompletedAllSteps = completedStepsCount === totalStepsCount;
  const progress = Math.round((completedStepsCount / totalStepsCount) * 100);

  return (
    <Stack space="medium">
      <Columns>
        <Column>
          <TextBox variant="heading-3">
            {hasCompletedAllSteps
              ? 'Your posting looks good!'
              : 'Use these tips to get your load booked faster:'}
          </TextBox>
        </Column>
        <Column width="content">
          <TextBox color="secondary">
            {completedStepsCount} out of {totalStepsCount}
          </TextBox>
        </Column>
      </Columns>
      <StyledLinearProgress variant="determinate" value={progress} />
    </Stack>
  );
}
