import { Link, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { Protect } from 'shared/data/UserPermissions';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { OrdersListHeader } from './core/list/OrdersListHeader';

const StyledContent = styled(Box)`
  margin: auto;
  text-align: center;
`;

const StyledEmoji = styled.span`
  font-size: 32px;
`;

export function OrdersEmptyState({ title }: { title?: string }) {
  const navigate = useNavigate();
  const { user } = useUserState();
  const shipperType = user?.shipper.shipper_type;

  return (
    <PageLayout header={<OrdersListHeader title={title} />}>
      <StyledContent padding="small">
        <Protect permission="canCreate" entity="ORDER">
          {shipperType === 'BROKER' && (
            <Stack space="large">
              <Stack>
                {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                <StyledEmoji role="img" aria-label="waving hand emoji">
                  {/* wave emoji code */}
                  &#128075;
                </StyledEmoji>
                <Typography variant="h4">Welcome to Super Dispatch!</Typography>
                <Stack space="small">
                  <Typography variant="h2">
                    Post now to get your first load on the road
                  </Typography>
                  <Link
                    color="primary"
                    href="https://support.superdispatch.com/hc/en-us/articles/35696862649875-How-to-Create-and-Post-an-Order-in-Shipper-TMS#h_d145182b65"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn How to Create and Post
                  </Link>
                </Stack>
              </Stack>

              <Button
                size="large"
                onClick={() => {
                  trackEventLegacy('Clicked Create Order');
                  navigate('/orders/create');
                }}
              >
                Create Order
              </Button>
            </Stack>
          )}

          {shipperType === 'CUSTOMER' && (
            <Typography color="textSecondary" variant="body2" align="center">
              This page shows list of orders once
              <br /> they are added by shipper.
            </Typography>
          )}
        </Protect>
      </StyledContent>
    </PageLayout>
  );
}
