import { Image } from '@superdispatch/ui';
import { HTMLAttributes } from 'react';
import SuperPayDark from 'shared/icons/SuperPayLabel-dark.png';
import SuperPay from 'shared/icons/SuperPayLabel.png';
import styled from 'styled-components';

const SuperPayLabelContainer = styled.div`
  display: inline;
`;

const SuperPayImg = styled(Image)`
  vertical-align: middle;
`;

export function SuperPayLogo({
  width = '70px',
  ...props
}: HTMLAttributes<HTMLDivElement> & { width?: string }) {
  return (
    <SuperPayLabelContainer {...props}>
      <SuperPayImg
        src={SuperPay}
        width={width}
        aria-label="SuperPay"
        srcDark={SuperPayDark}
      />
    </SuperPayLabelContainer>
  );
}
