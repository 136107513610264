import { Typography } from '@material-ui/core';
import { Comment } from '@material-ui/icons';
import { ColorDynamic, Inline, Stack } from '@superdispatch/ui';
import { startCase } from 'lodash';
import {
  OrderRequestDTO,
  OrderRequestStatus,
} from 'orders/data/dto/OrderRequestDTO';
import { useUserState } from 'shared/data/AppUserState';
import styled from 'styled-components';

const Container = styled.div<{ isMuted?: boolean }>`
  padding: 2px 4px;
  border-radius: 4px;
  max-width: 500px;
  width: max-content;
  overflow-wrap: break-word;
  margin-left: 24px;
  background-color: ${({ isMuted }) =>
    isMuted ? ColorDynamic.Silver200 : ColorDynamic.Blue50};

  @media (max-width: 600px) {
    max-width: 310px;
  }
`;

function formatCancelReason(reason: string) {
  switch (reason) {
    case 'UNIT_NOT_READY':
      return 'Unit Not Ready';
    case 'UNKNOWN_INOPERABLE_VEHICLE':
      return 'Unknown Inoperable Vehicle';
    case 'CANNOT_MEET_DATES':
      return 'Cannot Meet Dates';
    case 'LOW_PRICE':
      return 'Low Price';
    case 'CHANGED_ROUTE':
      return 'Changed Route';
    default:
      return startCase(reason);
  }
}

function getRequestDeclineReasons(
  status: OrderRequestStatus,
  request: OrderRequestDTO,
): { reason?: string; comment: string | null } | null {
  switch (status) {
    case 'OFFER_DECLINED':
      return {
        reason: request.offer_decline_reasons?.replaceAll(',', ', '),
        comment: request.offer_decline_comment,
      };
    case 'OFFER_CANCELED_BY_CARRIER':
      return {
        reason: request.offer_cancel_reasons
          ?.map((reason) => formatCancelReason(reason))
          .join(', '),
        comment: request.offer_cancel_comment,
      };
    case 'REQUEST_DECLINED_BY_BROKER':
      return { comment: request.broker_decline_reason };
    default:
      return null;
  }
}

export function RequestDeclineReasons({
  status,
  request,
}: {
  status: OrderRequestStatus;
  request: OrderRequestDTO;
}) {
  const { user } = useUserState();
  const { reason, comment } = getRequestDeclineReasons(status, request) || {};

  if (!reason && !comment) return null;

  return (
    <Stack space="xxsmall">
      <Inline verticalAlign="center" noWrap={true}>
        <Comment color="action" fontSize="small" />
        <Typography variant="body1">{reason || user?.shipper.name}</Typography>
      </Inline>
      {comment && (
        <Container isMuted={status === 'REQUEST_DECLINED_BY_BROKER'}>
          <Typography>{comment}</Typography>
        </Container>
      )}
    </Stack>
  );
}
