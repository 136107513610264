import { Box, Grid, Typography } from '@material-ui/core';
import { ColorDynamic, Stack } from '@superdispatch/ui';
import styled from 'styled-components';

const GutterRoot = styled.div`
  height: ${({ multilineTitle }: { multilineTitle?: boolean }) =>
    multilineTitle ? '75%' : '100%'};
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GutterPoint = styled.div`
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  &[data-start='true'] {
    background: ${ColorDynamic.Yellow300};
  }
  &[data-end='true'] {
    background: ${ColorDynamic.Green300};
  }
`;

const GutterLine = styled.div`
  flex: 1;
  width: 2px;
  background: repeating-linear-gradient(
    ${ColorDynamic.Dark100},
    ${ColorDynamic.Dark100} 3px,
    transparent 3px,
    transparent 5px
  );
  margin: 2px 0;
`;

const Title = styled.div`
  width: ${({ fullWidth }: { fullWidth?: boolean }) =>
    fullWidth ? '100%' : 'initial'};
  color: ${ColorDynamic.Dark500};
  margin-right: 4px;
`;

const SubTitle = styled.div`
  color: ${ColorDynamic.Dark300};
  margin-left: 4px;
`;

const Separator = styled.div`
  color: ${ColorDynamic.Dark300};
`;

function Gutter({ multilineTitle }: { multilineTitle?: boolean }) {
  return (
    <GutterRoot multilineTitle={multilineTitle}>
      <GutterPoint data-start="true" />
      <GutterLine />
      <GutterPoint data-end="true" />
    </GutterRoot>
  );
}

interface Waypoint {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
}

export interface WaypointsProps {
  from: Waypoint;
  to: Waypoint;
  separator?: string | JSX.Element;
  multilineTitle?: boolean;
  fullWidthTitle?: boolean;
}

export function Waypoints({
  from,
  to,
  separator,
  multilineTitle,
  fullWidthTitle,
}: WaypointsProps) {
  return (
    <Grid container={true} spacing={1} wrap="nowrap" aria-label="waypoints">
      <Grid item={true}>
        <Gutter multilineTitle={multilineTitle} />
      </Grid>

      <Grid item={true} xs={true}>
        <Stack>
          <Box display="flex">
            <Title fullWidth={fullWidthTitle}>
              <Typography
                noWrap={true}
                component="div"
                aria-label="waypoint start title"
              >
                {from.title}
              </Typography>
            </Title>

            {separator && (
              <Separator>
                <Typography variant="body2">{separator}</Typography>
              </Separator>
            )}

            <SubTitle>
              <Typography
                noWrap={true}
                variant="body2"
                aria-label="waypoint start subtitle"
                component="div"
              >
                {from.subtitle}
              </Typography>
            </SubTitle>
          </Box>

          <Box display="flex">
            <Title fullWidth={fullWidthTitle}>
              <Typography
                noWrap={true}
                component="div"
                aria-label="waypoint end title"
              >
                {to.title}
              </Typography>
            </Title>

            {separator && (
              <Separator>
                <Typography variant="body2">{separator}</Typography>
              </Separator>
            )}

            <SubTitle>
              <Typography
                noWrap={true}
                variant="body2"
                aria-label="waypoint end subtitle"
                component="div"
              >
                {to.subtitle}
              </Typography>
            </SubTitle>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
