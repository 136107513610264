import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import {
  usePrivateNetworkAPIs,
  usePrivateNetworkGroups,
} from 'manage-carriers/data/PrivateNetworkAPI';
import { formatGroupNames } from 'manage-carriers/data/PrivateNetworkDTO';
import { useEffect, useMemo, useState } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';

interface RemovePrivateNetworkFormProps {
  groupGuid?: string;
  carrierGuids: string[];
  onSubmitting: (value: boolean) => void;
  onSubmitSuccess: () => void;
  onCancel: () => void;
}

function RemovePrivateNetworkForm({
  groupGuid,
  carrierGuids,
  onSubmitSuccess,
  onSubmitting,
  onCancel,
}: RemovePrivateNetworkFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data } = usePrivateNetworkGroups();
  const {
    removeCarrierFromPrivateNetwork,
    removeCarrierFromPrivateNetworkGroup,
  } = usePrivateNetworkAPIs();
  const carrierTitle = formatPlural(
    carrierGuids.length,
    'Carrier',
    `${carrierGuids.length} carriers`,
  );

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit() {
      if (groupGuid) {
        return removeCarrierFromPrivateNetworkGroup(groupGuid, carrierGuids);
      }

      return removeCarrierFromPrivateNetwork(carrierGuids);
    },
    onSubmitSuccess() {
      if (groupGuid) {
        const name = formatGroupNames([groupGuid], data?.groups).join();
        addSnackbar(
          `${carrierGuids.length} ${formatPlural(
            carrierGuids.length,
            'carrier',
            'carriers',
          )} removed from '${name}' group`,
        );
        trackEvent('Shipper Removed Carrier from Private Network Group', {
          carrier_count: carrierGuids.length,
        });
      } else {
        addSnackbar(
          `${carrierGuids.length} ${formatPlural(
            carrierGuids.length,
            'carrier',
            'carriers',
          )} removed from Private Network`,
        );
        trackEvent('Shipper Removed Carrier from Private Network', {
          carrier_count: carrierGuids.length,
        });
      }
      onSubmitSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  useEffect(() => {
    onSubmitting(formik.isSubmitting);
  }, [formik.isSubmitting]);

  return (
    <DialogContent>
      <Typography>
        {groupGuid
          ? `${carrierTitle} will lose access to Private Loadboard dedicated to this group.`
          : `${carrierTitle} will lose access to your Private Loadboard and be removed from any groups they belong to.`}
      </Typography>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          onClick={onCancel}
          variant="neutral"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          pending={formik.isSubmitting}
          onClick={() => formik.submitForm()}
        >
          Remove
        </Button>
      </DialogActions>
    </DialogContent>
  );
}

interface RemovePrivateNetworkButtonProps {
  groupGuid?: string;
  carrierGuids: string[];
  onSubmitSuccess: () => void;
}

export function RemovePrivateNetworkButton({
  groupGuid,
  carrierGuids,
  onSubmitSuccess,
}: RemovePrivateNetworkButtonProps) {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { data } = usePrivateNetworkGroups();
  const groupName = useMemo(
    () => groupGuid && formatGroupNames([groupGuid], data?.groups).join(),
    [data, groupGuid],
  );
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="neutral"
      >
        {groupGuid ? 'Remove from Group' : 'Remove from Private Network'}
      </Button>

      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => {
          if (!submitting) {
            setOpen(false);
          }
        }}
      >
        <DialogTitle>
          {groupName
            ? `Remove from '${groupName}' group?`
            : 'Remove from Private Network?'}
        </DialogTitle>
        <RemovePrivateNetworkForm
          groupGuid={groupGuid}
          carrierGuids={carrierGuids}
          onSubmitting={setSubmitting}
          onSubmitSuccess={onSubmitSuccess}
          onCancel={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
}
