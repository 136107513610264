import { IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon, TurnedInNot } from '@material-ui/icons';
import { ColorDynamic, Tag } from '@superdispatch/ui';
import styled from 'styled-components';
import { useOrderTag } from './data/OrdersTagAPI';

const TagContent = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 4px;
  }
  & > *:first-child {
    margin-left: 0;
  }
`;

const DeleteIcon = styled(CloseIcon)`
  width: 12px;
  height: 12px;
`;

interface OrderTagProps {
  tagId: number;
  hideIcon?: boolean;
  onDelete?: () => void;
}

export function OrderTag({ tagId, hideIcon = false, onDelete }: OrderTagProps) {
  const { data } = useOrderTag(tagId);

  if (!data) {
    return null;
  }

  const { name, color } = data;

  return (
    <Tag color={color} variant="subtle">
      <TagContent>
        {!hideIcon && <TurnedInNot fontSize="small" />}

        <Typography variant="body1">{name}</Typography>
        {onDelete && (
          <IconButton size="small" onClick={onDelete}>
            <DeleteIcon
              fillOpacity={0}
              stroke={ColorDynamic.Dark100}
              strokeWidth={2}
            />
          </IconButton>
        )}
      </TagContent>
    </Tag>
  );
}
