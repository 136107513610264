import { Typography } from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { formatCustomerType } from '@superdispatch/sdk';
import { Inline, Tag, useUID } from '@superdispatch/ui';
import { startCase } from 'lodash';
import * as React from 'react';
import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import Order from 'shared/types/order';
import { Divider } from 'shared/ui/Divider';
import { makeAddressCityStateZip } from 'shared/utils/AddressUtils';
import { NoData } from './NoData';
import { OrderViewContactInfo } from './OrderViewContactInfo';
import { OrderViewNote } from './OrderViewNote';
import { OrderViewVenue } from './OrderViewVenue';

export interface StepProps {
  variant: 'pickup' | 'delivery';
  order: Order;
}

export const OrderViewStep: React.ComponentType<StepProps> = ({
  variant,
  order,
}) => {
  const uid = useUID();
  const step = order[variant];
  const { user } = useUserState();

  const origin = useMemo(
    () => order.pickup && makeAddressCityStateZip(order.pickup.venue),
    [order.pickup],
  );

  const destination = useMemo(
    () => order.delivery && makeAddressCityStateZip(order.delivery.venue),
    [order.delivery],
  );

  const hasVenueData = useMemo(
    () => step?.venue && Object.values(step.venue).some((value) => !!value),
    [step],
  );

  const route: [string, string] | undefined =
    origin && destination ? [origin, destination] : undefined;

  if (!step || !hasVenueData) {
    return (
      <Flex flexDirection="column" aria-labelledby={uid}>
        <Typography id={uid} variant="h3">
          {startCase(variant)}
        </Typography>

        <NoData my={3} text={`No ${variant} data yet`} />
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" aria-labelledby={uid}>
      <Inline space="small" verticalAlign="center">
        <Typography id={uid} variant="h3">
          {startCase(variant)}
        </Typography>

        {variant === 'delivery' && order.customer?.bill_the_delivery && (
          <Tag color="purple" variant="subtle">
            Billing Contact
          </Tag>
        )}
      </Inline>

      {step.venue && (
        <>
          <OrderViewVenue
            mt={2}
            name={step.venue.name}
            guid={step.counterparty_guid}
            type={step.counterparty_type}
            info={
              (step.venue.business_type &&
                user?.order_form_settings[variant].venue
                  .is_business_type_visible &&
                formatCustomerType(step.venue.business_type)) ||
              null
            }
            address={step.venue.address}
            city={step.venue.city}
            state={step.venue.state}
            zip={step.venue.zip}
            route={route}
          />

          <OrderViewContactInfo
            name={step.venue.contact_name}
            info={step.venue.contact_title}
            phone={step.venue.contact_phone}
            mobilePhone={step.venue.contact_mobile_phone}
            email={step.venue.contact_email}
            ContainerProps={{ mt: 3, 'aria-label': 'venue contact section' }}
          />
        </>
      )}

      {step.notes && (
        <>
          <Divider my={3} />

          <Typography variant="body2" color="textSecondary">
            Notes for Carrier
          </Typography>

          <OrderViewNote
            mt={2}
            aria-label={`${variant} notes`}
            notes={step.notes}
          />
        </>
      )}
    </Flex>
  );
};
