import { Link } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { CarrierRatingDrawer } from 'core/ratings/CarrierRatingDrawer';
import { useInvalidateCurrentOnboardingStep } from 'onboarding/OnboardingAPI';
import { AutoRepostNotifications } from 'orders/core/actions/AutoRepostNotifications';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { isAPIError, isAPIValidationError } from 'shared/errors/APIError';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { APIValidationErrorMessage } from 'shared/errors/APIValidationErrorMessage';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import Order from 'shared/types/order';
import { useIsShipperVerifiedOrInternational } from 'shipper-profile/data/VerificationApplicationUtils';
import { useShipperProfile } from '../../../shipper-profile/data/ShipperProfileAPI';
import { SuperPayCancelActionDialog } from '../../../superpay/cancel/SuperPayCancelDialog';
import { useSingleOrderActionAPI } from '../../data/OrderActionAPI';
import { OrderErrors } from '../error-modal/OrderErrors';
import { SingleSendOfferDrawer } from '../send-offer/SingleSendOfferDrawer';
import { BuildLoadsDrawer } from './BuildLoads/BuildLoadsDrawer';
import { CancelOfferDialog } from './CancelOfferDialog';
import { CancelOrderDialog } from './CancelOrderDialog';
import { SingleDeleteOrderDialog } from './DeleteOrderDialog';
import { useIsEscalationAvailable } from './escalation/EscalationUtils';
import { ResolveEscalationDrawer } from './escalation/ResolveEscalationDrawer';
import { SubmiteEscalationDrawer } from './escalation/SubmitEscalationDrawer';
import { InstantDispatchDrawer } from './InstantDispatchDrawer';
import { InstantDispatchOpener } from './InstantDispatchOpener';
import { InstantDispatchPostLoadboardDialog } from './InstantDispatchPostLoadboardDialog';
import { SingleMarkAsAcceptedDrawer } from './MarkAsAcceptedDrawer';
import { SingleMarkAsCarrierInvoicedPopover } from './MarkAsCarrierInvoicedPopover';
import { SingleMarkAsDeliveredPopover } from './MarkAsDeliveredPopover';
import { MarkAsPaidDrawer } from './MarkAsPaidDrawer';
import { SingleMarkAsPaymentOnHold } from './MarkAsPaymentOnHoldDialog';
import MarkAsPendingDialog from './MarkAsPendingDialog';
import { SingleMarkAsPickedUpPopover } from './MarkAsPickedUpPopover';
import { OrderActionSource } from './OrderActionsAnalytics';
import {
  getInstantActionConfig,
  isInstantAction,
  OrderInstantAction,
} from './OrderInstantActions';
import { useOrderValidateAction } from './OrderValidateAction';
import { PriceNegotiationDrawer } from './price-negotiation/PriceNegotiationDrawer';
import { SingleSendInvoiceDrawer } from './SendInvoiceDrawer';
import { SingleSuperPayNowDialog } from './superpay-now/SingleSuperPayNowDialog';
import { SuperPaySuspendDialog } from './SuperPaySuspendDialog';
import { UnmarkAsDeliveredDialog } from './UnmarkAsDeliveredDialog';

type OrderValidateAction =
  | 'mark_as_accepted'
  | 'mark_as_on_hold'
  | 'send_offer';

type OrderModalAction =
  | 'auto_repost_notifications'
  | 'build_loads'
  | 'cancel_offer'
  | 'cancel_order'
  | 'cancel_superpay'
  | 'delete_order'
  | 'instant_dispatch'
  | 'instant_dispatch_opener'
  | 'instant_dispatch_post_loadboard'
  | 'mark_as_paid'
  | 'mark_as_pending'
  | 'mark_as_accepted'
  | 'mark_as_hold_custom_reason'
  | 'price_negotiation'
  | 'rate_carrier'
  | 'send_offer'
  | 'submit_escalation'
  | 'resolve_escalation'
  | 'send_customer_invoice'
  | 'superpay_now'
  | 'superpay_suspend_send_offer'
  | 'superpay_suspend_post_order'
  | 'unmark_as_delivered';

type OrderPopoverAction =
  | 'price_update' // TODO implement price update popover
  | 'mark_as_picked_up'
  | 'mark_as_delivered'
  | 'mark_as_carrier_invoiced';

const cdCredentialsErrorsIds = [
  'CENTRAL_DISPATCH_LOGIN_REQUIRED',
  'CENTRAL_DISPATCH_UID_REQUIRED',
  'CENTRAL_DISPATCH_PASSWORD_REQUIRED',
];

export type OrderActionType =
  | OrderInstantAction
  | OrderModalAction
  | OrderPopoverAction;

interface OrderActionsContext {
  type?: OrderActionType;
  order?: Order;
  isValidating: boolean;
  isSubmitting: boolean;
  onActionComplete: (payload: OrderActionCompletePayload) => void;
  onAction: (
    type: OrderActionType,
    order: Order,
    element?: Element,
    arg?: Record<string, unknown>,
  ) => void;
  popoverAction?: {
    type: OrderPopoverAction;
    order: Order;
    anchorEl: Element;
  };
}

export function isPopoverAction(type: string): type is OrderPopoverAction {
  return [
    'mark_as_picked_up',
    'mark_as_delivered',
    'mark_as_carrier_invoiced',
  ].includes(type);
}

export function isValidateAction(type: string): type is OrderValidateAction {
  return ['mark_as_accepted', 'mark_as_on_hold', 'send_offer'].includes(type);
}

const useAutoRepostNotificaion = () => {
  const autoRepostNotificationCount = Number(
    useStorageValue('auto_repost_notification_count') || 0,
  );
  const isAutoRepostNotificationDismissed = Boolean(
    useStorageValue('is_auto_repost_notification_dismissed'),
  );

  return useCallback(
    (
      order: Order,
      setModalAction: (action: {
        type: OrderModalAction;
        order: Order;
      }) => void,
    ) => {
      const newCount = autoRepostNotificationCount + 1;
      const shouldShowModal =
        autoRepostNotificationCount === 0 || autoRepostNotificationCount === 5;
      const shouldDismiss = newCount > 5;

      if (
        (order.status !== 'canceled' && order.status !== 'declined') ||
        isAutoRepostNotificationDismissed
      ) {
        return;
      }

      if (shouldShowModal) {
        setModalAction({ type: 'auto_repost_notifications', order });
      }

      writeStorageItem('auto_repost_notification_count', newCount.toString());
      if (shouldDismiss) {
        writeStorageItem('is_auto_repost_notification_dismissed', 'true');
      }
    },
    [autoRepostNotificationCount, isAutoRepostNotificationDismissed],
  );
};

const Context = createContext<OrderActionsContext | null>(null);

export function useOrderActionsContext() {
  const context = useContext(Context);

  if (context === null) {
    throw new Error('useOrderActionsContext is used outside of context');
  }

  return context;
}

export interface OrderActionCompletePayload {
  type: OrderActionType;
  payload: { order: Order; updatedOrder?: Order };
}

interface OrderActionsProviderProps {
  children: ReactNode;
  source: OrderActionSource;
  onActionComplete: (payload: OrderActionCompletePayload) => void;
}

export function OrderActionsProvider({
  source,
  children,
  onActionComplete,
}: OrderActionsProviderProps) {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const orderActionAPI = useSingleOrderActionAPI();
  const [error, setError] = useState<unknown>();
  const { user } = useUserState();
  const { data: shipper } = useShipperProfile({ staleTime: Infinity });
  const buildLoadsEnabled = useFeatureToggle('build-loads.enabled.ui');
  const { isShipper, isBroker } = useIsEscalationAvailable();
  const hasSeenPostingView = useStorageValue('has_seen_posting_view');
  const invalidateCurrentOnboardingStep = useInvalidateCurrentOnboardingStep();
  const isVerifiedOrInternational = useIsShipperVerifiedOrInternational();
  const handleAutoRepostNotification = useAutoRepostNotificaion();

  const isRestrictRating = useFeatureToggle(
    'compliance.restrict-ratings-for-unverified-shippers.enabled',
  );
  const isPaymentMethodAndTermsAdoptionEnabled = useFeatureToggle(
    'payment_method_terms_adoption.enabled.ui',
  );

  const isRateAllowed = !isRestrictRating || isVerifiedOrInternational;

  const [validateAction, setValidateAction] = useState<{
    type: OrderValidateAction;
    order: Order;
  }>();

  const [instantAction, setInstantAction] = useState<{
    type: OrderInstantAction;
    order: Order;
    args?: Record<string, unknown>;
  }>();

  const [popoverAction, setPopoverAction] = useState<{
    type: OrderPopoverAction;
    order: Order;
    anchorEl: Element;
  }>();

  const [modalAction, setModalAction] = useState<{
    type: OrderModalAction;
    order: Order;
  }>();

  const instantActionConfig = useMemo(() => {
    if (instantAction?.type) {
      return getInstantActionConfig(instantAction.type, source, user, shipper, {
        ...instantAction.args,
        isPaymentMethodAndTermsAdoptionEnabled,
      });
    }
    return undefined;
  }, [
    instantAction,
    source,
    user,
    shipper,
    isPaymentMethodAndTermsAdoptionEnabled,
  ]);

  const instantForm = useFormikEnhanced<{ order?: Order }, Order | undefined>({
    initialValues: { order: instantAction?.order },
    onSubmit({ order }) {
      if (order && instantActionConfig) {
        return instantActionConfig.submit(orderActionAPI, order);
      }
      return Promise.reject(new Error('Order not found'));
    },
    onSubmitSuccess(updatedOrder) {
      addSnackbar(instantActionConfig?.message, { variant: 'success' });
      setInstantAction(undefined);

      if (instantAction) {
        onActionComplete({
          type: instantAction.type,
          payload: { order: instantAction.order, updatedOrder },
        });
      }

      if (
        instantAction?.type === 'post_to_all' ||
        instantAction?.type === 'post_to_sdlb'
      ) {
        handleAutoRepostNotification(instantAction.order, setModalAction);

        if (hasSeenPostingView) {
          addSnackbar(
            <Inline space="xxlarge">
              Posted to Super Loadboard
              <Button
                size="small"
                variant="inverted"
                onClick={() =>
                  navigate(
                    `/orders/view/${instantAction.order.guid}/load_requests`,
                  )
                }
              >
                View Posting
              </Button>
            </Inline>,
          );
        } else {
          writeStorageItem('has_seen_posting_view', 'true');
          navigate(`/orders/view/${instantAction.order.guid}/load_requests`);
        }
      }

      invalidateCurrentOnboardingStep();
    },
    onSubmitFailure(failureError, { order }) {
      if (
        isAPIError(failureError) &&
        failureError.error_id === 'SHIPPER_NOT_VERIFIED_TO_POST_ORDERS'
      ) {
        setError(failureError);
      } else if (isAPIValidationError(failureError)) {
        addSnackbar(
          <Stack>
            The following information is missing. Edit the order and provide
            this information:
            <APIValidationErrorMessage error={failureError} />{' '}
            <Link
              style={{ textDecoration: 'none' }}
              href={`/orders/edit/${order?.guid || ''}`}
            >
              <Button size="small" variant="neutral">
                Edit Order
              </Button>
            </Link>
          </Stack>,
          {
            variant: 'error',
          },
        );
      } else if (
        isAPIError(failureError) &&
        failureError.error_id &&
        cdCredentialsErrorsIds.includes(failureError.error_id)
      ) {
        addSnackbar(
          <Stack space="small">
            To post to external load boards, please add your credentials.
            <Button
              size="small"
              variant="neutral"
              onClick={() => {
                window.open('/profile/cd-integration/edit/', '_blank');
              }}
            >
              Add Credentials
            </Button>
          </Stack>,
          { variant: 'error' },
        );
      } else {
        addSnackbar(<APIErrorMessage error={failureError} />, {
          variant: 'error',
        });
      }
    },
  });

  const { isValidating, onValidate } = useOrderValidateAction({
    onComplete() {
      if (validateAction) {
        const { type, order } = validateAction;
        setValidateAction(undefined);

        if (type === 'mark_as_accepted') {
          if (order.active_offer) {
            setInstantAction({ type, order });
          } else {
            setModalAction({ type, order });
          }
        } else if (isInstantAction(type)) {
          setInstantAction({ type, order });
        } else {
          setModalAction({ type, order });
        }
      }
    },
    onFailure(validationError) {
      setError(validationError);
    },
  });

  const { handleSubmit } = instantForm;

  useEffect(() => {
    if (instantAction) {
      handleSubmit();
    }
  }, [handleSubmit, instantAction]);

  useEffect(() => {
    if (validateAction) {
      onValidate(validateAction.type, [validateAction.order]);
    }
  }, [onValidate, validateAction]);

  function hideModal() {
    setModalAction(undefined);
  }

  function hidePopover() {
    setPopoverAction(undefined);
  }

  function handleActionComplete(type: OrderActionType) {
    return (updatedOrder?: Order) => {
      if (modalAction) {
        setModalAction(undefined);
        onActionComplete({
          type,
          payload: { updatedOrder, order: modalAction.order },
        });
      }

      if (popoverAction) {
        setPopoverAction(undefined);
        onActionComplete({
          type,
          payload: { updatedOrder, order: popoverAction.order },
        });
      }
    };
  }

  function continueToRateCarrier(
    handleComplete: (updatedOrder?: Order) => void,
  ) {
    return (updatedOrder: Order) => {
      handleComplete(updatedOrder);
      if (updatedOrder.active_offer?.carrier_guid && isRateAllowed) {
        setModalAction({
          type: 'rate_carrier',
          order: updatedOrder,
        });
      }
    };
  }

  function getModalOrder(x: OrderModalAction) {
    return modalAction?.type === x ? modalAction.order : undefined;
  }

  function getPopoverProps(x: OrderPopoverAction) {
    return {
      onClose: hidePopover,
      onSubmitSuccess: handleActionComplete(x),
      order: popoverAction?.type === x ? popoverAction.order : undefined,
      anchorEl: popoverAction?.type === x ? popoverAction.anchorEl : null,
      anchorOrigin: { horizontal: 'left', vertical: 'top' } as const,
      transformOrigin: { horizontal: 'right', vertical: 'top' } as const,
    };
  }

  const ctx = useMemo<OrderActionsContext>(() => {
    const action =
      instantAction || modalAction || popoverAction || validateAction;
    return {
      popoverAction,
      type: action?.type,
      order: action?.order,
      isValidating,
      onActionComplete,
      isSubmitting: instantForm.isSubmitting,
      onAction(type, order, element, args) {
        if (type === 'mark_as_pending') {
          if (order.active_offer) {
            setInstantAction({ type, order, args });
          } else {
            setModalAction({ type, order });
          }
        } else if (isValidateAction(type)) {
          setValidateAction({ type, order });
        } else if (isInstantAction(type)) {
          setInstantAction({ type, order, args });
        } else if (isPopoverAction(type)) {
          if (element) {
            setPopoverAction({ type, order, anchorEl: element });
          }
        } else {
          setModalAction({ type, order });
        }
      },
    };
  }, [
    isValidating,
    onActionComplete,
    instantForm.isSubmitting,

    modalAction,
    popoverAction,
    instantAction,
    validateAction,

    setModalAction,
    setInstantAction,
    setPopoverAction,
  ]);

  return (
    <Context.Provider value={ctx}>
      {children}

      <SingleDeleteOrderDialog
        onCancel={hideModal}
        order={getModalOrder('delete_order')}
        onSubmitSuccess={handleActionComplete('delete_order')}
      />

      <CancelOfferDialog
        onCancel={hideModal}
        order={getModalOrder('cancel_offer')}
        onSubmitSuccess={handleActionComplete('cancel_offer')}
      />

      <CancelOrderDialog
        onCancel={hideModal}
        order={getModalOrder('cancel_order')}
        onSubmitSuccess={handleActionComplete('cancel_order')}
      />

      <SingleMarkAsAcceptedDrawer
        onClose={hideModal}
        order={getModalOrder('mark_as_accepted')}
        onSubmitSuccess={handleActionComplete('mark_as_accepted')}
      />

      <MarkAsPaidDrawer
        onCancel={hideModal}
        order={getModalOrder('mark_as_paid')}
        onSubmitSuccess={continueToRateCarrier(
          handleActionComplete('mark_as_paid'),
        )}
      />

      <CarrierRatingDrawer
        onClose={hideModal}
        order={getModalOrder('rate_carrier')}
        onSubmitSuccess={handleActionComplete('rate_carrier')}
      />

      <MarkAsPendingDialog
        onClose={hideModal}
        order={getModalOrder('mark_as_pending')}
        onSubmitSuccess={handleActionComplete('mark_as_pending')}
      />

      <InstantDispatchOpener order={getModalOrder('instant_dispatch_opener')} />

      <InstantDispatchDrawer
        source={source}
        onClose={hideModal}
        order={getModalOrder('instant_dispatch')}
        onSubmitSuccess={handleActionComplete('instant_dispatch')}
      />

      {isBroker && (
        <SubmiteEscalationDrawer
          source={source}
          onClose={hideModal}
          order={getModalOrder('submit_escalation')}
          onSubmitSuccess={handleActionComplete('submit_escalation')}
        />
      )}

      {isShipper && (
        <ResolveEscalationDrawer
          source={source}
          onClose={hideModal}
          order={getModalOrder('resolve_escalation')}
          onSubmitSuccess={handleActionComplete('resolve_escalation')}
        />
      )}

      <InstantDispatchPostLoadboardDialog
        onClose={hideModal}
        order={getModalOrder('instant_dispatch_post_loadboard')}
        onSubmitSuccess={handleActionComplete(
          'instant_dispatch_post_loadboard',
        )}
      />

      <UnmarkAsDeliveredDialog
        onCancel={hideModal}
        order={getModalOrder('unmark_as_delivered')}
        onSubmitSuccess={handleActionComplete('unmark_as_delivered')}
      />

      <SingleMarkAsPaymentOnHold
        onClose={hideModal}
        order={getModalOrder('mark_as_hold_custom_reason')}
        onSubmitSuccess={handleActionComplete('mark_as_hold_custom_reason')}
      />

      <SingleSuperPayNowDialog
        onClose={hideModal}
        order={getModalOrder('superpay_now')}
        onSubmitSuccess={handleActionComplete('superpay_now')}
      />

      <SuperPaySuspendDialog
        onClose={hideModal}
        order={getModalOrder('superpay_suspend_send_offer')}
        title="Cannot Send Offer"
      />

      <SuperPaySuspendDialog
        onClose={hideModal}
        order={getModalOrder('superpay_suspend_post_order')}
        title="Cannot Post Order"
      />

      <SuperPayCancelActionDialog
        onClose={hideModal}
        order={getModalOrder('cancel_superpay')}
      />

      <SingleSendInvoiceDrawer
        onClose={hideModal}
        order={getModalOrder('send_customer_invoice')}
        onSubmitSuccess={handleActionComplete('send_customer_invoice')}
      />

      <SingleSendOfferDrawer
        source={source}
        onClose={hideModal}
        order={getModalOrder('send_offer')}
        onSubmitSuccess={handleActionComplete('send_offer')}
      />

      <PriceNegotiationDrawer
        source={source}
        onClose={hideModal}
        order={getModalOrder('price_negotiation')}
        onSubmitSuccess={handleActionComplete('price_negotiation')}
      />

      {buildLoadsEnabled && getModalOrder('build_loads') && (
        <BuildLoadsDrawer
          onClose={hideModal}
          orderGUID={getModalOrder('build_loads')?.guid}
          onSubmitSuccess={handleActionComplete('build_loads')}
        />
      )}

      <SingleMarkAsPickedUpPopover {...getPopoverProps('mark_as_picked_up')} />

      <SingleMarkAsDeliveredPopover {...getPopoverProps('mark_as_delivered')} />

      <SingleMarkAsCarrierInvoicedPopover
        {...getPopoverProps('mark_as_carrier_invoiced')}
      />

      <AutoRepostNotifications
        onClose={hideModal}
        order={getModalOrder('auto_repost_notifications')}
      />

      <OrderErrors
        error={error}
        onCancel={() => setError(undefined)}
        action={instantAction?.type || validateAction?.type}
        actionType="single"
      />
    </Context.Provider>
  );
}
