import { yupNumber, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export const invoiceStatuses = [
  'PAID',
  'POSTED',
  'PAYMENT_DUE',
  'NOT_PAID',
  'VOIDED',
  'PENDING',
] as const;

export type InvoiceStatus = typeof invoiceStatuses[number];

export const invoiceSchema = yupObject({
  date: yupString(),
  status: yupString().oneOf(invoiceStatuses),
  number: yupString(),
  total: yupNumber().nullable(),
});

export type InvoiceDTO = InferType<typeof invoiceSchema>;
