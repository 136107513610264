import { Tag } from '@superdispatch/ui';
import { InvoiceStatus } from './InvoicesDTO';

const statusConfig = {
  PAID: { color: 'green', label: 'Paid' },
  POSTED: { color: 'blue', label: 'Posted' },
  PAYMENT_DUE: { color: 'red', label: 'Payment Due' },
  NOT_PAID: { color: 'red', label: 'Not Paid' },
  VOIDED: { color: 'grey', label: 'Voided' },
  pending: { color: 'purple', label: 'Pending' },
} as const;

interface StatusTagProps {
  status: InvoiceStatus;
}

export function StatusTag({ status }: StatusTagProps) {
  const config = statusConfig[status];

  return (
    <Tag color={config.color} variant="subtle">
      {config.label}
    </Tag>
  );
}
