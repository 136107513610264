import { renderChildren } from '@superdispatch/ui';
import { ReactNode } from 'react';
import {
  Navigate,
  Outlet,
  UIMatch,
  useLocation,
  useMatches,
} from 'react-router-dom';
import { useAppToken } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { stringifySearchQuery, useQuery } from 'shared/helpers/RouteHelpers';
import { AppBlankLayout, AppSidebarLayout } from './AppLayouts';

export function ProtectedRoute({ children }: { children: ReactNode }) {
  const { isBasicTier } = useProductTiers();

  if (isBasicTier) {
    return <Navigate to="/orders" />;
  }

  return renderChildren(children);
}

export function UnauthenticatedRoutesContainer() {
  const appToken = useAppToken();

  if (appToken) {
    return <RedirectToTms />;
  }

  return <Outlet />;
}

export function AuthenticatedRoutesContainer() {
  const appToken = useAppToken();

  if (!appToken) {
    return <RedirectToSignIn />;
  }

  return <AuthenticatedRoutesOutlet />;
}

function AuthenticatedRoutesOutlet() {
  const matches = useMatches() as Array<
    UIMatch<
      unknown,
      { isProtected?: boolean; layout?: 'blank' | 'sidebar' } | undefined
    >
  >;

  const isProtected = matches.some((match) => match.handle?.isProtected);

  const layout =
    matches.find((match) => !!match.handle?.layout)?.handle?.layout ??
    'sidebar';

  const LayoutComponent =
    layout === 'blank' ? AppBlankLayout : AppSidebarLayout;

  return (
    <LayoutComponent>
      {isProtected ? (
        <ProtectedRoute>
          <Outlet />
        </ProtectedRoute>
      ) : (
        <Outlet />
      )}
    </LayoutComponent>
  );
}

export function RedirectToSignIn() {
  const location = useLocation();

  return (
    <Navigate
      to={{
        pathname: '/signin',
        search: stringifySearchQuery({
          redirect_url: `${location.pathname}${decodeURIComponent(
            location.search,
          )}`,
        }),
      }}
    />
  );
}

export function RedirectToTms() {
  const [{ redirect_url }] = useQuery();

  return (
    <Navigate
      to={typeof redirect_url === 'string' ? redirect_url : '/orders'}
    />
  );
}
