import { Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { ColorDynamic, Inline, Tag, useMinBreakpoint } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { usePermission } from 'shared/data/UserPermissions';
import { useQuery } from 'shared/helpers/RouteHelpers';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { SuperPayRequestedBanner } from '../../superpay/request-to-use/SuperPayRequestedBanner';
import { CarrierType } from '../data/ManageCarriersAPI';
import { ManageCarriersListHeader } from './ManageCarriersListHeader';
import { ManageCarriersTable } from './ManageCarriersTable';
import { AddToNetworkDrawer } from './NetworkDrawer';
import { AddPrivateNetworkButton } from './PrivateNetworkAdd';
import { PrivateNetworkTab } from './PrivateNetworkTab';
import { ToggleExpeditePaymentButton } from './ToggleExpeditePaymentButton';

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: ${ColorDynamic.White};
  height: 100%;
`;

interface Tab {
  name: CarrierType;
  label: string;
  tooltip?: string;
  isNew?: boolean;
  isDisabled?: boolean;
}

const getTabs = (isCanExpeditePaymentTab: boolean): Tab[] => [
  {
    name: 'recent',
    label: 'Recent Carriers',
    tooltip:
      'This list contains Carriers that you have recently sent offers to, but have not marked as Approved or Can Instantly Book.',
  },
  {
    name: 'approved',
    label: 'Approved',
  },
  {
    name: 'preferred',
    label: 'Can Instantly Book',
  },
  {
    isNew: true,
    name: 'private-network',
    label: 'Private Network',
  },
  {
    isNew: true,
    name: 'can_expedite_payment',
    label: 'Can Expedite Payment',
    isDisabled: !isCanExpeditePaymentTab,
  },
  {
    name: 'insurance_certificate_holder',
    label: 'Certificate Holder',
  },
  {
    name: 'insurance_expired',
    label: 'Insurance Expired',
  },
  {
    name: 'blacklisted',
    label: 'Blocklisted',
  },
  {
    name: 'internal',
    label: 'Internal',
  },
];

export function ManageCarriersListPage() {
  const isCarrierEligibility = useFeatureToggle(
    'payments.expedited.pay.carrier.eligibility.access.ui',
  );
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );
  const navigate = useNavigate();
  const isDesktop = useMinBreakpoint('sm');
  const [dialog, setDialog] = useState<'add-to-network'>();

  const [{ carrier_type = 'recent', group_guid }, setQuery] = useQuery();

  const canUpdateCarrierProfile = usePermission(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
    'canExecute',
  );
  const { user } = useUserState();
  const isCanExpeditePaymentTab = !!(
    user?.shipper.super_pay_settings?.can_expedite_payment_tab_available &&
    isCarrierEligibility
  );

  const allowTabs: CarrierType[] = [
    'approved',
    'preferred',
    'can_expedite_payment',
  ];
  const showActions =
    canUpdateCarrierProfile &&
    allowTabs.includes(carrier_type as CarrierType) &&
    (!!user?.shipper.is_private_network_enabled || isCanExpeditePaymentTab);

  useEffect(() => {
    if (carrier_type !== 'private-network' && group_guid) {
      setQuery({ group_guid: undefined });
    }
  }, [carrier_type, group_guid]);

  return (
    <>
      <PageLayout
        header={
          <ManageCarriersListHeader
            carrier={null}
            onAddToNetwork={() => setDialog('add-to-network')}
            onSelectCarrier={(carrier) => {
              if (carrier?.guid) {
                navigate(
                  `/manage-carriers/${carrier.guid}?utm_medium=Manage Carriers&utm_content=Carrier Search`,
                );
              } else if (carrier?.us_dot) {
                navigate(
                  `/manage-carriers/usdot/${carrier.us_dot}?utm_medium=Manage Carriers&utm_content=Carrier Search`,
                );
              }
            }}
          />
        }
      >
        {isCarrierRequestsSuperPay &&
          user?.shipper.super_pay_settings
            ?.superpay_requested_banner_available && (
            <Box padding="small">
              <SuperPayRequestedBanner source="Manage Carriers" />
            </Box>
          )}
        <Content>
          <Box borderBottomWidth="small" borderColor="Silver400">
            <Tabs
              scrollButtons={isDesktop ? 'off' : 'on'}
              value={carrier_type}
              onChange={(_event, value: CarrierType) => {
                setQuery({ page: 0, carrier_type: value });
              }}
            >
              {getTabs(isCanExpeditePaymentTab)
                .filter(({ isDisabled }) => !isDisabled)
                .map(({ label, name, tooltip, isNew }) => (
                  <Tab
                    key={name}
                    value={name}
                    data-intercom-target={`${label} Tab`}
                    label={
                      <Inline verticalAlign="center" space="xxsmall">
                        <Typography>{label}</Typography>

                        {isNew && (
                          <Tag color="blue" variant="subtle">
                            New
                          </Tag>
                        )}

                        {tooltip && (
                          <Tooltip title={tooltip}>
                            <Info color="action" fontSize="small" />
                          </Tooltip>
                        )}
                      </Inline>
                    }
                  />
                ))}
            </Tabs>
          </Box>

          {carrier_type === 'private-network' ? (
            <PrivateNetworkTab />
          ) : (
            <ManageCarriersTable
              carrierType={carrier_type as CarrierType}
              renderActions={
                !showActions
                  ? undefined
                  : (carrierGuids, clearSelectedCarriers, selectedCarriers) => (
                      <>
                        {user.shipper.is_private_network_enabled && (
                          <AddPrivateNetworkButton
                            source={`${startCase(carrier_type)} tab`}
                            carrierGuids={carrierGuids}
                            onSubmitSuccess={() => {
                              clearSelectedCarriers();
                            }}
                          />
                        )}
                        {isCanExpeditePaymentTab && (
                          <ToggleExpeditePaymentButton
                            selectedCarriers={selectedCarriers}
                            carrierGuids={carrierGuids}
                            carrierType={carrier_type as CarrierType}
                          />
                        )}
                      </>
                    )
              }
            />
          )}
        </Content>
      </PageLayout>

      <AddToNetworkDrawer
        open={dialog === 'add-to-network'}
        onClose={() => setDialog(undefined)}
      />
    </>
  );
}
