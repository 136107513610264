import { Link, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import {
  ColorDynamic,
  Column,
  Columns,
  renderChildren,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { isSuperPayStatusAvailable } from 'core/PaymentUtils';
import { useOrderPaymentFlags } from 'orders/data/OrderPaymentFlagsAPI';
import { forwardRef, ReactElement, ReactNode, useState } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import ButtonGroupDropdown from 'shared/ui/ButtonGroupDropdown';
import styled from 'styled-components';
import { OrderFormValues } from '../../orders/core/form/OrderForm';
import { useOrderCache } from '../../orders/data/OrderAPI';
import {
  CancelSuperPayButton,
  CancelSuperPayLinkButton,
} from '../cancel/SuperPayCancel';
import { SuperPayCancelOrderEditDialog } from '../cancel/SuperPayCancelDialog';
import { SuperPayEditDrawer } from './SuperPayEditDrawer';

const PointerLink = styled(Link)`
  cursor: pointer;
`;

const TooltipLink = styled(PointerLink)`
  color: white;
  pointer-events: auto;
`;

const TooltipElementContainer = styled.div`
  &:before {
    content: '';
    position: absolute;
    height: 55px;
    width: 100%;
    z-index: 1;
  }
`;

interface SuperPayEditProps {
  open: boolean;
  onClose: () => void;
  order: OrderFormValues | Order;
}
function SuperPayEdit({ open, onClose, order }: SuperPayEditProps) {
  const { invalidateOrder } = useOrderCache();

  return (
    <SuperPayEditDrawer
      open={open}
      order={order}
      onClose={onClose}
      onSuccess={() => invalidateOrder(order.guid)}
    />
  );
}

function EditSuperPayLinkButton({ order }: { order: OrderFormValues }) {
  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);

  return (
    <>
      To update pricing or remove a vehicle,{' '}
      <PointerLink
        onClick={() => {
          setOpenEditDrawer(true);
          trackEvent('Shipper Clicked Edit SuperPay', {
            utm_medium: 'Order Edit',
            utm_content: 'Banner',
          });
        }}
      >
        Edit SuperPay.
      </PointerLink>
      <SuperPayEdit
        order={order}
        open={openEditDrawer}
        onClose={() => setOpenEditDrawer(false)}
      />
    </>
  );
}

export function EditSuperPayOrderBanner({
  hasEditButton,
  hasCancelButton,
  order,
}: {
  hasEditButton: boolean | null;
  hasCancelButton: boolean | null;
  order: OrderFormValues;
}) {
  return (
    <Box
      aria-label="edit superpay banner"
      backgroundColor="Blue50"
      padding="xsmall"
      borderRadius="small"
      maxWidth="fit-content"
    >
      <Columns space="xxsmall" align="center">
        <Column width="content">
          <Info fontSize="small" htmlColor={ColorDynamic.Blue500} />
        </Column>
        <Column>
          <Typography>
            {hasEditButton && <EditSuperPayLinkButton order={order} />}
            {hasCancelButton && (
              <>
                {hasEditButton
                  ? 'To change payment method'
                  : 'To enable pricing update, or remove a vehicle, first'}{' '}
                <CancelSuperPayLinkButton order={order} />
              </>
            )}
          </Typography>
        </Column>
      </Columns>
    </Box>
  );
}

interface TooltipElementProps {
  children?: ReactNode;
}

const TooltipElement = forwardRef<HTMLElement, TooltipElementProps>(
  ({ children, ...props }, ref) => (
    <span {...props} ref={ref}>
      {children}
    </span>
  ),
);

TooltipElement.displayName = 'TooltipElement';

interface EditSuperPayTermsTooltipProps {
  order: OrderFormValues | Order | undefined;
  children: ReactElement;
  source: 'edit_superpay_button' | 'payment';
}

export function SuperPayNotAvailableEditTooltip({
  order,
  children,
  source,
}: EditSuperPayTermsTooltipProps) {
  const orderPaymentFlags = useOrderPaymentFlags(order?.id);

  return isSuperPayStatusAvailable(order) && !orderPaymentFlags?.can_edit ? (
    <Tooltip
      aria-label="edit superpay disabled tooltip"
      arrow={true}
      title={
        <Typography>
          {source === 'edit_superpay_button'
            ? 'Not available'
            : 'Edit is not available'}{' '}
          as payment
          <br /> processing has already started.
        </Typography>
      }
    >
      <TooltipElement>{children}</TooltipElement>
    </Tooltip>
  ) : (
    renderChildren(children)
  );
}

type ToolTipSource = 'total_price' | 'vehicle_price' | 'remove_vehicle';

interface SuperPayGoEditTooltipProps {
  order?: OrderFormValues | Order;
  children: ReactElement;
  source: ToolTipSource;
}

function getTitleToolTip(source: ToolTipSource) {
  return source === 'total_price'
    ? 'To change Total Carrier Price'
    : source === 'vehicle_price'
    ? 'To change Carrier Price'
    : 'To remove a vehicle';
}

export function SuperPayGoEditTooltip(props: SuperPayGoEditTooltipProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { source, order, children } = props;
  const orderPaymentFlags = useOrderPaymentFlags(order?.id);

  if (!order || !isSuperPayStatusAvailable(order)) {
    return renderChildren(children);
  }

  if (orderPaymentFlags?.can_edit) {
    return (
      <>
        <Tooltip
          aria-label="edit superpay tooltip"
          arrow={true}
          leaveDelay={1000}
          title={
            <Box maxWidth="200px">
              <Typography>
                {getTitleToolTip(source)}
                ,
                <br />
                go to{' '}
                <TooltipLink
                  onClick={() => {
                    setOpen(true);
                    trackEvent('Shipper Clicked Edit SuperPay', {
                      utm_medium: 'Order Edit',
                      utm_content: 'Tooltip',
                    });
                  }}
                >
                  Edit SuperPay.
                </TooltipLink>
              </Typography>
            </Box>
          }
        >
          <TooltipElement>{children}</TooltipElement>
        </Tooltip>
        <SuperPayEdit
          open={open}
          onClose={() => setOpen(false)}
          order={order}
        />
      </>
    );
  }

  return (
    <SuperPayNotAvailableEditTooltip order={order} source="payment">
      {children}
    </SuperPayNotAvailableEditTooltip>
  );
}

interface SuperPayToChangeMethodTooltipProps {
  order: OrderFormValues | Order | undefined;
  children: ReactElement;
}
export function SuperPayToChangeMethodTooltip({
  order,
  children,
}: SuperPayToChangeMethodTooltipProps) {
  const orderPaymentFlags = useOrderPaymentFlags(order?.id);

  if (!order || !isSuperPayStatusAvailable(order)) {
    return renderChildren(children);
  }

  if (orderPaymentFlags?.can_cancel) {
    return (
      <Tooltip
        arrow={true}
        title="To change the payment method, cancel SuperPay"
        placement="right-start"
      >
        <TooltipElementContainer>
          <TooltipElement>{children}</TooltipElement>
        </TooltipElementContainer>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      arrow={true}
      title={
        <Typography>
          Edit is not available as payment
          <br /> processing has already started.
        </Typography>
      }
    >
      <TooltipElementContainer>
        <TooltipElement>{children}</TooltipElement>
      </TooltipElementContainer>
    </Tooltip>
  );
}

function EditSuperPayButtonGroup({ order }: { order: OrderFormValues }) {
  const [currentOverlay, setCurrentOverlay] = useState<
    'edit-drawer' | 'cancel-dialog' | null
  >(null);

  return (
    <>
      <ButtonGroupDropdown
        label="Edit SuperPay"
        variant="primary"
        onClick={() => {
          trackEvent('Shipper Clicked Edit SuperPay', {
            utm_medium: 'Order Edit',
            utm_content: 'Edit SuperPay Button',
          });
          setCurrentOverlay('edit-drawer');
        }}
      >
        <MenuItem onClick={() => setCurrentOverlay('cancel-dialog')}>
          Cancel SuperPay
        </MenuItem>
      </ButtonGroupDropdown>
      <SuperPayEdit
        open={currentOverlay === 'edit-drawer'}
        onClose={() => setCurrentOverlay(null)}
        order={order}
      />
      <SuperPayCancelOrderEditDialog
        open={currentOverlay === 'cancel-dialog'}
        onClose={() => setCurrentOverlay(null)}
        order={order}
      />
    </>
  );
}

export function EditSuperPayButton({ order }: { order: OrderFormValues }) {
  const orderPaymentFlags = useOrderPaymentFlags(order.id);

  if (orderPaymentFlags?.can_edit && orderPaymentFlags.can_cancel) {
    return <EditSuperPayButtonGroup order={order} />;
  }

  if (orderPaymentFlags?.can_cancel) {
    return <CancelSuperPayButton order={order} />;
  }

  return null;
}
