import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { Confirmation } from 'shared/ui/Confirmation';
import { useCustomersAPI } from '../data/CustomerAPI';

interface Props {
  id: number | null;
  onDeleteSuccess: () => void;
}

export function CustomerDeleteConfirmationForm({ id, onDeleteSuccess }: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { deleteCustomer } = useCustomersAPI();

  const deleteForm = useFormikEnhanced({
    initialValues: {},
    onSubmit() {
      if (!id) {
        return Promise.reject(new Error('No customer selected'));
      }
      return deleteCustomer(id);
    },
    onSubmitSuccess() {
      addSnackbar('Customer deleted', { variant: 'success' });
      onDeleteSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, {
        variant: 'error',
      });
    },
  });

  return (
    <Confirmation
      title="Delete this customer?"
      cancelText="No"
      processText="Yes, Delete"
      onProcess={(hideConfirmation) => {
        if (id) {
          hideConfirmation();
          deleteForm.handleSubmit();
        }
      }}
      trigger={({ showConfirmation }) => (
        <Button
          variant="neutral"
          onClick={showConfirmation}
          pending={deleteForm.isSubmitting}
        >
          Delete
        </Button>
      )}
    />
  );
}
