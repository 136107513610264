import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const Vin = styled.div`
  margin: 5px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface OrderWithVin {
  number: string;
  guid: string;
}

type VinsListProp = Record<string, OrderWithVin[]>;

interface VinsListsProps {
  vins: VinsListProp;
}

function VinsList({ vins }: VinsListsProps) {
  const hasMultipleVins = Object.keys(vins).length > 0;
  return (
    <>
      {hasMultipleVins
        ? 'Entered VINs already exist in the following orders'
        : 'Entered VIN already exists in the following orders'}
      :
      <div>
        {Object.keys(vins).map((vin) => {
          const orders = vins[vin];
          return (
            orders &&
            orders.length > 0 && (
              <Vin key={vin}>
                <b>{vin}</b>:&nbsp;
                {orders.map((order, index) => (
                  <Fragment key={order.guid}>
                    {index ? ', ' : ''}
                    <Link
                      to={`/orders/view/${order.guid}`}
                      component={RouterLink}
                    >
                      {order.number}
                    </Link>
                  </Fragment>
                ))}
              </Vin>
            )
          );
        })}
      </div>
    </>
  );
}

export interface DuplicateVinModalProps {
  vinList: VinsListProp | undefined;
  onClose: () => void;
}

export function DuplicateVinModal({
  onClose,
  vinList,
}: DuplicateVinModalProps) {
  return (
    <Dialog maxWidth="sm" fullWidth={true} onClose={onClose} open={!!vinList}>
      <DialogTitle>Warning: Duplicate VIN found</DialogTitle>
      <DialogContent>
        {vinList && <VinsList vins={vinList} />}

        <DialogActions>
          <Button variant="neutral" onClick={onClose}>
            Continue
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
