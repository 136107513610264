import { Link } from '@material-ui/core';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { useState } from 'react';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { usePhoneVerification } from '../data/VerificationApplicationAPI';
import { PhoneVerificationHelpDrawer } from './PhoneVerificationHelpDrawer';

function PhoneVerificationLearnMoreLink() {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  return (
    <>
      <PhoneVerificationHelpDrawer
        open={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
      />
      <Link
        color="primary"
        href="#"
        onClick={(event) => {
          event.preventDefault();
          trackVerificationApplicationEvent({
            name: 'Shipper Clicked on Learn More',
            utm_content: 'Phone Verification',
          });
          setIsDrawerOpened(true);
        }}
      >
        Learn more
      </Link>
    </>
  );
}

export function PhoneVerificationForm() {
  const { data } = usePhoneVerification();
  const isFMCSAPhoneNumber = data?.verification_phone_number_source === 'FMCSA';
  return (
    <Stack space="small">
      <SuspendedFormikPhoneField
        name="verification_phone_number"
        label={isFMCSAPhoneNumber ? 'FMCSA Phone number' : 'Phone number'}
        disabled={isFMCSAPhoneNumber}
        helperText={<PhoneVerificationLearnMoreLink />}
      />
      <FormikTextField
        multiline={true}
        fullWidth={true}
        name="shipper_phone_verification_comment"
        label="When is the best time to call you?"
        helperText="Please provide two specific dates and times for us to call."
      />
    </Stack>
  );
}
