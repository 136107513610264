import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Stack } from '@superdispatch/ui';
import { DescriptionLineItem } from '@superdispatch/ui-lab';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import { UsageIndicator } from './UsageIndicator';
import { UsageDTO, useUsageDetails } from './useUsageDetails';

const USAGES_CONFIG = {
  VIN_POSTED: {
    title: 'Posted VINs',
    type: 'indicator',
  },
  VIN_DELIVERED: {
    title: 'Delivered VINs',
    type: 'indicator',
  },
  SUPERPAY_PAID: {
    title: 'SuperPay Transactions',
    type: 'line',
  },
  PRICING_INSIGHTS_API: {
    title: 'Pricing Insights API Calls',
    type: 'line',
  },
};

function Usage({ usage }: { usage: UsageDTO }) {
  const { type, title } = USAGES_CONFIG[usage.name];

  return type === 'indicator' && usage.limit ? (
    <UsageIndicator value={usage.usage} max={usage.limit} title={title} />
  ) : (
    <DescriptionLineItem title={title}>
      <Typography variant="h5">
        {formatNumber(usage.usage, {
          maximumFractionDigits: 0,
        })}
      </Typography>
    </DescriptionLineItem>
  );
}

export function PricingSection() {
  const { usageDetails, isUsageDetailsLoading } = useUsageDetails();

  if (isUsageDetailsLoading) {
    return (
      <Stack space="small">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    );
  }

  if (!usageDetails?.usages.length) {
    return null;
  }

  return (
    <Stack space="small">
      {usageDetails.usages.map((usage) => (
        <Usage usage={usage} key={usage.name} />
      ))}
    </Stack>
  );
}
