import { useResponsiveValue } from '@superdispatch/ui';
import { ProgressStep, ProgressStepper } from 'shared/ui/ProgressStepper';

const NUMBER_OF_STEPS = 4;

interface VerificationStepperProps {
  step: 1 | 2 | 3 | 4;
}

export function VerificationStepper({ step }: VerificationStepperProps) {
  const isMobile = useResponsiveValue(true, false);

  function getStepTitle(stepNumber: number) {
    return isMobile
      ? `Step ${stepNumber} of ${NUMBER_OF_STEPS}`
      : `Step ${stepNumber}`;
  }

  return (
    <ProgressStepper progress={100 * (step / NUMBER_OF_STEPS)}>
      <ProgressStep
        title={getStepTitle(1)}
        subtitle="Your Company"
        isActive={step === 1}
        isCompleted={false}
      />
      <ProgressStep
        title={getStepTitle(2)}
        subtitle="Identification"
        isActive={step === 2}
        isCompleted={false}
      />
      <ProgressStep
        title={getStepTitle(3)}
        subtitle="Phone Verification"
        isActive={step === 3}
        isCompleted={false}
      />
      <ProgressStep
        title={getStepTitle(4)}
        subtitle="Insurance"
        isActive={step === 4}
        isCompleted={false}
      />
    </ProgressStepper>
  );
}
