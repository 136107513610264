import { Menu, MenuItem } from '@material-ui/core';
import { AddAPhoto, Edit } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { formatVehicleType, VEHICLE_TYPES } from '@superdispatch/sdk';
import {
  ColorDynamic,
  Column,
  Columns,
  OverflowText,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { useMemo, useRef, useState } from 'react';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import styled from 'styled-components';
import { OrderFormVehiclePhotoDTO } from '../form/data/OrderFormVehicleDTO';
import { VehicleConditionIcon } from '../pricing-insights/VehicleConditionIcon';

const ImageWrapper = styled.div`
  width: 48px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

export const ImageCount = styled(TextBox)`
  position: absolute;
  z-index: 1;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 6px 0;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  filter: ${({ hasReducedBrightness }: { hasReducedBrightness: boolean }) =>
    hasReducedBrightness ? 'brightness(0.5)' : 'brightness(1)'};

  &:hover {
    filter: brightness(0.5);
  }
`;

interface VehicleSamplePhotoFieldProps {
  name: string;
  disabled: boolean;
}

export function VehicleSamplePhotoField({
  name,
  disabled,
}: VehicleSamplePhotoFieldProps) {
  const { addSnackbar } = useSnackbarStack();
  const inputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [field, , { setValue }] = useField<
    OrderFormVehiclePhotoDTO[] | undefined
  >(name);
  const thumbnail = useMemo(
    () => field.value?.find((x) => x.photo_type === 'Sample'),
    [field.value],
  );

  const photoUrl = useMemo(
    () =>
      !thumbnail
        ? null
        : thumbnail.photo_file
        ? URL.createObjectURL(thumbnail.photo_file)
        : thumbnail.photo_url,
    [thumbnail],
  );

  function hideMenu() {
    setAnchorEl(null);
  }

  function openUploadDialog() {
    inputRef.current?.click();
  }

  return (
    <div>
      {!photoUrl ? (
        <Button
          size="small"
          variant="text"
          disabled={disabled}
          onClick={openUploadDialog}
          startIcon={<AddAPhoto fontSize="small" color="primary" />}
        >
          Add Vehicle Photo
        </Button>
      ) : (
        <PhotoButton
          thumbnailUrl={photoUrl}
          count={field.value?.length || 0}
          onClick={({ currentTarget }) => {
            setAnchorEl(currentTarget);
          }}
        />
      )}

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={hideMenu}>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            openUploadDialog();
            hideMenu();
          }}
        >
          Add Photo
        </MenuItem>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            hideMenu();
            setValue([]);
          }}
        >
          {field.value && field.value.length > 1 ? 'Remove All' : 'Remove'}
        </MenuItem>
      </Menu>

      <input
        type="file"
        hidden={true}
        disabled={disabled}
        ref={inputRef}
        accept=".jpg,.jpeg,.png"
        onClick={(event) => {
          // Allow choosing same file
          // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file/54632736#54632736
          event.currentTarget.value = '';
        }}
        onChange={({ currentTarget: { files } }) => {
          const file = files?.[0];

          const maxAllowedSize = 20 * 1024 * 1024;
          if (file) {
            if (file.size > maxAllowedSize) {
              addSnackbar('The photo should be less than 20 Mb');
            } else if (field.value) {
              void setValue([
                ...field.value,
                { photo_type: 'Sample', photo_file: file },
              ]);
            } else {
              void setValue([{ photo_type: 'Sample', photo_file: file }]);
            }
          }
        }}
      />
    </div>
  );
}

function PhotoButton({
  count,
  onClick,
  thumbnailUrl,
}: {
  count: number;
  thumbnailUrl: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <ImageWrapper
      onClick={onClick}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
    >
      {isFocused ? (
        <ImageCount color="white">
          <Edit fontSize="small" />
        </ImageCount>
      ) : (
        count > 1 && (
          <ImageCount variant="heading-6" color="white">
            +{count - 1}
          </ImageCount>
        )
      )}

      <ThumbnailImage
        aria-label="thumbnail"
        src={thumbnailUrl}
        hasReducedBrightness={count > 1}
      />
    </ImageWrapper>
  );
}

interface OrderDetailsVehicleItemProps {
  vehicle: VehicleDTO;
  index: number;
  disabled: boolean;
}

export function OrderDetailsVehicleItem({
  vehicle,
  index,
  disabled,
}: OrderDetailsVehicleItemProps) {
  return (
    <Columns space="large">
      <Column width="1/4">
        <OverflowText>
          <Box display="inline-block">
            <VehicleConditionIcon
              requiresEnclosedTrailer={false}
              isInoperable={!!vehicle.is_inoperable}
            />
          </Box>
          {vehicle.year} {vehicle.make} {vehicle.model}{' '}
        </OverflowText>
      </Column>

      <Column width="1/4">
        <FormikTextField
          name={`vehicles.${index}.type`}
          select={true}
          fullWidth={true}
          style={{ backgroundColor: ColorDynamic.White }}
        >
          {VEHICLE_TYPES.map((value) => (
            <MenuItem key={value} value={value}>
              {formatVehicleType(value)}
            </MenuItem>
          ))}
        </FormikTextField>
      </Column>

      <Column>
        <VehicleSamplePhotoField
          name={`vehicles.${index}.photos`}
          disabled={disabled}
        />
      </Column>
    </Columns>
  );
}
