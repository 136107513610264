import {
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { formatDate, parseDate } from '@superdispatch/dates';
import { Column, Columns, Image, Inline, Stack } from '@superdispatch/ui';
import { Box, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useEffect, useMemo, useState } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { CalendarOut } from 'shared/icons/CalendarOut';
import Order from 'shared/types/order';
import { PaymentMethodIcon } from 'shared/ui/PaymentMethodIcon';
import styled from 'styled-components';
import emptyLoadsImg from '../../assets/empty_loads.png';
import emptyLoadsImgDark from '../../assets/empty_loads_dark.png';
import { PricingInsightsRecentLoadDTO } from '../../data/dto/PricingInsightsRecentPostings';
import { useRecentLoads } from '../../data/OrderPriceAPI';
import { PostingVehicle } from '../posting/LoadVehicle';
import { LoadWaypoints } from '../posting/LoadWaypoints';
import { PostingPaymentTerm } from '../posting/PostingPaymentTerm';
import { PostingVehiclesSummary } from '../posting/PostingVehiclesSummary';

const StyledCardContent = styled(CardContent)`
  padding: 12px 16px;

  display: grid;
  grid-row-gap: 8px;
  grid-column-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr)) auto;

  &[data-cursor='pointer'] {
    cursor: pointer;
  }
`;

interface RecentLoadProps {
  type: 'postings' | 'moves';
  load: PricingInsightsRecentLoadDTO;
  onClick?: () => void;
}

export function RecentLoad({ type, load, onClick }: RecentLoadProps) {
  return (
    <Card onClick={onClick}>
      <StyledCardContent data-cursor={onClick ? 'pointer' : 'default'}>
        <LoadWaypoints
          pickup={{
            city: load.pickup_city,
            state: load.pickup_state,
            zip: load.pickup_zip,
          }}
          delivery={{
            city: load.delivery_city,
            state: load.delivery_state,
            zip: load.delivery_zip,
          }}
          distanceMiles={load.distance_miles}
        />

        <Stack space="xsmall">
          {load.vehicles[0] && <PostingVehicle vehicle={load.vehicles[0]} />}

          {load.vehicles.length > 1 && (
            <PostingVehiclesSummary
              title={`+ ${load.vehicles.length - 1} more`}
              vehicles={load.vehicles.slice(1)}
            />
          )}
        </Stack>

        {type === 'postings' ? (
          <Stack>
            {load.payment_terms && (
              <DescriptionItem
                icon={<PaymentMethodIcon paymentMethod={load.payment_method} />}
              >
                <PostingPaymentTerm
                  terms={load.payment_terms}
                  method={load.payment_method}
                />
              </DescriptionItem>
            )}

            <DescriptionItem label="Posted" icon={<CalendarOut />}>
              {formatDate(
                parseDate(load.posted_to_loadboard_at, {
                  format: 'JodaISO',
                }),
                { variant: 'Date' },
              )}
            </DescriptionItem>

            <DescriptionItem label="Available" icon={<CalendarOut />}>
              {formatDate(
                parseDate(load.available_at, {
                  format: 'JodaISO',
                }),
                { variant: 'Date' },
              )}
            </DescriptionItem>
          </Stack>
        ) : (
          <Stack>
            <DescriptionItem label="Dispatch" icon={<CalendarOut />}>
              {formatDate(
                parseDate(load.dispatched_date, {
                  format: 'JodaISO',
                }),
                { variant: 'Date' },
              )}
            </DescriptionItem>

            <DescriptionItem label="Delivery" icon={<CalendarOut />}>
              {formatDate(
                parseDate(load.delivered_date, {
                  format: 'JodaISO',
                }),
                { variant: 'Date' },
              )}
            </DescriptionItem>
          </Stack>
        )}

        <Stack space="xxsmall" align={['left', 'left', 'right']}>
          <TextBox variant="heading-4">
            {formatCurrency(load.price, { maximumFractionDigits: 0 })}
          </TextBox>

          {load.price_per_mile_per_vehicle != null && (
            <TextBox color="secondary">
              {formatCurrency(load.price_per_mile_per_vehicle, {
                maximumFractionDigits: 2,
              })}
              /mi
            </TextBox>
          )}
        </Stack>
      </StyledCardContent>
    </Card>
  );
}

interface RecentPostingsProps {
  type: 'postings' | 'moves';
  order: Partial<Order>;
}

export function RecentLoads({ type, order }: RecentPostingsProps) {
  const [localType, setLocalType] = useState<'postings' | 'moves' | 'my-moves'>(
    type,
  );
  const { data: myMoves } = useRecentLoads({
    order,
    type: 'my-moves',
  });
  const { data, error } = useRecentLoads({
    order,
    type: localType,
  });
  const [averagePrice, averagePricePerMile] = useMemo(() => {
    let totalPrice = 0;
    let totalPricePerMile = 0;

    if (!data) {
      return [0, 0];
    }

    for (let item of data.objects) {
      totalPrice += item.price_per_vehicle || 0;
      totalPricePerMile += item.price_per_mile_per_vehicle || 0;
    }

    return [
      totalPrice / Math.max(data.objects.length, 1),
      totalPricePerMile / Math.max(data.objects.length, 1),
    ];
  }, [data]);

  useEffect(() => {
    setLocalType(type);
  }, [type]);

  if (error) {
    return (
      <Flex justifyContent="center">
        <TextBox>Something went wrong</TextBox>
      </Flex>
    );
  }

  if (!data) {
    return (
      <Flex justifyContent="center">
        <CircularProgress />
      </Flex>
    );
  }

  if (data.objects.length === 0) {
    return (
      <Box paddingTop="large">
        <Stack align="center">
          <Image
            src={emptyLoadsImg}
            alt="empty"
            height="100px"
            srcDark={emptyLoadsImgDark}
          />

          {type === 'postings' ? (
            <TextBox align="center" color="secondary">
              No orders currently posted for this route. <br />
              Your own listings are excluded.
            </TextBox>
          ) : (
            <TextBox align="center" color="secondary">
              No orders have been recently <br />
              delivered on this route.
            </TextBox>
          )}
        </Stack>
      </Box>
    );
  }

  return (
    <Stack space="small">
      <Columns space="small">
        <Column>
          <Inline space={['xsmall', 'large']}>
            <Inline>
              <TextBox color="secondary">Avg price/vehicle</TextBox>
              <TextBox>
                {formatCurrency(averagePrice, { maximumFractionDigits: 0 })}
              </TextBox>
            </Inline>

            <Inline>
              <TextBox color="secondary">Avg price/mi</TextBox>
              <TextBox>
                {formatCurrency(averagePricePerMile, {
                  maximumFractionDigits: 2,
                })}
              </TextBox>
            </Inline>
          </Inline>
        </Column>

        {type === 'moves' && (
          <Column width="content">
            <Tooltip
              title={
                myMoves?.objects.length === 0
                  ? 'You don’t have recently delivered orders on this route.'
                  : ''
              }
            >
              <FormControlLabel
                label="My Moves"
                disabled={myMoves?.objects.length === 0}
                control={
                  <Switch
                    value={localType === 'my-moves'}
                    onChange={(_, checked) => {
                      setLocalType(checked ? 'my-moves' : 'moves');
                      if (checked) {
                        trackEvent(
                          'Shipper Enabled My Moves on Pricing Insights',
                        );
                      }
                    }}
                  />
                }
              />
            </Tooltip>
          </Column>
        )}
      </Columns>

      {data.objects.map((load) => {
        const brokerOrderGuid = load.broker_order_guid;
        return (
          <RecentLoad
            key={load.guid}
            type={type}
            load={load}
            onClick={
              localType === 'my-moves' && !!brokerOrderGuid
                ? () => {
                    window.open(`/orders/view/${brokerOrderGuid}`, '_blank');
                  }
                : undefined
            }
          />
        );
      })}
    </Stack>
  );
}
