import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { DrawerActions, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { ComponentType } from 'react';
import { DrawerForm } from 'shared/form/DrawerForm';
import {
  composeValidators,
  email,
  required,
} from 'shared/utils/ValidatorUtils';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { NormalizeWidth } from './AboutUsEditDrawer';

interface LabelRequiredProps {
  label: string;
}

const LabelRequired: ComponentType<LabelRequiredProps> = ({ label }) => (
  <>
    <label>{label}</label>
    <Typography component="span" color="textSecondary">
      &nbsp;(Required)&nbsp;
    </Typography>
  </>
);

interface ContactInfoEditDrawerProps {
  open: boolean;
  profile: ShipperProfileDTO;
  onClose: () => void;
  onUpdate?: () => void;
}

export function ContactInfoEditDrawer({
  open,
  profile,
  onClose,
  onUpdate,
}: ContactInfoEditDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateContactInfo } = useShipperProfileAPI();

  const formik = useFormikEnhanced({
    key: open,
    initialValues: profile,
    onSubmit: (values) => {
      return updateContactInfo(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedProfileValues('Contact Info', {
        contact_name: updatedValues.contact_name,
        contact_email: updatedValues.contact_email,
        contact_phone: updatedValues.contact_phone,
        fax: updatedValues.fax,
      });

      addSnackbar('Profile updated successfully', { variant: 'success' });
      onClose();
      onUpdate?.();
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to update profile', { variant: 'error' });
    },
  });

  const { dirty, isSubmitting, isValid, status, handleSubmit } = formik;

  return (
    <DrawerForm
      title="Contact Info"
      isDirty={dirty}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      disableCloseOnNavigation={true}
      disableNavigationPrompt={status.type === 'submitted'}
      actions={
        <DrawerActions>
          <Button
            type="submit"
            variant="primary"
            disabled={!dirty || !isValid || isSubmitting}
          >
            Save
          </Button>
        </DrawerActions>
      }
    >
      <FormikProvider value={formik}>
        <NormalizeWidth>
          <Stack space="large">
            <Stack space="small">
              <FormikTextField
                name="contact_name"
                label={<LabelRequired label="Contact Name" />}
                fullWidth={true}
                validate={required}
              />

              <FormikTextField
                name="contact_email"
                label={
                  <Grid container={true} spacing={1} alignItems="center">
                    <Grid item={true}>
                      <LabelRequired label="Contact Email" />
                    </Grid>

                    <Tooltip title="This email receives all notifications related to all orders. Ex: Pickup, Delivery, Invoices, Carrier Requests / Bookings, Order Modifications, etc.">
                      <Info color="action" fontSize="small" />
                    </Tooltip>
                  </Grid>
                }
                type="email"
                helperText="Visible to carriers and appears on BOL"
                fullWidth={true}
                validate={composeValidators(required, email)}
              />

              <SuspendedFormikPhoneField
                name="contact_phone"
                label={<LabelRequired label="Contact Phone" />}
                fullWidth={true}
                validate={required}
              />

              <SuspendedFormikPhoneField
                name="fax"
                label="Fax"
                fullWidth={true}
              />
            </Stack>
          </Stack>
        </NormalizeWidth>
      </FormikProvider>
    </DrawerForm>
  );
}
