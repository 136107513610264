import { Divider, FormControlLabel, Link, Radio } from '@material-ui/core';
import {
  FormikDateField,
  FormikRadioGroupField,
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { FormikFileField } from 'shared/form/FormikFileField';
import { getInsuranceTypeLabel } from '../data/ShipperProfileUtils';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import {
  INSURANCE_TYPE,
  VerificationInsuranceDTO,
} from '../data/VerificationApplicationDTO';
import { InsuranceHelpDrawer } from './InsuranceHelpDrawer';

interface InsuranceFormProps {
  showInsuranceLearnMoreLink?: boolean;
  isBroker?: boolean;
}

export function InsuranceForm({
  showInsuranceLearnMoreLink,
  isBroker,
}: InsuranceFormProps) {
  const [currentDrawer, setCurrentDrawer] = useState<
    'insurance_help' | undefined
  >();

  const { values } = useFormikContext<VerificationInsuranceDTO>();

  return (
    <Stack space="small">
      <InsuranceHelpDrawer
        open={currentDrawer === 'insurance_help'}
        onClose={() => setCurrentDrawer(undefined)}
      />
      {!isBroker && (
        <FormikRadioGroupField
          label="Insurance type"
          name="company_insurance_type"
        >
          {INSURANCE_TYPE.map((insuranceType) => (
            <FormControlLabel
              key={insuranceType}
              value={insuranceType}
              control={<Radio />}
              label={getInsuranceTypeLabel(insuranceType)}
            />
          ))}
        </FormikRadioGroupField>
      )}

      <FormikFileField
        label={
          values.company_insurance_type === 'BOND'
            ? 'Proof of Bond'
            : 'Proof of Insurance'
        }
        name="insurance_certificate_url"
        helperText={
          showInsuranceLearnMoreLink && (
            <Link
              color="primary"
              href="#"
              onClick={(event) => {
                event.preventDefault();
                trackVerificationApplicationEvent({
                  name: 'Shipper Clicked on Learn More',
                  utm_content: 'Certificate of Insurance',
                });
                setCurrentDrawer('insurance_help');
              }}
            >
              Learn more
            </Link>
          )
        }
      />

      {!isBroker && (
        <FormikDateField
          label={`Expiration Date ${
            values.company_insurance_type === 'BOND' ? '(optional)' : ''
          }`}
          name="bond_expiration_date"
          format="DateISO"
          enableClearable={true}
          fullWidth={true}
        />
      )}

      <FormikTextField
        label={
          values.company_insurance_type === 'BOND'
            ? 'Bond Holder Name'
            : 'Insurance Holder Name'
        }
        name="bond_company_name"
        fullWidth={true}
      />

      <Divider />
      <Columns space="xsmall" collapseBelow="tablet">
        <Column>
          <FormikTextField
            label="Agent Name"
            name="bond_agent_name"
            fullWidth={true}
          />
        </Column>
        <Column>
          <SuspendedFormikPhoneField
            name="bond_agent_phone"
            label="Agent Phone Number"
            fullWidth={true}
          />
        </Column>
      </Columns>
    </Stack>
  );
}
