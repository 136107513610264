import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const DriveawayIcon = createSvgIcon(
  'Driveaway',
  <path
    d="M8.66634 13.28C9.86634 13.1333 10.8997 12.6333 11.7663 11.7667C12.633 10.9 13.133 9.86667 13.2797 8.66667H11.2797C11.133 9.33334 10.8263 9.89334 10.3597 10.36C9.89301 10.8267 9.33301 11.1333 8.66634 11.28V13.28ZM6.66634 5.33334H9.33301L11.333 7.33334H13.2797C13.113 6.03334 12.5263 4.92 11.513 4C10.5063 3.10667 9.33301 2.66667 7.99967 2.66667C6.66634 2.66667 5.49301 3.10667 4.48634 4C3.47301 4.92 2.88634 6.03334 2.71967 7.33334H4.66634L6.66634 5.33334ZM7.33301 13.28V11.28C6.66634 11.1333 6.10634 10.8267 5.63967 10.36C5.17301 9.89334 4.86634 9.33334 4.71967 8.66667H2.71967C2.86634 9.84667 3.36634 10.8667 4.23301 11.7333C5.09967 12.6 6.13301 13.1133 7.33301 13.28ZM7.99967 1.33334C9.83301 1.33334 11.3997 2 12.6997 3.3C13.9997 4.6 14.6663 6.16667 14.6663 8C14.6663 9.83333 13.9997 11.4 12.6997 12.7C11.3997 14 9.83301 14.6667 7.99967 14.6667C6.16634 14.6667 4.59967 14 3.29967 12.7C1.99967 11.4 1.33301 9.83333 1.33301 8C1.33301 6.16667 1.99967 4.6 3.29967 3.3C4.59967 2 6.16634 1.33334 7.99967 1.33334Z"
    fill={ColorDynamic.Dark100}
  />,
  { viewBox: '0 0 16 16' },
);
