import { orderTagSchema } from 'core/dictionary/data/OrdersTagAPI';
import {
  yupArray,
  yupBoolean,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type GeneralSettingsDTO = InferType<typeof generalSettingsSchema>;

export const generalSettingsSchema = yupObject({
  theme_mode: yupString().required(),
  time_zone: yupString().required(),
  bol_template: yupString().nullable(),
  inspection_type: yupString().nullable(),
  is_sso_enabled: yupBoolean().optional(),
  is_sso_organization_created: yupBoolean().optional(),
  is_sso_connection_active: yupBoolean().optional(),
  domain: yupString().nullable().optional(),
  order_tag_settings: yupArray(orderTagSchema).required(),
  max_number_of_tags: yupNumber().required(),
});

export type TimeZoneWithOffsetsDTO = InferType<
  typeof timeZoneWithOffsetsSchema
>;

export const timeZoneWithOffsetsSchema = yupObject({
  offset: yupString().required(),
  timezone: yupString().required(),
  is_pinned: yupBoolean().required(),
});

export type WorkOSPortalLinkDTO = InferType<typeof workOSPortalLinkSchema>;

export const workOSPortalLinkSchema = yupObject({
  url: yupString().required(),
});

export type RecoverPasswordDTO = InferType<typeof recoverPasswordSchema>;

export const recoverPasswordSchema = yupObject({
  token: yupString().optional(),
  password: yupString().required(),
});
