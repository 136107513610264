import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { InfoCard, Stack } from '@superdispatch/ui';
import { LinkedText } from '@superdispatch/ui-lab';
import { truncate } from 'lodash';
import { useMemo, useState } from 'react';
import { CarrierFullInfo } from 'shared/types/carrier';
import { MultilineText } from 'shared/ui/MultilineTextView';

interface CarrierTermsAndConditionsProps {
  carrier: CarrierFullInfo;
}

export function CarrierTermsAndConditions({
  carrier,
}: CarrierTermsAndConditionsProps) {
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
  const termsTruncated = useMemo(
    () =>
      carrier.terms_and_conditions &&
      truncate(carrier.terms_and_conditions, { length: 140, omission: '…' }),
    [carrier],
  );

  return (
    <>
      <Dialog
        fullWidth={true}
        open={isTermsDialogOpen}
        onClose={() => {
          setIsTermsDialogOpen(false);
        }}
      >
        <DialogTitle>
          <Grid
            container={true}
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item={true}>
              <Typography variant="h3">Terms and Conditions</Typography>
            </Grid>

            <Grid item={true}>
              <IconButton
                size="small"
                onClick={() => setIsTermsDialogOpen(false)}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <MultilineText>
            <DialogContentText
              variant="body2"
              color="textPrimary"
              aria-label="full terms and conditions"
            >
              <LinkedText>{carrier.terms_and_conditions}</LinkedText>
            </DialogContentText>
          </MultilineText>
        </DialogContent>
      </Dialog>

      <InfoCard size="large">
        <Stack space="small">
          <Typography variant="h3">Terms and Conditions</Typography>

          {termsTruncated ? (
            <>
              <MultilineText>
                <Typography
                  component={MultilineText}
                  aria-label="truncated terms and conditions"
                >
                  {termsTruncated}
                </Typography>
              </MultilineText>

              <Link
                color="primary"
                component="button"
                onClick={() => setIsTermsDialogOpen(true)}
              >
                Read more
              </Link>
            </>
          ) : (
            <Typography
              aria-label="no terms and conditions"
              color="textSecondary"
            >
              No information provided
            </Typography>
          )}
        </Stack>
      </InfoCard>
    </>
  );
}
