import {
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core';
import { PopoverProps } from '@material-ui/core/Popover';
import { Box, Flex } from '@rebass/grid';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useMemo, useState } from 'react';
import { FormikCurrencyField } from 'shared/form/FormikCurrencyField';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import Order from 'shared/types/order';
import { composeValidators, required } from 'shared/utils/ValidatorUtils';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';
import { OrderErrorModal } from '../error-modal/OrderErrorModal';

function validatePositivePrice(value: number | unknown) {
  if (typeof value === 'number' && value < 0) return 'Negative';
  return undefined;
}

const localStorageKey = 'recentPriceIncrements';

interface BulkCarrierPriceUpdatePopoverProps
  extends Omit<PopoverProps, 'open'> {
  orders: Order[] | undefined;
  onSubmitSuccess: () => void;
}

export function BulkCarrierPriceUpdatePopover({
  orders,
  anchorEl,
  onClose,
  onSubmitSuccess,
  ...props
}: BulkCarrierPriceUpdatePopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const [error, setError] = useState<unknown>();
  const { bulkCarrierPriceUpdate } = useBulkOrderActionAPI();

  const recentIncrementsVal = useStorageValue(localStorageKey);
  const recentPriceIncrements = useMemo<number[]>(
    () => (recentIncrementsVal ? JSON.parse(recentIncrementsVal) : []),
    [recentIncrementsVal],
  );

  function saveIncrementInStorage(newIncrement: number) {
    const next = new Set([newIncrement, ...recentPriceIncrements]);

    writeStorageItem(localStorageKey, JSON.stringify(Array.from(next)));
  }

  const form = useFormikEnhanced<{ increment: number }, unknown>({
    key: !!orders,
    initialValues: { increment: 0 },
    onSubmit({ increment }) {
      if (orders) {
        const ids = orders.map((x) => x.id);

        saveIncrementInStorage(increment);
        return bulkCarrierPriceUpdate(ids, increment);
      }

      return Promise.reject(new Error('Order not selected'));
    },
    onSubmitSuccess() {
      if (orders) {
        addSnackbar('Carrier Prices are Updated', { variant: 'success' });
        onSubmitSuccess();
      }
    },
    onSubmitFailure(failureError) {
      setError(failureError);
    },
  });

  return (
    <>
      <Popover
        {...props}
        anchorEl={anchorEl}
        open={!!orders && !!anchorEl}
        onClose={form.isSubmitting ? undefined : onClose}
      >
        <FormikProvider value={form}>
          <Form noValidate={true}>
            <Flex flexDirection="column">
              <Box>
                <List dense={true}>
                  <ListItem>
                    <FormikCurrencyField
                      name="increment"
                      autoFocus={true}
                      validate={composeValidators(
                        validatePositivePrice,
                        required,
                      )}
                    />
                  </ListItem>

                  {recentPriceIncrements.length > 0 && (
                    <>
                      <ListItem>
                        <Typography variant="h5" color="textSecondary">
                          Recent
                        </Typography>
                      </ListItem>

                      {recentPriceIncrements.map((increment, index) => (
                        <ListItem
                          key={index}
                          button={true}
                          onClick={() =>
                            form.setFieldValue('increment', increment)
                          }
                        >
                          <ListItemText primary={`$${increment}`} />
                        </ListItem>
                      ))}
                    </>
                  )}
                </List>
              </Box>

              <Box alignSelf="flex-end" m={3}>
                <Button
                  size="small"
                  type="submit"
                  variant="primary"
                  pending={form.isSubmitting}
                >
                  Done
                </Button>
              </Box>
            </Flex>
          </Form>
        </FormikProvider>
      </Popover>

      <OrderErrorModal error={error} onCancel={() => setError(undefined)} />
    </>
  );
}
