import { Tooltip } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Inline, Tag } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { IconBox } from 'shared/icons/IconBox';
import styled from 'styled-components';
import { escalationReasonsMap } from './EscalationUtils';

const StyledEscalationReasonList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const StyledEscalationReasonItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

interface EscalationReasonsProps {
  reasons: Record<string, string | null>;
}

function getLabel(reason: string) {
  return escalationReasonsMap.get(reason) || reason;
}

function EscalationReasonsTooltipList({ reasons }: EscalationReasonsProps) {
  return (
    <StyledEscalationReasonList>
      {Object.entries(reasons).map(([reason, value], index) => (
        <StyledEscalationReasonItem key={index}>
          <TextBox variant="heading-4" color="white">
            {getLabel(reason)}
          </TextBox>
          {value && <Box color="Silver500">{value}</Box>}
        </StyledEscalationReasonItem>
      ))}
    </StyledEscalationReasonList>
  );
}

interface EscalationSentTagProps {
  reasons: Record<string, string | null>;
}

export function EscalationSentTag({ reasons }: EscalationSentTagProps) {
  return (
    <Tooltip
      interactive={true}
      title={<EscalationReasonsTooltipList reasons={reasons} />}
    >
      <Tag aria-label="escalation submited" color="red" variant="subtle">
        <Inline space="none" verticalAlign="center">
          <IconBox>
            <Warning fontSize="small" />
          </IconBox>
          Escalated
        </Inline>
      </Tag>
    </Tooltip>
  );
}
