import { Box as MuiBox, Divider, Typography } from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import { Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import {
  calculatePriceNegotiationInfo,
  PriceNegotiationFormDTO,
} from 'orders/data/dto/PriceNegotiationDTO';
import { useMemo } from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';

export interface PriceNegotiationDrawerSummaryProps {
  order: Order;
  currentValues: PriceNegotiationFormDTO;
}

export function PriceNegotiationDrawerSummary({
  order,
  currentValues,
}: PriceNegotiationDrawerSummaryProps) {
  const priceNegotiationInfo = useMemo(
    () => calculatePriceNegotiationInfo(order.price, currentValues),
    [order.price, currentValues],
  );

  if (!Number.isFinite(priceNegotiationInfo.currentPrice)) {
    return null;
  }

  return (
    <Box backgroundColor="Silver200" padding="small" borderRadius="small">
      <Stack space="small">
        <Typography variant="h5" color="textSecondary">
          {!currentValues.increase_by_percent && !currentValues.increase_every
            ? 'Summary'
            : priceNegotiationInfo.currentIteration > 0
            ? `Summary for Today, Day ${priceNegotiationInfo.currentDay}`
            : `Summary until Day 1 · ${formatDate(
                priceNegotiationInfo.startDate,
                {
                  variant: 'ShortDate',
                },
              )}`}
        </Typography>

        {priceNegotiationInfo.currentIteration > 0 && (
          <>
            <MuiBox justifyContent="space-between" display="flex">
              <Typography variant="h5">
                Load Offers sent for requests
              </Typography>

              <Inline space="xxsmall">
                <Typography color="textSecondary" variant="body1">
                  {order.price &&
                    formatCurrency(order.price + 1, {
                      maximumFractionDigits: 0,
                    })}{' '}
                  -{' '}
                </Typography>
                <Typography variant="body1">
                  {formatCurrency(priceNegotiationInfo.currentPrice, {
                    maximumFractionDigits: 0,
                  })}
                </Typography>
              </Inline>
            </MuiBox>

            <Divider />
          </>
        )}

        {priceNegotiationInfo.currentIteration > 0 ? (
          <Typography color="textSecondary">
            Requests over ${priceNegotiationInfo.currentPrice} will receive a $
            {priceNegotiationInfo.currentPrice} counter offer.
          </Typography>
        ) : (
          <Typography variant="body1" color="textPrimary">
            Requests over ${order.price} will receive a ${order.price} counter
            offer.
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
