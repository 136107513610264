import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Field } from 'formik';
import { useThemeModeSetting } from 'shared/theme/AppTheme';

export function SettingsAppearance() {
  const themeMode = useThemeModeSetting();
  return (
    <Box marginTop="small" marginBottom="large">
      <Card>
        <CardContent>
          <Stack space="xsmall">
            <Typography>Appearance</Typography>
            <FormControl variant="outlined" fullWidth={true}>
              <Field
                as={Select}
                name="theme_mode"
                style={{ width: '250px' }}
                value={themeMode}
              >
                <MenuItem value="system">Use system settings</MenuItem>
                <MenuItem value="light">Light</MenuItem>
                <MenuItem value="dark">Dark</MenuItem>
              </Field>
            </FormControl>
            <Typography variant="body2">
              Customize how Shipper TMS looks on your device.
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
