import { createSvgIcon } from 'shared/icons/IconUtils';

export const PaymentAchIcon = createSvgIcon(
  'PaymentAchIcon',
  <path
    d="M7.66671 0.666656L1.33337 3.99999V5.33332H14V3.99999L7.66671 0.666656ZM10.6667 6.66666V11.3333H12.6667V6.66666H10.6667ZM1.33337 14.6667H14V12.6667H1.33337V14.6667ZM6.66671 6.66666V11.3333H8.66671V6.66666H6.66671ZM2.66671 6.66666V11.3333H4.66671V6.66666H2.66671Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 16' },
);
