import { Divider, Popover, Typography } from '@material-ui/core';
import { Calendar, FormattedDate } from '@superdispatch/dates';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button, DescriptionLineItem } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { CustomerSupportButtonSwitcher } from 'shared/helpers/customer-support/CustomerSupportButtonSwitcher';
import {
  useDisableDatesExpectedCharge,
  useExpectedChargeDateSchedule,
} from 'shared/helpers/superpay/SuperPayAPI';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';

function isDayDisabled(value: DateTime, disabledDays?: string[]) {
  if (
    value <= DateTime.local() ||
    disabledDays?.includes(value.toISODate()) ||
    value.weekday > 5
  ) {
    return true;
  }

  return value > DateTime.local().plus({ month: 3 });
}

interface ScheduleCalendarProps {
  onClose: () => void;
  onClick: () => void;
  isOpen: boolean;
  isLoading: boolean;
  anchorRef: HTMLButtonElement | null;
  actionButtonTitle: string;
}

export function SuperPayScheduleCalendar({
  onClick,
  isOpen,
  anchorRef,
  onClose,
  actionButtonTitle,
  isLoading,
}: ScheduleCalendarProps) {
  const [{ value: expectedDate }, , { setValue }] = useField<string | null>(
    'expected_date',
  );
  const isMobile = useResponsiveValue(true, false);
  const { data: disableExpectedChargeData } = useDisableDatesExpectedCharge();
  const { data: expectedChargeData, error } =
    useExpectedChargeDateSchedule(expectedDate);
  useErrorSnackbar(error);

  const hasFooter = useMemo(() => {
    if (!expectedDate || !disableExpectedChargeData) {
      return false;
    }

    let isAllDaysDisabled = true;
    let periodDay = DateTime.local(); // between now and chosen day

    while (
      periodDay.toISODate() !== DateTime.fromISO(expectedDate).toISODate()
    ) {
      isAllDaysDisabled = isDayDisabled(
        periodDay,
        disableExpectedChargeData.disabled_dates,
      );
      periodDay = periodDay.plus({ day: 1 });

      if (!isAllDaysDisabled) {
        break;
      }
    }

    const easternTime = DateTime.now().setZone('America/New_York');

    // If the nearest available date is set and the user has time between 3:30-4:00 PM ET
    return (
      isAllDaysDisabled &&
      easternTime.hour === 15 &&
      easternTime.minute >= 30 &&
      easternTime.minute <= 59
    );
  }, [disableExpectedChargeData, expectedDate]);

  useEffect(() => {
    if (
      expectedDate &&
      isDayDisabled(
        DateTime.fromISO(expectedDate),
        disableExpectedChargeData?.disabled_dates,
      )
    ) {
      setValue(null);
    }
  }, [disableExpectedChargeData, expectedDate, setValue]);

  return (
    <Popover
      onClose={onClose}
      open={isOpen}
      anchorEl={anchorRef}
      anchorOrigin={{
        vertical: isMobile ? -40 : 40,
        horizontal: 'left',
      }}
      marginThreshold={isMobile ? 24 : 48}
    >
      <CustomerSupportButtonSwitcher />
      <Box padding="small">
        <Stack space="small" align="right">
          <Box margin="auto">
            <Typography variant="body1">
              When should carrier receive payment?
            </Typography>
          </Box>
          <Calendar
            onDayClick={({ disabled, stringValue }) =>
              !disabled && setValue(stringValue)
            }
            disabledDays={({ dateValue }) =>
              isDayDisabled(
                dateValue,
                disableExpectedChargeData?.disabled_dates,
              )
            }
            selectedDays={({ dateValue }) =>
              !!expectedDate &&
              dateValue.toISODate() ===
                DateTime.fromISO(expectedDate).toISODate()
            }
          />
          {expectedChargeData?.expected_charge_date && (
            <DescriptionLineItem
              title={
                <Typography color="textSecondary">Charge Scheduled</Typography>
              }
            >
              <Typography variant="h5">
                <FormattedDate
                  variant="Date"
                  format="DateISO"
                  date={expectedChargeData.expected_charge_date}
                />
              </Typography>
            </DescriptionLineItem>
          )}
          {expectedDate && (
            <DescriptionLineItem
              title={
                <Typography color="textSecondary">Deposit Expected</Typography>
              }
            >
              <Typography variant="h5">
                <FormattedDate
                  variant="Date"
                  format="DateISO"
                  date={expectedDate}
                />
              </Typography>
            </DescriptionLineItem>
          )}

          {hasFooter && (
            <>
              <Box width="100%">
                <Divider />
              </Box>
              <Typography color="textSecondary">
                To ensure on-time payment schedule by 4 PM ET.
              </Typography>
            </>
          )}

          <Button
            onClick={onClick}
            disabled={
              !expectedDate || !expectedChargeData?.expected_charge_date
            }
            pending={isLoading}
          >
            {actionButtonTitle}
          </Button>
        </Stack>
      </Box>
    </Popover>
  );
}
