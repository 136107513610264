import { Tooltip } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { ColorDynamic } from '@superdispatch/ui';
import { Fragment, useMemo } from 'react';
import { OrderWithLoadsDTO } from 'shared/types/load';
import Order from 'shared/types/order';
import { makeAddressCityStateZip } from 'shared/utils/AddressUtils';
import styled from 'styled-components';

interface TerminalProps {
  isIntermediate?: boolean;
}

const Terminal = styled.div<TerminalProps>`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 5px solid ${ColorDynamic.Dark100};
  border-radius: ${(props) => (props.isIntermediate ? '0' : '50%')};
`;

type SplitStatus =
  | 'split_new'
  | 'split_new_picked_up'
  | 'split_new_delivered'
  | 'split_picked_up'
  | 'split_picked_up_delivered'
  | 'split_delivered';

type DiagramNodeStatus = Order['status'] | SplitStatus;
interface LoadProps {
  status: DiagramNodeStatus;
}

const UnknownLoad = styled.div`
  flex: 1;
  height: 2px;
  box-sizing: border-box;
  border-width: 1px 0;
  border: dashed ${ColorDynamic.Dark100};
`;

const Load = styled.div<LoadProps>`
  flex: 1;
  height: 2px;
  box-sizing: border-box;
  border-width: 1px 0;
  border: solid
    ${(props) =>
      ['delivered', 'invoiced', 'paid', 'completed'].includes(props.status)
        ? ColorDynamic.Green300
        : props.status === 'picked_up'
        ? ColorDynamic.Yellow500
        : ColorDynamic.Dark100};
`;

const splitStatusBordersMap: Record<
  SplitStatus,
  { top: ColorDynamic; bottom: ColorDynamic }
> = {
  split_new: { top: ColorDynamic.Dark100, bottom: ColorDynamic.Dark100 },
  split_new_picked_up: {
    top: ColorDynamic.Yellow500,
    bottom: ColorDynamic.Dark100,
  },
  split_new_delivered: {
    top: ColorDynamic.Green300,
    bottom: ColorDynamic.Dark100,
  },
  split_picked_up: {
    top: ColorDynamic.Yellow500,
    bottom: ColorDynamic.Yellow500,
  },
  split_picked_up_delivered: {
    top: ColorDynamic.Green300,
    bottom: ColorDynamic.Yellow500,
  },
  split_delivered: {
    top: ColorDynamic.Green300,
    bottom: ColorDynamic.Green300,
  },
};

const SplitLoad = styled.div<LoadProps>`
  flex: 1;
  height: 12px;
  box-sizing: border-box;
  border-width: 4px 0;
  border-style: solid;
  border-top-color: ${(props) => splitStatusBordersMap[props.status].top};
  border-bottom-color: ${(props) => splitStatusBordersMap[props.status].bottom};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;
interface OrderDiagramProps {
  hasUnassignedVehicles?: OrderWithLoadsDTO['has_unassigned_vehicles'];
  loads: OrderWithLoadsDTO['loads'];
  order: Order;
}

interface DiagramNode {
  type:
    | 'terminal'
    | 'load'
    | 'split_load'
    | 'unknown_terminal'
    | 'unknown_load';

  label?: string;
  status?: DiagramNodeStatus;
}

function getSplitStatusFromLoads(
  loads: OrderWithLoadsDTO['loads'],
  hasUnassignedVehicles: OrderWithLoadsDTO['has_unassigned_vehicles'],
): SplitStatus {
  // if type of diagram is `loads` - add two terminals and decide which split type do we have
  let hasDelivered, hasPickedUp, hasNew;

  loads.forEach(({ status }) => {
    if (['delivered', 'invoiced', 'paid', 'completed'].includes(status)) {
      hasDelivered = true;
    } else if (status === 'picked_up') {
      hasPickedUp = true;
    } else {
      hasNew = true;
    }
  });

  // each status represents the color set for two lines
  let status: SplitStatus = 'split_new';

  if (hasNew || hasUnassignedVehicles) {
    if (hasPickedUp) {
      status = 'split_new_picked_up';
    } else if (hasDelivered) {
      status = 'split_new_delivered';
    }
  } else if (hasPickedUp) {
    if (hasDelivered) {
      status = 'split_picked_up_delivered';
    } else {
      status = 'split_picked_up';
    }
  } else if (hasDelivered) {
    status = 'split_delivered';
  }

  return status;
}

export const OrderDiagram = ({
  hasUnassignedVehicles,
  loads,
  order,
}: OrderDiagramProps) => {
  const [pickup_address, delivery_address] = useMemo(() => {
    return [
      makeAddressCityStateZip(order.pickup?.venue, ', '),
      makeAddressCityStateZip(order.delivery?.venue, ', '),
    ];
  }, [order]);

  // Transforming loads list into diagram nodes (terminals as dots, loads as lines)
  const nodes = useMemo<DiagramNode[]>(() => {
    if (order.has_legs) {
      // if type of diagram is `legs` - add all loads consequently
      return loads.reduce<DiagramNode[]>((current, load, i) => {
        if (i === 0) {
          // if order's pickup address is not equal to first load's pickup address -  add unknown terminal
          if (pickup_address !== load.pickup_address) {
            current.push({
              type: 'unknown_terminal',
              label: pickup_address
                ? `No loads built from ${pickup_address}`
                : '',
            });
            current.push({ type: 'unknown_load' });
          }

          // adding first load's pickup address to draw the first diagram terminal
          current.push({ type: 'terminal', label: load.pickup_address });
        }

        // adding a load to draw a line
        current.push({
          type: 'load',
          status: load.status as DiagramNodeStatus,
        });
        // adding delivery address
        current.push({ type: 'terminal', label: load.delivery_address });

        // if last load's delivery address is not equal to order's delivery address
        if (
          i === loads.length - 1 &&
          load.delivery_address !== delivery_address
        ) {
          current.push({ type: 'unknown_load' });
          current.push({
            type: 'unknown_terminal',
            label: delivery_address
              ? `No loads built to ${delivery_address}`
              : '',
          });
        }

        return current;
      }, []);
    }

    const status = getSplitStatusFromLoads(loads, hasUnassignedVehicles);

    return [
      {
        type: 'terminal',
        label: loads[0]?.pickup_address,
      },
      {
        type: 'split_load',
        status,
      },
      {
        type: 'terminal',
        label: loads[0]?.delivery_address,
      },
    ];
  }, [loads, hasUnassignedVehicles]);

  return (
    <Wrapper>
      {nodes.map((node, i) => (
        <Fragment key={i}>
          {node.type === 'terminal' && (
            <Tooltip
              placement="top"
              title={
                node.label ||
                `${i === 0 ? 'Pickup' : 'Delivery'} address not specified`
              }
            >
              <Terminal isIntermediate={i !== 0 && i !== nodes.length - 1} />
            </Tooltip>
          )}
          {node.type === 'load' && <Load status={node.status ?? 'new'} />}
          {node.type === 'split_load' && (
            <SplitLoad status={node.status ?? 'split_new'} />
          )}
          {node.type === 'unknown_terminal' && (
            <Tooltip
              placement="top"
              title={
                node.label ||
                `${i === 0 ? 'Pickup' : 'Delivery'} address not specified`
              }
            >
              <Error style={{ width: 20, fill: ColorDynamic.Dark100 }} />
            </Tooltip>
          )}
          {node.type === 'unknown_load' && <UnknownLoad />}
        </Fragment>
      ))}
    </Wrapper>
  );
};
