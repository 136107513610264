import { Divider, Link, Typography } from '@material-ui/core';
import { ColorDynamic, Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useUserState } from 'shared/data/AppUserState';
import { TripIcon } from 'shared/icons/TripIcon';
import { UnityIcon } from 'shared/icons/UnityIcon';
import { VerifiedIcon } from 'shared/icons/VerifiedIcon';
import styled from 'styled-components';
import { PaywallCustomerComments } from './core/PaywallCustomerComments';
import { PaywallPaymentForm } from './core/PaywallPaymentForm';
import { trackClickedContactSupportEmail } from './PaywallAnalyticsEvents';

const Content = styled.div`
  display: flex;
  gap: 64px;
  justify-content: center;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`;

function SubscriptionBenefitItem({
  icon,
  title,
  description,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
}) {
  return (
    <Columns space="medium">
      <Column width="content">{icon}</Column>
      <Column width="fluid">
        <Stack space="none">
          <Typography variant="h4">{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
      </Column>
    </Columns>
  );
}

export function PaywallTrialExpired() {
  const { user } = useUserState();
  const isSelfServeUser = user?.shipper.is_self_serve;

  return (
    <Stack space="xlarge">
      <Stack align="center">
        <Typography variant="h1">
          Your Shipper TMS {isSelfServeUser ? 'Trial' : 'Subscription'} Has
          Expired
        </Typography>
        <Typography variant="h4" color="textSecondary" align="center">
          {isSelfServeUser ? (
            <>
              Who says all good things must come to an end? Subscribe today{' '}
              <br />
              to continue using Shipper TMS.
            </>
          ) : (
            <>
              Let&apos;s pick up where you left off. Call{' '}
              <Link href="tel:+18162810007" color="primary">
                (816) 281-0007
              </Link>{' '}
              to reactivate <br /> your Shipper TMS subscription.
            </>
          )}
        </Typography>
      </Stack>
      <Content>
        <Box maxWidth={isSelfServeUser ? '464px' : '518px'} width="100%">
          <Stack space="medium">
            <Typography variant="h3">
              Become a Shipper TMS customer today to continue moving cars faster
              and easier with Super Dispatch
            </Typography>
            <SubscriptionBenefitItem
              icon={
                <UnityIcon fontSize="large" htmlColor={ColorDynamic.Dark300} />
              }
              title="Manage your Business all from One-Platform"
              description="Simplify the auto-transport process from load posting to carrier payment, all without the need for manual paperwork or phone calls"
            />
            <SubscriptionBenefitItem
              icon={
                <VerifiedIcon
                  fontSize="large"
                  htmlColor={ColorDynamic.Dark300}
                />
              }
              title="Exclusive Access to our Verified Carrier Network"
              description="Every carrier on the Super Dispatch platform is vetted and monitored to ensure their insurance and authority is up-to-date"
            />
            <SubscriptionBenefitItem
              icon={
                <TripIcon fontSize="large" htmlColor={ColorDynamic.Dark300} />
              }
              title="Real-Time Tracking and Instant eBOL Access"
              description="Remove all uncertainty with real-time driver location updates, instant status notifications, and immediate access to electronic Bills of Lading (eBOL) upon delivery"
            />
            <Divider />
            <PaywallCustomerComments />

            <Box marginTop="small" marginLeft="xxlarge" paddingLeft="xsmall">
              <Stack space="xsmall">
                <Typography variant="h4" color="textSecondary">
                  Have a question or need help?
                </Typography>
                <Typography color="textSecondary">
                  Chat with us or email us at{' '}
                  <Link
                    color="primary"
                    underline="none"
                    href="mailto:support@superdispatch.com"
                    onClick={trackClickedContactSupportEmail}
                  >
                    support@superdispatch.com
                  </Link>
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
        {isSelfServeUser && (
          <Box
            backgroundColor="White"
            borderRadius="small"
            borderWidth="small"
            borderColor="Silver400"
            paddingTop="medium"
            paddingBottom="medium"
            paddingLeft="large"
            paddingRight="large"
            maxWidth="464px"
            width="100%"
          >
            <PaywallPaymentForm />
          </Box>
        )}
      </Content>
    </Stack>
  );
}
