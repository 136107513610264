import { startCase } from 'lodash';
import { AutocompleteBase, AutocompleteBaseProps } from './AutocompleteBase';

const paymentStatus = [
  'CHARGE_SCHEDULED',
  'SUPER_PAY_CHARGED',
  'PAYMENT_ON_HOLD',
  'PAYMENT_INITIATED',
  'FAILED',
  'PAID',
];

export function formatPaymentStatus(status: string) {
  switch (status) {
    case 'SUPER_PAY_CHARGED':
      return 'SuperPay Charged';
    case 'CHARGE_SCHEDULED':
      return 'Charge Scheduled';
    case 'PAYMENT_ON_HOLD':
      return 'Payment on Hold';
    case 'PAYMENT_INITIATED':
      return 'Payment Initiated';
    case 'FAILED':
      return 'Payment Failed';
    default:
      return startCase(status.toLowerCase());
  }
}

interface PaymentStatusAutocompleteProps
  extends Omit<
    AutocompleteBaseProps<string>,
    'options' | 'onChange' | 'onSelect'
  > {
  onChange: (values: string[]) => void;
}

export function PaymentStatusAutocomplete({
  value,
  onChange,
  onClose,
}: PaymentStatusAutocompleteProps) {
  return (
    <AutocompleteBase
      value={value}
      onClose={onClose}
      options={paymentStatus}
      onChange={(_, option) => onChange(option)}
      getOptionLabel={(option: string) => formatPaymentStatus(option)}
      filterOptions={(results, { inputValue }) =>
        results.filter((x) =>
          x.toLowerCase().includes(inputValue.trim().toLowerCase()),
        )
      }
    />
  );
}
