import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import Order from 'shared/types/order';

export const escalationReasonsMap = new Map([
  ['CHARGES_DUE', 'Charges Due'],
  ['VEHICLE_IS_NOT_AT_LOCATION', 'Vehicle is Not at Location'],
  ['INCORRECT_PHONE_NUMBER', 'Incorrect Phone Number'],
  ['LOCATION_WILL_NOT_RELEASE', 'Location Will Not Release'],
  ['LOCATION_CHANGED', 'Location Changed'],
  ['NO_RETURNED_CALLS_OR_EMAILS', 'No Returned Calls/Emails'],
  ['OTHER', 'Other'],
]);

export function useIsEscalationAvailable() {
  const isEscalationEnabled = useFeatureToggle('stms.escalation.enable');
  const { user } = useUserState();

  return {
    isEscalationAvailable:
      isEscalationEnabled || user?.shipper.is_submit_escalation_allowed,
    isShipper: isEscalationEnabled,
    isBroker: user?.shipper.is_submit_escalation_allowed,
  };
}

export function useEscalationData(order: Order) {
  const escalationData = order.escalation || order.escalations?.[0];

  const brokerName = escalationData?.submitted_by_user_name;

  const escalationGuid = escalationData?.guid;
  const escalationReasons = escalationData?.reasons;
  const isOrderEscalationSent =
    escalationData?.guid && escalationReasons && !escalationData.is_resolved;

  const isCanEscalate =
    escalationData?.is_allowed_to_submit &&
    order.status !== 'order_canceled' &&
    !order.is_canceled_by_customer &&
    !order.is_archived &&
    order.is_active;

  return {
    escalationData,
    brokerName,
    escalationGuid,
    escalationReasons,
    isOrderEscalationSent,
    isCanEscalate,
  };
}
