import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { findAPIPageQueryItem, useAPIPageQuery } from 'shared/api/APIPageQuery';
import { useAPIQuery, UseAPIQueryOptions } from 'shared/api/APIQuery';
import { AttachmentDTO } from 'shared/types/attachment';
import {
  Carrier,
  CarrierBrokerPreferencesUpdatePayload,
  CarrierBrokerStatusUpdatePayload,
  CarrierFullInfo,
  FmcsaInfo,
} from 'shared/types/carrier';
import { NoteDTO } from 'shared/types/note';
import { valuesToPlain } from 'shared/utils/DataUtils';
import { hasOnlyDigits } from 'shared/utils/StringUtils';
import {
  CarrierPerformanceDTO,
  carrierPerformanceSchema,
} from './CarrierPerformanceDTO';
import { CarrierReportDTO } from './CarrierReportDTO';
import { usePrivateNetworkCache } from './PrivateNetworkAPI';

export type CarrierType =
  | 'recent'
  | 'approved'
  | 'preferred'
  | 'private-network'
  | 'can_expedite_payment'
  | 'internal'
  | 'blacklisted'
  | 'insurance_expired'
  | 'insurance_certificate_holder';

export function useCarriersCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateCarriers() {
      void queryClient.invalidateQueries('carriers');
    }

    function findCarrier(guid: string) {
      return findAPIPageQueryItem<CarrierFullInfo>(
        ['carriers', 'list'],
        queryClient,
        (item) => item.guid === guid,
      );
    }

    function replaceCarrier(carrier: CarrierFullInfo) {
      queryClient.setQueryData(
        ['carriers', 'item', { guid: carrier.guid }],
        carrier,
      );
    }

    return { findCarrier, invalidateCarriers, replaceCarrier };
  }, [queryClient]);
}

interface CarrierParams {
  guid?: string;
  usdot?: string;
}

export function useCarrier(
  { usdot, guid }: CarrierParams,
  options: UseAPIQueryOptions<CarrierFullInfo> = {},
) {
  const { requestResource } = useAPI();
  const { findCarrier, replaceCarrier } = useCarriersCache();

  return useAPIQuery(
    ['carriers', 'item', { usdot, guid }],
    () => {
      if (guid) {
        return requestResource(
          'GET /internal/carriers/{guid}',
          (data) => plainToClass(CarrierFullInfo, data),
          { guid },
        );
      }

      return requestResource(
        'POST /internal/carriers/usdot',
        (data) => plainToClass(CarrierFullInfo, data),
        { json: { us_dot: usdot } },
      ).then((response) => {
        replaceCarrier(response);
        return response;
      });
    },
    {
      enabled: !!usdot || !!guid,
      initialData: () => {
        if (guid) {
          return findCarrier(guid);
        }
        return undefined;
      },
      // Carriers list returns rating_stats field null
      // Need to refetch each carrier to get it
      initialDataUpdatedAt: 0,
      ...options,
    },
  );
}

export function useCarrierPerformanceIndicators(guid: string) {
  const { requestResource } = useAPI();
  return useAPIQuery<CarrierPerformanceDTO>(
    ['carriers', 'performance', { guid }],
    () =>
      requestResource(
        'GET /internal/carriers/{guid}/performance',
        (data) => carrierPerformanceSchema.cast(data),
        {
          guid,
        },
      ),
  );
}

export function useAuthority(
  usdot?: string | null,
  options?: UseAPIQueryOptions<FmcsaInfo>,
) {
  const { requestResource } = useAPI();
  return useAPIQuery(
    ['carriers', 'authority', { usdot }],
    () =>
      requestResource(
        'GET /internal/carriers/fmcsa/{usdot}',
        (data) => plainToClass(FmcsaInfo, data),
        { usdot },
      ),
    {
      enabled: hasOnlyDigits(usdot),
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useCarrierAttachments(guid: string | undefined) {
  const { requestPage } = useAPI();
  return useAPIPageQuery(
    ['carriers', 'attachments', { guid }],
    () =>
      requestPage(
        'GET /internal/carriers/{guid}/attachments',
        (data) => plainToClass(AttachmentDTO, data),
        { guid },
      ),
    { enabled: !!guid },
  );
}

export function useCarrierInternalNotes(guid: string) {
  const { requestPage } = useAPI();
  return useAPIPageQuery(['carriers', 'internal-notes', { guid }], () =>
    requestPage(
      '/internal/carriers/{guid}/notes?size=60',
      (data) => plainToClass(NoteDTO, data),
      { guid },
    ),
  );
}

interface CarrierListParams {
  type: CarrierType;
  page: number;
  size?: number;
  sort?: string[];
  group_guid?: string;
}

export function useCarriers({
  type,
  size,
  page = 0,
  sort = ['name', 'ASC'],
  group_guid,
}: CarrierListParams) {
  const { requestPage } = useAPI();
  return useAPIPageQuery(
    ['carriers', 'list', { type, page, size, sort, group_guid }],
    () => {
      const params = new URLSearchParams();
      const [key, order, extraKey] = sort;

      params.append('page', String(page));
      params.append('sort', [key, order].join(','));

      if (size) {
        params.append('size', String(size));
      }

      if (extraKey) {
        params.append('sort', extraKey);
      }

      if (group_guid) {
        params.append('group_guid', group_guid);
      }

      return requestPage(
        `GET /internal/carriers/{type}?${params.toString()}`,
        (data) => plainToClass(CarrierFullInfo, data),
        { type, page },
      );
    },
    { keepPreviousData: false },
  );
}

export function useCarrierProfileAPI() {
  const cache = useCarriersCache();
  const privateNetworkCache = usePrivateNetworkCache();
  const { request, requestResource } = useAPI();

  return useMemo(
    () => ({
      parseAndFetchAuthority: (carrierUsdot: string) =>
        request(`GET /internal/carriers/fmcsa/${carrierUsdot}/parse`),

      updateInternalBrokerRecords: (
        carrierGuid: string,
        values: CarrierBrokerPreferencesUpdatePayload,
      ) =>
        request(`PUT /internal/carriers/${carrierGuid}/set_status`, {
          json: valuesToPlain(CarrierBrokerPreferencesUpdatePayload, values),
        }).then((response) => {
          cache.invalidateCarriers();
          privateNetworkCache.invalidatePrivateNetwork();
          return response;
        }),

      updateBrokerStatus: (
        carrierGuid: string,
        values: CarrierBrokerStatusUpdatePayload,
      ) =>
        request(`PUT /internal/carriers/${carrierGuid}/set_status`, {
          json: valuesToPlain(CarrierBrokerStatusUpdatePayload, values),
        }).then((response) => {
          cache.invalidateCarriers();
          return response;
        }),

      uploadInsurance: (carrierGuid: string, file: File) => {
        const body = new FormData();
        body.append('file', file);

        return request(
          `POST /internal/carriers/${carrierGuid}/insurance_holder_cert_file`,
          { body },
        );
      },

      uploadAttachment: (carrierGuid: string, file: File) => {
        const body = new FormData();
        body.append('file', file);

        return request(`POST /internal/carriers/${carrierGuid}/attachments`, {
          body,
        });
      },

      removeInsurance: (carrierGuid: string) =>
        request(
          `DELETE /internal/carriers/${carrierGuid}/insurance_holder_cert_file`,
        ),

      removeAttachment: (carrierGuid: string, id: number) =>
        request(`DELETE /internal/carriers/${carrierGuid}/attachments/${id}`),

      addCarrierNote: (carrierGuid: string, note: { note: string }) =>
        request(`POST /internal/carriers/${carrierGuid}/notes`, {
          json: note,
        }).then((response) => {
          cache.invalidateCarriers();
          return response;
        }),

      createInternalCarrier: (carrier: Carrier) =>
        requestResource(
          'POST /internal/carriers/internal',
          (data) => plainToClass(Carrier, data),
          { json: valuesToPlain(Carrier, carrier) },
        ).then(() => {
          cache.invalidateCarriers();
        }),

      updateInternalCarrier: (carrier: Carrier) =>
        requestResource(
          'PUT /internal/carriers/internal/{guid}',
          (data) => plainToClass(Carrier, data),
          { guid: carrier.guid, json: carrier },
        ).then(() => {
          cache.invalidateCarriers();
        }),

      removeInternalCarrier: (guid: string) =>
        request('DELETE /internal/carriers/internal/{guid}', { guid }).then(
          (response) => {
            cache.invalidateCarriers();
            return response;
          },
        ),

      reportCarrier: (carrierGuid: string, report: CarrierReportDTO) =>
        request(`POST /internal/carriers/{guid}/report`, {
          json: report,
          guid: carrierGuid,
        }),
    }),
    [cache, request, requestResource],
  );
}
