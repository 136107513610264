import { PaymentMethod, PaymentTerm } from '@superdispatch/sdk';
import { SuperPayStatus } from 'shared/types/order';
import { DeliveryVerificationFailureReasonType } from './DeliveryVerificationDTO';

export class CarrierPaymentDTO {
  tariff: number;

  deposit: number;

  amount: number;

  terms: PaymentTerm;

  notes: string;

  method: PaymentMethod;

  reference_number: string;

  sent_date: string;

  super_pay: {
    amount: number | null;
    status: SuperPayStatus;
    is_on_hold: true;
    expected_at: string | null;
    scheduled_for: string | null;
    paid_at: string;
    on_hold_reasons?: DeliveryVerificationFailureReasonType[];
  };
  superpay_canceled: boolean;
}
