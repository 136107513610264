import {
  yupArray,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { array, boolean, InferType, number, string } from 'yup';
import {
  dictionaryCarrierSchema,
  dictionaryPlaceSchema,
} from '../../core/dictionary/data/DictionaryDTO';

export type OrderListPageParamsDTO = InferType<
  typeof orderListPageParamsSchema
>;

export const orderListPageParamsSchema = yupObject({
  limit: yupNumber(),
  size: yupNumber(),
  page: yupNumber().default(1).required(),
  sort: yupArray(yupString()).required(),
  statuses: array(yupString()),
  payment_statuses: array(yupString()),
  carrier_guids: array(yupString()),
  carriers: array(dictionaryCarrierSchema),
  customer_names: array(yupString()),
  dispatcher_names: array(yupString()),
  salespersons: array(yupString()),
  lot_numbers: array(yupString()),
  contact_names: array(yupString()),
  tags: array().of(yupString()),
  pickup_addresses: array(dictionaryPlaceSchema),
  delivery_addresses: array(dictionaryPlaceSchema),
  delivered_on_start_date: yupString(),
  delivered_on_end_date: yupString(),
  delivered_on_date: array(yupString()),
  is_superpay_available_carrier: array(yupString()),
  is_carrier_requested_superpay: array(yupString()),
  is_expedited_payment: array(yupString()),
  payment_methods: array(yupString()),
  query_field: yupString(),
  period: yupString(),
});

export const orderSearchPayloadSchema = orderListPageParamsSchema.shape({
  query: yupString(),
  limit: number(),
  sort: array(string()),
  size: number(),
  page: number(),
  is_superpay_available_carrier: boolean(),
  is_carrier_requested_superpay: boolean(),
  is_expedited_payment: boolean(),
});

export type DashboardOrderPageParamsDTO = InferType<
  typeof dashboardOrderPageParamsSchema
>;
export const dashboardOrderPageParamsSchema = yupObject({
  page: yupNumber(),
  pageSize: yupNumber(),
  sort: array().of(string()).ensure().required(),
});
