import { createSvgIcon } from 'shared/icons/IconUtils';

export const PaymentZelleIcon = createSvgIcon(
  'PaymentZelleIcon',
  <>
    <path
      d="M11.6667 14H4.33333C3.04467 14 2 12.9553 2 11.6667V4.33333C2 3.04467 3.04467 2 4.33333 2H11.6667C12.9553 2 14 3.04467 14 4.33333V11.6667C14 12.9553 12.9553 14 11.6667 14Z"
      fill="currentColor"
    />
    <path
      d="M5.83333 6.16666H10.5C10.684 6.16666 10.8333 6.01732 10.8333 5.83332V4.99999C10.8333 4.81599 10.684 4.66666 10.5 4.66666H5.83333C5.64933 4.66666 5.5 4.81599 5.5 4.99999V5.83332C5.5 6.01732 5.64933 6.16666 5.83333 6.16666Z"
      fill="white"
    />
    <path
      d="M5.66666 11.5H10.5C10.684 11.5 10.8333 11.3507 10.8333 11.1667V10.3333C10.8333 10.1493 10.684 10 10.5 10H5.66666C5.48266 10 5.33333 10.1493 5.33333 10.3333V11.1667C5.33333 11.3507 5.48266 11.5 5.66666 11.5Z"
      fill="white"
    />
    <path
      d="M7.41667 3.66667V5.66667C7.41667 5.75867 7.49134 5.83333 7.58334 5.83333H8.75001C8.84201 5.83333 8.91667 5.75867 8.91667 5.66667V3.66667C8.91667 3.57467 8.84201 3.5 8.75001 3.5H7.58334C7.49134 3.5 7.41667 3.57467 7.41667 3.66667Z"
      fill="white"
    />
    <path
      d="M7.41667 10.6667V12.6667C7.41667 12.7587 7.49134 12.8333 7.58334 12.8333H8.75001C8.84201 12.8333 8.91667 12.7587 8.91667 12.6667V10.6667C8.91667 10.5747 8.84201 10.5 8.75001 10.5H7.58334C7.49134 10.5 7.41667 10.5747 7.41667 10.6667Z"
      fill="white"
    />
    <path
      d="M5.526 10.3127H7.33333L10.7647 6.033C10.824 5.959 10.771 5.849 10.676 5.849H8.83333L5.425 10.0993C5.355 10.1843 5.41566 10.3127 5.526 10.3127Z"
      fill="white"
    />
  </>,
  { viewBox: '0 0 16 16' },
);
