import { Divider, Link, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { List } from 'shared/ui/List';
import { useShipperProfileAPI } from '../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../shipper-profile/data/ShipperProfileDTO';
import {
  MoreInformationExpandingBlock,
  SectionSwitch,
  SectionSwitchCard,
  SuperLoadboardSettingsSection,
} from './core/SuperLoadboardSettingsSection';
import { trackUpdatedSuperLoadboardSettingsValues } from './data/SuperLoadboardAnalytics';

const AUTO_REPOST_SWITCHES = [
  {
    title: 'Carrier Declined Offers',
    SwitchComponent: AutoRepostDeclinedSwitch,
  },
  {
    title: 'Carrier Cancelled Offers',
    SwitchComponent: AutoRepostCancelledSwitch,
  },
] as const;

export function SuperLoadboardAutoRepostLegacy({
  profile,
}: {
  profile: ShipperProfileDTO;
}) {
  return (
    <SuperLoadboardSettingsSection aria-label="Auto Repost">
      <Stack space="small">
        <Stack space="xxsmall">
          <Typography variant="h4">Repost Orders Automatically</Typography>
          <Typography color="textSecondary">Only to Super Loadboard</Typography>
        </Stack>

        <Stack space="medium">
          {AUTO_REPOST_SWITCHES.map(({ title, SwitchComponent }) => (
            <SectionSwitchCard key={title}>
              <Columns align="center">
                <Column width="fluid">{title}</Column>
                <Column width="content">
                  <SwitchComponent profile={profile} />
                </Column>
              </Columns>
            </SectionSwitchCard>
          ))}
        </Stack>

        <MoreInformationExpandingBlock>
          <Typography color="textSecondary">
            Loads won&apos;t be reposted if the carrier cancels for any of these
            reasons:
          </Typography>

          <List>
            <li>
              <Typography color="textSecondary">Unit Not Ready</Typography>
            </li>
            <li>
              <Typography color="textSecondary">
                Unknown Inoperable Vehicle
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary">
                Carrier leaves a note
              </Typography>
            </li>
          </List>
        </MoreInformationExpandingBlock>
      </Stack>
    </SuperLoadboardSettingsSection>
  );
}

export function SuperLoadboardAutoRepost({
  profile,
}: {
  profile: ShipperProfileDTO;
}) {
  const isAutoRepostRecommendationEnabled = useFeatureToggle(
    'auto-repost-recommendation.enabled.ui',
  );

  if (!isAutoRepostRecommendationEnabled) {
    return <SuperLoadboardAutoRepostLegacy profile={profile} />;
  }

  return (
    <SuperLoadboardSettingsSection aria-label="Auto Repost">
      <Stack space="small">
        <Stack space="large">
          <Columns collapseBelow="tablet">
            <Column>
              <Stack space="xxsmall">
                <Typography variant="h3">
                  Repost Orders Automatically
                </Typography>
                <Typography color="textSecondary">
                  Only to Super Loadboard
                </Typography>
              </Stack>
            </Column>
            <Column>
              <Inline verticalAlign="center" horizontalAlign="right">
                <Help fontSize="small" color="action" />
                <Link
                  color="textSecondary"
                  href="https://support.superdispatch.com/hc/en-us/articles/35671054821139-How-To-Set-Up-Shipper-TMS-Automatic-Load-Reposting"
                >
                  Learn how automatic load repost works
                </Link>
              </Inline>
            </Column>
          </Columns>

          <Columns align="center">
            <Column width="fluid">
              <Inline>
                <TextBox variant="heading-4">
                  Repost when Carrier Declines the Load Offer
                </TextBox>
              </Inline>
            </Column>
            <Column width="content">
              <AutoRepostDeclinedSwitch profile={profile} />
            </Column>
          </Columns>
          <Columns align="center">
            <Column width="fluid">
              <Stack space="xxsmall">
                <Inline>
                  <TextBox variant="heading-4">
                    Repost when Carrier Cancels Order after Accepting
                  </TextBox>
                </Inline>
                <Stack space="none">
                  <Typography color="textSecondary">
                    Orders <b>will not be reposted</b> if the carrier cancels
                    for any of these reasons:
                  </Typography>

                  <List>
                    <li>
                      <Typography color="textSecondary">
                        Unit Not Ready
                      </Typography>
                    </li>
                    <li>
                      <Typography color="textSecondary">
                        Unknown Inoperable Vehicle
                      </Typography>
                    </li>
                    <li>
                      <Typography color="textSecondary">
                        Carrier leaves a note
                      </Typography>
                    </li>
                  </List>
                </Stack>
              </Stack>
            </Column>
            <Column width="content">
              <AutoRepostCancelledSwitch profile={profile} />
            </Column>
          </Columns>
        </Stack>

        <Divider />
        <Stack space="xxsmall">
          <TextBox variant="heading-5">
            All reposted orders will retain the following:
          </TextBox>
          <List>
            <li>
              <Typography color="textPrimary">
                The original pickup and delivery dates as specified prior to any
                negotiation or carrier request.
              </Typography>
            </li>
            <li>
              <Typography color="textPrimary">
                The total carrier price as established before any negotiation or
                carrier request.
              </Typography>
            </li>
          </List>
        </Stack>
      </Stack>
    </SuperLoadboardSettingsSection>
  );
}

interface AutoRepostSwitchProps {
  profile: ShipperProfileDTO;
  fieldName: keyof ShipperProfileDTO;
  onSubmitSuccess: (updatedValues: ShipperProfileDTO) => void;
}

function AutoRepostCancelledSwitch({
  profile,
}: {
  profile: ShipperProfileDTO;
}) {
  return (
    <AutoRepostSwitch
      profile={profile}
      fieldName="is_auto_repost_for_cancel_offers_enabled"
      onSubmitSuccess={(updatedValues) => {
        trackUpdatedSuperLoadboardSettingsValues({
          name: 'Auto Repost Cancelled Automatically',
          is_auto_repost_for_cancel_offers_enabled:
            updatedValues.is_auto_repost_for_cancel_offers_enabled,
        });
      }}
    />
  );
}

function AutoRepostDeclinedSwitch({ profile }: { profile: ShipperProfileDTO }) {
  return (
    <AutoRepostSwitch
      profile={profile}
      fieldName="is_auto_repost_for_decline_offers_enabled"
      onSubmitSuccess={(updatedValues) => {
        trackUpdatedSuperLoadboardSettingsValues({
          name: 'Auto Repost Declined Automatically',
          is_auto_repost_for_declined_offers_enabled:
            updatedValues.is_auto_repost_for_decline_offers_enabled,
        });
      }}
    />
  );
}

function AutoRepostSwitch({
  profile,
  fieldName,
  onSubmitSuccess,
}: AutoRepostSwitchProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateLoadboardSettings(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      onSubmitSuccess(updatedValues);
      addSnackbar('Settings updated', {
        variant: 'success',
      });
    },
    onSubmitFailure(submitError) {
      addSnackbar(submitError.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <SectionSwitch
        disabled={!canUpdateProfile || formik.isSubmitting}
        checked={formik.values[fieldName] as boolean}
        onChange={(_, checked) => {
          formik.setFieldValue(fieldName, checked);
          void formik.submitForm();
        }}
      />
    </FormikProvider>
  );
}
