import { parseDate } from '@superdispatch/dates';
import { MouseEvent, useEffect } from 'react';
import { isInternalUser } from 'shared/data/AppUtils';
import { AnyObject } from 'shared/types/Utils';
import { objectFlatten } from 'shared/utils/DataUtils';
import { getAppType } from '../data/AppUtils';
import { CurrentUser } from '../types/user';
import { hasSuperDispatchExtensionInstalled } from './ExtensionHelper';
import { logError } from './MonitoringService';
import { parseSearchQuery } from './RouteHelpers';

{
  const segmentKey =
    import.meta.env.VITE_TARGET === 'production'
      ? 'sIbTUwhxwIQbBGkeMwyLco0hwVs3FLPM'
      : import.meta.env.VITE_TARGET === 'staging'
      ? 'TJIFg8r379iFXC8BwIqPQH0oX6ttVaft'
      : null;

  if (segmentKey) {
    tryRun(() => {
      analytics.load(segmentKey);
      analytics.page();
    });
  }
}

function tryRun(fn: () => void): void {
  if (typeof analytics != 'undefined') {
    try {
      fn();
      return;
    } catch (error) {
      logError(error, 'Analytics');
    }
  }
}

export function onAnalyticsReady(fn: () => Promise<void> | void): void {
  tryRun(() => {
    analytics.ready(() => {
      void fn();
    });
  });
}

function getUserDetails(user: CurrentUser) {
  const { shipper, user_hash } = user;
  const shipperType = shipper.shipper_type;

  const shipperData = {
    companyType: 'Broker',
    isActive: true,
    id: shipper.guid,
    name: shipper.name,
    email: shipper.contact_email,
    phone: shipper.contact_phone,
    usdot: shipper.usdot_number,
    carrierGUID: shipper.carrier_guid,
    isTestAccount: shipper.is_test_account,
    self_serve_signup_company: shipper.is_self_serve,
    superpay_onboarding_status:
      shipper.super_pay_settings?.on_boarding_status ?? null,
    subscription_plan: shipper.subscription_plan,
    verification_status: shipper.verification_status,
    superpay_flow: shipper.super_pay_settings?.superpay_flow ?? null,
  };

  const userData = {
    user_hash,
    id: user.guid,
    isActive: true,
    email: user.email,
    lastName: user.last_name,
    createdAt: user.created_at,
    firstName: user.first_name,
    has_chrome_extension: hasSuperDispatchExtensionInstalled(),

    shipperType,
    company: shipperData,
    groupId: shipper.guid,
    shipper_guid: shipper.guid,
    shipper_user_role: user.user_roles.map((role) => role.role.name).join(', '),
  };

  return userData;
}

export function resetAnalytics(): void {
  tryRun(() => {
    analytics.reset();
  });
}

export function identifyUser(user?: CurrentUser, callback?: () => void) {
  onAnalyticsReady(() => {
    if (user) {
      const shipperType = user.shipper.shipper_type;
      const userData = getUserDetails(user);
      const options =
        shipperType === 'CUSTOMER' || isInternalUser(user)
          ? { integrations: { Intercom: false } }
          : ({
              Intercom: { user_hash: user.user_hash },
            } as SegmentAnalytics.SegmentOpts);

      analytics.identify(user.guid, userData, options, callback);
    } else {
      analytics.identify(undefined, {
        integrations: {
          Intercom: getAppType() === 'broker',
        },
      });
    }

    analytics.page();
  });
}

/** @deprecated
 * Use trackEvent instead of trackEventLegacy
 * */
export function trackEventLegacy(
  eventName: string,
  eventData?: AnyObject,
  callback?: () => void,
) {
  const { utm_medium } = eventData || parseSearchQuery(window.location.search);
  const properties = {
    product: 'Shipper TMS',
    utm_medium,
    ...objectFlatten(eventData || {}, '.', 'eventData'),
  };

  tryRun(() => {
    analytics.track(`[STMS] ${eventName}`, properties, callback);
  });

  if (import.meta.env.MODE !== 'production') {
    // eslint-disable-next-line no-console
    console.info('Analytics: "[STMS] %s": %O', eventName, properties);
    callback?.();
  }
}

function getUTMs() {
  const query = parseSearchQuery(window.location.search);
  const tags: Record<string, unknown> = {};

  Object.keys(query).forEach((key) => {
    if (key.startsWith('utm_')) {
      tags[key] = query[key];
    }
  });

  return tags;
}

function getUserTraits() {
  // analytics.user is not available before identify() is called
  if (
    typeof analytics === 'undefined' ||
    typeof analytics.user !== 'function'
  ) {
    return undefined;
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression -- analytics.user().traits() returns object
    return analytics.user().traits() as unknown as Record<string, unknown>;
  } catch (error: unknown) {
    return undefined;
  }
}

export function trackEvent(
  eventName: string,
  data?: Record<string, string | number | undefined | boolean | null>,
  callback?: () => void,
) {
  const queryUtms = getUTMs();

  const properties = {
    ...queryUtms,
    ...data,
    utm_source: data?.utm_source || queryUtms.utm_source || 'Web STMS',
  };

  tryRun(() => {
    analytics.track(
      eventName,
      properties,
      { context: { traits: getUserTraits() } },
      callback,
    );
  });

  if (import.meta.env.MODE !== 'production') {
    // eslint-disable-next-line no-console
    console.info('Analytics: "%s": %O', eventName, properties);
    callback?.();
  }
}

export function useTrackEventLegacy(name: string) {
  useEffect(() => {
    trackEventLegacy(name);
  }, [name]);
}

export function useTrackEvent(name: string) {
  useEffect(() => {
    trackEvent(name);
  }, [name]);
}

export function trackDurationAnalyticsLegacy(
  eventName: string,
  openedTime: number,
  eventData?: AnyObject,
  callback?: () => void,
) {
  const startTime = parseDate(openedTime, { format: 'JodaISO' });
  const duration = Math.abs(Math.round(startTime.diffNow().as('seconds')));
  trackEventLegacy(eventName, { duration, ...eventData }, callback);
}

export function trackAnchorClickLegacy(
  event: string,
  properties: AnyObject | undefined,
  mouseEvent: MouseEvent<HTMLAnchorElement>,
) {
  if (
    // Capture only left clicks.
    mouseEvent.button !== 0 ||
    // Do not capture prevented clicks.
    mouseEvent.defaultPrevented
  ) {
    return;
  }

  const { href, target } = mouseEvent.currentTarget;

  const shouldWaitTrackComplete =
    // Skip links without href.
    !!href &&
    // Skip clicks with modifiers.
    !mouseEvent.altKey &&
    !mouseEvent.ctrlKey &&
    !mouseEvent.metaKey &&
    !mouseEvent.shiftKey &&
    // Wait only for links to current window.
    (!target || target === '_self');

  if (shouldWaitTrackComplete) {
    mouseEvent.preventDefault();
  }

  trackEventLegacy(event, properties, () => {
    if (shouldWaitTrackComplete) {
      window.location.href = href;
    }
  });
}
