// Based on src/shared/layout/AppNavbarItems.tsx
const routeStatuses = [
  'all',
  'new',
  'escalations_sent',
  'escalations_received',
  'submitted',
  'on_hold',
  'posted_to_lb',
  'posted_to_sdlb',
  'posted_to_private_loadboard',
  'posted_to_cd',
  'requests',
  'scheduled',
  'completed',
  'pending',
  'declined',
  'accepted',
  'picked_up',
  'delivered',
  'invoiced',
  'delivery_verified',
  'paid',
  'flagged',
  'order_canceled',
  'archived',
  'inactive',
  'superpay_delivery_verified',
  'superpay_charge_scheduled',
  'superpay_charged',
  'superpay_on_hold',
  'superpay_initiated',
  'superpay_failed',
  'superpay_paid',
] as const;

export type RouteStatus = typeof routeStatuses[number];

export function isRouteStatus(value: string | undefined): value is RouteStatus {
  return routeStatuses.includes(value as RouteStatus);
}
