import { useMemo } from 'react';
import { apiInstance, useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import Order from 'shared/types/order';
import { makeCityStateZip } from 'shared/utils/AddressUtils';
import {
  OrderPricePredictionDTO,
  orderPricePredictionSchema,
} from './dto/OrderPriceDTO';
import {
  PricingInsightsRecentLoadDTO,
  pricingInsightsRecentLoadSchema,
} from './dto/PricingInsightsRecentPostings';

interface LastPostingsParams {
  type: 'postings' | 'moves' | 'my-moves';
  order: Partial<Order>;
}

export function useRecentLoads({ type, order }: LastPostingsParams) {
  const { requestPage } = useAPI();

  const [pickup_address, delivery_address] = useMemo(
    () => [
      order.pickup && makeCityStateZip(order.pickup.venue),
      order.delivery && makeCityStateZip(order.delivery.venue),
    ],
    [order.pickup, order.delivery],
  );

  return useAPIPageQuery<PricingInsightsRecentLoadDTO>(
    [
      `last_${type}`,
      'list',
      {
        pickup_address,
        delivery_address,
        vehicles: order.vehicles,
        trailer_type: order.transport_type,
      },
    ],
    () => {
      if (type === 'postings') {
        return requestPage(
          'GET /internal/last_postings{?pickup_address,delivery_address}',
          (data) => pricingInsightsRecentLoadSchema.cast(data),
          { pickup_address, delivery_address },
        );
      }

      return requestPage(
        'POST /internal/recent_moves{?mine}',
        (data) => pricingInsightsRecentLoadSchema.cast(data),
        {
          mine: type === 'my-moves',
          json: {
            pickup_city: order.pickup?.venue?.city,
            pickup_state: order.pickup?.venue?.state,
            pickup_zip: order.pickup?.venue?.zip,
            delivery_city: order.delivery?.venue?.city,
            delivery_state: order.delivery?.venue?.state,
            delivery_zip: order.delivery?.venue?.zip,
            pickup_address,
            delivery_address,
            trailer_type: order.transport_type?.toLowerCase(),
            vehicles:
              order.vehicles?.map((vehicle) => ({
                type: vehicle.type,
                is_inoperable: vehicle.is_inoperable,
                make: vehicle.make,
                model: vehicle.model,
                year: vehicle.year && Number(vehicle.year),
              })) || [],
          },
        },
      );
    },
  );
}

interface PricePredictionParams {
  average_vehicle_year?: number;
  transport_type?: string;
  pickup_address?: string;
  delivery_address?: string;
}

export function usePricePrediction({
  transport_type,
  pickup_address,
  delivery_address,
  average_vehicle_year,
}: PricePredictionParams = {}) {
  const { requestPage } = apiInstance;

  return useAPIPageQuery<OrderPricePredictionDTO>(
    [
      'price_prediction',
      'list',
      {
        pickup_address,
        delivery_address,
        average_vehicle_year,
        transport_type,
      },
    ],
    () =>
      requestPage(
        '/internal/price_prediction{?pickup_address,delivery_address,avg_vehicle_year,transport_type}',
        (data) => orderPricePredictionSchema.cast(data),
        {
          pickup_address,
          delivery_address,
          transport_type,
          avg_vehicle_year: average_vehicle_year,
        },
      ),
    {
      enabled: !!pickup_address && !!delivery_address,
    },
  );
}
