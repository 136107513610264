import {
  Grid,
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { useUID } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useEffect, useMemo, useRef, useState } from 'react';

export interface FileFieldProps
  extends Omit<StandardTextFieldProps, 'onChange'> {
  name: string;
  disabled?: boolean;
  label?: string;
  value?: string | File;
  onChange?: (value: File | undefined) => void;
  accept?: HTMLInputElement['accept'];
}

export function FileField({
  id,
  name: _,
  value,
  onChange,
  disabled,
  accept,
  label,
  error,
  helperText,
  ...rest
}: FileFieldProps) {
  const uid = useUID();
  const [previewURL, setPreviewURL] = useState<string>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const fileName = useMemo(
    () => (typeof value === 'string' ? value.split('/').pop() : value?.name),
    [value],
  );

  function openInput() {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }

  useEffect(() => {
    if (!(value instanceof File)) {
      setPreviewURL(value);

      return;
    }

    const nextPreviewURL = URL.createObjectURL(value);

    setPreviewURL(nextPreviewURL);

    return () => {
      URL.revokeObjectURL(nextPreviewURL);
    };
  }, [value]);

  return (
    <Grid
      spacing={1}
      container={true}
      alignItems={helperText ? 'center' : 'flex-end'}
    >
      <input
        value=""
        ref={inputRef}
        type="file"
        accept={accept}
        hidden={true}
        onChange={({ target: { files } }) => {
          if (files?.length) {
            onChange?.(files[0]);
          }
        }}
      />

      <Grid item={true} xs={true}>
        <TextField
          {...rest}
          id={id || uid}
          fullWidth={true}
          disabled={disabled}
          label={label}
          value={fileName}
          error={error}
          helperText={helperText}
          inputProps={{ onClick: openInput }}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <Button
                  variant="neutral"
                  size="small"
                  disabled={disabled}
                  onClick={openInput}
                >
                  Choose file
                </Button>
              </InputAdornment>
            ),
            endAdornment: previewURL && (
              <InputAdornment position="end">
                <IconButton
                  aria-label={`${label || ''} preview link`}
                  href={previewURL}
                  target="_blank"
                  size="small"
                  disabled={disabled}
                >
                  <Launch color="action" fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {value && (
        <Grid item={true}>
          <Button
            variant="neutral"
            disabled={disabled}
            onClick={() => {
              onChange?.(undefined);
            }}
          >
            Remove
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
