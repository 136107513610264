import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  useResponsiveValue,
} from '@superdispatch/ui';

export interface ManageUsersFooterProps {
  page?: number;
  totalPages?: number;
  handlePageChange: ({ selected }: { selected: number }) => void;
}

export function ManageUsersFooter({
  page = 0,
  totalPages,
  handlePageChange,
}: ManageUsersFooterProps) {
  const siblingCount = useResponsiveValue(0, 3);
  return (
    <Box
      padding={2}
      bgcolor={ColorDynamic.White}
      borderTop={`1px solid ${ColorDynamic.Silver400};`}
    >
      <Columns>
        {totalPages && totalPages > 0 && (
          <Column width="fluid">
            <Inline horizontalAlign="center">
              <Pagination
                siblingCount={siblingCount}
                page={page + 1}
                count={totalPages}
                onChange={(_, selectedPage) =>
                  handlePageChange({ selected: selectedPage - 1 })
                }
              />
            </Inline>
          </Column>
        )}
      </Columns>
    </Box>
  );
}
