import { Link, Typography } from '@material-ui/core';
import { FormikCheckboxField } from '@superdispatch/forms';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { useSuperLoadboardSettingsChannel } from 'loadboard-settings/data/SuperLoadboardChannel';
import { useMemo } from 'react';
import {
  useAppToken,
  useShipperIdForAdmin,
  useUserState,
} from 'shared/data/AppUserState';
import { useUser } from 'shared/data/AuthAPI';
import { useCanExecute } from 'shared/data/UserPermissions';
import { InstantIcon } from 'shared/icons/InstantIcon';
import Order from 'shared/types/order';
import { List } from 'shared/ui/List';
import styled from 'styled-components';

const InstantBookingIcon = styled(InstantIcon)`
  font-size: 20px;
`;

function useInstantBookingState() {
  const { user: initialData } = useUserState();

  const token = useAppToken();
  const shipperId = useShipperIdForAdmin();
  const { data: user, refetch } = useUser(
    token,
    shipperId,
    'order-form-instant-booking',
    {
      initialData,
      enabled: !!initialData && !!token,
    },
  );

  useSuperLoadboardSettingsChannel(() => {
    void refetch();
  });

  return user;
}

interface OrderFormInstantBookingProps {
  status: Order['status'];
}

export function OrderFormInstantBooking({
  status,
}: OrderFormInstantBookingProps) {
  const user = useInstantBookingState();
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');

  const isBroker = user?.shipper.shipper_type === 'BROKER';
  const isValidStatus = useMemo(
    () =>
      [
        'new',
        'canceled',
        'declined',
        'order_canceled',
        undefined, //for new order form
      ].includes(status),
    [status],
  );

  if (!canUpdateProfile || !isValidStatus || !isBroker) {
    return null;
  }

  return user.shipper.is_instant_booking_allowed ? (
    <FormikCheckboxField
      label="Allow Instant Booking by Verified Carriers"
      name="can_be_instantly_booked"
      helperText={
        <List>
          <li>
            By disabling this, you might experience longer booking times, limit
            your order&apos;s visibility, and extended communication with
            carriers.
          </li>
          <li>
            Carriers marked as &quot;Can Instantly Book&quot; are not affected
            by this setting.
          </li>
        </List>
      }
    />
  ) : (
    <Columns space="xsmall">
      <Column width="content">
        <InstantBookingIcon htmlColor={ColorDynamic.Blue500} />
      </Column>
      <Column>
        <Stack space="xxsmall">
          <Inline space="xxsmall">
            <Typography>
              Allow Instant Booking for Verified Carriers and automate the
              booking process.{' '}
            </Typography>
            <Link
              href="/super-loadboard-settings?utm_medium=Order%20Form"
              target="_blank"
              rel="noreferrer"
              color="primary"
            >
              View Setting
            </Link>
          </Inline>

          <Typography variant="caption">
            Now you have more control and can exclude specific loads from
            Instant Booking
          </Typography>
        </Stack>
      </Column>
    </Columns>
  );
}
