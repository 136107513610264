import { useResponsiveValue, useSnackbarStack } from '@superdispatch/ui';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { VerificationApplicationSidebar } from '../core/VerificationApplicationSidebar';
import { useHandleCloseConfirmation } from '../core/VerificationCloseConfirmation';
import { VerificationOverlay } from '../core/VerificationOverlay';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { VerificationApplicationSteps } from './VerificationApplicationSteps';

interface VerificationApplicationDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess?: () => void;
  action?: string;
  actionType?: string;
}

export function VerificationApplicationDialog({
  open,
  onClose,
  onSubmitSuccess,
  action,
  actionType,
}: VerificationApplicationDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const location = useLocation();
  const isMobile = useResponsiveValue(true, false);

  const dialogLocation = location.pathname.includes('profile')
    ? 'Profile'
    : 'Orders';

  useEffect(() => {
    if (open) {
      trackVerificationApplicationEvent({
        name: 'Shipper Opened Verification Dialog',
        utm_medium: dialogLocation,
        action,
        action_type: actionType,
      });
    }
  }, [open, action, actionType, dialogLocation]);

  const closeHandler = useHandleCloseConfirmation(onClose);

  return (
    <VerificationOverlay
      open={open}
      onClose={closeHandler}
      fullWidth={true}
      sidebar={!isMobile && <VerificationApplicationSidebar />}
    >
      <VerificationApplicationSteps
        onSubmitSuccess={() => {
          onSubmitSuccess?.();
          addSnackbar('Your documents were submitted successfully', {
            variant: 'success',
          });
        }}
      />
    </VerificationOverlay>
  );
}
