import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
} from '@material-ui/core';
import { Button, LinkedText } from '@superdispatch/ui-lab';
import { ComponentType } from 'react';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import { MultilineTextView } from 'shared/ui/MultilineTextView';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { isEmpty } from '../data/ShipperProfileUtils';

export const isEmptyTermsAndConditions = (profile: ShipperProfileDTO) => {
  return isEmpty(profile, ['terms_and_conditions']);
};

export const TermsAndConditionsView: ComponentType<ShipperProfileDTO> = ({
  terms_and_conditions,
}) => {
  const [isOpen, showDialog, hideDialog] = useBoolean(false);

  if (!terms_and_conditions) {
    return null;
  }

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Box maxHeight="80px" overflow="hidden">
            <MultilineTextView>{terms_and_conditions}</MultilineTextView>
          </Box>
        </Grid>
        <Grid item={true} xs={12}>
          <Link color="primary" component="button" onClick={showDialog}>
            Read more
          </Link>
        </Grid>
      </Grid>
      <Dialog open={isOpen} onClose={hideDialog}>
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          <MultilineTextView>
            <LinkedText>{terms_and_conditions}</LinkedText>
          </MultilineTextView>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDialog} variant="neutral">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
