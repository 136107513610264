import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  GeneralSettingsDTO,
  generalSettingsSchema,
  RecoverPasswordDTO,
  recoverPasswordSchema,
  timeZoneWithOffsetsSchema,
  WorkOSPortalLinkDTO,
  workOSPortalLinkSchema,
} from './GeneralSettingsDTO';

export function useGeneralSettingsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateSSettings() {
      void queryClient.invalidateQueries('settings');
    }
    return { invalidateSSettings };
  }, [queryClient]);
}
export function useTimezones() {
  const { requestPage } = useAPI();
  return useAPIQuery('timezone', () =>
    requestPage('GET /internal/timezones/with_offsets', (data) =>
      timeZoneWithOffsetsSchema.cast(data),
    ),
  );
}

export function useGeneralSettings() {
  const { requestResource } = useAPI();
  return useAPIQuery('settings', () =>
    requestResource<GeneralSettingsDTO>(
      'GET /internal/shippers/profile_settings',
      (data) => generalSettingsSchema.cast(data),
    ),
  );
}

export function useValidateToken(token: string) {
  const { request } = useAPI();

  return useAPIQuery(['reset-password', 'validate-token', { token }], () =>
    request('POST /reset-password/validate-token{?token}', { token })
      .then(() => true)
      .catch(() => false),
  );
}

export function useGeneralSettingsAPI() {
  const { request, requestResource } = useAPI();
  const cache = useGeneralSettingsCache();
  return useMemo(
    () => ({
      updateSetting: (settingType: keyof GeneralSettingsDTO, value: string) => {
        return request(
          `PUT /internal/shippers/profile_settings/${settingType}`,
          {
            json: { value },
          },
        ).then(() => {
          cache.invalidateSSettings();
        });
      },

      getOrganizationPortalLink: () =>
        requestResource<WorkOSPortalLinkDTO>(
          'GET /internal/workos/organization/portal_link',
          (data) => workOSPortalLinkSchema.cast(data),
        ),

      getAuthLink: (email: string) =>
        requestResource<WorkOSPortalLinkDTO, { email: string }>(
          `GET /workos/auth?email=${email}`,
          (data) => workOSPortalLinkSchema.cast(data),
        ),

      setSSOState: (value: boolean) =>
        request(
          `POST /internal/workos/change_state?state=${
            value ? 'true' : 'false'
          }`,
        ),

      resetPassword: (values: RecoverPasswordDTO) =>
        request('PUT /reset-password', {
          json: recoverPasswordSchema.cast(values),
        }),
    }),
    [request, cache],
  );
}
