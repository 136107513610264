import { IconButton, Typography } from '@material-ui/core';
import { ColorDynamic, useSnackbarStack } from '@superdispatch/ui';
import { parseURITemplate } from '@superdispatch/uri';
import { useMemo } from 'react';
import { useUserState } from '../../shared/data/AppUserState';
import { VehicleDTO } from '../../shared/dto/Order/VehicleDTO';
import { BOLIcon } from '../../shared/icons/BOLIcon';

interface Props {
  vehicle: VehicleDTO;
}

export function OrderViewVehicleBOL({
  vehicle: { status, pdf_bol_url },
}: Props) {
  const { addSnackbar } = useSnackbarStack();

  const { user } = useUserState();
  const shipperType = user?.shipper.shipper_type;

  const template = user?.shipper.default_bol_template;
  const downloadBolUrl = useMemo(
    () =>
      pdf_bol_url
        ? parseURITemplate(`${pdf_bol_url}{?template}`, { template })
        : null,
    [pdf_bol_url, template],
  );

  if (!downloadBolUrl && (status === 'picked_up' || status === 'delivered')) {
    return (
      <IconButton
        aria-label="pdf bol url"
        style={{ margin: -12 }}
        onClick={() => {
          addSnackbar(
            shipperType === 'CUSTOMER' ? (
              'The BOL is not available for this vehicle. Contact shipper for details'
            ) : (
              <Typography>
                The document is syncing. <br />
                To speed up the process, ask to keep the driver app open and
                wait till sync finishes.
              </Typography>
            ),
            { key: 'SyncingBOL' },
          );
        }}
      >
        <BOLIcon htmlColor={ColorDynamic.Silver500} />
      </IconButton>
    );
  }

  return !downloadBolUrl ? null : (
    <IconButton
      href={downloadBolUrl}
      target="_blank"
      aria-label="pdf bol url"
      rel="noopener noreferrer"
      style={{ margin: -12 }}
    >
      <BOLIcon color="action" />
    </IconButton>
  );
}
