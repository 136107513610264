import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormikCurrencyField } from '@superdispatch/forms';
import { Inline } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { round } from 'lodash';
import styled from 'styled-components';

const TableCellStyled = styled(TableCell)`
  border: none;
  padding: 8px 4px 8px 0;
`;

export interface SuperPayEditableVehicle {
  guid: string;
  price: number;
  is_active: boolean;
  title: string;
}

export function vehiclePrices(params: {
  editableVehicles: SuperPayEditableVehicle[];
  initialEditableVehicles: SuperPayEditableVehicle[];
  expeditedPayFeeAmount: number;
  orderPrice?: number;
}) {
  const {
    editableVehicles,
    initialEditableVehicles,
    expeditedPayFeeAmount,
    orderPrice = 0,
  } = params;

  const vehiclePrice = editableVehicles.reduce(
    (total, { price = 0 }) => total + price,
    0,
  );
  const initialVehiclePrice = initialEditableVehicles.reduce(
    (total, { price = 0 }) => total + price,
    0,
  );
  const isChangedVehiclePrice = vehiclePrice !== initialVehiclePrice;
  const roundedVehiclePrice = round(vehiclePrice, 2);

  const totalCarrierPrice = isChangedVehiclePrice
    ? roundedVehiclePrice
    : roundedVehiclePrice || orderPrice;

  return {
    totalCarrierPrice,
    totalCarrierPriceMinusFee: totalCarrierPrice - expeditedPayFeeAmount,
  };
}

interface VehicleRowProps {
  isDeleteActive: boolean;
  index: number;
  isDisabled?: boolean;
}

function VehicleRow({
  title,
  isDeleteActive,
  index,
  isDisabled,
}: VehicleRowProps & SuperPayEditableVehicle) {
  const priceFieldName = `editableVehicles.${index}.price`;
  const activeFieldName = `editableVehicles.${index}.is_active`;
  const showDeleteButton = isDeleteActive && !isDisabled;

  const [{ value: priceValue }, , { setValue: setPrice }] =
    useField<number>(priceFieldName);

  const [{ value: activeValue }, , { setValue: setActive }] =
    useField<boolean>(activeFieldName);

  if (!activeValue) {
    return null;
  }

  return (
    <TableRow>
      <TableCellStyled width="263px">{title}</TableCellStyled>
      <TableCellStyled>
        <Inline verticalAlign="center" noWrap={true}>
          <Box width="95px">
            <FormikCurrencyField
              disabled={isDisabled}
              name={priceFieldName}
              placeholder="0.00"
              fullWidth={true}
              inputProps={{
                allowNegative: false,
                maxLength: 11,
                fixedDecimalScale: true,
              }}
              onBlur={() => {
                if (!priceValue) {
                  void setPrice(0);
                }
              }}
            />
          </Box>
          {showDeleteButton && (
            <IconButton
              aria-label="remove vehicle"
              size="small"
              onClick={() => {
                void setActive(false);
                void setPrice(0);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Inline>
      </TableCellStyled>
    </TableRow>
  );
}

interface VehiclesTableProps {
  isDisabled?: boolean;
  editableVehicles: SuperPayEditableVehicle[];
}

export function VehiclesTable({
  isDisabled,
  editableVehicles,
}: VehiclesTableProps) {
  const isDeleteActive =
    editableVehicles.filter(({ is_active }) => is_active).length > 1;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellStyled>
            <TextBox noWrap={true} color="secondary" variant="heading-5">
              Vehicles
            </TextBox>
          </TableCellStyled>
          <TableCellStyled>
            <TextBox noWrap={true} color="secondary" variant="heading-5">
              Carrier Price
            </TextBox>
          </TableCellStyled>
        </TableRow>
      </TableHead>
      <TableBody>
        {editableVehicles.map((item, index) => (
          <VehicleRow
            {...item}
            isDisabled={isDisabled}
            isDeleteActive={isDeleteActive}
            key={item.guid}
            index={index}
          />
        ))}
      </TableBody>
    </Table>
  );
}
