import { DrawerActions, Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useRef } from 'react';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { CarrierFullInfo } from 'shared/types/carrier';
import styled from 'styled-components';
import { useCarrierInternalRecordsEditForm } from './CarrierInternalRecordsEditForm';
import { CarrierInternalRecordsFields } from './CarrierInternalRecordsFields';
import { useReportedCarriers } from './ReportedCarriers';

const StyledFormikDrawer = styled(FormikDrawer)`
  & .MuiDrawer-paper {
    width: 100%;
  }
`;

interface CarrierInternalRecordsEditDrawerProps {
  title: string;
  open: boolean;
  carrier: CarrierFullInfo;
  onClose: () => void;
  onSubmitSuccess: (reportCarrier: boolean) => void;
  onSubmitFailure: () => void;
}

interface CarrierInternalRecordsEditDrawerContentProps {
  title: string;
  carrier: CarrierFullInfo;
  onSubmitSuccess: (reportCarrier: boolean) => void;
  onSubmitFailure: () => void;
}

export function CarrierInternalRecordsEditDrawer({
  open,
  onClose,
  ...rest
}: CarrierInternalRecordsEditDrawerProps) {
  return (
    <StyledFormikDrawer open={open} onClose={onClose} confirmClose={true}>
      <CarrierInternalRecordsEditDrawerContent {...rest} />
    </StyledFormikDrawer>
  );
}

function CarrierInternalRecordsEditDrawerContent({
  title,
  carrier,
  onSubmitSuccess,
  onSubmitFailure,
}: CarrierInternalRecordsEditDrawerContentProps) {
  const reportCarrier = useRef(false);
  const [reportedCarriersGUIDs] = useReportedCarriers();
  const formik = useCarrierInternalRecordsEditForm({
    carrier,
    onSubmitSuccess() {
      onSubmitSuccess(reportCarrier.current);
    },
    onSubmitFailure,
  });

  const isReportButtonVisible = formik.values.status === 'IN_BLACKLIST';

  return (
    <FormikDrawerContent
      formik={formik}
      title={title}
      actions={
        <DrawerActions>
          <Inline space="small">
            <Button type="submit" pending={formik.isSubmitting}>
              Save
            </Button>
            {isReportButtonVisible && (
              <Button
                onClick={() => {
                  reportCarrier.current = true;
                  void formik.submitForm();
                }}
                variant="neutral"
                pending={formik.isSubmitting}
                disabled={reportedCarriersGUIDs.includes(carrier.guid)}
              >
                Save and Report
              </Button>
            )}
          </Inline>
        </DrawerActions>
      }
    >
      <FormikProvider value={formik}>
        <CarrierInternalRecordsFields title={title} carrier={carrier} />
      </FormikProvider>
    </FormikDrawerContent>
  );
}
