import {
  Box,
  CircularProgress,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { ColorDynamic, useUID } from '@superdispatch/ui';
import * as React from 'react';
import styled from 'styled-components';

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: inherit;
`;

const StyledHeader = styled(Box)`
  display: flex;
  padding: 12px 16px;
  min-height: 71px;
  align-items: center;
  border-bottom: solid 1px ${ColorDynamic.Silver400};
  background: inherit;
`;

const StyledContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

interface DrawerProps extends MuiDrawerProps {
  children: React.ReactNode;
  isOpen: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  header?: React.ReactNode;
  title?: string;
  width?: string;
  maxWidth?: string;
  disableAutoClose?: boolean;
}

/** @deprecated */
export const Drawer = ({
  children,
  header,
  title,
  isOpen,
  onClose,
  isLoading,
  width,
  maxWidth,
  disableAutoClose,
  ...props
}: DrawerProps) => {
  const uid = useUID();

  return (
    <MuiDrawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      aria-labelledby={uid}
      PaperProps={{ style: { width, maxWidth: maxWidth || '100%' } }}
      ModalProps={{ disableEnforceFocus: true }}
      {...props}
    >
      <StyledContainer>
        <StyledHeader>
          <Box my="-12px" mx="4px">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>

          {header}

          {title && (
            <Typography id={uid} variant="h3">
              {isLoading ? <Skeleton width="150px" /> : title}
            </Typography>
          )}
        </StyledHeader>

        <StyledContent>
          {isLoading ? (
            <Box
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress
                variant="indeterminate"
                color="primary"
                size={50}
              />
            </Box>
          ) : (
            children
          )}
        </StyledContent>
      </StyledContainer>
    </MuiDrawer>
  );
};
