import {
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { Button, Color, ColorDynamic, Stack } from '@superdispatch/ui';
import { usePartnerCarrier } from 'shared/api/LinkedBrokersAPI';
import { LinkedBrokerDTO } from 'shared/dto/LinkedBrokerDTO';
import { OutlineCheckIcon as SelectedIcon } from 'shared/icons/OutlineCheckIcon';
import { TextOverflow } from 'shared/ui/TextOverflow';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  overflow: auto;
  margin: 0 -16px;
  border-top: 1px solid ${ColorDynamic.Silver400};
`;

const StyledSpinner = styled(CircularProgress)`
  margin: auto;
`;

const StyledSelectedIcon = styled(SelectedIcon)`
  color: ${Color.Blue300};
`;

interface SendOfferPartnerCarriersProps {
  onSelect: (carrier: LinkedBrokerDTO) => void;
  selectedCarrierGuid?: string;
}

export function SendOfferPartnerCarriers({
  onSelect,
  selectedCarrierGuid,
}: SendOfferPartnerCarriersProps) {
  const { data, isLoading } = usePartnerCarrier();

  if (!data || !data.objects.length) {
    return null;
  }

  return (
    <Stack space="small">
      <div>
        <Typography variant="h4">Broker Partners</Typography>
        <Typography color="textSecondary">
          Orders sent to Broker Partners will be automatically accepted.
        </Typography>
      </div>

      <StyledContainer>
        {isLoading ? (
          <StyledSpinner variant="indeterminate" color="primary" size={40} />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Carrier</TableCell>
                  <TableCell>USDOT</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {data.objects.map((carrier) => (
                  <TableRow
                    key={carrier.guid}
                    style={{
                      background:
                        selectedCarrierGuid === carrier.guid
                          ? ColorDynamic.Blue50
                          : undefined,
                    }}
                  >
                    <TableCell>
                      <Flex alignItems="flex-start">
                        <TextOverflow>{carrier.name}</TextOverflow>
                      </Flex>
                    </TableCell>

                    <TableCell>{carrier.us_dot}</TableCell>

                    <TableCell>
                      {carrier.phone_numbers && (
                        <Link href={`tel:${carrier.phone_numbers}`}>
                          {carrier.phone_numbers}
                        </Link>
                      )}
                    </TableCell>

                    <TableCell align="right">
                      {selectedCarrierGuid === carrier.guid ? (
                        <StyledSelectedIcon />
                      ) : (
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => onSelect(carrier)}
                        >
                          Select
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledContainer>
    </Stack>
  );
}
