import { useUserState } from 'shared/data/AppUserState';
import { useVerificationApplication } from './VerificationApplicationAPI';

export function useIsShipperVerifiedOrInternational() {
  const { user } = useUserState();
  const { data: verificationInfo } = useVerificationApplication({
    enabled: user?.shipper.shipper_type !== 'CUSTOMER',
  });

  return (
    verificationInfo?.status === 'VERIFIED' ||
    verificationInfo?.status === 'INTERNATIONAL_SHIPPER'
  );
}
