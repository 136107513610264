import { Card, CardContent } from '@material-ui/core';
import { Business } from '@material-ui/icons';
import { formatDate, parseDate } from '@superdispatch/dates';
import { OverflowText, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useUserState } from 'shared/data/AppUserState';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { CalendarOut } from 'shared/icons/CalendarOut';
import Order from 'shared/types/order';
import { PaymentMethodIcon } from 'shared/ui/PaymentMethodIcon';
import {
  kmToMile,
  pricePerKmToPricePerMile,
} from 'shared/utils/MeasurementUtils';
import styled from 'styled-components';
import { PostingVehicle } from './LoadVehicle';
import { LoadWaypoints } from './LoadWaypoints';
import { PostingPaymentTerm } from './PostingPaymentTerm';
import { PostingVehiclesSummary } from './PostingVehiclesSummary';

const StyledCardContent = styled(CardContent)`
  display: grid;
  padding: 12px 16px;
  grid-row-gap: 8px;
  grid-template-columns: 1fr;

  &[data-cursor='pointer'] {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    grid-row-gap: 8px;
    grid-column-gap: 16px;
    grid-template-columns: repeat(4, minmax(0, 1fr)) auto;
  }
`;

function getAvailableAtDate(date?: string | null) {
  if (!date) return '';

  const availableAtDate = parseDate(date, { format: 'JodaISO' });
  const isPast = availableAtDate.diffNow('millisecond').milliseconds < 0;

  if (isPast) {
    return 'NOW';
  }
  return formatDate(availableAtDate, { variant: 'Date' });
}

export function PostingView({ order }: { order: Order }) {
  const { user } = useUserState();
  const screen = useResponsiveValue('mobile', 'mobile', 'desktop');

  const pickupAddress = {
    city: order.pickup?.venue?.city,
    state: order.pickup?.venue?.state,
    zip: order.pickup?.venue?.zip,
  };
  const deliveryAddress = {
    city: order.delivery?.venue?.city,
    state: order.delivery?.venue?.state,
    zip: order.delivery?.venue?.zip,
  };

  return (
    <Card data-testid="posting-view">
      <StyledCardContent>
        <LoadWaypoints
          pickup={pickupAddress}
          delivery={deliveryAddress}
          distanceMiles={
            order.distance_meters
              ? kmToMile(order.distance_meters / 1000)
              : null
          }
        />

        {!!order.vehicles?.length &&
          (screen === 'mobile' ? (
            <TextBox>
              {order.vehicles.length}{' '}
              {formatPlural(order.vehicles.length, 'Vehicle', 'Vehicles')}
            </TextBox>
          ) : (
            <Stack space="xsmall">
              {order.vehicles.slice(0, 2).map((vehicle, index) => (
                <PostingVehicle key={index} vehicle={vehicle} />
              ))}

              {order.vehicles.length > 2 && (
                <PostingVehiclesSummary
                  title={`+ ${order.vehicles.slice(2).length} more`}
                  vehicles={order.vehicles.slice(2)}
                />
              )}
            </Stack>
          ))}

        <Stack space="xsmall">
          <DescriptionItem icon={<Business />}>
            <Box maxWidth="130px">
              <OverflowText disableUnderline={true}>
                {user?.shipper.name}
              </OverflowText>
            </Box>
          </DescriptionItem>

          {screen === 'desktop' && (
            <DescriptionItem icon={<CalendarOut />}>
              {getAvailableAtDate(order.pickup?.scheduled_at)}
            </DescriptionItem>
          )}
        </Stack>

        <Stack>
          {screen === 'desktop' && order.payment && (
            <DescriptionItem
              icon={<PaymentMethodIcon paymentMethod={order.payment.method} />}
            >
              <PostingPaymentTerm
                terms={order.payment.terms}
                method={order.payment.method}
              />
            </DescriptionItem>
          )}

          {screen === 'desktop' && (
            <DescriptionItem label="Posted" icon={<CalendarIcon />}>
              {formatDate(
                parseDate(order.posted_to_loadboard_at, {
                  format: 'JodaISO',
                }),
                { variant: 'Date', fallback: 'N/A' },
              )}
            </DescriptionItem>
          )}

          <DescriptionItem label="Available" icon={<CalendarOut />}>
            {getAvailableAtDate(order.pickup?.scheduled_at)}
          </DescriptionItem>
        </Stack>

        <Stack space="xxsmall" align={['left', 'left', 'right']}>
          {!!order.price && (
            <TextBox variant="heading-3">
              {formatCurrency(order.price, { maximumFractionDigits: 0 })}
            </TextBox>
          )}

          {order.price_per_km != null && (
            <TextBox color="secondary">
              {formatCurrency(pricePerKmToPricePerMile(order.price_per_km), {
                maximumFractionDigits: 2,
              })}
              /mi
            </TextBox>
          )}
        </Stack>
      </StyledCardContent>
    </Card>
  );
}
