import { Drawer, IconButton, Typography } from '@material-ui/core';
import { ChevronRight, Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { AnchorButton, Box, Button } from '@superdispatch/ui-lab';
import { RequirementItem } from 'shared/ui/RequirementItem';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';

export interface DealerLicenseHelpDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function DealerLicenseHelpDrawer({
  open,
  onClose,
}: DealerLicenseHelpDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Learn more"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <DrawerContent>
        <Box maxWidth="400px">
          <Stack space="xlarge">
            <Stack space="small">
              <Typography variant="h2">
                Why do we need your dealer license?
              </Typography>
              <Typography>
                A dealers license is an official government document that allows
                Super Dispatch to verify important information needed to confirm
                your business.
              </Typography>
            </Stack>
            <Stack space="small">
              <Typography>
                Before uploading your dealer license verify::
              </Typography>
              <RequirementItem text="The company name on the dealer license matches the company name on your Super Dispatch account." />
              <RequirementItem text="Your dealer license is up to date and includes the state your business is registered with." />
            </Stack>
            <AnchorButton
              variant="text"
              endIcon={<ChevronRight />}
              href="https://support.superdispatch.com/hc/en-us/articles/35663145878291-Verified-Shipper-Requirements"
              target="_blank"
              onClick={() => {
                trackVerificationApplicationEvent({
                  name: 'Shipper Clicked on Read Our Article',
                  utm_content: 'Dealer License',
                });
              }}
            >
              Read our Article
            </AnchorButton>
          </Stack>
        </Box>
      </DrawerContent>
      <DrawerActions>
        <Button onClick={onClose}>Ok</Button>
      </DrawerActions>
    </Drawer>
  );
}
