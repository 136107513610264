import {
  yupArray,
  yupBoolean,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type PricingInsightsRecentLoadVehicleDTO = InferType<
  typeof pricingInsightsRecentLoadVehicleSchema
>;
const pricingInsightsRecentLoadVehicleSchema = yupObject({
  make: yupString().nullable(),
  model: yupString().nullable(),
  year: yupNumber(),
  is_inoperable: yupBoolean(),
  type: yupString().nullable(),
  requires_enclosed_trailer: yupBoolean(),
});

export type PricingInsightsRecentLoadDTO = InferType<
  typeof pricingInsightsRecentLoadSchema
>;
export const pricingInsightsRecentLoadSchema = yupObject({
  dispatched_date: yupString().nullable(),
  delivered_date: yupString().nullable(),
  posted_to_loadboard_at: yupString().nullable(),
  available_at: yupString().nullable(),
  delivery_city: yupString().nullable(),
  delivery_state: yupString().nullable(),
  delivery_zip: yupString().nullable(),
  pickup_zip: yupString().nullable(),
  pickup_city: yupString().nullable(),
  pickup_state: yupString().nullable(),
  price: yupNumber().default(0).nullable(false),
  price_per_mile: yupNumber().default(0).nullable(false),
  distance_miles: yupNumber(),
  load_count: yupNumber(),
  vehicle_types: yupString(),
  guid: yupString(), // Carrier TMS order guid
  broker_order_guid: yupString().nullable(),
  is_posted_to_loadboard: yupBoolean(),
  vehicles: yupArray(pricingInsightsRecentLoadVehicleSchema).required(),
  payment_terms: yupString().nullable(),
  payment_method: yupString().nullable(),
  price_per_vehicle: yupNumber().default(0),
  price_per_mile_per_vehicle: yupNumber().default(0),
});
