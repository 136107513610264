import { updatedDiff } from 'deep-object-diff';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';

export function getUpdatedOrderDetails(values: Order, newValues?: Order) {
  const updatedPayment = updatedDiff(
    values.payment || {},
    newValues?.payment || {},
  );

  const updatedVehicles = newValues?.vehicles?.map((vehicle) => {
    const prevVehicle = values.vehicles?.find((v) => v.id === vehicle.id);
    return updatedDiff(vehicle, prevVehicle ?? {});
  });

  return { payment: updatedPayment, vehicles: updatedVehicles };
}

interface UpdatedValues {
  vehicles?: Array<{ type?: unknown; photos?: unknown }>;
  payment?: {
    method?: unknown;
    terms?: unknown;
  };
  recommended_price?: unknown;
  sms_notification_phone_number?: unknown;
  is_personal_email_notifications_enabled?: unknown;
  is_personal_push_notifications_enabled?: unknown;
  is_auto_repost_for_decline_offers_enabled?: unknown;
  is_auto_repost_for_cancel_offers_enabled?: unknown;
}

interface TrackingProperty {
  key: string;
  getValue: (values: UpdatedValues) => boolean;
}

const TRACKING_PROPERTIES: TrackingProperty[] = [
  {
    key: 'updated_vehicle_type',
    getValue: (values) =>
      (values.vehicles || []).some((vehicle) => 'type' in vehicle),
  },
  {
    key: 'updated_vehicle_photo',
    getValue: (values) =>
      (values.vehicles || []).some((vehicle) => 'photos' in vehicle),
  },
  {
    key: 'updated_payment_method',
    getValue: (values) => Boolean(values.payment?.method),
  },
  {
    key: 'updated_payment_term',
    getValue: (values) => Boolean(values.payment?.terms),
  },
  {
    key: 'set_recommended_price',
    getValue: (values) => 'recommended_price' in values,
  },
  {
    key: 'enabled_sms',
    getValue: (values) => Boolean(values.sms_notification_phone_number),
  },
  {
    key: 'enabled_email',
    getValue: (values) =>
      Boolean(values.is_personal_email_notifications_enabled),
  },
  {
    key: 'enabled_push',
    getValue: (values) =>
      Boolean(values.is_personal_push_notifications_enabled),
  },
  {
    key: 'enabled_repost_for_decline_offers',
    getValue: (values) =>
      Boolean(values.is_auto_repost_for_decline_offers_enabled),
  },
  {
    key: 'enabled_repost_for_cancel_offers',
    getValue: (values) =>
      Boolean(values.is_auto_repost_for_cancel_offers_enabled),
  },
];

export function trackOrderRecommendations(
  updatedValues: UpdatedValues,
  requestsCount = 0,
): void {
  const additionalProps = TRACKING_PROPERTIES.reduce(
    (acc, { key, getValue }) => {
      if (getValue(updatedValues)) {
        acc[key] = 'yes';
      }
      return acc;
    },
    {},
  );

  trackEvent('Shipper Applied Recommendation', {
    utm_medium: 'Order Requests',
    requestsCount,
    ...additionalProps,
  });
}
