import { Inline, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { CarrierInternalRecordsEditDrawer } from 'manage-carriers/core/CarrierInternalRecordsEditDrawer';
import { useState } from 'react';
import { CarrierFullInfo, hasCarrierBrokerStatus } from 'shared/types/carrier';

interface Props {
  carrier: CarrierFullInfo;
  onCarrierReport?: () => void;
}

export function RecentCarriersTableAction({ carrier, onCarrierReport }: Props) {
  const [dialog, setDialog] = useState<'edit-carrier' | 'add-to-network'>();
  const hasStatus = hasCarrierBrokerStatus(carrier.broker_records);
  const { addSnackbar } = useSnackbarStack();

  return (
    <Inline>
      {hasStatus ? (
        <Button variant="neutral" onClick={() => setDialog('edit-carrier')}>
          Edit Carrier
        </Button>
      ) : (
        <Button variant="neutral" onClick={() => setDialog('add-to-network')}>
          Add to Network
        </Button>
      )}

      <CarrierInternalRecordsEditDrawer
        title={
          dialog === 'add-to-network'
            ? 'Add Recent Carrier to my Network'
            : 'Edit Carrier from the Network'
        }
        open={dialog === 'edit-carrier' || dialog === 'add-to-network'}
        carrier={carrier}
        onClose={() => {
          setDialog(undefined);
        }}
        onSubmitSuccess={(reportCarrier) => {
          setDialog(undefined);
          addSnackbar('Successfully updated Carrier', {
            variant: 'success',
          });

          if (reportCarrier) {
            onCarrierReport?.();
          }
        }}
        onSubmitFailure={() => {
          addSnackbar('Failed to update Carrier', {
            variant: 'error',
          });
        }}
      />
    </Inline>
  );
}
