import { Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatDate, formatDateRange, parseDate } from '@superdispatch/dates';
import { Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { useUsageDetails } from './useUsageDetails';

export function OverviewSection() {
  const { usageDetails, isUsageDetailsLoading } = useUsageDetails();

  if (isUsageDetailsLoading) {
    return <Skeleton />;
  }

  if (!usageDetails) {
    return null;
  }

  return (
    <Stack space="small">
      <Typography color="textSecondary">
        Overage fees apply when the posted or delivered VINs exceed your plan
        limits.{' '}
        <Link
          href="https://superdispatch.com/pricing-for-shippers"
          target="_blank"
          rel="noreferrer"
        >
          Learn More
        </Link>
      </Typography>

      <Box justifyContent="space-between" alignItems="center" display="flex">
        <TermDateRange
          start={usageDetails.current_term_start}
          end={usageDetails.current_term_end}
        />

        {usageDetails.last_updated_at && (
          <Typography color="textSecondary">
            Last Updated:{' '}
            {formatDate(usageDetails.last_updated_at, { variant: 'DateTime' })}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

function TermDateRange({
  start,
  end,
}: {
  start: string | null;
  end: string | null;
}) {
  if (!start || !end) {
    return null;
  }

  const formattedDate = formatDateRange(
    [
      //extracting date from datetime
      parseDate(start).toFormat('yyyy-MM-dd'),
      parseDate(end).toFormat('yyyy-MM-dd'),
    ],
    {
      fallback: 'N/A',
    },
    { format: 'DateISO' },
  );

  return (
    <Inline verticalAlign="center">
      <CalendarIcon color="action" fontSize="small" />
      <Typography>{formattedDate}</Typography>
    </Inline>
  );
}
