import { Box, Flex, FlexProps } from '@rebass/grid';
import { ColorDynamic } from '@superdispatch/ui';
import * as React from 'react';
import ShowMoreText from 'react-show-more-text';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  color: ${ColorDynamic.Dark500};
  border-radius: 4px;
  overflow: auto;
  overflow-wrap: anywhere;
`;

interface NoteProps extends Omit<FlexProps, 'children'> {
  lines?: number;
  notes?: string | null;
}

export const OrderViewNote: React.ComponentType<NoteProps> = ({
  lines = 2,
  bg = ColorDynamic.Blue50,
  notes,
  ...props
}) =>
  notes ? (
    <Flex {...props}>
      <StyledBox p={2} flex={lines > 0 ? 1 : undefined} bg={bg}>
        <ShowMoreText lines={lines} more="Read more" less="">
          {notes.split('\n').map((item, key) => (
            <React.Fragment key={key}>
              {item}
              <br />
            </React.Fragment>
          ))}
        </ShowMoreText>
      </StyledBox>
    </Flex>
  ) : null;
