import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { stringifyDate } from '@superdispatch/dates';
import {
  FormikCheckboxField,
  FormikDateField,
  FormikRadioGroupField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  ColorDynamic,
  RadioField,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { countBy, mapValues } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useMemo, useState } from 'react';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { Drawer } from 'shared/ui/Drawer';
import { TextOverflow } from 'shared/ui/TextOverflow';
import {
  composeValidators,
  email,
  emails as emailsValidator,
  required,
} from 'shared/utils/ValidatorUtils';
import { InvoiceDTO } from '../../data/dto/InvoiceDTO';
import {
  useBulkOrderActionAPI,
  useSingleOrderActionAPI,
} from '../../data/OrderActionAPI';
import { OrderErrorModal } from '../error-modal/OrderErrorModal';

export interface SendInvoiceFormDTO {
  customer_emails: Record<string, string>;
  emails: string;
  include_bol_template: 'none' | 'broker' | 'carrier';
  invoice_date: string | null;
  send_to: 'default_billing_emails' | 'custom_email';
  combined_invoice?: boolean;
}

function createInvoice({
  customer_emails,
  emails,
  include_bol_template,
  invoice_date,
  send_to,
  combined_invoice,
}: SendInvoiceFormDTO): InvoiceDTO {
  return {
    attach_bol: include_bol_template !== 'none',
    bol_type: include_bol_template !== 'none' ? include_bol_template : null,
    customer_emails:
      send_to === 'default_billing_emails'
        ? mapValues(customer_emails, (customerEmail) => [customerEmail])
        : null,
    emails:
      send_to === 'custom_email'
        ? emails.split(',').map((str) => str.trim())
        : null,
    invoice_date,
    combined_invoice,
  };
}

interface EmailsTableFormProps {
  orders: Order[];
}

function EmailsTableForm({ orders }: EmailsTableFormProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Box ml={2}>ID</Box>
          </TableCell>
          <TableCell>Customer</TableCell>
          <TableCell>
            <Box mr={2}>Billing Email</Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((order) => (
          <TableRow key={order.guid}>
            <TableCell>
              <Box ml={2} aria-label="order number">
                {order.number}
              </Box>
            </TableCell>

            <TableCell>
              <TextOverflow size={140} aria-label="customer name">
                {order.customer?.name}
              </TextOverflow>
            </TableCell>

            <TableCell align="right">
              <Box mr={2} width="180px">
                <FormikTextField
                  fullWidth={true}
                  placeholder="Enter email"
                  name={`customer_emails.${order.guid}`}
                  validate={composeValidators(required, email)}
                />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

interface SendInvoiceDrawerProps<T> {
  header: ReactNode;
  orders: Order[] | undefined;
  onClose: () => void;
  onSubmit: (values: SendInvoiceFormDTO) => Promise<T>;
  onSubmitSuccess: (response: T, values: SendInvoiceFormDTO) => void;
}

export function SendInvoiceDrawer<T>({
  header,
  onClose,
  orders = [],
  onSubmit,
  onSubmitSuccess,
}: SendInvoiceDrawerProps<T>) {
  const uid = useUID();
  const { addSnackbar } = useSnackbarStack();
  const [error, setError] = useState<unknown>();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const isOpen = orders.length > 0;

  const initialValues = useMemo<SendInvoiceFormDTO>(
    () => ({
      emails: '',
      customer_emails: {},
      include_bol_template: 'broker',
      send_to: 'default_billing_emails',
      invoice_date: stringifyDate(startOfWorkDay(DateTime.local()), {
        format: 'JodaISO',
      }),
    }),
    [],
  );

  const form = useFormikEnhanced<SendInvoiceFormDTO, T>({
    key: isOpen,
    initialValues,
    onSubmit,
    onSubmitSuccess,
    onSubmitFailure(failureError) {
      if ('details' in failureError) {
        setError(failureError);
      } else {
        addSnackbar(
          failureError.message ||
            'Cannot send customer invoices on selected orders',
          { variant: 'error' },
        );
      }
    },
  });

  const { values, dirty, isSubmitting, errors } = form;

  const ordersMissingEmail = orders.filter((order) => !order.customer?.email);
  const hasEnteredMissingEmails = useMemo(
    () => ordersMissingEmail.every((x) => !!values.customer_emails[x.guid]),
    [values.customer_emails, ordersMissingEmail],
  );

  const hasSameCustomerEmail =
    orders.length > 1
      ? orders.every(
          (order) => order.customer?.email === orders[0]?.customer?.email,
        )
      : false;

  function handleCancelChanges() {
    setShowConfirmation(false);
    onClose();
  }

  function handleClose() {
    if (dirty) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  }

  return (
    <>
      <Drawer
        width="560px"
        isOpen={isOpen}
        onClose={handleClose}
        aria-labelledby={uid}
        header={<div id={uid}>{header}</div>}
      >
        <FormikProvider value={form}>
          <Form>
            <Box px={4} py={2}>
              <Box width="150px">
                <FormikDateField name="invoice_date" label="Invoice date" />
              </Box>
            </Box>

            {orders.length > 1 && (
              <Box px={4} py={2}>
                <FormikCheckboxField
                  label="Combine orders into one invoice"
                  name="combined_invoice"
                  onChange={(event) => {
                    if (event.target.checked) {
                      form.setFieldValue('send_to', 'custom_email');

                      if (hasSameCustomerEmail) {
                        form.setFieldValue(
                          'emails',
                          orders[0]?.customer?.email,
                        );
                      }
                    }
                  }}
                />
              </Box>
            )}

            {!values.combined_invoice && (
              <Box px={4} py={2}>
                <FormikRadioGroupField
                  name="send_to"
                  RadioGroupProps={{ row: true }}
                  label={<Typography variant="h5">Send to</Typography>}
                >
                  <RadioField
                    label="Default billing emails"
                    value="default_billing_emails"
                  />
                  <RadioField label="Custom email" value="custom_email" />
                </FormikRadioGroupField>
              </Box>
            )}

            {values.send_to === 'default_billing_emails' &&
              ordersMissingEmail.length > 0 && (
                <Box py={2}>
                  {(!hasEnteredMissingEmails || !!errors.customer_emails) && (
                    <Box px={4} pb={1}>
                      <Typography color="error">
                        At least one billing email was missing. Enter valid
                        email addresses below.
                      </Typography>
                    </Box>
                  )}

                  <EmailsTableForm orders={ordersMissingEmail} />
                </Box>
              )}

            {values.send_to === 'custom_email' && (
              <Box px={4} py={2}>
                <FormikTextField
                  name="emails"
                  fullWidth={true}
                  label="Billing email"
                  placeholder="Enter emails separated by commas"
                  validate={composeValidators(required, emailsValidator)}
                  helperText={
                    values.combined_invoice
                      ? 'Combined invoice will be sent to these emails'
                      : 'All invoices will be sent to these emails'
                  }
                />
              </Box>
            )}

            <Box px={4} py={2}>
              <FormikRadioGroupField
                name="include_bol_template"
                label={
                  <Typography variant="h5">Include BOL template</Typography>
                }
              >
                <RadioField label="None" value="none" />
                <RadioField label="Shipper BOL" value="broker" />
                <RadioField label="Carrier BOL" value="carrier" />
              </FormikRadioGroupField>
            </Box>

            <Box
              display="flex"
              px={4}
              py={2}
              position="sticky"
              bottom={0}
              bgcolor={ColorDynamic.White}
            >
              <Button type="submit" variant="primary" pending={isSubmitting}>
                Send
              </Button>
            </Box>
          </Form>
        </FormikProvider>
      </Drawer>

      <ConfirmDialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        title="Cancel Without Sending?"
        cancelButtonProps={{ children: 'No' }}
        confirmButtonProps={{
          onClick: handleCancelChanges,
          children: 'Yes, Cancel Changes',
        }}
      >
        Changes have not been saved. Leaving will result in unsaved changes
        being lost.
      </ConfirmDialog>

      <OrderErrorModal error={error} onCancel={() => setError(undefined)} />
    </>
  );
}

interface BulkSendInvoiceDrawerProps {
  orders: Order[] | undefined;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function BulkSendInvoiceDrawer({
  onClose,
  orders = [],
  onSubmitSuccess,
}: BulkSendInvoiceDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { sendBulkInvoices } = useBulkOrderActionAPI();
  return (
    <SendInvoiceDrawer
      header={
        <div>
          <Typography variant="h2">Send Customer Invoice</Typography>
          <Typography>{orders.length} orders</Typography>
        </div>
      }
      onClose={onClose}
      orders={orders}
      onSubmit={(values) => {
        const ids = orders.map((x) => x.id);
        return sendBulkInvoices(ids, createInvoice(values));
      }}
      onSubmitSuccess={(_, values) => {
        const invoice = createInvoice(values);

        addSnackbar(
          `${orders.length} ${formatPlural(
            orders.length,
            'order',
            'orders',
          )} invoiced`,
          { variant: 'success' },
        );

        trackEventLegacy('Bulk Send Customer Invoice', {
          count: orders.length,
          send_to: invoice.emails?.[0],
          bol_template: invoice.bol_type == null ? 'none' : invoice.bol_type,
          statuses: countBy(orders, 'status'),
          combined_invoice: invoice.combined_invoice,
        });

        onSubmitSuccess();
      }}
    />
  );
}

interface SingleSendInvoicesDrawerProps {
  order: Order | undefined;
  onClose: () => void;
  onSubmitSuccess: (order: Order) => void;
}

export function SingleSendInvoiceDrawer({
  order,
  onClose,
  onSubmitSuccess,
}: SingleSendInvoicesDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { sendInvoice } = useSingleOrderActionAPI();
  return (
    <SendInvoiceDrawer
      onClose={onClose}
      orders={order ? [order] : undefined}
      header={<Typography variant="h2">Send Customer Invoice</Typography>}
      onSubmit={(values) => {
        if (order) {
          return sendInvoice(order.id, createInvoice(values));
        }

        return Promise.reject(new Error('Order not found'));
      }}
      onSubmitSuccess={(response: Order) => {
        addSnackbar('Sent', { variant: 'success' });
        trackEventLegacy('Sent Customer Invoice');
        onSubmitSuccess(response);
      }}
    />
  );
}
