import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const Circle2Icon = createSvgIcon(
  'Circle2Icon',
  <>
    <rect width="24" height="24" rx="12" fill={ColorDynamic.Blue50} />
    <path
      d="M8.40483 17V15.6676L11.9396 12.2024C12.2777 11.861 12.5594 11.5578 12.7848 11.2926C13.0102 11.0275 13.1792 10.7706 13.2919 10.522C13.4046 10.2734 13.4609 10.0083 13.4609 9.72656C13.4609 9.40507 13.388 9.12997 13.2422 8.90128C13.0964 8.66927 12.8958 8.49029 12.6406 8.36435C12.3854 8.2384 12.0954 8.17543 11.7706 8.17543C11.4358 8.17543 11.1425 8.24503 10.8906 8.38423C10.6387 8.52012 10.4432 8.71401 10.304 8.96591C10.1681 9.2178 10.1001 9.51776 10.1001 9.86577H8.34517C8.34517 9.21946 8.49266 8.65767 8.78764 8.1804C9.08262 7.70312 9.48864 7.33357 10.0057 7.07173C10.526 6.8099 11.1226 6.67898 11.7955 6.67898C12.4782 6.67898 13.0781 6.80658 13.5952 7.06179C14.1122 7.317 14.5133 7.66667 14.7983 8.1108C15.0866 8.55492 15.2308 9.06203 15.2308 9.6321C15.2308 10.0133 15.1579 10.3878 15.0121 10.7557C14.8662 11.1236 14.6094 11.5312 14.2415 11.9787C13.8769 12.4261 13.3648 12.968 12.7053 13.6044L10.9503 15.3892V15.4588H15.3849V17H8.40483Z"
      fill={ColorDynamic.Blue300}
    />
  </>,
);
