import type {
  Account,
  BusinessProfile,
  BusinessType,
  Moov,
  NewRepresentative,
  Representative,
} from '@moovio/moov-js';
import { parseDate } from '@superdispatch/dates';
import { useMemo } from 'react';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  DEFAULT_ERROR_MESSAGE,
  getError,
  NOT_AVAILABLE_ERROR_MESSAGE,
} from '../helpers/MoovError';
import { logPaymentError } from '../helpers/PaymentLogger';
import {
  ExtendedNewAccount,
  MoovAccount,
  MoovRepresentative,
  MoovScope,
  MoovTokenResponse,
  NewBankAccount,
  NewShipperMoovAccount,
  SearchRoutingNumberResponse,
} from './MoovDTO';
import {
  generateMoovTokenAndGetAccount,
  useSuperPayTermsConditionsToken,
  useSyncSuperPayOnboardingStep,
  useUpdateSuperPayTermsConditionsAccept,
} from './SuperPayAPI';

async function getMoov(accessToken: string) {
  try {
    const { loadMoov } = await import('@moovio/moov-js');
    return await loadMoov(accessToken);
  } catch {
    throw new Error(NOT_AVAILABLE_ERROR_MESSAGE);
  }
}

async function actionWrapper<T>(
  scope: MoovScope,
  errorSource: string,
  action: (moov: Moov, moovInformation: MoovTokenResponse) => Promise<T>,
) {
  let moovInformation: MoovTokenResponse | null = null;

  try {
    moovInformation = await generateMoovTokenAndGetAccount(scope);
    const moov = await getMoov(moovInformation.access_token);

    if (!moov) {
      throw new Error(DEFAULT_ERROR_MESSAGE);
    }

    return await action(moov, moovInformation);
  } catch (error: unknown) {
    const preparedError = getError(error);

    logPaymentError(preparedError as Error, `MoovAPI.${errorSource}`, {
      moovAccountId: moovInformation?.moov_account_id,
    });

    return Promise.reject(preparedError);
  }
}

export function useCreateMoovAccount() {
  const { mutateAsync: syncSuperPay } = useSyncSuperPayOnboardingStep();

  return useAPIMutation((newAccountData: NewShipperMoovAccount) => {
    const scope = 'account';

    return actionWrapper(scope, 'createMoovAccount', async (moov) => {
      const termsOfServiceToken = await moov.accounts.getTermsOfServiceToken();

      const newAccount: ExtendedNewAccount = {
        accountType: 'business',
        termsOfService: { token: termsOfServiceToken.token },
        profile: {
          business: {
            businessType: newAccountData.businessType as BusinessType,
            description: newAccountData.description,
            legalBusinessName: newAccountData.legalBusinessName,
            taxID: { ein: { number: newAccountData.einNumber } },
            industryDetails: {
              mcc: newAccountData.mccNumber,
            },
            address: {
              country: newAccountData.country,
              addressLine1: newAccountData.streetAddress,
              city: newAccountData.city,
              postalCode: newAccountData.zip,
              stateOrProvince: newAccountData.state,
            },
            phone: {
              countryCode: newAccountData.phoneCountryCode,
              number: newAccountData.phoneNumber,
            },
            website: newAccountData.website,
          },
        },
        metadata: {
          guid: newAccountData.shipperGuid,
          type: 'shipper',
        },
        foreignID: newAccountData.shipperGuid,
        capabilities: ['transfers', 'send-funds'],
      };
      const account = await moov.accounts.create(newAccount);
      return syncSuperPay({
        scope,
        moov_bank_account_id: '',
        moov_account_id: account.accountID,
      }).finally(() => account);
    });
  });
}

export function useCreateBankAccount() {
  const { mutateAsync: syncSuperPay } = useSyncSuperPayOnboardingStep();

  return useAPIMutation((newBankAccountData: NewBankAccount) => {
    const scope = 'bank_account';

    return actionWrapper(
      scope,
      'createShipperBankAccount',
      async (moov, moovInformation) => {
        const bankAccount = await moov.accounts.bankAccounts.link({
          accountID: moovInformation.moov_account_id || '',
          bankAccount: {
            routingNumber: newBankAccountData.routingNumber,
            accountNumber: newBankAccountData.accountNumber,
            holderName: newBankAccountData.holderName,
            holderType: 'business',
            bankAccountType: 'checking',
          },
        });
        return syncSuperPay({
          scope,
          moov_bank_account_id: bankAccount.bankAccountID,
          moov_account_id: moovInformation.moov_account_id,
        }).finally(() => bankAccount);
      },
    );
  });
}

export function useCompleteMicroDepositVerification() {
  const { mutateAsync: syncSuperPay } = useSyncSuperPayOnboardingStep();

  return useAPIMutation((amounts: number[]) => {
    const scope = 'micro_deposit';

    return actionWrapper(
      scope,
      'completeMicroDeposit',
      async (moov, moovInformation) => {
        const bankAccountID = moovInformation.moov_bank_account_id || '';
        const accountID = moovInformation.moov_account_id || '';

        const result =
          await moov.accounts.bankAccounts.completeMicroDepositVerification({
            accountID,
            bankAccountID,
            amounts,
          });

        return syncSuperPay({
          scope,
          moov_account_id: accountID,
          moov_bank_account_id: bankAccountID,
        }).finally(() => result);
      },
    );
  });
}

export function useBankNameByRoutingNumber() {
  return useAPIMutation((routingNumber: string) => {
    return actionWrapper('account', 'bankNameByRoutingNumber', (moov) => {
      return moov.institutions
        .lookupByRoutingNumber(routingNumber)
        .then((response: SearchRoutingNumberResponse) => {
          return response.result?.[0]?.customerName;
        });
    });
  });
}

export function useUpdateOwnersProvidedInfo(options?: APIMutationOptions) {
  const { mutateAsync: syncSuperPay } = useSyncSuperPayOnboardingStep();

  return useAPIMutation(() => {
    return actionWrapper(
      'account',
      'updateOwnersProvidedInfo',
      async (moov, moovInformation) => {
        const params = {
          accountID: moovInformation.moov_account_id || '',
          profile: {
            business: {
              ownersProvided: true,
            } as BusinessProfile,
          },
        };

        const result = (await moov.accounts.patch(
          params,
        )) as unknown as Account;

        return syncSuperPay({
          scope: 'representative',
          moov_bank_account_id: moovInformation.moov_bank_account_id || '',
          moov_account_id: moovInformation.moov_account_id || '',
        }).finally(() => result);
      },
    );
  }, options);
}

export function useAddRepresentative() {
  const { mutateAsync: syncSuperPay } = useSyncSuperPayOnboardingStep();

  return useAPIMutation((data: NewRepresentative) => {
    const scope = 'representative';

    return actionWrapper(
      scope,
      'addRepresentative',
      async (moov, moovInformation) => {
        const representative = await moov.accounts.representatives.create({
          accountID: moovInformation.moov_account_id || '',
          representative: data,
        });
        return syncSuperPay({
          scope,
          moov_bank_account_id: moovInformation.moov_bank_account_id || '',
          moov_account_id: moovInformation.moov_account_id || '',
        }).finally(() => representative);
      },
    );
  });
}

export function useDeleteRepresentative() {
  return useAPIMutation((representativeID: string) => {
    return actionWrapper(
      'representative',
      'deleteRepresentative',
      (moov, moovInformation) => {
        return moov.accounts.representatives.delete({
          accountID: moovInformation.moov_account_id || '',
          representativeID,
        });
      },
    );
  });
}

export function useEditRepresentative() {
  return useAPIMutation(
    async ({
      representativeID,
      data,
    }: {
      representativeID: string;
      data: Partial<Representative>;
    }) => {
      return actionWrapper(
        'representative',
        'editRepresentative',
        async (_, moovInformation) => {
          const response = await fetch(
            `https://api.moov.io/accounts/${
              moovInformation.moov_account_id || ''
            }/representatives/${representativeID}`,
            {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${moovInformation.access_token}`,
              },
              body: JSON.stringify(data),
            },
          );
          return response.json();
        },
      );
    },
  );
}

export function useMoovAcceptAPI() {
  const { mutateAsync: termsConditionsToken } =
    useSuperPayTermsConditionsToken();
  const { mutateAsync: termsConditionsAccept } =
    useUpdateSuperPayTermsConditionsAccept();
  const { mutateAsync: createMoovAccount } = useCreateMoovAccount();

  return useMemo(
    () => ({
      createAcceptMoovAccount: async (moovAccount: NewShipperMoovAccount) => {
        const termsConditionsUserToken = await termsConditionsToken();
        if (!termsConditionsUserToken.acceptance_token) {
          throw new Error('Please try again or reload the page.');
        }

        await termsConditionsAccept(termsConditionsUserToken.acceptance_token);
        return createMoovAccount(moovAccount);
      },
    }),
    [createMoovAccount, termsConditionsAccept, termsConditionsToken],
  );
}

export function useMoovAccount(enabled: boolean) {
  return useAPIQuery(
    ['super-pay', 'moov-account'],
    () => {
      return actionWrapper(
        'account',
        'getMoovAccount',
        async (moov, moovInformation) => {
          const account: MoovAccount = await moov.accounts.get({
            accountID: moovInformation.moov_account_id || '',
          });

          return account;
        },
      );
    },
    { enabled, refetchOnWindowFocus: false },
  );
}

export function useRepresentativesList() {
  return useAPIQuery(
    ['super-pay', 'representatives'],
    () => {
      return actionWrapper(
        'representative',
        'getRepresentativesList',
        async (moov, moovInformation) => {
          const response = (await moov.accounts.representatives.list({
            accountID: moovInformation.moov_account_id || '',
          })) as unknown as MoovRepresentative[];

          return response.sort((a, b) =>
            parseDate(a.createdOn, { format: 'DateTimeISO' }) >
            parseDate(b.createdOn, { format: 'DateTimeISO' })
              ? 1
              : -1,
          );
        },
      );
    },
    { refetchOnWindowFocus: false },
  );
}
