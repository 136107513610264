import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const InfoIconRounded = createSvgIcon(
  'InfoIconRounded',
  <>
    <circle cx="8" cy="8" r="8" fill={ColorDynamic.Blue50} />
    <path
      d="M8 3C5.24 3 3 5.24 3 8C3 10.76 5.24 13 8 13C10.76 13 13 10.76 13 8C13 5.24 10.76 3 8 3ZM8.5 10.5H7.5V7.5H8.5V10.5ZM8.5 6.5H7.5V5.5H8.5V6.5Z"
      fill={ColorDynamic.Blue300}
    />
  </>,
  {
    viewBox: '0 0 16 16',
    'aria-label': 'info icon',
  },
);
