import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { useMemo } from 'react';
import { useSuperPayEdit } from 'shared/helpers/superpay/SuperPayAPI';
import Order from 'shared/types/order';
import { OrderFormValues } from '../../orders/core/form/OrderForm';
import { vehiclePrices } from './superPayEditDrawerVehicle';

interface SuperPayEditDrawerFormProps {
  order: OrderFormValues | Order;
  onClose: () => void;
  onSuccess: () => void;
}

export function useSuperPayEditDrawerForm({
  order,
  onClose,
  onSuccess,
}: SuperPayEditDrawerFormProps) {
  const { addSnackbar } = useSnackbarStack();

  const { vehicles, payment, id, price: orderPrice } = order;

  const { mutateAsync: superPayEdit } = useSuperPayEdit(id);
  const expeditedPayFeeAmount =
    payment?.super_pay?.expedited_pay_fee_amount || 0;

  const initialValues = useMemo(() => {
    const editableVehicles = vehicles?.length
      ? vehicles.map((vehicle) => ({
          price: vehicle.price || 0,
          guid: vehicle.guid || '',
          is_active: !!vehicle.is_active,
          title: `${vehicle.year || ''} ${vehicle.make || ''} ${
            vehicle.model || ''
          }`,
        }))
      : [];

    return {
      editableVehicles,
    };
  }, [vehicles]);

  return useFormikEnhanced({
    initialValues,
    onSubmit: ({ editableVehicles }) => {
      const { totalCarrierPrice } = vehiclePrices({
        editableVehicles,
        expeditedPayFeeAmount,
        initialEditableVehicles: initialValues.editableVehicles,
        orderPrice,
      });

      const params = { price: totalCarrierPrice, vehicles: editableVehicles };
      return superPayEdit(params);
    },
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
    onSubmitSuccess: () => {
      onSuccess();
      onClose();
      addSnackbar('SuperPay updated', { variant: 'success' });
    },
  });
}
