import { ColorDynamic, useUID } from '@superdispatch/ui';
import { List } from 'shared/ui/List';

interface ErrorMessage {
  details: Record<string, string>;
  message: string;
}

export interface Props {
  label: string;
  value: string | ErrorMessage;
}

export const getErrorsMessage = (error: string | ErrorMessage) => {
  if (typeof error === 'string') {
    return error;
  }

  const details = error.details ? Object.keys(error.details) : [];

  if (details.length > 0) {
    return (
      <List color={ColorDynamic.Dark500}>
        {details.map((errorDetail) => (
          <li key={`${errorDetail}`}>{error.details[errorDetail]}</li>
        ))}
      </List>
    );
  }

  return error.message;
};

export const ErrorItem = ({ label, value }: Props) => {
  const uid = useUID();
  return (
    <div aria-labelledby={uid}>
      <p>
        <strong id={uid}>{label}</strong>
      </p>
      <p>{getErrorsMessage(value)}</p>
    </div>
  );
};
