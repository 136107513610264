import { Type } from 'class-transformer';
import { CARRIER_APP_HOST } from 'shared/config/ServerConstants';
import { Attachment } from 'shared/types/attachment';
import { Offer } from 'shared/types/offer';
import Order, {
  CustomerInvoice,
  DeliveryVerificationStatus,
  InspectionType,
  TransportType,
} from 'shared/types/order';
import { CarrierPaymentDTO } from './CarrierPaymentDTO';
import { CustomerPaymentDTO } from './CustomerPaymentDTO';
import { DeliveryVerificationFailureReasonType } from './DeliveryVerificationDTO';
import { OrderCustomerDTO } from './OrderCustomerDTO';
import { OrderStepDTO } from './OrderStepDTO';
import { VehicleDTO } from './VehicleDTO';

export class OrderDTO implements Order {
  static getLoadboardLoadURL(guid: string): string {
    return `${CARRIER_APP_HOST}/loadboard/loads?current_load_guid=${guid}`;
  }

  static getCarrierOrderURL(guid: string): string {
    return `${CARRIER_APP_HOST}/dashboard/orders/${guid}/view/`;
  }
  static getDeliveryVerificationFailureReasonText(
    failureReason: DeliveryVerificationFailureReasonType,
  ): string {
    switch (failureReason) {
      case 'MANUALLY_PUT_PAYMENT_ON_HOLD':
        return 'Shipper put Payment on Hold';
      case 'VEHICLE_MARKED_WITH_DAMAGES':
        return 'Vehicle marked with damages';
      case 'VEHICLE_IS_NOT_PICKED_UP':
        return 'Vehicle marked as Not Picked Up';
      case 'PICKUP_MARKED_MANUALLY_BY_CARRIER':
        return 'Carrier manually marked as Picked Up';
      case 'DELIVERY_MARKED_MANUALLY_BY_CARRIER':
        return 'Carrier manually marked as Delivered';
      case 'PICKUP_CUSTOMER_SIGNATURE_LOCATION_NOT_EXISTS':
        return 'Pickup signature location not received';
      case 'PICKUP_CUSTOMER_SIGNATURE_IS_NOT_CLOSE':
        return 'Pickup signature received outside of listed location';
      case 'PICKUP_CUSTOMER_SIGNATURE_NOT_EXISTS':
        return 'Pickup signature not received';
      case 'PICKUP_ACTUAL_LOCATION_NOT_EXISTS':
        return 'Pickup location was not received';
      case 'PICKUP_ACTUAL_LOCATION_IS_NOT_CLOSE':
        return 'Pickup marked outside of listed location';
      case 'PICKUP_INSPECTION_PHOTOS_LOCATION_NOT_EXISTS':
        return 'Pickup inspection location not received';
      case 'PICKUP_INSPECTION_PHOTOS_LOCATION_IS_NOT_CLOSE':
        return 'Pickup photos taken outside of listed location';
      case 'PICKUP_VIN_SCANNED_LOCATION_NOT_EXISTS':
        return 'VIN not scanned at Pickup Inspection';
      case 'PICKUP_VIN_SCANNED_LOCATION_IS_NOT_CLOSE':
        return 'VIN scanned outside of Pickup listed location';
      case 'DELIVERY_CUSTOMER_SIGNATURE_LOCATION_NOT_EXISTS':
        return 'Delivery signature location not received';
      case 'DELIVERY_CUSTOMER_SIGNATURE_IS_NOT_CLOSE':
        return 'Delivery signature received outside of listed location';
      case 'DELIVERY_CUSTOMER_SIGNATURE_NOT_EXISTS':
        return 'Delivery signature not received';
      case 'DELIVERY_ACTUAL_LOCATION_NOT_EXISTS':
        return 'Delivery location was not received';
      case 'DELIVERY_ACTUAL_LOCATION_IS_NOT_CLOSE':
        return 'Delivery marked outside of listed location';
      case 'DELIVERY_INSPECTION_PHOTOS_LOCATION_NOT_EXISTS':
        return 'Delivery inspection location not received';
      case 'DELIVERY_INSPECTION_PHOTOS_LOCATION_IS_NOT_CLOSE':
        return 'Delivery photos taken outside of listed location';
      case 'DELIVERY_VIN_SCANNED_LOCATION_NOT_EXISTS':
        return 'VIN not scanned at Delivery Inspection';
      case 'DELIVERY_VIN_SCANNED_LOCATION_IS_NOT_CLOSE':
        return 'VIN scanned outside of Delivery listed location';
    }
  }

  id: number;
  guid: string;
  number: string;
  carrier_guid: string | null;

  active_offer?: Offer;
  transport_type: TransportType;
  inspection_type: InspectionType;
  distance_meters?: number;
  attachments?: Attachment[];
  broker_fee?: number;
  price?: number;
  price_per_km?: number;
  tariff?: number;
  recommended_price?: number;
  customer_invoice: CustomerInvoice | null;
  invoice: Order['invoice'];
  status: Order['status'];
  customer_invoices: [CustomerInvoice];
  tags: number[];

  escalation?: {
    guid: string | null;
    is_allowed_to_submit: boolean;
    is_resolved: boolean;

    reasons?: {
      CHARGES_DUE?: string;
      VEHICLE_IS_NOT_AT_LOCATION?: string;
      INCORRECT_PHONE_NUMBER?: string;
      LOCATION_WILL_NOT_RELEASE?: string;
      LOCATION_CHANGED?: string;
      NO_RETURNED_CALLS_OR_EMAILS?: string;
      OTHER?: string;
    };

    receiver_guid: string | null;
    receiver_name: string | null;
    receiver_order_id: string | null;
    resolve_reasons?: {
      CHARGES_DUE?: string;
      VEHICLE_IS_NOT_AT_LOCATION?: string;
      INCORRECT_PHONE_NUMBER?: string;
      LOCATION_WILL_NOT_RELEASE?: string;
      LOCATION_CHANGED?: string;
      NO_RETURNED_CALLS_OR_EMAILS?: string;
      OTHER?: string;
    };
    resolved_by_user: string | null;
    resolved_by_user_name: string | null;
    sender_guid: string | null;
    sender_name: string | null;
    sender_order_id: string | null;
    sender_type: string | null;
    submitted_by_user: string | null;
    submitted_by_user_name: string | null;
  };

  is_archived: boolean;
  is_flagged: boolean;
  is_on_hold: boolean;
  is_posted_to_loadboard: boolean;
  is_posted_to_private_loadboard: boolean;
  private_network_groups?: string[];
  posted_to_loadboard_at: string | null;
  is_status_changed_manually: boolean;
  is_posted_to_centraldispatch: boolean;
  is_instantly_dispatched: boolean;
  is_load: boolean;
  is_demo: boolean;
  has_loads: boolean;
  has_legs?: boolean;
  can_be_instantly_booked: boolean;

  online_bol_url?: string;
  pdf_bol_url?: string;
  pdf_bol_url_with_template?: string;
  pdf_delivery_receipt_url?: string;
  dispatch_sheet_url?: string;

  is_brokers_order?: boolean;

  changed_at: Order['changed_at'];
  created_at: Order['created_at'];
  @Type(() => VehicleDTO)
  vehicles: VehicleDTO[];
  @Type(() => OrderCustomerDTO)
  customer: OrderCustomerDTO;
  instructions: string;
  loadboard_instructions: string | null;
  @Type(() => OrderStepDTO)
  pickup: OrderStepDTO;
  @Type(() => OrderStepDTO)
  delivery: OrderStepDTO;
  @Type(() => CarrierPaymentDTO)
  payment: CarrierPaymentDTO;
  @Type(() => CustomerPaymentDTO)
  customer_payment: CustomerPaymentDTO;
  dispatcher_name: string;
  dispatcher_user_id: number | null;
  sales_representative: string;
  load_request_count: number;
  delivery_verification_status: DeliveryVerificationStatus | null;
}
