import { ColorDynamic } from '@superdispatch/ui';

export function FlashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.66668 12.3333L9.90668 5.84659H7.66668V1.66659L4.33334 8.15325H6.66668V12.3333ZM7.00001 0.333252C8.83334 0.333252 10.4 0.999918 11.7 2.29992C13 3.59992 13.6667 5.16659 13.6667 6.99992C13.6667 8.83325 13 10.3999 11.7 11.6999C10.4 12.9999 8.83334 13.6666 7.00001 13.6666C5.16668 13.6666 3.60001 12.9999 2.30001 11.6999C1.00001 10.3999 0.333344 8.83325 0.333344 6.99992C0.333344 5.16659 1.00001 3.59992 2.30001 2.29992C3.60001 0.999918 5.16668 0.333252 7.00001 0.333252Z"
        fill={ColorDynamic.Teal500}
      />
    </svg>
  );
}
