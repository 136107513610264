import { SvgIconProps } from '@material-ui/core';
import { Payment } from '@material-ui/icons';
import { PaymentAchIcon } from 'shared/icons/PaymentAchIcon';
import { PaymentCashAppIcon } from 'shared/icons/PaymentCashAppIcon';
import { PaymentCashIcon } from 'shared/icons/PaymentCashIcon';
import { PaymentCheckIcon } from 'shared/icons/PaymentCheckIcon';
import { PaymentUShipIcon } from 'shared/icons/PaymentUShipIcon';
import { PaymentVenmoIcon } from 'shared/icons/PaymentVenmoIcon';
import { PaymentZelleIcon } from 'shared/icons/PaymentZelleIcon';

export interface PaymentMethodIconProps extends SvgIconProps {
  paymentMethod?: string | null;
}

export function PaymentMethodIcon({
  paymentMethod,
  ...props
}: PaymentMethodIconProps) {
  switch (paymentMethod) {
    case 'zelle':
      return <PaymentZelleIcon {...props} />;
    case 'cashapp':
      return <PaymentCashAppIcon {...props} />;
    case 'uship':
      return <PaymentUShipIcon {...props} />;
    case 'venmo':
      return <PaymentVenmoIcon {...props} />;
    case 'check':
    case 'comchek':
    case 'cashiers_check':
    case 'money_order':
      return <PaymentCheckIcon {...props} />;
    case 'ach':
    case 'direct_deposit':
    case 'other':
      return <PaymentAchIcon {...props} />;
    case 'cash':
      return <PaymentCashIcon {...props} />;
    default:
      return <Payment {...props} />;
  }
}
