import {
  Box,
  Paper,
  Popover,
  PopoverProps,
  Typography,
} from '@material-ui/core';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { Button, ButtonProps } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';

export interface ConfirmPopoverProps
  extends Omit<PopoverProps, 'title' | 'onClose' | 'children'> {
  onClose?: () => void;

  title?: ReactNode;
  children?: ReactNode;
  cancelButtonProps?: Partial<ButtonProps>;
  confirmButtonProps?: Partial<ButtonProps>;
}

export function ConfirmPopover({
  onClose,
  title,
  children,
  cancelButtonProps,
  confirmButtonProps,
  PaperProps,
  ...props
}: ConfirmPopoverProps) {
  const uid = useUID();
  return (
    <Popover
      {...props}
      onClose={onClose}
      PaperProps={{ ...PaperProps, 'aria-labelledby': uid }}
    >
      <Paper>
        <Box padding={2}>
          <Stack>
            {title && (
              <Typography id={uid} variant="body2">
                {title}
              </Typography>
            )}

            {children}

            <Inline space="xsmall">
              <Button
                variant="neutral"
                onClick={onClose}
                {...cancelButtonProps}
              >
                {cancelButtonProps?.children || 'Cancel'}
              </Button>

              <Button
                autoFocus={true}
                variant="primary"
                {...confirmButtonProps}
              >
                {confirmButtonProps?.children || 'Confirm'}
              </Button>
            </Inline>
          </Stack>
        </Box>
      </Paper>
    </Popover>
  );
}
