import {
  yupBoolean,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type OrderPricePredictionDTO = InferType<
  typeof orderPricePredictionSchema
>;

export const orderPricePredictionSchema = yupObject({
  estimated_price: yupNumber(),
  is_inoperable: yupBoolean(),
  vehicle_type_name: yupString(),
});
