import {
  createHTTP,
  HTTPError,
  HTTPRequestJSONOptions,
  HTTPRequestOptions,
} from '@superdispatch/http';
import { URITemplateParams } from '@superdispatch/uri';
import { has, isObject } from 'lodash';
import {
  emitLogout,
  readAppToken,
  readShipperIdForAdmin,
  setAppToken,
} from 'shared/data/AppUserState';
import {
  emitPaywallError,
  isPaywallError,
} from 'shared/errors/paywall/PaywallEvents';
import { getCookie } from 'shared/helpers/CookieHelpers';
import { logInfo } from 'shared/helpers/MonitoringService';
import { ensureError } from 'shared/utils/ErrorUtils';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { v4 as uuidv4 } from 'uuid';
import { SHIPPER_API_HOST } from '../config/ServerConstants';
import { createAPIError } from '../errors/APIError';
import { maybeEmitCentralDispatchMfaError } from './centralDispatchMfaError';

interface BaseAPIResponse {
  status: string;
}

export type APIErrorValidationDetails = Record<string, string>;

export interface APIErrorCTMSDetailsInfo {
  message: string;
  error_id: string;
  details: APIErrorCTMSDetails;
}
export type APIErrorCTMSDetails = string | APIErrorCTMSDetailsInfo;

export type APIErrorImportDetails = Record<string, Record<string, string>>;

export type APIErrorDetails =
  | string
  | APIErrorCTMSDetails
  | APIErrorImportDetails
  | APIErrorValidationDetails;

export interface APIErrorResponse<T = APIErrorDetails> extends BaseAPIResponse {
  data: {
    message?: string;
    error_id: string;
    details?: T;
    error?: string;
  };
}

export interface APIResponse<T = unknown> extends BaseAPIResponse {
  data: {
    object: T;
  };
}

export interface APIPageResponse<T = unknown> extends BaseAPIResponse {
  data: {
    objects: T[];
    pagination: {
      limit: number;
      page: number;
      total_objects: number;
      total_pages: number;
    };
  };
}

function isAPIErrorResponse(error: unknown): error is APIErrorResponse {
  return isObject(error) && has(error, 'data');
}

export interface API {
  /** Temporary function for a feature toggle functionality */
  request: <TParams extends URITemplateParams = URITemplateParams>(
    endpoint: string,
    options?: TParams & HTTPRequestOptions,
  ) => Promise<Response>;

  requestBlob: <TParams extends URITemplateParams = URITemplateParams>(
    endpoint: string,
    options?: TParams & HTTPRequestOptions,
  ) => Promise<Blob>;

  requestJSON: <TData, TParams extends URITemplateParams = URITemplateParams>(
    endpoint: string,
    options?: TParams & HTTPRequestJSONOptions<TData>,
  ) => Promise<TData>;

  requestResource: <
    TData,
    TParams extends URITemplateParams = URITemplateParams,
  >(
    endpoint: string,
    normalize: (data: unknown) => TData,
    options?: TParams & HTTPRequestJSONOptions<TData>,
  ) => Promise<TData>;

  requestPage: <TData, TParams extends URITemplateParams = URITemplateParams>(
    endpoint: string,
    normalize: (data: unknown) => TData,
    options?: TParams & HTTPRequestJSONOptions<TData>,
  ) => Promise<APIPageResponse<TData>['data']>;
}

const refreshTokenResponseSchema = yupObject({
  data: yupObject({
    object: yupObject({
      access_token: yupString().required(),
    }),
  }),
});

let tokenRefreshEnabled = false;
let isTokenRefreshLogsEnabled = false;

export function enableTokenRefresh(): void {
  tokenRefreshEnabled = true;
}

export function disableTokenRefresh(): void {
  tokenRefreshEnabled = false;
}

export function getIsTokenRefreshEnabled(): boolean {
  return tokenRefreshEnabled;
}

export function enableTokenRefreshLogs(): void {
  isTokenRefreshLogsEnabled = true;
}

export function createAPI(): API {
  let requestsWaitingToBeRetriedAfterTokenRefresh: string[] = [];

  const { request, requestJSON } = createHTTP({
    fetch(input: RequestInfo, init: RequestInit = {}) {
      const token = readAppToken();

      const shipperIdForAdmin = readShipperIdForAdmin();
      const url = typeof input === 'string' ? input : input.url;

      const isAuthRequest = url.includes('/auth');
      const isPublicFeatureToggleRequest = url.includes('/features');

      return fetch(input, {
        ...init,
        ...(isAuthRequest && { credentials: 'include' }),
        headers: {
          ...(init.headers ?? {}),
          ...(token &&
            !isPublicFeatureToggleRequest && {
              authorization: `Bearer ${token}`,
            }),
          ...(shipperIdForAdmin && { 'X-shipper-ID': shipperIdForAdmin }),
        },
      });
    },
    baseURL: SHIPPER_API_HOST,
  });

  let refreshTokenPromise: Promise<void> | null = null;

  async function refreshToken() {
    let refreshTokenRequestId: string | undefined;

    if (isTokenRefreshLogsEnabled) {
      refreshTokenRequestId = uuidv4();

      logInfo('Token refresh request', {
        refreshToken: getCookie('refresh_token') ?? null,
        refreshTokenRequestId,
      });
    }

    try {
      const response = await fetch(`${SHIPPER_API_HOST}/auth/refresh-token`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.status > 200) {
        emitLogout();

        if (isTokenRefreshLogsEnabled) {
          const json: unknown = await response.json();

          logInfo('Token refresh request failed, logging out', {
            refreshTokenRequestId,
            requestsWaitingToBeRetriedAfterTokenRefresh,
            response: {
              status: response.status,
              data: json,
            },
          });
        }
      } else {
        const json: unknown = await response.json();
        setAppToken(
          refreshTokenResponseSchema.cast(json).data.object.access_token,
        );
      }
    } catch (error: unknown) {
      emitLogout();

      if (isTokenRefreshLogsEnabled) {
        logInfo('Token refresh failed', {
          refreshTokenRequestId,
          requestsWaitingToBeRetriedAfterTokenRefresh,
          error,
        });
      }
    } finally {
      refreshTokenPromise = null;
      requestsWaitingToBeRetriedAfterTokenRefresh = [];
    }
  }

  function handleRefreshToken() {
    if (refreshTokenPromise !== null) {
      return refreshTokenPromise;
    }

    refreshTokenPromise = refreshToken();
    return refreshTokenPromise;
  }

  function createResponseErrorHandler<T>(originalRequest: () => Promise<T>) {
    return (error: unknown) => {
      if (error instanceof HTTPError) {
        const is401 = error.response.status === 401;
        const is429 = error.response.status === 429;
        const isLoginEndpoint = error.endpoint.url.includes('/auth/login');

        if (is401 && getIsTokenRefreshEnabled() && !isLoginEndpoint) {
          if (isTokenRefreshLogsEnabled) {
            requestsWaitingToBeRetriedAfterTokenRefresh.push(
              error.endpoint.url,
            );
          }

          return handleRefreshToken().then(originalRequest);
        }

        if (is401 && !getIsTokenRefreshEnabled()) {
          emitLogout();
        }

        if (is429) {
          return Promise.reject(
            createAPIError({
              message:
                'This happens when you exceed the number of allowed requests to our API for a certain time period. If the error continues, please contact your support team.',
            }),
          );
        }

        if (isPaywallError(error)) {
          emitPaywallError(error);
        }

        return error.response.json().then((jsonError) => {
          if (isAPIErrorResponse(jsonError)) {
            const { data } = jsonError;

            if (data.error_id === 'SHIPPER_INACTIVE_ERROR') {
              emitLogout();
              return Promise.reject();
            }

            maybeEmitCentralDispatchMfaError(jsonError);

            return Promise.reject(
              createAPIError({
                ...data,
                responseError: error.response,
                message: data.message || data.error_id,
              }),
            );
          }

          return Promise.reject(createAPIError({ message: 'Unknown Error' }));
        });
      }

      return Promise.reject(ensureError(error));
    };
  }

  return {
    request: (endpoint, options) => {
      const originalRequest = () => request(endpoint, options);
      return originalRequest().catch(
        createResponseErrorHandler(originalRequest),
      );
    },

    requestBlob: (endpoint, options) => {
      const originalRequest = () =>
        request(endpoint, options).then((response) => response.blob());

      return originalRequest().catch(
        createResponseErrorHandler(originalRequest),
      );
    },

    requestJSON: <TData, TParams extends URITemplateParams = URITemplateParams>(
      endpoint: string,
      options?: TParams & HTTPRequestJSONOptions<TData>,
    ) => {
      const originalRequest = () =>
        requestJSON<TData, TParams>(endpoint, options);

      return originalRequest().catch(
        createResponseErrorHandler(originalRequest),
      );
    },

    requestResource: <
      TData,
      TParams extends URITemplateParams = URITemplateParams,
    >(
      endpoint: string,
      normalize: (data: unknown) => TData,
      options?: TParams & HTTPRequestJSONOptions<TData>,
    ) => {
      const originalRequest = () =>
        requestJSON<APIResponse<TData>>(endpoint, options).then(({ data }) =>
          normalize(data.object),
        );
      return originalRequest().catch(
        createResponseErrorHandler(originalRequest),
      );
    },

    requestPage: <TData, TParams extends URITemplateParams = URITemplateParams>(
      endpoint: string,
      normalize: (data: unknown) => TData,
      options?: TParams & HTTPRequestJSONOptions<TData>,
    ) => {
      const originalRequest = () =>
        requestJSON<APIPageResponse<TData>>(endpoint, options).then(
          ({ data }) => ({
            ...data,
            objects: data.objects.map(normalize),
          }),
        );

      return originalRequest().catch(
        createResponseErrorHandler(originalRequest),
      );
    },
  };
}

export const apiInstance = createAPI();

/**
 * @deprecated - use `apiInstance` directly instead of hook
 * e.g. `import { apiInstance } from 'shared/api/API'`
 *
 **/
export function useAPI(): API {
  return apiInstance;
}
