import {
  yupArray,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import * as yup from 'yup';
import { CarrierType } from './ManageCarriersAPI';

export const carriersPageParamsSchema = yupObject({
  carrier_type: yup.mixed<CarrierType>().optional(),
  group_guid: yupString().optional(),
  page: yupNumber().optional(),
  page_size: yupNumber().optional(),
  sort: yupArray(yupString()).default([]),
});

export type CarriersPageParamsDTO = yup.InferType<
  typeof carriersPageParamsSchema
>;
