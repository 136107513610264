import { CentralDispatchCredentialsDTO } from 'core/cental-dispatch/CentralDispatchCredentialsDTO';
import { ComponentType } from 'react';
import { FormikDrawer } from 'shared/form/FormikDrawer';
import { CentralDispatchIntegrationDrawerContent } from './CentralDispatchIntegrationDrawerContent';

interface CentralIntegrationEditProps {
  open: boolean;
  credentials: CentralDispatchCredentialsDTO;
  onSubmitSuccess: () => void;
  onClose: () => void;
  disableNavigationPrompt?: boolean;
}

export const CentralDispatchIntegrationDrawer: ComponentType<CentralIntegrationEditProps> =
  ({
    open,
    credentials,
    onSubmitSuccess,
    onClose,
    disableNavigationPrompt,
  }) => {
    return (
      <FormikDrawer
        open={open}
        onClose={onClose}
        disableNavigationPrompt={disableNavigationPrompt}
      >
        <CentralDispatchIntegrationDrawerContent
          credentials={credentials}
          onSubmitSuccess={onSubmitSuccess}
        />
      </FormikDrawer>
    );
  };
