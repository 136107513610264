import { onAnalyticsReady } from '../../AnalyticsHelpers';
import { readStorageItem, writeStorageItem } from '../../LocalStorage';
import './Intercom.css';

window.intercomSettings = {
  ...window.intercomSettings,
  vertical_padding: 72,
  hide_default_launcher: true,
};

function executeCommand<C extends Intercom_.IntercomCommand>(
  command: C,
  ...params: Parameters<Intercom_.IntercomCommandSignature[C]>
) {
  onAnalyticsReady(() => {
    if (typeof Intercom !== 'undefined') {
      if (command === 'update' && typeof params[0] === 'object') {
        window.intercomSettings = {
          ...window.intercomSettings,
          ...(params[0] as typeof window.intercomSettings),
        };
      }

      Intercom(command, ...params);
    }
  });
}

const INTERCOM_TOURS = {
  load_matching_intercom_tour_view_count: {
    staging: 460772,
    production: 460363,
  },
};

export type IntercomTourKey = keyof typeof INTERCOM_TOURS;

export function launchIntercomTour(tourKey: IntercomTourKey) {
  const hasSeenTour = readStorageItem(tourKey);
  if (!hasSeenTour) {
    startIntercomTour(
      import.meta.env.VITE_TARGET === 'production'
        ? INTERCOM_TOURS[tourKey].production
        : INTERCOM_TOURS[tourKey].staging,
    );
    writeStorageItem(tourKey, 'true');
  }
}

export function updateIntercom() {
  executeCommand('update', { last_request_at: Math.floor(Date.now() / 1000) });
}

export function shutdownIntercom() {
  executeCommand('shutdown');
}

export function startIntercomTour(tourId: number): void {
  executeCommand('startTour', tourId);
}
