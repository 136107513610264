import { Tooltip } from '@material-ui/core';
import { Bookmark, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { ClickIcon } from 'orders/assets/ClickIcon';
import { usePostingStatistics } from 'orders/data/PostingStatisticsAPI';
import { ReactNode } from 'react';
import Order from 'shared/types/order';
import styled from 'styled-components';

interface PostingStatisticsProps {
  order: Order;
}

export function PostingStatistics({ order }: PostingStatisticsProps) {
  const { data, isLoading } = usePostingStatistics(order.guid);

  return (
    <Columns
      collapseBelow="tablet"
      space="small"
      data-testid="posting-statistics"
    >
      <Column>
        {order.is_posted_to_loadboard ? (
          <Stack space="xsmall">
            <Inline space="xxsmall" verticalAlign="center">
              <TextBox variant="heading-3">
                Your order is posted on Super Loadboard!
              </TextBox>
            </Inline>

            <TextBox color="secondary">
              This is how your load appears to carriers.
            </TextBox>
          </Stack>
        ) : (
          <TextBox variant="heading-3">Your order is not posted</TextBox>
        )}
      </Column>

      <Column width="content">
        <Columns space="large" align="bottom">
          <Column width="content">
            <StatsElement
              isLoading={isLoading}
              label="Search appearances"
              value={data?.search_appearance}
              icon={<Search fontSize="small" color="action" />}
              tooltipText="Number of times this posting appeared in Super Loadboard search results."
            />
          </Column>

          <Column width="content">
            <StatsElement
              isLoading={isLoading}
              label="Clicks"
              value={data?.clicks}
              icon={<ClickIcon fontSize="small" color="action" />}
              tooltipText="Number of times carriers clicked
to view details of this posting."
            />
          </Column>

          <Column width="content">
            <StatsElement
              isLoading={isLoading}
              label="Saves"
              value={data?.saves}
              icon={<Bookmark fontSize="small" color="action" />}
              tooltipText="Number of times carriers
saved this posting."
            />
          </Column>
        </Columns>
      </Column>
    </Columns>
  );
}

const StatName = styled(TextBox)`
  text-decoration: underline dashed ${Color.Silver500};
  text-underline-offset: 4px;
`;

interface StatsElementProps {
  isLoading: boolean;
  label: string;
  value?: number | null;
  icon: ReactNode;
  tooltipText: string;
}

function StatsElement({
  isLoading,
  label,
  value,
  icon,
  tooltipText,
}: StatsElementProps) {
  const screen = useResponsiveValue('mobile', 'desktop');

  if (screen === 'mobile') {
    return (
      <Inline
        space="xsmall"
        noWrap={true}
        verticalAlign="center"
        horizontalAlign="right"
        data-testid={`posting statistics ${label}`}
      >
        {icon}

        {isLoading ? (
          <Skeleton />
        ) : value ? (
          <TextBox variant="heading-2">{value}</TextBox>
        ) : (
          <TextBox variant="heading-2" color="secondary">
            -
          </TextBox>
        )}
      </Inline>
    );
  }

  return (
    <Tooltip title={tooltipText}>
      <span data-testid={`posting statistics ${label}`}>
        <Stack space="xsmall">
          <Box paddingLeft="medium">
            {isLoading ? (
              <Skeleton />
            ) : value ? (
              <TextBox variant="heading-2">{value}</TextBox>
            ) : (
              <TextBox variant="heading-2" color="secondary">
                -
              </TextBox>
            )}
          </Box>

          <Inline
            space="xsmall"
            noWrap={true}
            verticalAlign="center"
            horizontalAlign="right"
          >
            {icon}

            <StatName variant="heading-5" color="secondary">
              {label}
            </StatName>
          </Inline>
        </Stack>
      </span>
    </Tooltip>
  );
}
