import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { LoadingContainer } from '../shared/ui/LoadingContainer';
import { useShipperProfile } from '../shipper-profile/data/ShipperProfileAPI';
import { SuperLoadboardAutoRepost } from './SuperLoadboardAutoRepost';
import { SuperLoadboardHideContacts } from './SuperLoadboardHideContacts';
import { SuperLoadboardInstantBooking } from './SuperLoadboardInstantBooking';
import { SuperLoadboardPrivateNetwork } from './SuperLoadboardPrivateNetwork';

const StyledAppBar = styled(AppBar)`
  border-left: none;
  border-top: none;
`;

export function SuperLoadboardSettingsPage() {
  const { addSnackbar } = useSnackbarStack();

  const { data: profile, error } = useShipperProfile();

  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
    }
  }, [error, addSnackbar]);

  if (!profile) {
    return <LoadingContainer />;
  }

  return (
    <PageLayout
      withContentPadding={true}
      header={
        <StyledAppBar>
          <Toolbar>
            <Box marginLeft="small" marginRight="small">
              <Typography variant="h2">Super Loadboard</Typography>
            </Box>
          </Toolbar>
        </StyledAppBar>
      }
    >
      <Stack space="small">
        <SuperLoadboardInstantBooking profile={profile} />
        <SuperLoadboardHideContacts profile={profile} />
        <SuperLoadboardAutoRepost profile={profile} />
        <SuperLoadboardPrivateNetwork profile={profile} />
      </Stack>
    </PageLayout>
  );
}
