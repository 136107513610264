import { MonetizationOn } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { formatDateType } from '@superdispatch/sdk';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { RecommendedPriceIcon } from 'shared/icons/RecommendedPriceIcon';
import Order from 'shared/types/order';
import { Step } from 'shared/types/step';
import { DetailedFormattedDateRange } from 'shared/ui/DetailedFormattedDateRange';
import styled from 'styled-components';
import { OrderRequestPriceNegotiationInfo } from './OrderRequestPriceNegotationInfo';

const Wrapper = styled.div`
  margin-left: 20px;
  @media (max-width: 600px) {
    margin-left: 28px;
  }
`;

function OrderStepDate({
  step,
  stepType,
}: {
  step?: Step;
  stepType: 'Pickup' | 'Delivery';
}) {
  if (!step) return null;

  if (
    step.date_type === 'estimated' &&
    (step.scheduled_at || step.scheduled_ends_at)
  ) {
    return (
      <Inline space="xxsmall">
        <TextBox variant="body-semibold" color="secondary">
          <DetailedFormattedDateRange
            range={[step.scheduled_at, step.scheduled_ends_at]}
          />
        </TextBox>
        &#xb7;
        <TextBox color="secondary">{stepType}</TextBox>
      </Inline>
    );
  }

  if (
    step.date_type === 'not_earlier_than' ||
    step.date_type === 'not_later_than'
  ) {
    return (
      <Inline space="xxsmall">
        <TextBox variant="body-semibold" color="secondary">
          <FormattedDate
            variant="ShortDate"
            format="JodaISO"
            date={step.scheduled_at}
            fallback="N/A"
          />
        </TextBox>
        &#xb7;
        <TextBox color="secondary">{formatDateType(step.date_type)}</TextBox>
      </Inline>
    );
  }

  return step.scheduled_at ? (
    <Inline space="xxsmall">
      <TextBox variant="body-semibold" color="secondary">
        <FormattedDate
          variant="ShortDate"
          format="JodaISO"
          date={step.scheduled_at}
        />
      </TextBox>
      &#xb7;
      <TextBox color="secondary">{stepType}</TextBox>
    </Inline>
  ) : (
    <TextBox color="secondary">No Date</TextBox>
  );
}

interface OrderRequestsHeaderProps {
  order: Order;
  title: string;
  isCollapsed?: boolean;
  setCollapsed?: () => void;
  showOrderDetails?: boolean;
  collapseIcon?: React.ReactNode;
}

export function OrderRequestsHeader({
  order,
  title,
  collapseIcon,
  showOrderDetails = false,
}: OrderRequestsHeaderProps) {
  const screen = useResponsiveValue('mobile', 'mobile', 'desktop');

  if (screen === 'mobile') {
    return (
      <Box paddingTop="small" paddingLeft="small" paddingRight="small">
        <Stack space="small">
          {showOrderDetails && (
            <>
              <Stack space="xxsmall">
                <TextBox color="secondary">Order Details</TextBox>
                <Inline space="xxsmall" verticalAlign="center">
                  <CalendarIcon fontSize="small" color="action" />
                  <OrderStepDate step={order.pickup} stepType="Pickup" />
                </Inline>
                <Wrapper>
                  <OrderStepDate step={order.delivery} stepType="Delivery" />
                </Wrapper>
              </Stack>

              <Stack>
                {!!order.customer_payment?.tariff && (
                  <Inline space="xxsmall" verticalAlign="center">
                    <MonetizationOn fontSize="small" color="action" />
                    <TextBox color="secondary">
                      {formatCurrency(order.customer_payment.tariff, {
                        maximumFractionDigits: 0,
                      })}
                    </TextBox>
                    &#xb7;
                    <TextBox color="secondary">Total Tariff</TextBox>
                  </Inline>
                )}
                {!!order.price && (
                  <Wrapper>
                    <Inline space="xxsmall">
                      <TextBox variant="body-semibold" color="secondary">
                        {formatCurrency(order.price, {
                          maximumFractionDigits: 0,
                        })}
                      </TextBox>
                      &#xb7;
                      <TextBox color="secondary">Total Carrier Price</TextBox>
                    </Inline>
                  </Wrapper>
                )}

                <OrderRequestPriceNegotiationInfo order={order} />

                {!!order.recommended_price && (
                  <Inline space="xxsmall">
                    <RecommendedPriceIcon fontSize="small" color="primary" />
                    <TextBox variant="body-semibold" color="secondary">
                      {formatCurrency(order.recommended_price, {
                        maximumFractionDigits: 0,
                      })}
                    </TextBox>
                    &#xb7;
                    <TextBox color="secondary">Recommended Price</TextBox>
                  </Inline>
                )}
              </Stack>
            </>
          )}
          <Box paddingTop={showOrderDetails ? 'large' : 'xxlarge'}>
            <Inline verticalAlign="center">
              <TextBox variant="heading-3">{title}</TextBox>
              {collapseIcon}
            </Inline>
          </Box>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      paddingTop="small"
      paddingBottom="small"
      paddingLeft="large"
      paddingRight="large"
    >
      <Columns space="small" align="bottom">
        <Column width="content">
          <Box width="330px" paddingTop="xxlarge">
            <Inline verticalAlign="center">
              <TextBox variant="heading-3">{title}</TextBox>
              {collapseIcon}
            </Inline>
          </Box>
        </Column>

        {showOrderDetails && (
          <Column>
            <Columns space="small" align="top">
              <Column width="content">
                <Box width="180px">
                  <Stack>
                    <TextBox color="secondary">Order Details</TextBox>
                    <OrderStepDate step={order.pickup} stepType="Pickup" />
                    <OrderStepDate step={order.delivery} stepType="Delivery" />
                  </Stack>
                </Box>
              </Column>

              <Column>
                <Stack>
                  {!!order.customer_payment?.tariff && (
                    <Wrapper>
                      <Inline space="xxsmall" verticalAlign="center">
                        <TextBox color="secondary">
                          {formatCurrency(order.customer_payment.tariff, {
                            maximumFractionDigits: 0,
                          })}
                        </TextBox>
                        &#xb7;
                        <TextBox color="secondary">Total Tariff</TextBox>
                      </Inline>
                    </Wrapper>
                  )}

                  {!!order.price && (
                    <Wrapper>
                      <Inline space="xxsmall">
                        <TextBox variant="body-semibold" color="secondary">
                          {formatCurrency(order.price, {
                            maximumFractionDigits: 0,
                          })}
                        </TextBox>
                        &#xb7;
                        <TextBox color="secondary">Total Carrier Price</TextBox>
                      </Inline>
                    </Wrapper>
                  )}

                  <OrderRequestPriceNegotiationInfo order={order} />

                  {!!order.recommended_price && (
                    <Inline space="xxsmall">
                      <RecommendedPriceIcon fontSize="small" color="primary" />
                      <TextBox variant="body-semibold" color="secondary">
                        {formatCurrency(order.recommended_price, {
                          maximumFractionDigits: 0,
                        })}
                      </TextBox>
                      &#xb7;
                      <TextBox color="secondary">Recommended Price</TextBox>
                    </Inline>
                  )}
                </Stack>
              </Column>
            </Columns>
          </Column>
        )}
      </Columns>
    </Box>
  );
}
