import { Drawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DrawerActions, DrawerTitle } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const PerformanceDrawerContent = styled.div`
  padding: 0 32px;
`;

interface PerformanceDrawerProps {
  onClose: () => void;
  open: boolean;
  title: ReactNode;
  children: ReactNode;
}

export function PerformanceDrawer({
  onClose,
  open,
  title,
  children,
}: PerformanceDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title={title}
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <Box paddingTop="medium" paddingBottom="small" maxWidth="464px">
        {children}
      </Box>
      <DrawerActions>
        <Button onClick={onClose}>Ok</Button>
      </DrawerActions>
    </Drawer>
  );
}
