import {
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  ResponsiveProp,
  SpaceProp,
  Stack,
  Tag,
  useCollapseBreakpoint,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { OrderTag } from 'core/dictionary/OrderTag';
import { ErrorMessage, FieldArray, FormikProvider } from 'formik';
import { chunk } from 'lodash';
import { Children, ReactNode, useEffect, useMemo, useState } from 'react';
import { useCanExecute } from 'shared/data/UserPermissions';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { yupArray, yupObject } from 'shared/utils/YupUtils';
import {
  OrderTagDTO,
  OrderTagPayloadDTO,
  orderTagPayloadSchema,
  useMaxNumberOfTagsMutation,
  useOrderTagsMutation,
} from '../core/dictionary/data/OrdersTagAPI';

export interface OrderTagsValuesDTO {
  orderTags: OrderTagPayloadDTO[];
}
export const orderTagsValuesSchema = yupObject({
  orderTags: yupArray(orderTagPayloadSchema)
    .test('duplicated-tag', 'Duplicated tags are not allowed', (tags) => {
      const newTags: string[] = [];
      const activeTags: string[] = [];
      const inactiveTags: string[] = [];

      tags?.forEach((x) => {
        const arr = x.id ? (x.is_active ? activeTags : inactiveTags) : newTags;
        const name = x.name.toLowerCase();

        arr.push(name);
      });

      if (
        // 1. new -> new
        new Set(newTags).size !== newTags.length ||
        // 2. active -> active
        new Set(activeTags).size !== activeTags.length ||
        // 3. new -> active
        newTags.filter((x) => activeTags.includes(x)).length > 0
      ) {
        return false;
      }

      return true;
    })
    .ensure()
    .required(),
});

function createDefaultTag() {
  return orderTagPayloadSchema.cast({
    name: '',
    color: 'grey',
    is_active: true,
  });
}

function OrderTagsTiles({
  children,
  space,
  tagsPerColumn,
}: {
  children: ReactNode;
  space?: ResponsiveProp<SpaceProp>;
  tagsPerColumn: number;
}) {
  const childrenArray = Children.toArray(children).filter(Boolean);
  const chunks = useMemo(
    () => chunk(childrenArray, tagsPerColumn),
    [childrenArray, tagsPerColumn],
  );
  return (
    <Columns collapseBelow="desktop">
      {chunks.map((items, index) => (
        <Column key={index}>
          <Stack space={space}>{items}</Stack>
        </Column>
      ))}
    </Columns>
  );
}

function MaxNumberOfTagsForm({ value: valueProp }: { value: number }) {
  const { addSnackbar } = useSnackbarStack();
  const { mutateAsync } = useMaxNumberOfTagsMutation();

  const formik = useFormikEnhanced<{ value: number }, unknown>({
    initialValues: { value: valueProp },
    validationSchema: orderTagsValuesSchema,
    onSubmit({ value }) {
      return mutateAsync(value);
    },
    onSubmitSuccess() {
      addSnackbar('Settings updated', { variant: 'success' });
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const { handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Stack space="xxsmall">
        <Typography>Max. Tags per Order</Typography>
        <FormikTextField
          name="value"
          select={true}
          fullWidth={true}
          onChange={(_) => handleSubmit()}
        >
          <MenuItem value={1}>1 tag</MenuItem>
          <MenuItem value={2}>2 tags</MenuItem>
          <MenuItem value={3}>3 tags</MenuItem>
        </FormikTextField>
      </Stack>
    </FormikProvider>
  );
}

function OrderTagsEditForm({
  values: valuesProp,
  onCancel,
  onSubmitSuccess,
}: {
  values: OrderTagDTO[];
  onCancel: () => void;
  onSubmitSuccess: () => void;
}) {
  const { addSnackbar } = useSnackbarStack();
  const { mutateAsync } = useOrderTagsMutation();

  const [showConfirm, $showConfirm] = useState(false);

  const isCollapsed = useCollapseBreakpoint('md');

  const activeTags = useMemo(
    () => valuesProp.filter(({ is_active }) => is_active),
    [valuesProp],
  );

  const formik = useFormikEnhanced<OrderTagsValuesDTO, unknown>({
    initialValues: { orderTags: valuesProp },
    enableReinitialize: false,
    validationSchema: orderTagsValuesSchema,
    onSubmit({ orderTags }) {
      return mutateAsync(orderTags);
    },
    onSubmitSuccess() {
      onSubmitSuccess();
      addSnackbar('Order tags has been saved', { variant: 'success' });
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const { values, handleSubmit, dirty, setFieldValue, isValid } = formik;
  const indexedTags = useMemo(
    () =>
      values.orderTags
        // preserve original tag index
        .map((tag, index) => ({ tagIndex: index, tag }))
        .filter(({ tag }) => tag.is_active),
    [values.orderTags],
  );

  const deletedTags = useMemo(() => {
    const initialActiveTags = new Set(
      valuesProp.filter((x) => x.is_active).map((x) => x.id),
    );
    return values.orderTags.filter(
      (x) => x.id != null && !x.is_active && initialActiveTags.has(x.id),
    );
  }, [valuesProp, values.orderTags]);

  const reactivatedTags = useMemo(() => {
    const newTagNames = new Set(
      values.orderTags
        .filter((x) => x.is_active)
        .map((x) => x.name.toLowerCase().trim()),
    );

    // check if inactive tag names intersect newly added tag names
    return values.orderTags.filter(
      (x) => !x.is_active && newTagNames.has(String(x.name).toLowerCase()),
    ) as OrderTagDTO[];
  }, [values.orderTags]);

  function checkAndSubmit() {
    if (reactivatedTags.length > 0) {
      reactivateTags();
    }

    handleSubmit();
  }

  useEffect(() => {
    if (activeTags.length === 0) {
      setFieldValue('orderTags', [createDefaultTag()]);
    }
  }, [setFieldValue, activeTags.length]);

  function reactivateTags() {
    let orderTags = Array.from(values.orderTags);
    const duplicateIndexes: number[] = [];

    values.orderTags.forEach((tag, index: number) => {
      if (tag.is_active) {
        // checking if there duplicate index among inactive
        const duplicateIndex = orderTags.findIndex(
          (x) =>
            !x.is_active &&
            tag.name.toLowerCase().trim() === x.name.toLowerCase().trim(),
        );

        if (duplicateIndex !== -1) {
          if (!tag.id) {
            // merge tag in place of newly added
            orderTags[index] = {
              ...tag,
              id: orderTags[duplicateIndex]?.id || 0,
            };
            // mark as duplicated to filter out
            duplicateIndexes.push(duplicateIndex);
          } else {
            const duplicatedOrderTag = orderTags[duplicateIndex];
            if (duplicatedOrderTag) {
              orderTags[duplicateIndex] = {
                ...duplicatedOrderTag,
                is_active: true,
              };
            }
          }
        }
      }
    });

    orderTags = orderTags.filter(
      (_, index) => !duplicateIndexes.includes(index),
    );

    setFieldValue('orderTags', orderTags);
  }

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="orderTags"
        render={({ push, replace, remove }) => (
          <Stack>
            <OrderTagsTiles space="none" tagsPerColumn={50}>
              {indexedTags.map(({ tag, tagIndex }, index) => {
                const isFirstRow = isCollapsed ? index === 0 : index % 50 === 0;
                return (
                  <Inline key={index} verticalAlign="top" noWrap={true}>
                    <FormikTextField
                      select={true}
                      fullWidth={true}
                      style={{ minWidth: 100 }}
                      label={isFirstRow && 'Color'}
                      name={`orderTags.${tagIndex}.color`}
                    >
                      <MenuItem value="green">
                        <Tag color="green" variant="subtle">
                          Green
                        </Tag>
                      </MenuItem>
                      <MenuItem value="yellow">
                        <Tag color="yellow" variant="subtle">
                          Yellow
                        </Tag>
                      </MenuItem>
                      <MenuItem value="red">
                        <Tag color="red" variant="subtle">
                          Red
                        </Tag>
                      </MenuItem>
                      <MenuItem value="grey">
                        <Tag color="grey" variant="subtle">
                          Grey
                        </Tag>
                      </MenuItem>
                      <MenuItem value="blue">
                        <Tag color="blue" variant="subtle">
                          Blue
                        </Tag>
                      </MenuItem>
                      <MenuItem value="teal">
                        <Tag color="teal" variant="subtle">
                          Teal
                        </Tag>
                      </MenuItem>
                      <MenuItem value="purple">
                        <Tag color="purple" variant="subtle">
                          Purple
                        </Tag>
                      </MenuItem>
                    </FormikTextField>

                    <FormikTextField
                      name={`orderTags.${tagIndex}.name`}
                      fullWidth={true}
                      helperText=" " // preserve empty space for errors
                      label={isFirstRow && 'Tag Name'}
                      style={{ minWidth: 276 }}
                    />

                    <IconButton
                      size="small"
                      style={{ marginTop: isFirstRow ? 24 : undefined }}
                      onClick={() => {
                        // remove locally created tags
                        if (tag.id == null) {
                          remove(tagIndex);
                        } else if (tag.name) {
                          replace(tagIndex, { ...tag, is_active: false });
                        }
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </Inline>
                );
              })}
            </OrderTagsTiles>

            {indexedTags.length < 50 && (
              <Button
                variant="text"
                disabled={
                  indexedTags.length > 0 &&
                  // disabled if last tag is not filled
                  !indexedTags[indexedTags.length - 1]?.tag.name
                }
                onClick={() => {
                  push(createDefaultTag());
                }}
              >
                + Add Another Tag
              </Button>
            )}

            <ErrorMessage
              name="orderTags"
              render={(errorMessage) =>
                typeof errorMessage === 'string' && (
                  <Typography color="error">{errorMessage}</Typography>
                )
              }
            />

            <Inline space="small">
              <Button variant="neutral" onClick={onCancel}>
                Cancel
              </Button>

              <Button
                disabled={!dirty}
                onClick={() => {
                  if (
                    isValid &&
                    (deletedTags.length > 0 || reactivatedTags.length > 0)
                  ) {
                    $showConfirm(true);
                  } else {
                    handleSubmit();
                  }
                  trackEvent('[STMS] Clicked Order Tags "Save" button');
                }}
              >
                Save
              </Button>
            </Inline>
          </Stack>
        )}
      />

      <ConfirmDialog
        maxWidth={false}
        fullWidth={false}
        open={showConfirm}
        title="Confirm changes below?"
        cancelButtonProps={{
          children: 'Cancel',
          onClick: () => {
            $showConfirm(false);
          },
        }}
        confirmButtonProps={{
          children: 'Yes, Confirm',
          onClick: () => {
            $showConfirm(false);
            checkAndSubmit();
          },
        }}
      >
        <Stack space="small">
          {deletedTags.length > 0 && (
            <Stack>
              <Typography variant="h4">These tags will be deleted:</Typography>
              <Typography color="textSecondary" noWrap={true}>
                If these tags are assigned to orders, they will stay assigned.
              </Typography>
              <Inline>
                {deletedTags.map((tag) => (
                  <OrderTag hideIcon={false} key={tag.id} tagId={tag.id || 0} />
                ))}
              </Inline>
            </Stack>
          )}

          {reactivatedTags.length > 0 && (
            <Stack>
              <Typography variant="h4">
                These tags will be reactivated:
              </Typography>
              <Typography color="textSecondary" noWrap={true}>
                There are tags with the same name that were deactivated before.
                <br />
                If you continue, deactivated tags will be reactivated.
              </Typography>
              <Inline>
                {reactivatedTags.map((tag) => (
                  <OrderTag hideIcon={false} key={tag.id} tagId={tag.id} />
                ))}
              </Inline>
            </Stack>
          )}
        </Stack>
      </ConfirmDialog>
    </FormikProvider>
  );
}

interface OrderTagsSettingsProp {
  orderTags: OrderTagDTO[];
  maxNumberOfTags: number;
}

export function OrderTagsSettings({
  orderTags,
  maxNumberOfTags,
}: OrderTagsSettingsProp) {
  const canUpdateSettings = useCanExecute('UPDATE_COMPANY_PROFILE');
  const [isEdit, $isEdit] = useState(false);
  const activeOrderTags = useMemo(
    () => orderTags.filter((x) => x.is_active),
    [orderTags],
  );

  return (
    <Box marginTop="small" marginBottom="large">
      <Card>
        <CardContent>
          <Stack space="small">
            <Stack space="xxsmall">
              <Typography variant="h4">Order Tags</Typography>
              <Typography color="textSecondary">
                Create Order Tags that make it easier to navigate through
                orders. Order Tags are only visible to your users. You can add
                up to 50 tags.
              </Typography>
            </Stack>

            <MaxNumberOfTagsForm value={maxNumberOfTags} />

            {isEdit ? (
              <OrderTagsEditForm
                values={orderTags}
                onCancel={() => {
                  $isEdit(false);
                  trackEvent('[STMS] Clicked Order Tags "Cancel" button');
                }}
                onSubmitSuccess={() => $isEdit(false)}
              />
            ) : (
              <Stack space="small">
                <OrderTagsTiles space="small" tagsPerColumn={10}>
                  {orderTags
                    .filter((x) => x.is_active)
                    .map(({ id }) => (
                      <OrderTag key={id} tagId={id} />
                    ))}
                </OrderTagsTiles>

                {!canUpdateSettings ? null : activeOrderTags.length ? (
                  <Button
                    variant="text"
                    startIcon={<Edit fontSize="small" />}
                    onClick={() => {
                      $isEdit(true);
                    }}
                  >
                    Edit Order Tags
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    onClick={() => {
                      $isEdit(true);
                      trackEvent('[STMS] Clicked "Add Order Tag" button');
                    }}
                  >
                    + Add Order Tag
                  </Button>
                )}
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
