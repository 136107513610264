import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { AddAPhoto, Edit } from '@material-ui/icons';
import { useSnackbarStack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { OrderFormVehiclePhotoDTO } from '../data/OrderFormVehicleDTO';

const ImageWrapper = styled.div`
  width: 48px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

export const ImageCount = styled(TextBox)`
  position: absolute;
  z-index: 1;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 6px 0;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  filter: ${({ hasReducedBrightness }: { hasReducedBrightness: boolean }) =>
    hasReducedBrightness ? 'brightness(0.5)' : 'brightness(1)'};

  &:hover {
    filter: brightness(0.5);
  }
`;

interface Props {
  name: string;
}

export function VehicleSamplePhotoField({ name }: Props) {
  const { addSnackbar } = useSnackbarStack();
  const inputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [field, , { setValue }] = useField<
    OrderFormVehiclePhotoDTO[] | undefined
  >(name);
  const thumbnail = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => field.value?.find((x) => x.photo_type === 'Sample'),
    [field.value],
  );

  const photoUrl = useMemo(
    () =>
      !thumbnail
        ? null
        : thumbnail.photo_file
        ? URL.createObjectURL(thumbnail.photo_file)
        : thumbnail.photo_url,
    [thumbnail],
  );

  function hideMenu() {
    setAnchorEl(null);
  }

  function openUploadDialog() {
    inputRef.current?.click();
  }

  return (
    <div>
      {!photoUrl ? (
        <IconButton onClick={openUploadDialog}>
          <AddAPhoto color="primary" />
        </IconButton>
      ) : (
        <PhotoButton
          onClick={({ currentTarget }) => {
            setAnchorEl(currentTarget);
          }}
          thumbnailUrl={photoUrl}
          count={field.value?.length || 0}
        />
      )}

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={hideMenu}>
        <MenuItem
          onClick={() => {
            openUploadDialog();
            hideMenu();
          }}
        >
          Add Photo
        </MenuItem>
        <MenuItem
          onClick={() => {
            hideMenu();
            setValue([]);
          }}
        >
          {field.value && field.value.length > 1 ? 'Remove All' : 'Remove'}
        </MenuItem>
      </Menu>
      <input
        type="file"
        hidden={true}
        ref={inputRef}
        accept=".jpg,.jpeg,.png"
        onClick={(event) => {
          // Allow choosing same file
          // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file/54632736#54632736
          event.currentTarget.value = '';
        }}
        onChange={({ currentTarget: { files } }) => {
          const file = files?.[0];

          const maxAllowedSize = 20 * 1024 * 1024;
          if (file) {
            if (file.size > maxAllowedSize) {
              addSnackbar('The photo should be less than 20 Mb');
            } else if (field.value) {
              void setValue([
                ...field.value,
                { photo_type: 'Sample', photo_file: file },
              ]);
            } else {
              void setValue([{ photo_type: 'Sample', photo_file: file }]);
            }
          }
        }}
      />
    </div>
  );
}

function PhotoButton({
  thumbnailUrl,
  count,
  onClick,
}: {
  thumbnailUrl: string;
  count: number;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <ImageWrapper
      onClick={onClick}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
    >
      {isFocused ? (
        <ImageCount color="white">
          <Edit fontSize="small" />
        </ImageCount>
      ) : (
        count > 1 && (
          <ImageCount variant="heading-6" color="white">
            +{count - 1}
          </ImageCount>
        )
      )}

      <ThumbnailImage
        aria-label="thumbnail"
        src={thumbnailUrl}
        hasReducedBrightness={count > 1}
      />
    </ImageWrapper>
  );
}
