import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@material-ui/core';
import {
  Email,
  Error,
  Language,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useCentralDispatchCredentials } from 'core/cental-dispatch/CentralDispatchAPI';
import { CentralDispatchCredentialsDTO } from 'core/cental-dispatch/CentralDispatchCredentialsDTO';
import { ComponentType, ReactNode, useState } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import {
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useShipperProfileAPI } from 'shipper-profile/data/ShipperProfileAPI';
import styled from 'styled-components';
import { centralIntegrationFormSchema } from '../data/ShipperProfileDTO';

const WarningWrapper = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  max-width: 344px;
  background-color: ${ColorDynamic.Red50};
`;

function FieldLabel({
  children,
  subtext,
}: {
  children: ReactNode;
  subtext?: string;
}) {
  return (
    <Inline space="xxsmall">
      <TextBox>{children}</TextBox>
      {subtext && <TextBox color="secondary">{subtext}</TextBox>}
    </Inline>
  );
}

interface CentralDispatchIntegrationDrawerContentProps {
  credentials: CentralDispatchCredentialsDTO;
  onSubmitSuccess: () => void;
}

export const CentralDispatchIntegrationDrawerContent: ComponentType<CentralDispatchIntegrationDrawerContentProps> =
  ({ onSubmitSuccess }) => {
    const { addSnackbar } = useSnackbarStack();
    const { updateCentralDispatchInfo } = useShipperProfileAPI();
    const { data: credentials } = useCentralDispatchCredentials();
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const isCD2FAEnabled = useFeatureToggle('cd-integration-web-2fa.enabled');

    const formik = useFormikEnhanced({
      initialValues: {
        centraldispatch_login: credentials?.login || '',
        centraldispatch_password: '',
        centraldispatch_uid: credentials?.uid || '',
      },
      validateOnBlur: true,
      validationSchema: centralIntegrationFormSchema,
      onSubmit: (values) => {
        const payload = centralIntegrationFormSchema.cast(values, {
          stripUnknown: true,
        });
        return updateCentralDispatchInfo(payload);
      },
      onSubmitSuccess: (_, updatedValues) => {
        if (!isCD2FAEnabled) {
          addSnackbar('Integration credentials updated successfully', {
            variant: 'success',
          });
        }

        trackEvent('Integration credentials updated successfully', {
          centraldispatch_uid: updatedValues.centraldispatch_uid,
          centraldispatch_login: updatedValues.centraldispatch_login,
        });
        onSubmitSuccess();
      },
      onSubmitFailure(error) {
        addSnackbar(error.message, { variant: 'error' });
      },
    });

    const handleClickShowPassword = () => {
      setPasswordVisible(!isPasswordVisible);
    };

    const isLoginWasSaved = formik.initialValues.centraldispatch_login;

    const shouldShowWarning =
      (!!credentials?.login && !credentials.is_valid_web_credentials) ||
      (formik.status.type === 'rejected' &&
        formik.status.payload.message ===
          'Invalid Central Dispatch credentials');

    return (
      <FormikDrawerContent
        formik={formik}
        title="Connect to Central Dispatch"
        actions={
          <FormikDrawerActions isDisabled={!formik.dirty || !formik.isValid} />
        }
      >
        <Box maxWidth="368px" marginTop="-20px">
          <Stack space="large">
            <Typography color="textSecondary">
              Manage and post orders to both Super Dispatch <br /> and Central
              Dispatch conveniently from Shipper TMS.
            </Typography>

            <Stack space="xsmall">
              <Inline verticalAlign="center">
                <Language fontSize="small" htmlColor={Color.Dark100} />
                <Typography variant="h4">Web Connection</Typography>
              </Inline>

              <Box
                paddingLeft="24px"
                display="flex"
                flexDirection="column"
                gridGap="16px"
              >
                <Typography color="textSecondary">
                  Update posted loads effortlessly without reposting.
                </Typography>

                {shouldShowWarning && (
                  <WarningWrapper>
                    <Columns space="xsmall">
                      <Column width="content">
                        <Box paddingTop="2px">
                          <Error fontSize="small" htmlColor={Color.Red300} />
                        </Box>
                      </Column>
                      <Column width="adaptive">
                        <Typography>
                          Invalid Web credentials. Please check and try again.
                        </Typography>
                      </Column>
                    </Columns>
                  </WarningWrapper>
                )}
                <FormikTextField
                  name="centraldispatch_login"
                  fullWidth={true}
                  label={
                    <FieldLabel subtext="for Central Dispatch">
                      Username
                    </FieldLabel>
                  }
                />

                <FormikTextField
                  name="centraldispatch_password"
                  fullWidth={true}
                  validate={() => {
                    if (
                      formik.touched.centraldispatch_login &&
                      credentials?.login !==
                        formik.values.centraldispatch_login &&
                      !formik.values.centraldispatch_password &&
                      !formik.touched.centraldispatch_password
                    ) {
                      return 'Password is required';
                    }
                    return undefined;
                  }}
                  placeholder={
                    isLoginWasSaved && !formik.touched.centraldispatch_login
                      ? '••••••••'
                      : ''
                  }
                  label={
                    <FieldLabel subtext="for Central Dispatch">
                      Password
                    </FieldLabel>
                  }
                  type={isPasswordVisible ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {!isLoginWasSaved ||
                          formik.touched.centraldispatch_login ? (
                            isPasswordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )
                          ) : null}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>

            <Stack space="small">
              <Stack>
                <Columns align="center">
                  <Column>
                    <Inline verticalAlign="center">
                      <Email fontSize="small" htmlColor={Color.Dark100} />
                      <Typography variant="h4">Email Connection</Typography>
                    </Inline>
                  </Column>

                  <Column width="content">
                    <Link
                      color="textSecondary"
                      rel="noreferrer"
                      target="_blank"
                      href="https://support.superdispatch.com/en/articles/2121335-shipper-tms-request-and-set-up-your-central-dispatch-uid"
                    >
                      How to request UID?
                    </Link>
                  </Column>
                </Columns>
              </Stack>
              <Box
                paddingLeft="24px"
                display="flex"
                flexDirection="column"
                gridGap="16px"
              >
                <Typography color="textSecondary">
                  Works with Web Connection to enhance reliability.
                </Typography>
                <FormikTextField
                  name="centraldispatch_uid"
                  fullWidth={true}
                  label={
                    <FieldLabel subtext="from Central Dispatch">UID</FieldLabel>
                  }
                  helperText="At least 6 characters, only numbers and letters"
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </FormikDrawerContent>
    );
  };
