/*
- ETA (average: 43%): Top >80% ; Good: 55% - 80% ; Can Improve: 0-54%
- Late Cancellation(average: 1%): Top 0% ; Good 1%-10% ; Can improve: >10%
- Photos taken on-site (average: 79%): Top: >90% ; Good: 80%-90% ; Can improve: 0-79%
 */

import { useMemo } from 'react';
import { formatPlural } from '../../shared/helpers/IntlHelpers';
import { CarrierPerformanceDTO } from '../data/CarrierPerformanceDTO';

export function totalPlural(total: number | null) {
  const totalNumber = total || 0;
  return formatPlural(
    totalNumber,
    `Out of ${totalNumber} delivery`,
    `Out of ${totalNumber} deliveries`,
  );
}

export type PerformanceThreshold = 'top' | 'good' | 'can-improve';

export function etaThresholds(eta: number | null): PerformanceThreshold | null {
  if (eta == null) return null;
  if (eta > 80) return 'top';
  if (eta >= 55 && eta <= 80) return 'good';
  return 'can-improve';
}

export function lateCancellationThresholds(
  lateCancellation: number | null,
): PerformanceThreshold | null {
  if (lateCancellation == null) return null;
  if (lateCancellation < 1) return 'top';
  if (lateCancellation >= 1 && lateCancellation <= 10) return 'good';
  return 'can-improve';
}

export function photosOnSiteThresholds(
  photosTaken: number | null,
): PerformanceThreshold | null {
  if (photosTaken == null) return null;
  if (photosTaken > 90) return 'top';
  if (photosTaken >= 80 && photosTaken <= 90) return 'good';
  return 'can-improve';
}

export function calculateRate(
  finalSample: number | null,
  generalSample: number | null,
) {
  if (finalSample == null || generalSample == null) return null;
  return Math.round((finalSample / generalSample) * 100);
}

export function calculateAverageRate(
  finalSamples: number[],
  generalSample: number,
) {
  const totalRate = finalSamples.reduce(
    (acc, finalSample) =>
      acc + (calculateRate(finalSample, generalSample) || 0),
    0,
  );
  return Math.round(totalRate / finalSamples.length);
}

export function calculateETAMeanRate(indicators: CarrierPerformanceDTO) {
  const generalCount = indicators.eta_provided_general_count;
  const finalPickupCount = indicators.eta_provided_final_pickup_count;
  const finalDeliveryCount = indicators.eta_provided_final_delivery_count;

  if (!generalCount || finalPickupCount == null || finalDeliveryCount == null) {
    return null;
  }

  return calculateAverageRate(
    [finalPickupCount, finalDeliveryCount],
    generalCount,
  );
}

export function calculatePhotosOnSiteMeanRate(
  indicators: CarrierPerformanceDTO,
) {
  const generalCount = indicators.photos_on_site_general_count;
  const finalPickupCount = indicators.photos_on_site_final_pickup_count;
  const finalDeliveryCount = indicators.photos_on_site_final_delivery_count;

  if (!generalCount || finalPickupCount == null || finalDeliveryCount == null) {
    return null;
  }

  return calculateAverageRate(
    [finalPickupCount, finalDeliveryCount],
    generalCount,
  );
}

export function calculateLateCancellationMeanRate(
  indicators: CarrierPerformanceDTO,
) {
  const generalCount = indicators.late_cancellation_general_count;
  const finalCount = indicators.late_cancellation_final_count;

  if (!generalCount || finalCount == null) return null;

  return calculateRate(finalCount, generalCount);
}

interface Rates {
  meanRate: number | null;
  threshold: PerformanceThreshold | null;
}

interface StatusRates {
  pickupRate: number | null;
  deliveryRate: number | null;
}

export function useCalculateEtaRates(
  indicators: CarrierPerformanceDTO | undefined,
): Rates {
  return useMemo(() => {
    if (!indicators) return { meanRate: null, threshold: null };

    const meanRate = calculateETAMeanRate(indicators);
    const threshold = etaThresholds(meanRate);

    return { meanRate, threshold };
  }, [indicators]);
}

export function useCalculateEtaStatusRates(
  indicators: CarrierPerformanceDTO | undefined,
): StatusRates {
  return useMemo(() => {
    if (!indicators) return { pickupRate: null, deliveryRate: null };

    const pickupRate = calculateRate(
      indicators.eta_provided_final_pickup_count,
      indicators.eta_provided_general_count,
    );

    const deliveryRate = calculateRate(
      indicators.eta_provided_final_delivery_count,
      indicators.eta_provided_general_count,
    );

    return { pickupRate, deliveryRate };
  }, [indicators]);
}

export function useCalculateLateCancellationRates(
  indicators: CarrierPerformanceDTO | undefined,
): Rates {
  return useMemo(() => {
    if (!indicators) return { meanRate: null, threshold: null };

    const meanRate = calculateLateCancellationMeanRate(indicators);
    const threshold = lateCancellationThresholds(meanRate);

    return { meanRate, threshold };
  }, [indicators]);
}

export function useCalculatePhotosOnSiteRates(
  indicators: CarrierPerformanceDTO | undefined,
): Rates {
  return useMemo(() => {
    if (!indicators) return { meanRate: null, threshold: null };

    const meanRate = calculatePhotosOnSiteMeanRate(indicators);
    const threshold = photosOnSiteThresholds(meanRate);

    return { meanRate, threshold };
  }, [indicators]);
}

export function useCalculatePhotosOnSiteStatusRates(
  indicators: CarrierPerformanceDTO | undefined,
): StatusRates {
  return useMemo(() => {
    if (!indicators) return { pickupRate: null, deliveryRate: null };

    const pickupRate = calculateRate(
      indicators.photos_on_site_final_pickup_count,
      indicators.photos_on_site_general_count,
    );

    const deliveryRate = calculateRate(
      indicators.photos_on_site_final_delivery_count,
      indicators.photos_on_site_general_count,
    );

    return { pickupRate, deliveryRate };
  }, [indicators]);
}
