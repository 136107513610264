import { Typography } from '@material-ui/core';
import { ColorDynamic } from '@superdispatch/ui';
import { renderTransportTypeIcons } from 'core/TransportType';
import { useMemo } from 'react';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { DamagedIcon } from 'shared/icons/DamagedIcon';
import { InoperableVehicleIcon } from 'shared/icons/InoperableVehicleIcon';
import Order from 'shared/types/order';
import { ShipperType } from 'shared/types/user';
import { BulletTextItem } from 'shared/ui/BulletTextItem';
import { ResponsiveCopyToClipboardTooltip } from 'shared/ui/CopyToClipboardTooltip';
import styled from 'styled-components';
import { OrderVehicleStatus } from '../posting/OrderVehicleStatus';

const Wrapper = styled.div`
  display: flex;
`;

const List = styled.div`
  flex-grow: ${(props: { compactView?: boolean }) =>
    props.compactView ? '0' : '1'};
  display: ${(props: { compactView?: boolean }) =>
    props.compactView ? 'flex' : 'initial'};
  align-items: center;
`;

const More = styled.div`
  white-space: nowrap;
  color: ${ColorDynamic.Dark300};
  font-size: 12px;
  line-height: 16px;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;

  & > img {
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }
`;

export function getVehicleTitle({ year, make, model }: VehicleDTO) {
  if (!year && !make && !model) {
    return 'Processing...';
  }
  return [year, make, model].filter(Boolean).join(' ');
}

function normalizeVinText(vin: string | undefined) {
  return vin == null ? vin : String(vin).trim().toLowerCase();
}

function groupVehicles(
  vehicles: VehicleDTO[],
  vinSearchText?: string,
  showOnlyFirstVehicle?: boolean,
): [VehicleDTO[], VehicleDTO[]] {
  const index = vehicles.length > (showOnlyFirstVehicle ? 1 : 2) ? 1 : 2;

  if (vinSearchText != null) {
    const matched = vehicles.filter(
      (x) => normalizeVinText(x.vin) === normalizeVinText(vinSearchText),
    );
    const unmatched = vehicles.filter(
      (x) => normalizeVinText(x.vin) !== normalizeVinText(vinSearchText),
    );

    if (matched.length > 0) {
      const sorted = [...matched, ...unmatched];
      const matchIndex = Math.max(index, matched.length);
      return [sorted.slice(0, matchIndex), sorted.slice(matchIndex)];
    }
  }

  return [vehicles.slice(0, index), vehicles.slice(index)];
}

export interface VehiclesVinsProps {
  order: Order;
  vinSearchText?: string;
  compactView?: boolean;
}

export function OrderListVehiclesVins({
  order: { status, vehicles = [] },
  vinSearchText,
  compactView,
}: VehiclesVinsProps) {
  const [visibleVehicles, restVehicles] = useMemo(
    () => groupVehicles(vehicles, vinSearchText, compactView),
    [vinSearchText, vehicles],
  );

  return (
    <>
      <div>
        {visibleVehicles.map((vehicle) => {
          const matched =
            normalizeVinText(vehicle.vin) === normalizeVinText(vinSearchText);
          return (
            <div key={vehicle.guid} style={{ marginBottom: 4 }}>
              <BulletTextItem
                secondary={
                  matched &&
                  (status === 'accepted' || status === 'picked_up') && (
                    <OrderVehicleStatus status={vehicle.status} />
                  )
                }
                primary={
                  vehicle.vin && (
                    <ResponsiveCopyToClipboardTooltip
                      text={vehicle.vin}
                      title="Copy VIN"
                      onCopy={() =>
                        trackEvent('Shipper Copied VIN', {
                          utm_medium: 'Order List',
                        })
                      }
                    >
                      {compactView ? (
                        <Typography
                          display="inline"
                          noWrap={true}
                          style={{
                            padding: 2,
                            paddingLeft: 0,
                            backgroundColor: matched
                              ? ColorDynamic.Yellow50
                              : undefined,
                          }}
                        >
                          {vehicle.vin}
                        </Typography>
                      ) : (
                        <Typography
                          display="inline"
                          noWrap={true}
                          style={{
                            padding: 2,
                            paddingLeft: 2,
                            backgroundColor: matched
                              ? ColorDynamic.Yellow50
                              : undefined,
                          }}
                        >
                          {vehicle.vin}
                        </Typography>
                      )}
                    </ResponsiveCopyToClipboardTooltip>
                  )
                }
              />
            </div>
          );
        })}
      </div>

      {restVehicles.length > 0 && (
        <More title={restVehicles.map((vehicle) => vehicle.vin).join('\n')}>
          +{restVehicles.length} more
        </More>
      )}
    </>
  );
}

interface VehiclesLotnumbersProps {
  vehicles: VehicleDTO[] | undefined;
  vinSearchText?: string;
}

export function OrderListVehiclesLotNumbers({
  vehicles = [],
  vinSearchText,
}: VehiclesLotnumbersProps) {
  const [visibleVehicles, restVehicles] = useMemo(
    () => groupVehicles(vehicles, vinSearchText),
    [vinSearchText, vehicles],
  );

  return (
    <>
      {visibleVehicles.map((vehicle) => (
        <div key={vehicle.guid} style={{ marginBottom: 4 }}>
          <Typography>{vehicle.lot_number || 'N/A'}</Typography>
        </div>
      ))}

      {restVehicles.length > 0 && (
        <More
          title={restVehicles
            .map((vehicle) => vehicle.lot_number || 'N/A')
            .join('\n')}
        >
          +{restVehicles.length} more
        </More>
      )}
    </>
  );
}

interface VehiclesProps {
  order: Order;
  vinSearchText?: string;
  shipperType?: ShipperType;
  compactView?: boolean;
}

export function OrderListVehicles({
  order,
  shipperType,
  vinSearchText,
  compactView,
}: VehiclesProps) {
  const { vehicles = [] } = order;
  const hasDamage = vehicles.some((x) => x.is_damaged);
  const hasInoperableVehicle = vehicles.some((x) => x.is_inoperable);

  const [visibleVehicles, restVehicles] = useMemo(
    () => groupVehicles(vehicles, vinSearchText, compactView),
    [vinSearchText, vehicles],
  );

  return (
    <Wrapper>
      <List compactView={compactView}>
        {visibleVehicles.map((vehicle) => (
          <div key={vehicle.guid} style={{ marginBottom: compactView ? 0 : 4 }}>
            <ResponsiveCopyToClipboardTooltip
              text={getVehicleTitle(vehicle)}
              title="Copy Info"
              onCopy={() =>
                trackEvent('Shipper Copied Vehicle Info', {
                  utm_medium: 'Order List',
                })
              }
            >
              <Typography
                variant={compactView ? 'body1' : 'inherit'}
                noWrap={!compactView}
              >
                {getVehicleTitle(vehicle)}
              </Typography>
            </ResponsiveCopyToClipboardTooltip>
          </div>
        ))}

        {restVehicles.length > 0 && !compactView && (
          <More
            title={restVehicles.map((v) => `${getVehicleTitle(v)}`).join('\n')}
          >
            +{restVehicles.length} more
          </More>
        )}
      </List>

      <Icons>
        {renderTransportTypeIcons(order)}

        {hasInoperableVehicle && (
          <InoperableVehicleIcon aria-label="vehicle inoperable" />
        )}

        {shipperType === 'BROKER' && hasDamage && (
          <DamagedIcon aria-label="vehicle damaged" />
        )}
      </Icons>
    </Wrapper>
  );
}
