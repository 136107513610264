import { yupNumber, yupObject } from 'shared/utils/YupUtils';
import { InferType, string } from 'yup';

const validateReasonField = (fieldName: string) =>
  string().when(
    '$reasons',
    (reasons: Record<string, unknown> | undefined, schema) => {
      if (reasons && fieldName in reasons) {
        return schema.required('Please provide details');
      }
      return schema.optional();
    },
  );

export type SubmitEscalationDTO = InferType<typeof submitEscalationSchema>;

export const submitEscalationSchema = yupObject({
  order_id: yupNumber().required(),
  reasons: yupObject({
    CHARGES_DUE: string().optional(),
    VEHICLE_IS_NOT_AT_LOCATION: string().optional(),
    INCORRECT_PHONE_NUMBER: string().optional(),
    LOCATION_WILL_NOT_RELEASE: string().optional(),
    LOCATION_CHANGED: string().optional(),
    NO_RETURNED_CALLS_OR_EMAILS: string().optional(),
    OTHER: validateReasonField('OTHER'),
  }).required(),
});

export type ResolveEscalationDTO = InferType<typeof resolveEscalationSchema>;

export const resolveEscalationSchema = yupObject({
  reasons: yupObject({
    CHARGES_DUE: validateReasonField('CHARGES_DUE'),
    VEHICLE_IS_NOT_AT_LOCATION: validateReasonField(
      'VEHICLE_IS_NOT_AT_LOCATION',
    ),
    INCORRECT_PHONE_NUMBER: validateReasonField('INCORRECT_PHONE_NUMBER'),
    LOCATION_WILL_NOT_RELEASE: validateReasonField('LOCATION_WILL_NOT_RELEASE'),
    LOCATION_CHANGED: validateReasonField('LOCATION_CHANGED'),
    NO_RETURNED_CALLS_OR_EMAILS: validateReasonField(
      'NO_RETURNED_CALLS_OR_EMAILS',
    ),
    OTHER: validateReasonField('OTHER'),
  }).required(),
});
