import { Box, Link } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { ShieldCarIcon } from 'shared/icons/Icons';

export interface OrderRequestCargoInsuranceProps {
  url: string | null;
  limit: number | null;
  deductible: number | null;
  largestTrailerCapacity: number | null;
}

export function OrderRequestCargoInsurance({
  url,
  limit,
  deductible,
  largestTrailerCapacity,
}: OrderRequestCargoInsuranceProps) {
  if (!url) {
    return <OrderRequestCargoInsuranceNotProvided />;
  }

  return (
    <Columns aria-label="Cargo Insurance details" space="xxsmall">
      <Column width="content">
        <Box paddingTop="2px">
          <ShieldCarIcon fontSize="small" htmlColor={Color.Dark100} />
        </Box>
      </Column>
      <Column width="fluid">
        <Stack space="xxsmall">
          <TextBox color="secondary">
            <Link
              target="_blank"
              color="textSecondary"
              rel="noopener noreferrer"
              href={url}
              onClick={() =>
                trackEvent('Shipper Clicked Carrier’s Cargo Insurance', {
                  utm_medium: 'Carrier Requests Drawer',
                  utm_content: 'Cargo Insurance with Limit',
                })
              }
            >
              Cargo insurance
            </Link>
            {!!limit && (
              <>
                {' '}
                limit is <CargoAmount amount={limit} />
                {deductible != null && (
                  <>
                    {' '}
                    with <CargoAmount amount={deductible} /> deductible.
                  </>
                )}
              </>
            )}
          </TextBox>
          {!!largestTrailerCapacity && (
            <TextBox color="secondary">
              Largest trailer capacity is{' '}
              <TextBox>{largestTrailerCapacity} sedans</TextBox>.
            </TextBox>
          )}
        </Stack>
      </Column>
    </Columns>
  );
}

interface OrderRequestCargoInsuranceBelowMinimumProps {
  url: string | null;
  largestTrailerCapacity: number | null;
  insuranceRequirementsMet: boolean;
}

export function OrderRequestCargoInsuranceBelowMinimum({
  url,
  largestTrailerCapacity,
  insuranceRequirementsMet,
}: OrderRequestCargoInsuranceBelowMinimumProps) {
  if (!largestTrailerCapacity || insuranceRequirementsMet || !url) {
    return null;
  }

  return (
    <Inline verticalAlign="center" space="xxsmall">
      <Error htmlColor={ColorDynamic.Red500} fontSize="small" />
      <TextBox color="red">This is below your Cargo Insurance Minimum.</TextBox>
    </Inline>
  );
}

export function OrderRequestCargoInsuranceNotProvided() {
  return (
    <Columns aria-label="Cargo Insurance details" space="xxsmall">
      <Column width="content">
        <Box paddingTop="2px">
          <ShieldCarIcon fontSize="small" htmlColor={ColorDynamic.Red500} />
        </Box>
      </Column>
      <Column width="fluid">
        <TextBox color="secondary">
          Cargo insurance info is not provided.
        </TextBox>
      </Column>
    </Columns>
  );
}

interface CargoAmountProps {
  amount: number;
}

export function CargoAmount({ amount }: CargoAmountProps) {
  return (
    <TextBox color="primary">
      <Price value={amount} />
    </TextBox>
  );
}
