import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const ETAIcon = createSvgIcon(
  'ETA',
  <path
    d="M14 2L2 7.02V7.67333L6.56 9.44L8.32 14H8.97333L14 2Z"
    fill={ColorDynamic.Green500}
  />,
  { viewBox: '0 0 16 16' },
);

export const ETASystemIcon = createSvgIcon(
  'ETASystem',
  <path
    d="M5.02646 7.25996L4.94684 7.05366L4.74063 6.97377L0.527288 5.34141L11.0675 0.932101L6.65228 11.4723L5.02646 7.25996Z"
    fill={ColorDynamic.White}
    stroke={ColorDynamic.Dark100}
  />,
  { viewBox: '-2 -2 16 16' },
);
