import { Dialog, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Image, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import microDepositExample from '.././assets/micro_deposit_example.png';
import microDepositIllustration from '.././assets/micro_deposit_illustration.png';
import microDepositIllustrationDark from '.././assets/micro_deposit_illustration_dark.png';

interface MicroDepositsInitiatedDialogProps {
  open: boolean;
  onClose: () => void;
}

const StyledImg = styled.img`
  display: block;
  margin: auto;
  max-width: 400px;
`;

export function MicroDepositsInitiatedDialog({
  open,
  onClose,
}: MicroDepositsInitiatedDialogProps) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box width="480px" padding="large">
        <Typography align="right">
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Typography>
        <Stack space="large" align="center">
          <Image
            src={microDepositIllustration}
            width="133px"
            alt="Micro Deposit Illustration"
            srcDark={microDepositIllustrationDark}
          />
          <Stack space="xsmall">
            <Stack space="none" align="center">
              <Typography variant="h2">Micro-deposits Initiated.</Typography>
              <Typography variant="h2">
                Please wait 1-2 business days.
              </Typography>
            </Stack>
            <Typography>
              You should expect <b>three small transactions</b> to appear in
              your bank account.
            </Typography>
          </Stack>
          <Box maxWidth="368px">
            <StyledImg
              src={microDepositExample}
              width="100%"
              alt="micro deposit example"
            />
          </Box>
          <Button onClick={onClose} fullWidth={isMobile}>
            Got it
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
