import {
  yupBoolean,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const orderFormDecodedVehicleVenueSchema = yupObject({
  completed_at: yupString().nullable(),
});

export const decodedOrderVehicleSchema = yupObject({
  id: yupNumber().nullable(),
  vin: yupString().nullable(),
  status: yupString().nullable(),
  guid: yupString().nullable(),
  is_inoperable: yupBoolean(),
  pickup: orderFormDecodedVehicleVenueSchema,
  delivery: orderFormDecodedVehicleVenueSchema,
  make: yupString().nullable(),
  model: yupString().nullable(),
  color: yupString().nullable(),
  requires_enclosed_trailer: yupBoolean(),
  type: yupString().nullable(),
  year: yupString().nullable(),
  jtracker_id: yupNumber(),
  tariff: yupNumber().optional(),
  price: yupNumber().optional(),
  lot_number: yupString().nullable(),
  is_damaged: yupBoolean(),
  curb_weight: yupNumber(),
  curb_weight_unit: yupString().nullable(),
  length: yupString().nullable(),
  width: yupString().nullable(),
  height: yupString().nullable(),
});

export type DecodedOrderVehicleDTO = InferType<
  typeof decodedOrderVehicleSchema
>;
