import { useEffect } from 'react';
import { useCustomerSupport } from './CustomerSupport';

export function useHideSwitchButton() {
  const { hideCustomerButton, showCustomerButton } = useCustomerSupport();

  useEffect(() => {
    hideCustomerButton();
    return showCustomerButton;
  }, [hideCustomerButton, showCustomerButton]);
}

export function CustomerSupportButtonSwitcher() {
  useHideSwitchButton();
  return null;
}
