import { Button } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import { useQuery } from 'shared/helpers/RouteHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { PageLayout } from 'shared/layout/PageLayout';
import { BrokerFooter, BrokerHeader } from './BrokerLayout';
import { LoginForm } from './LoginForm';
import { LoginFormSSO } from './LoginFormSSO';
import { TermsServiceLink } from './TermsServiceLink';

export function BrokerLoginPage() {
  const [showSSO, setShowSSO] = useState(false);
  const [{ sso_login_enabled: SSOLoginEnabled }] = useQuery();
  const { showCustomerButton } = useCustomerSupport();

  useEffect(() => showCustomerButton(), [showCustomerButton]);

  return (
    <PageLayout
      contentBackground="inherit"
      header={
        <BrokerHeader
          actions={
            <Button
              variant="neutral"
              onClick={() => {
                openExternalURL('https://superdispatch.com/signup/shipper', {
                  target: '_blank',
                });
              }}
            >
              Request access
            </Button>
          }
        />
      }
      footer={<BrokerFooter />}
    >
      {showSSO ? (
        <LoginFormSSO
          title="Single Sign-On"
          waiver={<TermsServiceLink type="broker" />}
          onBackClick={() => {
            setShowSSO(false);
          }}
        />
      ) : (
        <LoginForm
          title="Shipper TMS"
          waiver={<TermsServiceLink type="broker" />}
          showSSOButton={!!SSOLoginEnabled}
          onLoginSSOClick={() => {
            setShowSSO(true);
          }}
        />
      )}
    </PageLayout>
  );
}
