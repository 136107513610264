import { Link } from '@material-ui/core';
import { Work } from '@material-ui/icons';
import { formatRelativeTime } from '@superdispatch/dates';
import { Inline, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { CarrierTags, UnverifiedTag, VerifiedTag } from 'core/CarrierTags';
import { dictionaryCarrierSchema } from 'core/dictionary/data/DictionaryDTO';
import { isCarrierInsuranceExpiresInMonth } from 'core/insurance/ExpirationDates';
import { CertificateExpirationDetails } from 'core/insurance/InsuranceExpirationInfo';
import { RatingStats } from 'core/ratings/RatingStats';
import { TotalOrdersDispatchedLink } from 'core/TotalOrdersDispatchedLink';
import { OrderRequestDTO } from 'orders/data/dto/OrderRequestDTO';
import { Link as RouterLink } from 'react-router-dom';
import Order from 'shared/types/order';
import { TextOverflow } from 'shared/ui/TextOverflow';
import {
  OrderRequestCargoInsurance,
  OrderRequestCargoInsuranceBelowMinimum,
} from './OrderRequestCargoInsurance';
import { OrderRequestSuperPayActions } from './OrderRequestSuperPayActions';

interface OrderRequestCarrierDetailsProps {
  order: Order;
  request: OrderRequestDTO;
  isSuperPay: boolean;
  setSuperPay: (checked: boolean) => void;
  isSuperPayDisabled: boolean;
}

export function OrderRequestCarrierDetails({
  order,
  request,
  isSuperPay,
  setSuperPay,
  isSuperPayDisabled,
}: OrderRequestCarrierDetailsProps) {
  const {
    created_at,
    broker_records,
    carrier_guid,
    carrier_name,
    is_super_carrier,
    carrier_phone,
    carrier_usdot,
    carrier_rating_stats,
    carrier_contact_name,
    cargo_insurance,
    cargo_limit,
    carrier_email,
    cargo_deductible,
    largest_trailer_capacity,
    insurance_requirements_met,
  } = request;

  return (
    <Stack space="xsmall">
      <Inline space="xsmall">
        <TextOverflow size={280}>
          <Link
            target="_blank"
            component={RouterLink}
            to={parseURITemplate(
              `/manage-carriers/${carrier_guid}{?utm_medium}`,
              {
                utm_medium: 'Carrier Requests Drawer',
              },
            )}
          >
            <TextBox variant="heading-5">{carrier_name}</TextBox>
          </Link>
        </TextOverflow>

        {is_super_carrier ? <VerifiedTag /> : <UnverifiedTag />}
        <RatingStats
          source="Carrier Requests Drawer"
          ratingStats={carrier_rating_stats}
          carrierGuid={carrier_guid}
        />
      </Inline>

      <Inline space="xxsmall">
        <TextBox color="secondary">{carrier_contact_name}</TextBox>
        &#xb7;
        <Link href={`tel:${carrier_phone}`}>
          <TextBox color="secondary">{carrier_phone}</TextBox>
        </Link>
      </Inline>

      {broker_records && (
        <CarrierTags carrierBrokerPreferences={broker_records} />
      )}

      {!broker_records?.approved &&
        !broker_records?.insurance_certificate_holder && (
          <OrderRequestCargoInsuranceBelowMinimum
            url={cargo_insurance}
            largestTrailerCapacity={largest_trailer_capacity}
            insuranceRequirementsMet={!!insurance_requirements_met}
          />
        )}

      {!broker_records?.approved &&
        !broker_records?.insurance_certificate_holder && (
          <OrderRequestCargoInsurance
            url={cargo_insurance}
            limit={cargo_limit}
            deductible={cargo_deductible}
            largestTrailerCapacity={largest_trailer_capacity}
          />
        )}

      {isCarrierInsuranceExpiresInMonth(
        broker_records?.insurance_expires_at,
      ) && (
        <CertificateExpirationDetails
          insuranceExpiresAt={broker_records?.insurance_expires_at}
          certificateFileURl={
            broker_records?.insurance_cert_holder_file_url || null
          }
        />
      )}

      {!!broker_records?.accepted_loads_count && (
        <Inline space="xsmall" verticalAlign="center">
          <Work fontSize="small" color="action" />
          <TotalOrdersDispatchedLink
            carrier={dictionaryCarrierSchema.cast({
              guid: carrier_guid,
              name: carrier_name,
              usdot_number: carrier_usdot,
              phone: carrier_phone,
              email: carrier_email,
            })}
            source="load_offer_drawer"
            loadsCount={broker_records.accepted_loads_count}
          />
        </Inline>
      )}

      <OrderRequestSuperPayActions
        isDisabled={isSuperPayDisabled}
        request={request}
        order={order}
        isSuperPay={isSuperPay}
        setSuperPay={setSuperPay}
      />
      <TextBox color="secondary" variant="caption">
        Requested {formatRelativeTime(created_at)}
      </TextBox>
    </Stack>
  );
}
