import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { FormikProvider, FormikValues } from 'formik';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { ReactNode, useCallback, useEffect } from 'react';

const verificationDialogFormStateAtom = atom({
  dirty: false,
  isSubmitted: false,
});

export function useShowVerificationDialogCloseConfirmation<
  TValues extends FormikValues,
>(formik: FormikContextTypeEnhanced<TValues, Response>) {
  const setCloseConfirmation = useSetAtom(verificationDialogFormStateAtom);
  const { dirty, status } = formik;
  const isSubmitted = status.type === 'submitted';

  useEffect(() => {
    setCloseConfirmation({ dirty, isSubmitted });
  }, [dirty, isSubmitted, setCloseConfirmation]);
}

export function useHandleCloseConfirmation(onClose: () => void) {
  const { isSubmitted, dirty } = useAtomValue(verificationDialogFormStateAtom);

  return useCallback(() => {
    if (dirty && !isSubmitted) {
      if (
        window.confirm(
          'Changes have not been saved. Leaving will result in unsaved changes being lost.',
        )
      ) {
        onClose();
      }
    } else {
      onClose();
    }
  }, [dirty, isSubmitted, onClose]);
}

interface VerificationFormikProviderProps<TValues extends FormikValues> {
  value: FormikContextTypeEnhanced<TValues, Response>;
  children: ReactNode;
}

export function VerificationFormikProvider<TValues>({
  value,
  children,
}: VerificationFormikProviderProps<TValues>) {
  useShowVerificationDialogCloseConfirmation(value);
  return <FormikProvider value={value}>{children}</FormikProvider>;
}
