import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const CheckIconRounded = createSvgIcon(
  'CheckIconRounded',
  <>
    <circle cx="16" cy="16" r="16" fill={ColorDynamic.Green50} />
    <path
      d="M9.46697 15.5578L9.29226 15.3859L9.11695 15.5572L7.82531 16.8189L7.64288 16.9971L7.82467 17.176L12.9094 22.1782L13.0847 22.3507L13.26 22.1782L24.1753 11.44L24.3565 11.2617L24.1753 11.0835L22.8928 9.82178L22.7175 9.64938L22.5422 9.8217L13.0848 19.1169L9.46697 15.5578Z"
      fill={ColorDynamic.Green300}
      stroke="white"
      strokeWidth="0.5"
    />
  </>,
  {
    viewBox: '0 0 32 32',
    'aria-label': 'check icon',
  },
);
