import { Divider, Grid } from '@material-ui/core';
import { isSuperpayPayment } from 'core/PaymentUtils';
import { PropsWithChildren } from 'react';
import { CARRIER_APP_HOST } from 'shared/config/ServerConstants';
import Order from 'shared/types/order';
import { CurrentUser } from 'shared/types/user';

export function showCancelOffer(order: Order) {
  // for Payment Initiated with payment_scheduled or paid status.
  const superpayStatus = order.payment?.super_pay?.status;
  if (superpayStatus === 'PAYMENT_SCHEDULED' || superpayStatus === 'PAID') {
    return false;
  }

  return (
    // pending order may have multiple pending offers but not active offer
    order.status === 'pending' ||
    (order.active_offer?.is_active &&
      !order.active_offer.is_status_changed_manually &&
      !['new', 'canceled', 'declined'].includes(order.status))
  );
}

export function showCancelOrderAction(order: Order) {
  if (order.status === 'pending') {
    return false;
  }
  return (
    order.status === 'new' ||
    order.status === 'canceled' ||
    order.status === 'declined' ||
    order.is_posted_to_centraldispatch ||
    order.is_posted_to_loadboard
  );
}

export function showBuildLoads(order: Order) {
  return (
    ['new', 'canceled', 'order_canceled', 'declined'].includes(order.status) &&
    !order.is_on_hold &&
    !order.is_posted_to_loadboard &&
    !order.is_posted_to_centraldispatch &&
    !!order.vehicles?.length
  );
}

export function showSplitOrderAction(order: Order) {
  return (
    ['new', 'canceled', 'order_canceled'].includes(order.status) &&
    !order.is_on_hold &&
    !order.is_posted_to_loadboard &&
    !order.is_posted_to_centraldispatch &&
    order.vehicles &&
    order.vehicles.length > 1
  );
}

export function showSplitOrderIntoLoadsAction(order: Order) {
  return (
    ['new', 'canceled', 'order_canceled'].includes(order.status) &&
    !order.is_on_hold &&
    !order.is_posted_to_loadboard &&
    !order.is_posted_to_centraldispatch &&
    order.vehicles &&
    order.vehicles.length > 1
  );
}

export function showMarkAsNew(order: Order) {
  if (order.is_on_hold || order.status === 'order_canceled') {
    return true;
  }

  return (
    !!order.active_offer &&
    order.active_offer.is_status_changed_manually &&
    ['pending', 'accepted', 'picked_up', 'delivered'].includes(order.status)
  );
}

export function showMarkAsAccepted({
  active_offer,
  status,
  has_loads,
  is_load,
  payment,
}: Order): boolean {
  const isParentOrder = has_loads && !is_load;

  if (isParentOrder || isSuperpayPayment(payment)) {
    return false;
  }

  if (
    !!active_offer?.is_status_changed_manually &&
    ['picked_up', 'delivered'].includes(status)
  ) {
    return true;
  }

  return ['new', 'canceled', 'declined', 'pending', 'order_canceled'].includes(
    status,
  );
}

export function showMarkAsPending({
  status,
  active_offer,
  has_loads,
  is_load,
  payment,
}: Order): boolean {
  const allowedStatuses: Array<Order['status']> = [
    'new',
    'canceled',
    'declined',
    'order_canceled',
  ];

  const allowedManualStatuses: Array<Order['status']> = [
    'accepted',
    'picked_up',
    'delivered',
  ];

  const isParentOrder = has_loads && !is_load;

  if (isParentOrder || isSuperpayPayment(payment)) {
    return false;
  }

  return (
    allowedStatuses.includes(status) ||
    (!!active_offer?.is_status_changed_manually &&
      allowedManualStatuses.includes(status))
  );
}

export function canSubmitOrder(user: CurrentUser | undefined): boolean {
  return (
    user?.linked_brokers?.length === 1 &&
    !!user.linked_brokers[0]?.is_order_submit_allowed
  );
}

export function showSubmitToBroker(
  order: Order,
  user: CurrentUser | undefined,
): boolean {
  return order.status === 'new' && canSubmitOrder(user);
}

export function showMarkAsOnHold(order: Order): boolean {
  const availableStatuses: Array<Order['status']> = [
    'new',
    'canceled',
    'declined',
  ];

  if (order.is_on_hold || order.status === 'order_canceled') {
    return false;
  }

  return (
    availableStatuses.includes(order.status) ||
    order.is_posted_to_centraldispatch ||
    order.is_posted_to_loadboard
  );
}

export function getInvoiceUrl(
  order: Order,
  customCarrierInvoiceEnabled: boolean,
) {
  if (order.invoice?.url) {
    const isNotShipperToBroker =
      !order.active_offer?.carrier_type ||
      (order.active_offer.carrier_type !== 'BROKER' &&
        order.active_offer.carrier_type !== 'INTERNAL');

    return isNotShipperToBroker &&
      customCarrierInvoiceEnabled &&
      order.active_offer?.guid &&
      ['paid', 'invoiced'].includes(order.status)
      ? `${CARRIER_APP_HOST}/dashboard/orders/${
          order.active_offer.guid
        }/shipper-invoice-pdf/?po_number=${order.purchase_order_number || ''}`
      : order.invoice.url;
  }

  if (['delivered', 'paid', 'invoiced'].includes(order.status)) {
    return order.invoice?.url;
  }

  return undefined;
}

export function getCustomerInvoiceUrl(order: Order) {
  if (['delivered', 'paid', 'invoiced'].includes(order.status)) {
    return `/orders/preview/customer_invoice/${order.id}`;
  }

  return undefined;
}

export function showEditAction(order: Order, user: CurrentUser | undefined) {
  return (
    user?.shipper.shipper_type === 'BROKER' ||
    (canSubmitOrder(user) && order.status === 'new')
  );
}

export function showRemoveOrder(order: Order, user: CurrentUser | undefined) {
  return (
    user?.shipper.shipper_type === 'BROKER' ||
    (canSubmitOrder(user) && order.status === 'new')
  );
}

export function showRollbackAction(order: Order, isSuperPay = false) {
  const isShowRollBackActionDefault =
    (order.status === 'accepted' ||
      order.status === 'picked_up' ||
      order.status === 'delivered') &&
    order.is_status_changed_manually &&
    (order.active_offer?.is_status_changed_manually === false ||
      order.has_loads);

  if (!isShowRollBackActionDefault) {
    return isShowRollBackActionDefault;
  }

  if (isSuperPay && order.status === 'delivered') {
    return order.payment?.super_pay?.status !== 'PAYMENT_SCHEDULED';
  }

  return isShowRollBackActionDefault;
}

export function showMarkAsCarrierInvoiced(order: Order) {
  return (
    (order.status === 'delivered' || order.status === 'delivery_verified') &&
    !order.invoice?.adjusted_invoice_date
  );
}

export function showUnmarkAsCarrierInvoiced(order: Order) {
  return (
    (order.status === 'invoiced' || order.status === 'delivery_verified') &&
    !!order.invoice?.adjusted_invoice_date
  );
}

export function getBOLUrl(
  order: Order,
  user: CurrentUser | undefined,
): string | undefined {
  if (order.pdf_bol_url_with_template) {
    return order.pdf_bol_url_with_template;
  }
  if (order.pdf_bol_url) {
    return order.pdf_bol_url;
  }
  if (user?.shipper.shipper_type === 'BROKER') {
    return `/orders/preview/bol/${order.id}`;
  }
  return undefined;
}

export function getOnlineBOLUrl(order: Order, utmMedium = 'View Online BOL') {
  return (
    order.online_bol_url &&
    `${order.online_bol_url}?utm_source=Web STMS&utm_medium=${utmMedium}&source=stms`
  );
}

export function showOnlineBOL(order: Order) {
  const statuses: Array<Order['status']> = [
    'picked_up',
    'delivered',
    'delivery_verified',
    'invoiced',
    'paid',
  ];

  return !!order.online_bol_url && statuses.includes(order.status);
}

export function isBOLSyncing(order: Order): boolean {
  const isShipper2BrokerFlow = order.active_offer?.carrier_type === 'BROKER';
  const hasFiles = order.pdf_bol_url_with_template || order.pdf_bol_url;

  if (!hasFiles && isShipper2BrokerFlow) {
    return false;
  }

  return (
    (!order.pdf_bol_url_with_template || !order.pdf_bol_url) &&
    !order.active_offer?.is_status_changed_manually &&
    !!order.active_offer?.status &&
    ['picked_up', 'delivered', 'invoiced', 'paid'].includes(
      order.active_offer.status,
    )
  );
}

export function isDeliveryReceiptUrlSyncing(order: Order): boolean {
  return (
    !order.pdf_delivery_receipt_url &&
    !!order.active_offer &&
    !order.active_offer.is_status_changed_manually &&
    order.active_offer.status === 'delivered'
  );
}

export function showSendOffer(
  order: Order,
  user: CurrentUser | undefined,
): boolean {
  const availableStatusesForSendOffer = [
    'new',
    'declined',
    'canceled',
    'order_canceled',
  ];

  const allowedStatus =
    availableStatusesForSendOffer.includes(order.status) ||
    (order.status === 'pending' && order.active_offer?.is_counter_offer);

  const isParentOrder = order.has_loads && !order.is_load;

  return (
    user?.shipper.shipper_type === 'BROKER' &&
    !order.is_archived &&
    !!order.is_active &&
    !!allowedStatus &&
    !isParentOrder
  );
}

export function BOLSyncText({ children }: PropsWithChildren<unknown>) {
  return (
    <div style={{ flex: 1 }}>
      <Grid
        container={true}
        justifyContent="space-between"
        spacing={1}
        wrap="nowrap"
      >
        <Grid item={true}>{children}</Grid> <Grid item={true}>Syncing…</Grid>
      </Grid>
    </div>
  );
}

export function isPrimaryAction(type: string) {
  return [
    'send_offer',
    'post_to_cd',
    'post_to_sdlb',
    'post_to_all',
    'unpost_from_cd',
    'unpost_from_sdlb',
    'unpost_from_all',
  ].includes(type);
}
export function ActionDivider() {
  return <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />;
}

export function isNewOrderWithLoads(order: Order) {
  return order.status === 'new' && order.has_loads;
}
