import { MapboxGeocoding } from 'shared/helpers/MapboxHelpers';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type DictionaryPlaceKey = 'pickup_addresses' | 'delivery_addresses';
export type DictionaryStringKey =
  | 'statuses'
  | 'tags'
  | 'payment_statuses'
  | 'payment_methods'
  | 'customer_names'
  | 'dispatcher_names'
  | 'salespersons'
  | 'lot_numbers'
  | 'contact_names'
  | 'delivered_on_date'
  | 'is_superpay_available_carrier'
  | 'is_carrier_requested_superpay'
  | 'is_expedited_payment';
export type DictionaryCarriersKey = 'carriers';

export type DictionaryKey =
  | DictionaryPlaceKey
  | DictionaryStringKey
  | DictionaryCarriersKey;

export type DictionaryValues = {
  [key in DictionaryStringKey]?: string[];
} & {
  [key in DictionaryPlaceKey]?: DictionaryPlaceDTO[];
} & {
  [key in DictionaryCarriersKey]?: DictionaryCarrierDTO[];
};

export type DictionaryPlaceDTO = InferType<typeof dictionaryPlaceSchema>;

export const dictionaryPlaceSchema = yupObject({
  place_id: yupString(),
  route: yupString(),
  street_number: yupString(),
  street_address: yupString(),
  zip: yupString(),
  city: yupString(),
  state: yupString(),
  country: yupString(),
});

export function buildDictionaryPlaceFromGeocide({
  postcode,
  region_short,
  country_short,
  place,
}: MapboxGeocoding) {
  return dictionaryPlaceSchema.cast({
    zip: postcode,
    city: place,
    state: region_short?.toUpperCase(),
    country: country_short?.toUpperCase(),
  });
}

export type DictionaryCarrierDTO = InferType<typeof dictionaryCarrierSchema>;

export const dictionaryCarrierSchema = yupObject({
  name: yupString(),
  guid: yupString(),
  usdot_number: yupString().nullable(),
  phone: yupString().nullable(),
  email: yupString().nullable(),
});

const dictionaryFormatMap: Record<DictionaryKey, string> = {
  statuses: 'Status',
  carriers: 'Carrier',
  customer_names: 'Customer',
  dispatcher_names: 'Dispatcher',
  salespersons: 'Salesperson',
  lot_numbers: 'Lot Number',
  pickup_addresses: 'Pickup',
  delivery_addresses: 'Delivery',
  delivered_on_date: 'Delivered On',
  contact_names: 'Contact',
  payment_statuses: 'Payment Status',
  payment_methods: 'SuperPay',
  tags: 'Tags',
  is_superpay_available_carrier: 'SuperPay Carriers',
  is_carrier_requested_superpay: 'SuperPay Requested',
  is_expedited_payment: 'Expedited Payment',
};

const multipleDictionaryFormatMap: Record<DictionaryKey, string> = {
  statuses: 'statuses',
  carriers: 'carriers',
  customer_names: 'customers',
  dispatcher_names: 'dispatchers',
  salespersons: 'salespersons',
  lot_numbers: 'lot numbers',
  pickup_addresses: 'pickups',
  delivery_addresses: 'deliveries',
  delivered_on_date: 'delivered on',
  contact_names: 'contacts',
  payment_statuses: 'payment statuses',
  payment_methods: 'SuperPay',
  tags: 'tags',
  is_superpay_available_carrier: 'SuperPay Carriers',
  is_carrier_requested_superpay: 'SuperPay Requested',
  is_expedited_payment: 'Expedited Payment',
};

export function formatDictionary(type: DictionaryKey): string {
  return dictionaryFormatMap[type];
}

export function formatMultipleDictionaryValues(type: DictionaryKey): string {
  return multipleDictionaryFormatMap[type];
}
