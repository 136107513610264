import { listCustomerTypes } from '@superdispatch/sdk';
import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useRecentSelections } from 'shared/helpers/RecentSelectionsHelpers';
import { InspectionType, Order } from 'shared/types/order';
import { joinStrings } from 'shared/utils/StringUtils';
import { useGeneralSettings } from '../../../general-settings/data/GeneralSettingsAPI';
import { useShipperProfile } from '../../../shipper-profile/data/ShipperProfileAPI';
import { OrderFormVehicleDTO } from './data/OrderFormVehicleDTO';
import {
  createContact,
  getInspectionTypeInitialValue,
  getPaymentInitialValue,
  getStepInitialValue,
} from './helpers/orderFormHelpers';
import { OrderFormValues } from './OrderForm';

/**
    Custom hook to get initial values for order form
  @param order - order object
  @param source - source of form
  @returns {initialValues} - initial values for order form
 */
export const useOrderFormInitialValues = (
  order: Order,
  source: 'order_edit_page' | 'order_create_page',
  isPaymentMethodTermsAdoptionEnabled?: boolean,
): { initialValues: OrderFormValues } => {
  const { user } = useUserState();
  const { data: settings } = useGeneralSettings();
  const { data: shipper } = useShipperProfile({ refetchOnWindowFocus: false });
  const recentSelections = useRecentSelections();
  const isOrderCreate = source === 'order_create_page';
  const defaultInspectionType = settings?.inspection_type as
    | InspectionType
    | undefined;

  const initialValues = useMemo(() => {
    const {
      attachments,
      customer,
      customer_payment,
      payment,
      pickup,
      delivery,
      vehicles,
      dispatcher_name,
      tags,
      price,
      transport_type,
      inspection_type,
      ...rest
    } = order;

    return {
      ...rest,
      price,
      attachments: attachments || [],
      transport_type: transport_type || 'OPEN',
      customer_payment: customer_payment || {
        terms: recentSelections.customer_payment_terms,
      },
      payment:
        payment ||
        getPaymentInitialValue(
          shipper?.default_payment_terms,
          shipper?.default_payment_method,
          isPaymentMethodTermsAdoptionEnabled,
        ),
      dispatcher_name:
        isOrderCreate && (user?.first_name || user?.last_name)
          ? joinStrings(' ', user.first_name, user.last_name)
          : dispatcher_name,
      inspection_type: isOrderCreate
        ? getInspectionTypeInitialValue(
            defaultInspectionType,
            shipper?.default_payment_method,
          )
        : inspection_type,
      customer:
        !customer && user?.order_form_settings.customer.is_business_type_visible
          ? {
              business_type: listCustomerTypes()[0],
              contact: createContact(customer),
            }
          : { ...customer, contact: createContact(customer) },
      pickup: getStepInitialValue(
        pickup,
        recentSelections.pickup_date_type,
        isOrderCreate,
        !!user?.order_form_settings.pickup.venue.is_business_type_visible,
      ),
      delivery: getStepInitialValue(
        delivery,
        recentSelections.delivery_date_type,
        isOrderCreate,
        !!user?.order_form_settings.delivery.venue.is_business_type_visible,
      ),
      vehicles: vehicles
        ? vehicles.map(OrderFormVehicleDTO.create)
        : [OrderFormVehicleDTO.create({ key: -1, type: 'other' })],
      tags: tags ?? [],
      can_be_instantly_booked: order.can_be_instantly_booked ?? true,
    };
  }, [
    order,
    isOrderCreate,
    user?.first_name,
    user?.last_name,
    recentSelections,
    defaultInspectionType,
    shipper?.default_payment_terms,
    shipper?.default_payment_method,
    user?.order_form_settings.customer.is_business_type_visible,
    user?.order_form_settings.pickup.venue.is_business_type_visible,
    user?.order_form_settings.delivery.venue.is_business_type_visible,
  ]);

  return { initialValues };
};
