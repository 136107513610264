import { Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { SourceManager } from 'shared/helpers/SourceManager';
import { Order } from 'shared/types/order';
import { PricingInsightsDrawer } from './PricingInsightsDrawer';

interface PricingInsightsButtonWithDrawerProps {
  isDrawerOpen?: boolean;
  onCloseDrawer?: () => void;
  order: Partial<Order>;
}

export function PricingInsightsButtonWithDrawer({
  order,
  isDrawerOpen,
  onCloseDrawer,
}: PricingInsightsButtonWithDrawerProps) {
  const [isOpen, setIsOpen] = useState(false);

  const isButtonDisabled =
    !(order.pickup?.venue?.city && order.pickup.venue.state) ||
    !(order.delivery?.venue?.city && order.delivery.venue.state);

  return (
    <SourceManager secondarySource="Pricing Insights Button">
      <Button
        variant="neutral"
        disabled={isButtonDisabled}
        onClick={() => {
          setIsOpen(true);
        }}
        data-intercom-target={!isButtonDisabled && 'pricing-data'}
      >
        Pricing Insights
      </Button>

      <PricingInsightsDrawer
        open={isDrawerOpen || isOpen}
        order={order}
        onClose={() => {
          onCloseDrawer?.();
          setIsOpen(false);
        }}
      />
    </SourceManager>
  );
}
