import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Column, Columns, useResponsiveValue, useUID } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { ExitTransitionPlaceholder } from 'shared/ui/ExitTransitionPlaceholder';
import styled from 'styled-components';

const VerifyShipperDialog = styled(Dialog)<{ hasMargins: boolean }>`
  & .MuiDialog-paperWidthSm {
    max-width: 1480px;
    max-height: 880px;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    transition: width 0.3s, height 0.3s;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      max-width: 100%;
      max-height: 100%;
      width: ${({ hasMargins }) => (hasMargins ? 'calc(100% - 32px)' : '100%')};
      height: ${({ hasMargins }) =>
        hasMargins ? 'calc(100% - 32px)' : '100%'};
      margin: 0;
    }
  }
`;

const VerificationContainer = styled.div`
  display: flex;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
  }
`;

const VerificationContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

interface VerificationOverlayProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  fullWidth?: boolean;
  sidebar?: ReactNode;
  showHeader?: boolean;
}

export function VerificationOverlay({
  open,
  onClose,
  fullWidth = false,
  sidebar,
  showHeader = true,
  children,
}: VerificationOverlayProps) {
  const uid = useUID();
  const isMobile = useResponsiveValue(true, false);
  return (
    <VerifyShipperDialog
      aria-labelledby={uid}
      open={open}
      onClose={onClose}
      fullWidth={true}
      hasMargins={!fullWidth}
    >
      <ExitTransitionPlaceholder in={open}>
        {open && (
          <VerificationContainer>
            {isMobile && showHeader && (
              <VerificationOverlayHeader
                title="Verification Application"
                id={uid}
                onClose={onClose}
              />
            )}
            {sidebar}
            <VerificationContent>
              {!isMobile && showHeader && (
                <VerificationOverlayHeader
                  title="Verification Application"
                  id={uid}
                  onClose={onClose}
                />
              )}
              {children}
            </VerificationContent>
          </VerificationContainer>
        )}
      </ExitTransitionPlaceholder>
    </VerifyShipperDialog>
  );
}

interface VerificationApplicationDialogContentProps {
  title: string;
  id?: string;
  onClose?: () => void;
}

export function VerificationOverlayHeader({
  title,
  onClose,
  id,
}: VerificationApplicationDialogContentProps) {
  return (
    <AppBar>
      <Toolbar>
        <Columns align="center">
          <Column width="fluid">
            <Typography id={id} variant="h2">
              {title}
            </Typography>
          </Column>
          {onClose && (
            <Column width="content">
              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            </Column>
          )}
        </Columns>
      </Toolbar>
    </AppBar>
  );
}
