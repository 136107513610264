import { Link } from '@material-ui/core';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useSingleOrderActionAPI } from 'orders/data/OrderActionAPI';
import { useOrderCache } from 'orders/data/OrderAPI';
import { useAPIMutation } from 'shared/api/APIMutation';
import { APIError, isAPIValidationError } from 'shared/errors/APIError';
import { APIValidationErrorMessage } from 'shared/errors/APIValidationErrorMessage';

export function usePostToSuperLoadboard({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (failureError: APIError) => void;
} = {}) {
  const { invalidateOrder } = useOrderCache();
  const { postToLoadboards } = useSingleOrderActionAPI();

  const { addSnackbar } = useSnackbarStack();

  const { mutate, isLoading } = useAPIMutation<{ orderGuid: string }>(
    ({ orderGuid }) => postToLoadboards(orderGuid, ['sdlb']),
    {
      onSuccess: (_, { orderGuid }) => {
        addSnackbar('Posted to Super Loadboard', { variant: 'success' });
        void invalidateOrder(orderGuid);
        onSuccess?.();
      },
      onError: (failureError, { orderGuid }) => {
        if (isAPIValidationError(failureError)) {
          addSnackbar(
            <Stack>
              The following information is missing. Edit the order and provide
              this information:
              <APIValidationErrorMessage error={failureError} />{' '}
              <Link
                style={{ textDecoration: 'none' }}
                href={`/orders/edit/${orderGuid}`}
              >
                <Button size="small" variant="neutral">
                  Edit Order
                </Button>
              </Link>
            </Stack>,
            {
              variant: 'error',
            },
          );
        }

        onError?.(failureError);
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
}
