import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import { Image, Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import unverifiedCarrierWarningDark from '../../assets/unverified-warning-dark.svg';
import unverifiedCarrierWarning from '../../assets/unverified-warning.svg';
const CenteredDialogActions = styled(DialogActions)`
  justify-content: center;
`;

interface UnverifiedCarrierWarningDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export function UnverifiedCarrierWarningDialog({
  open,
  onClose,
  onContinue,
}: UnverifiedCarrierWarningDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        <Stack align="center" space="small">
          <Image
            src={unverifiedCarrierWarning}
            srcDark={unverifiedCarrierWarningDark}
            alt="Unverified carrier warning"
          />
          <Typography variant="h3">This Carrier is Unverified</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack align="center" space="small">
          <Typography align="center">
            You are sending an offer to a carrier who is not verified on Super
            Dispatch. We recommend taking additional steps to vet this carrier’s
            legal documents.
          </Typography>

          <Link
            rel="noreferrer"
            target="_blank"
            href="https://support.superdispatch.com/hc/articles/36043726330899-How-to-Check-a-Carrier-s-Verified-Carrier-Status-in-Shipper-TMS"
          >
            Learn more
          </Link>
        </Stack>
      </DialogContent>

      <CenteredDialogActions>
        <Inline space="small" horizontalAlign="center">
          <Button variant="neutral" onClick={onContinue}>
            I Understand the Risks
          </Button>
          <Button variant="primary" onClick={onClose}>
            Cancel
          </Button>
        </Inline>
      </CenteredDialogActions>
    </Dialog>
  );
}
